import { useEffect, useState } from 'react';
import { Modal, Form, message, Tabs, Button } from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import { useTranslation } from 'component/useTranslation';
import {
  actSetModalEmployeeSettingVisible,
  actSetSelectedEmployeeSetting,
  actCreateEmployee,
  actUpdateEmployeeInfo,
  actGetEmployeeSetting
} from 'redux/action/employeeSetting';
import {
  actCreateEmployeeSkillSet,
  actDeleteEmployeeSkillSet
} from 'redux/action/employeeSkillSet';

import ListSchedule from './ListSchedule';
import EmployeeForm from './EmployeeForm';
import ListAntiPattern from './ListAntiPattern';

const { TabPane } = Tabs;

const TAB_NAME = {
  INFO: 'INFO',
  SCHEDULE: 'SCHEDULE',
  ANTI_PATTERN: 'ANTI_PATTERN'
};

const ModalEmployeeSetting = (props) => {
  const {
    modalVisible,
    selectedEmployeeSetting,
    listContract,
    tenantId,
    toggleStatus
  } = props;

  const [activeChecked, setActiveChecked] = useState();
  const [activeTab, setActiveTab] = useState(TAB_NAME.INFO);

  const [form] = Form.useForm();
  const { translate } = useTranslation();

  const isCreateMode = !selectedEmployeeSetting?.id;

  useEffect(() => {
    if (isCreateMode) {
      setActiveChecked(true);
    } else {
      setActiveChecked(selectedEmployeeSetting.is_active);
    }
  }, [selectedEmployeeSetting]);

  useEffect(() => {
    if (isCreateMode) {
      setActiveTab(TAB_NAME.INFO);
    }
  }, [isCreateMode]);

  useEffect(() => {
    const {
      name,
      contractName,
      team,
      rank,
      skills = [],
      email,
      mobile,
      rrules,
      is_active,
      employee_anti_pattern
    } = selectedEmployeeSetting;

    form.setFieldsValue({
      name,
      contractName,
      team,
      rank,
      skills: skills.map((skill) => skill.id),
      email,
      mobile,
      rrules,
      is_active,
      employee_anti_pattern
    });
  }, [selectedEmployeeSetting]);

  const onHideModal = () => {
    props.actSetModalEmployeeSettingVisible(false);
    props.actSetSelectedEmployeeSetting({});
  };

  const onSubmitForm = async () => {
    try {
      const {
        name,
        contractName,
        team,
        rank,
        skills,
        email,
        mobile,
        rrules,
        employee_anti_pattern
      } = await form.validateFields([
        'name',
        'contractName',
        'team',
        'rank',
        'skills',
        'email',
        'mobile',
        'rrules',
        'employee_anti_pattern'
      ]);

      if (!validateRRules(rrules)) return;

      let employeeInfo = {
        name,
        skills,
        version: selectedEmployeeSetting.version
      };

      if (contractName) {
        employeeInfo = _.merge(employeeInfo, {
          contract: _.find(listContract, { name: contractName }).id
        });
      }

      if (employee_anti_pattern) {
        employeeInfo = _.merge(employeeInfo, {
          employee_anti_pattern: [
            ...new Set(_.map(employee_anti_pattern, (data) => data.id))
          ].join(';')
        });
      }

      employeeInfo = _.merge(employeeInfo, {
        information: {
          ...selectedEmployeeSetting.information,
          contact: [
            {
              email,
              line: mobile || ''
            }
          ],
          others: [
            {
              department: team || '',
              supervisor: rank || ''
            }
          ]
        }
      });

      if (isCreateMode) {
        props.actCreateEmployee(
          {
            tenantId,
            body: {
              ...employeeInfo,
              is_active: activeChecked,
              rrules,
              contract: _.find(listContract, { name: contractName }).id,
              version: 0,
              employee_anti_pattern
            }
          },
          (err) => {
            if (!err) {
              onHideModal();
              message.success(translate('employeeSetting.createSuccess'));
            } else {
              message.error(err.message);
            }
          }
        );
      } else {
        props.actUpdateEmployeeInfo(
          {
            tenantId,
            employeeId: selectedEmployeeSetting.id,
            body: {
              ...employeeInfo,
              rrules,
              is_active: activeChecked
            }
          },
          (err) => {
            if (!err) {
              onHideModal();
              props.actGetEmployeeSetting({
                tenantId,
                employeeStatus: toggleStatus ? 'all' : 'active'
              });
              message.success(translate('employeeSetting.updateSuccess'));
            } else {
              message.error(err.message);
            }
          }
        );
      }
    } catch (err) {}
  };

  const title = isCreateMode
    ? translate('employeeSetting.createEmployeeSetting')
    : translate('employeeSetting.updateEmployeeSetting');

  const validateRRules = (rrules) => {
    if (_.isEmpty(rrules)) return true;

    const invalidRule = _.find(rrules, (rule) => !rule?.availability);
    return invalidRule ? false : true;
  };

  return (
    <Modal
      title={`${title} - ${selectedEmployeeSetting?.name || ''}`}
      width={700}
      visible={modalVisible}
      maskClosable={false}
      onCancel={onHideModal}
      forceRender={true}
      destroyOnClose={true}
      getContainer={false}
      style={{ top: '1rem' }}
      footer={
        <div>
          <Button type="danger" ghost onClick={onHideModal}>
            {translate('cancel')}
          </Button>

          <Button type="primary" onClick={onSubmitForm}>
            {isCreateMode ? translate('create') : translate('update')}
          </Button>
        </div>
      }
    >
      <Form layout="vertical" form={form}>
        <Tabs activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
          <TabPane tab={translate('employeeSetting.info')} key={TAB_NAME.INFO}>
            <EmployeeForm
              initialProps={{ activeChecked, ...props }}
              setActiveChecked={setActiveChecked}
            />
          </TabPane>

          {!isCreateMode && (
            <TabPane
              tab={translate('employeeSetting.schedule')}
              key={TAB_NAME.SCHEDULE}
            >
              <ListSchedule />
            </TabPane>
          )}

          {!isCreateMode && (
            <TabPane
              tab={translate('employeeSetting.Anti-pattern')}
              key={TAB_NAME.ANTI_PATTERN}
            >
              <ListAntiPattern />
            </TabPane>
          )}
        </Tabs>
      </Form>
    </Modal>
  );
};

export default connect(
  (state) => ({
    selectedEmployeeSetting: state.EmployeeSetting.selectedEmployeeSetting,
    modalVisible: state.EmployeeSetting.modalVisible,
    listSkill: state.SkillSetting.listSkill,
    listContract: state.ContractSetting.listContract,
    tenantId: state?.App?.user?.tenant?.id,
    listEmployee: state.EmployeeSetting.listEmployee
  }),
  {
    actSetModalEmployeeSettingVisible,
    actSetSelectedEmployeeSetting,
    actCreateEmployee,
    actUpdateEmployeeInfo,
    actCreateEmployeeSkillSet,
    actDeleteEmployeeSkillSet,
    actGetEmployeeSetting
  }
)(ModalEmployeeSetting);
