import { Navigate as Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import DashboardLayout from 'component/DashboardLayout';

const PrivateRoute = (props) => {
  const { component: ComponentInRoute, token, ...rest } = props;
  const Element = token ? (
    <DashboardLayout>
      <ComponentInRoute />
    </DashboardLayout>
  ) : (
    <Redirect to="/" />
  );
  return Element;
};

export default connect((state) => ({
  token: state.App.token
}))(PrivateRoute);
