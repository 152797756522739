import { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Select,
  Form,
  Switch,
  Tag,
  DatePicker,
  Tooltip,
  Button,
  Radio,
  message,
  notification,
  Input
} from 'antd';
import moment from 'moment';
import qs from 'qs';
import _ from 'lodash';
import { useNavigate as useHistory, useLocation } from 'react-router-dom';
import {
  CloseOutlined,
  EditOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { LIST_TAG_COLOR, GRID_VIEW_MODE } from 'utils/constant';
import { useTranslation } from 'component/index';
import {
  actGetSolveRotationStatus,
  actTerminateRotation
} from 'redux/action/rotationSetting';
import {
  actSolveRotation,
  actPublishRotation
} from 'redux/action/rotationSetting';
import { actSetSelectedRotation } from 'redux/action/skillView';
import {
  actSaveListAssignmentColumn,
  actEmailStatusCheck,
  actSetAvailEditable,
  actSetGlobalTime,
  actSetGridViewMode,
  actSetSelectedShiftType,
  actSetRefreshAssignment
} from 'redux/action/grid';
import {
  actGetExcelFile,
  actGetAssignmentScore
} from 'redux/action/rotationSetting';

import ModalExport from './ModalExport';

const { Option } = Select;
let solveInterval = null;
const TERMINATED = 'TERMINATED';
let lastSolverStatus = '';

const openNotification = (notiDesc, first_draft_date, translate) => {
  lastSolverStatus = notiDesc;

  notification.info({
    message: translate('rotation.solverStatus'),
    description: `${notiDesc} ${translate('for')} ${first_draft_date}`
  });
};

const GridHeading = (props) => {
  const {
    listEmployee,
    listShiftType,
    globalTime,
    first_draft_date,
    solvingStatus,
    assignmentScore,
    isGridFullScreen,
    openFullScreen,
    closeFullScreen,
    listRotation,
    tenantId,
    gridViewMode,
    selectedRotation,
    selectedShiftType,
    setSkillViewRefresh
  } = props;

  const [isSolveBtnDisable, setIsSolveBtnDisable] = useState(false);
  const [isTerminateBtnLoading, setIsTerminateBtnLoading] = useState(false);
  const [isPublishBtnLoading, setIsPublishBtnLoading] = useState(false);
  const [isExcelBtnLoading, setIsExcelBtnLoading] = useState(false);
  const [isRotationApply, setIsRotationApply] = useState(true);
  const [isModalExportOpen, setModalExportOpen] = useState(false);

  // @selectedEmployee: used when filter by employee
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const { translate } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const searchObj = qs.parse(location?.search.replace('?', ''));
    const viewMode = searchObj?.view;

    if (viewMode === GRID_VIEW_MODE.ASSIGNMENTS.toLowerCase()) {
      props.actSetGridViewMode(GRID_VIEW_MODE.ASSIGNMENTS);
    } else if (viewMode === GRID_VIEW_MODE.SKILLS.toLowerCase()) {
      props.actSetGridViewMode(GRID_VIEW_MODE.SKILLS);
    }
  }, [location?.search]);

  useEffect(() => {
    if (listShiftType.length > 0) {
      props.actSetSelectedShiftType(listShiftType[0]?.id);
    }
  }, [listShiftType]);

  useEffect(() => {
    if (solvingStatus && solvingStatus !== TERMINATED) {
      if (solveInterval) {
        clearInterval(solveInterval);
      }
      solveInterval = setInterval(onGetRotationStatus, 4000);
    }
  }, [solvingStatus]);

  useEffect(() => {
    props.actGetAssignmentScore({
      tenantId,
      year: moment(+globalTime).year(),
      month: moment(+globalTime).month() + 1
    });
  }, [globalTime]);

  useEffect(() => {
    if (listRotation[0]) {
      props.actSetSelectedRotation(listRotation[0]?.id);
    }

    if (_.find(listRotation, 'is_time_different')) {
      setIsRotationApply(false);
    }
  }, [listRotation]);

  useEffect(() => {
    if (+moment(first_draft_date).format('x') > +globalTime) {
      setIsSolveBtnDisable(true);
    } else {
      setIsSolveBtnDisable(false);
    }
  }, [globalTime, first_draft_date]);

  useEffect(() => {
    props.actEmailStatusCheck({
      tenantId,
      year: moment(+globalTime).year(),
      month: moment(+globalTime).month() + 1
    });
  }, [globalTime]);

  useEffect(() => {
    const rotationHasShift = _.find(listRotation, { spot: selectedShiftType });
    props.actSetSelectedRotation(rotationHasShift?.id || listRotation[0]?.id);
  }, [selectedShiftType, listRotation]);

  const onToggleGridFullScreen = () => {
    if (isGridFullScreen) {
      closeFullScreen();
    } else {
      openFullScreen();
    }
  };

  const onTerminateRotation = () => {
    setIsTerminateBtnLoading(true);

    props.actTerminateRotation(
      {
        tenantId
      },
      (err) => {
        setIsTerminateBtnLoading(false);
        if (err) {
          message.error(translate('rotation.terminatefailure'));
        }
      }
    );
  };

  const onPublishRotation = () => {
    setIsPublishBtnLoading(true);

    if (!isRotationApply) {
      message.error(translate('assignment.PublishErrorMessage'));
      setIsPublishBtnLoading(false);
    } else {
      props.actPublishRotation(
        {
          tenantId,
          body: {
            year: moment(+globalTime).year(),
            month: moment(+globalTime).month() + 1
          }
        },
        (err, res) => {
          setIsPublishBtnLoading(false);

          if (!err) {
            notification.success({
              message: `${translate('published')} ${res.first_draft_date}`
            });
          } else {
            message.error(err.message);
          }
        }
      );
    }
  };

  // filter employee
  const onFilterByEmployee = (selectedItems) => {
    setSelectedEmployee(selectedItems);

    if (selectedItems.length !== 0) {
      props.actSaveListAssignmentColumn(
        listEmployee.filter((col) => selectedItems.includes(col.id))
      );
    } else {
      props.actSaveListAssignmentColumn(listEmployee);
    }
  };

  const onSearchEmployee = (value) => {
    if (value) {
      setTimeout(() => {
        const regex = new RegExp(value.toLowerCase(), 'i');

        const tempListEmp = listEmployee.filter((emp) => regex.test(emp?.name));
        props.actSaveListAssignmentColumn(tempListEmp);
      }, 200);
    } else {
      props.actSaveListAssignmentColumn(listEmployee);
    }
  };

  // filter by shift
  const onFilterByShift = (value) => {
    props.actSetSelectedShiftType(value);
  };

  const onExportToExcel = (exportType) => {
    props.actGetExcelFile(
      {
        tenantId: tenantId,
        year: moment(+globalTime).year(),
        month: moment(+globalTime).month() + 1,
        pivot: exportType
      },
      (err, res) => {
        if (res) {
          const year = moment(+globalTime).year();
          const month = moment(+globalTime).month() + 1;
          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `scheduling_${month}_${year}.xlsx`); //or any other extension
          document.body.appendChild(link);
          link.click();
          setModalExportOpen(false);
        }

        if (err) {
          message.error(translate('rotation.ExcelFail'));
        }
      }
    );
  };

  const onDateChange = (date) => {
    props.actSetGlobalTime(date.format('x'));
  };

  const onSolveRotation = () => {
    props.actSolveRotation(
      {
        tenantId,
        body: {
          year: moment(+globalTime).year(),
          month: moment(+globalTime).month() + 1
        }
      },
      (err) => {
        if (!err) {
          onGetRotationStatus();

          if (solveInterval) {
            clearInterval(solveInterval);
          }

          solveInterval = setInterval(onGetRotationStatus, 4000);
          message.success(translate('rotation.solveSuccess'));
        } else {
          message.error(translate('rotation.solveFail'));
        }
      }
    );
  };

  const onGetRotationStatus = () => {
    props.actGetSolveRotationStatus(
      {
        tenantId
      },
      (err, res) => {
        if (res) {
          props.actGetAssignmentScore({
            tenantId: tenantId,
            year: moment(+globalTime).year(),
            month: moment(+globalTime).month() + 1
          });

          if (res !== lastSolverStatus) {
            openNotification(res, first_draft_date, translate);
          }

          if (res === TERMINATED) {
            solveInterval && clearInterval(solveInterval);
            props.actSetRefreshAssignment(true);
            setSkillViewRefresh(true);
          }
        } else {
          // If request fails, show notification and stop polling.
          message.error(translate('rotation.solveFail'));
          solveInterval && clearInterval(solveInterval);
          props.actSetRefreshAssignment(true);
          setSkillViewRefresh(true);
        }
      }
    );
  };

  const onChangeGridViewMode = (event) => {
    const { value } = event.target;
    // history.replace(`/assignments?view=${value.toLowerCase()}`);
    history(`/assignments?view=${value.toLowerCase()}`);
    props.actSetGridViewMode(value);
  };

  const rotationInfo = _.find(listRotation, { id: selectedRotation });

  return !isGridFullScreen ? (
    <Form layout="vertical" style={{ backgroundColor: 'white' }}>
      <Row
        gutter={16}
        style={{ marginTop: '10px', marginBottom: '10px' }}
        justify="flex-start"
      >
        {/* filter by employee */}
        {gridViewMode === GRID_VIEW_MODE.ASSIGNMENTS && (
          <Col>
            <Form.Item>
              <Select
                mode="multiple"
                value={selectedEmployee}
                onChange={onFilterByEmployee}
                onSearch={onSearchEmployee}
                style={{ minWidth: '20rem' }}
                placeholder={translate('assignment.filterByEmployee')}
                size="large"
                optionFilterProp="children"
              >
                {_.map(listEmployee, (emp, index) => (
                  <Option key={index} value={emp.id}>
                    {emp.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        {/* filter by shifts */}
        {gridViewMode === GRID_VIEW_MODE.SKILLS && (
          <Col>
            <Form.Item>
              <Select
                value={selectedShiftType}
                style={{ width: '20rem' }}
                placeholder={translate('assignment.filterByShiftType')}
                onChange={onFilterByShift}
                size="large"
              >
                {listShiftType.map((shiftType, index) => (
                  <Option key={index} value={shiftType.id}>
                    <span style={{ marginRight: '10px' }}>
                      <Tag
                        color={LIST_TAG_COLOR[index % LIST_TAG_COLOR.length]}
                        style={{ height: '13px' }}
                      />
                    </span>

                    <span>{shiftType?.name}</span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )}

        <Col>
          <Form.Item>
            <DatePicker
              onSelect={onDateChange}
              value={moment(+globalTime)}
              size="large"
              picker="month"
              className="input-border"
              style={{ width: '20rem' }}
              format="MMMM YYYY"
              placeholder={translate('assignment.filterMonth')}
            />
          </Form.Item>
        </Col>

        {gridViewMode === GRID_VIEW_MODE.SKILLS && (
          <Col>
            <Input
              value={`${rotationInfo?.start_time} => ${rotationInfo?.end_time}`}
              size="large"
            />
          </Col>
        )}

        {gridViewMode === GRID_VIEW_MODE.ASSIGNMENTS && (
          <Col>
            <Form.Item>
              <Tooltip
                title={translate('assignment.editPreferences')}
                placement="top"
              >
                <Switch
                  checkedChildren={<EditOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  onChange={(value) => props.actSetAvailEditable(value)}
                />
              </Tooltip>
            </Form.Item>
          </Col>
        )}

        <Col>
          <Tooltip title={`Start Date: ${first_draft_date}`} placement="top">
            <span>
              <Button
                type="primary"
                size="large"
                className="custom-button"
                onClick={onSolveRotation}
                loading={solvingStatus !== TERMINATED && !!solvingStatus}
                disabled={isSolveBtnDisable}
              >
                {translate('rotation.solve')}
              </Button>
            </span>
          </Tooltip>
        </Col>

        {solvingStatus && solvingStatus !== TERMINATED && (
          <Col>
            <Button
              type="primary"
              size="large"
              danger
              className="custom-button terminate-btn"
              disabled={solvingStatus === TERMINATED || !solvingStatus}
              onClick={onTerminateRotation}
              loading={isTerminateBtnLoading}
            >
              {translate('assignment.terminate')}
            </Button>
          </Col>
        )}

        <Col>
          <Button
            type="primary"
            size="large"
            className="custom-button"
            onClick={onPublishRotation}
            loading={isPublishBtnLoading}
            disabled={
              isSolveBtnDisable ||
              moment(+globalTime).month() !==
                moment(first_draft_date, 'YYYY-MM-DD').month()
            }
          >
            <span>{translate('rotation.publish')}</span>
          </Button>
        </Col>

        <Col>
          <Button
            type="primary"
            size="large"
            className="custom-button"
            onClick={() => setModalExportOpen(true)}
            loading={isExcelBtnLoading}
          >
            <span>{translate('assignment.export')}</span>
          </Button>

          <ModalExport
            isModalOpen={isModalExportOpen}
            onCloseModal={() => setModalExportOpen(false)}
            onExportToExcel={onExportToExcel}
          />
        </Col>

        <Col style={{ marginLeft: 'auto' }}>
          <Radio.Group value={gridViewMode} onChange={onChangeGridViewMode}>
            <Radio value={GRID_VIEW_MODE.ASSIGNMENTS}>
              {translate('assignment.assignment')}
            </Radio>

            <Radio value={GRID_VIEW_MODE.SKILLS}>
              {translate('assignment.skills')}
            </Radio>
          </Radio.Group>
        </Col>

        <Col>
          <Tooltip
            title={translate('assignment.enterFullScreen')}
            placement="top"
          >
            <FullscreenOutlined
              style={{ fontSize: '2.5rem', cursor: 'pointer' }}
              onClick={onToggleGridFullScreen}
            />
          </Tooltip>
        </Col>
      </Row>
    </Form>
  ) : (
    <Row
      gutter={16}
      style={{ padding: '5px 10px', backgroundColor: 'white' }}
      justify="flex-start"
    >
      <Col>
        <Tooltip
          title={translate('assignment.exitFullScreen')}
          placement="left"
        >
          <FullscreenExitOutlined
            style={{ fontSize: '2.5rem', cursor: 'pointer' }}
            onClick={onToggleGridFullScreen}
          />
        </Tooltip>
      </Col>

      <Col style={{ marginLeft: 'auto' }}>
        <Radio.Group value={gridViewMode} onChange={onChangeGridViewMode}>
          <Radio value={GRID_VIEW_MODE.ASSIGNMENTS}>Assignments</Radio>
          <Radio value={GRID_VIEW_MODE.SKILLS}>Skills</Radio>
        </Radio.Group>
      </Col>

      {gridViewMode === GRID_VIEW_MODE.ASSIGNMENTS && (
        <Col>
          <div className="score__cont">
            <div className="hard__score">
              {Object.keys(assignmentScore).length
                ? assignmentScore.hard_score
                : '0'}
            </div>
            <div className="medium__score">
              {Object.keys(assignmentScore).length
                ? assignmentScore.medium_score
                : '0'}
            </div>
            <div className="soft__score">
              {Object.keys(assignmentScore).length
                ? assignmentScore.soft_score
                : '0'}
            </div>
          </div>
        </Col>
      )}
    </Row>
  );
};

export default connect(
  (state) => ({
    listEmployee: state.EmployeeSetting.listData,
    listShiftType: state.ShiftTypeSetting.listShiftType,
    globalTime: state.App.globalTime,
    first_draft_date: state.App.first_draft_date,
    solvingStatus: state.App.solvingStatus,
    assignmentScore: state.RotationSetting.assignmentScore,
    isGridFullScreen: state.App.isGridFullScreen,
    listRotation: state.RotationSetting.listRotation,
    tenantId: state?.App?.user?.tenant?.id,
    gridViewMode: state.Grid.gridViewMode,
    selectedRotation: state.SkillView.selectedRotation,
    selectedShiftType: state.Grid.selectedShiftType
  }),
  {
    actGetSolveRotationStatus,
    actTerminateRotation,
    actSolveRotation,
    actPublishRotation,
    actSaveListAssignmentColumn,
    actGetExcelFile,
    actGetAssignmentScore,
    actEmailStatusCheck,
    actSetAvailEditable,
    actSetGlobalTime,
    actSetGridViewMode,
    actSetSelectedShiftType,
    actSetSelectedRotation,
    actSetRefreshAssignment
  }
)(GridHeading);
