import { message } from 'antd';
import { takeLatest, all, takeEvery } from 'redux-saga/effects';
import {
  GET_EMPLOYEE_SETTING,
  SAVE_GET_EMPLOYEE_SETTING,
  UPDATE_EMPLOYEE_INFO,
  SAVE_UPDATE_EMPLOYEE_INFO,
  DELETE_EMPLOYEE_INFO,
  CREATE_EMPLOYEE,
  SAVE_CREATE_EMPLOYEE,
  GET_EMPLOYEE_SHIFTS,
  SAVE_GET_EMPLOYEE_SHIFTS,
  UPDATE_EMPLOYEE_INACTIVE,
  SEARCH_EMPLOYEE_ANTI_PATTERN,
  GET_EMPLOYEE_BY_ID,
  SAVE_GET_EMPLOYEE_BY_ID
} from 'redux/type';
import employeeSettingAPI from 'redux/api/employeeSetting';
import { createRequestSaga } from './common';

const getEmployeeSetting = createRequestSaga({
  request: employeeSettingAPI.getEmployeeSetting,
  key: 'getEmployeeSetting',
  success: [
    (res) => ({
      type: SAVE_GET_EMPLOYEE_SETTING,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: [
    (err) => message.error('Error occured when get list employee')
  ]
});

const updateEmployeeInfo = createRequestSaga({
  request: employeeSettingAPI.updateEmployeeInfo,
  key: 'updateEmployeeInfo',
  success: [
    (res) => ({
      type: SAVE_UPDATE_EMPLOYEE_INFO,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: []
});

const deleteEmployeeInfo = createRequestSaga({
  request: employeeSettingAPI.deleteEmployeeInfo,
  key: 'deleteEmployeeInfo',
  functionSuccess: [],
  functionFailure: []
});

const createEmployeeInfo = createRequestSaga({
  request: employeeSettingAPI.createEmployeeInfo,
  key: 'createEmployeeInfo',
  success: [
    (res) => ({
      type: SAVE_CREATE_EMPLOYEE,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: []
});

const getEmployeeShifts = createRequestSaga({
  request: employeeSettingAPI.getEmployeeShifts,
  key: 'getEmployeeShifts',
  success: [
    (res) => ({
      type: SAVE_GET_EMPLOYEE_SHIFTS,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: [
    (err) => message.error('Error occured when get employee shift team')
  ]
});

const updateEmployeeToIncative = createRequestSaga({
  request: employeeSettingAPI.updateEmployeeToInactive,
  key: 'updateEmployeeToIncative',
  success: [
    // (res) => ({
    //   type: SAVE_GET_EMPLOYEE_SHIFTS,
    //   payload: res
    // })
  ],
  functionSuccess: [],
  functionFailure: [
    (err) => message.error('Error occured when get employee shift team')
  ]
});

const getEmployeeSearchAntiPattern = createRequestSaga({
  request: employeeSettingAPI.searchEmployeeAntiPattern,
  key: 'getEmployeeSearchAntiPattern',
  success: [
    (res) => ({
      type: SEARCH_EMPLOYEE_ANTI_PATTERN,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: []
});

const getEmployeeById = createRequestSaga({
  request: employeeSettingAPI.getEmployeeById,
  key: 'getEmployeeById',
  success: [
    (res) => ({
      type: SAVE_GET_EMPLOYEE_BY_ID,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: [
    (err) => message.error('Error occured when get list employee')
  ]
});

const saga = [
  function* fetchWatcher() {
    yield all([
      takeLatest(GET_EMPLOYEE_SETTING, getEmployeeSetting),
      takeLatest(UPDATE_EMPLOYEE_INFO, updateEmployeeInfo),
      takeLatest(DELETE_EMPLOYEE_INFO, deleteEmployeeInfo),
      takeLatest(CREATE_EMPLOYEE, createEmployeeInfo),
      takeLatest(GET_EMPLOYEE_SHIFTS, getEmployeeShifts),
      takeLatest(UPDATE_EMPLOYEE_INACTIVE, updateEmployeeToIncative),
      takeEvery(SEARCH_EMPLOYEE_ANTI_PATTERN, getEmployeeSearchAntiPattern),
      takeEvery(GET_EMPLOYEE_BY_ID, getEmployeeById)
    ]);
  }
];
export default saga;
