import { PURGE } from 'redux-persist';
import {
  SAVE_PROFILE_DETAILS,
  SET_MODAL_PROFILE_VISIBLE,
  GET_PDF_DOCUMENTATION,
  GET_RULE_STATUS,
  UPDATE_RULE_STATUS
} from 'redux/type';

const initialState = {};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case SAVE_PROFILE_DETAILS:
      return {
        ...state,
        data: payload
      };

    case SET_MODAL_PROFILE_VISIBLE:
      return {
        ...state,
        modalVisible: payload
      };

    case GET_PDF_DOCUMENTATION:
      return {
        ...state,
        pdf_links: payload
      };

    case GET_RULE_STATUS:
      return {
        ...state,
        rulesStatus: payload
      };

    case UPDATE_RULE_STATUS:
      // TODO: update rulesStatus.
      return state;

    default:
      return state;
  }
};

export default reducer;
