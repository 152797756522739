import { PURGE } from 'redux-persist';
import _ from 'lodash';
import moment from 'moment';
import {
  SAVE_LIST_DATA,
  SET_STATISTIC_ROW,
  SAVE_DATA_BY_COLUMN,
  SAVE_SPOT,
  SET_COLLAPSE_STATISTIC,
  RESET_GRID_DATA,
  SAVE_EMAIL_STATUS_CHECK,
  SET_AVAIL_EDITABLE,
  SET_GRID_VIEW_MODE,
  SET_SELECTED_SHIFT_TYPE,
  SAVE_GET_ASSIGNMENT_DATA,
  SET_REFRESH_ASSIGNMENT
} from '../type';
import {
  getGridDataByColumn,
  getListTimeLine
} from 'component/EmployeeGrid/util';
import { GRID_VIEW_MODE } from 'utils/constant';

const initialState = {
  listData: [],
  dataByColumn: [],
  statisticRow: [],
  spotId: 1,
  isStatisticCollapse: true,
  listTimeline: [],
  emailStatus: [],
  availEditable: false,
  gridViewMode: GRID_VIEW_MODE.ASSIGNMENTS,
  selectedShiftType: null,
  shouldRefresh: false
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case SAVE_GET_ASSIGNMENT_DATA: {
      let listData = [];
      const { listRotation, globalTime } = payload;
      const selectedTime = moment(+globalTime);
      const noOfDay = selectedTime.daysInMonth();
      const month =
        selectedTime.month() < 9
          ? `0${selectedTime.month() + 1}`
          : selectedTime.month() + 1;
      const year = selectedTime.year();
      const isDailyRotation = _.isEmpty(
        _.flatten(_.map(listRotation, 'tab.days'))
      );

      // initiate all day with its rotation
      for (let i = 1; i <= noOfDay; i++) {
        let dateString;

        if (i < 10) {
          dateString = `${year}-${month}-0${i}`;
        } else {
          dateString = `${year}-${month}-${i}`;
        }

        const uniqRotations = _.uniqBy(listRotation, (rotation) =>
          JSON.stringify(_.pick(rotation, ['start_time', 'end_time', 'tab.id']))
        );

        _.sortBy(uniqRotations, 'start_time').forEach((rotation) => {
          const day = _.toNumber(moment(dateString).format('d'));
          if (isDailyRotation || _.includes(rotation.tab.days, day)) {
            listData.push({
              date: dateString,
              start_time: rotation.start_time,
              end_time: rotation.end_time,
              days: rotation.tab.days,
              rotation: rotation?.id
            });
          }
        });
      }

      payload.data.forEach((data) => {
        const dateString = moment(data?.start_date_time).format('YYYY-MM-DD');
        const start_time = moment(data?.start_date_time).format('HH:mm');
        const end_time = moment(data?.end_date_time).format('HH:mm');
        const employee = data?.employee;
        const employeeKey = employee?.id.toString();

        const indexOfRow = _.findIndex(listData, {
          date: dateString,
          start_time,
          end_time
        });

        if (indexOfRow !== -1) {
          // cell data map to date & rotation & employee column
          let cellData = listData[indexOfRow][employeeKey];

          if (typeof cellData === 'undefined') {
            cellData = { shifts: [], availability: null };
          }

          if (!cellData.shifts) {
            cellData.shifts = [];
          } else {
            // a cell can contain multiple shift, @shift from api response can be null
            if (data.shift) {
              cellData.shifts.push(data.shift);
            }
          }

          // a cell contain only one availability
          cellData.availability = data.availability;

          // assign cell data
          listData[indexOfRow][employeeKey] = cellData;
        }
      });
      return {
        ...state,
        listData,
        dataByColumn: getGridDataByColumn(listData),
        listTimeline: getListTimeLine(listRotation)
      };
    }

    case SET_COLLAPSE_STATISTIC:
      return { ...state, isStatisticCollapse: payload };

    case SAVE_LIST_DATA:
      return {
        ...state,
        listData: payload
      };

    case SAVE_DATA_BY_COLUMN:
      return { ...state, dataByColumn: payload };

    case SET_STATISTIC_ROW:
      return {
        ...state,
        statisticRow: payload
      };

    case SAVE_SPOT:
      return {
        ...state,
        spotId: payload
      };

    case SAVE_EMAIL_STATUS_CHECK:
      return {
        ...state,
        emailStatus: payload
      };

    case RESET_GRID_DATA:
      return {
        selectedMonth: state.selectedMonth,
        gridViewMode: state.gridViewMode,
        ...initialState
      };

    case SET_AVAIL_EDITABLE:
      return {
        ...state,
        availEditable: payload
      };

    case SET_GRID_VIEW_MODE:
      return {
        ...state,
        gridViewMode: payload
      };

    case SET_SELECTED_SHIFT_TYPE:
      return {
        ...state,
        selectedShiftType: payload
      };

    case SET_REFRESH_ASSIGNMENT:
      return {
        ...state,
        shouldRefresh: payload
      };

    default:
      return state;
  }
};

export default reducer;
