import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { actGetTimeReportStatistic } from 'redux/action/timeReport';
import { StatisticWrapper } from './style';
import OvertimeChart from './OvertimeChart';
import ScheduleVsRealChart from './ScheduleVsRealChart';
import RevenueChart from './RevenueChart';
import PreferenceChart from './PreferenceChart';

const Statistic = (props) => {
  const { tenantId, year, chartMode } = props;
  const location = useLocation();
  const query = queryString.parse(location.search);
  const { employee } = query;

  useEffect(() => {
    if (!isNaN(Number(employee))) {
      props.actGetTimeReportStatistic({
        employeeId: Number(employee),
        year,
        tenantId
      });
    }
  }, [employee, tenantId, year]);

  return (
    <StatisticWrapper>
      <Row gutter={8} justify="space-between" style={{ marginBottom: '4rem' }}>
        <Col span={11}>
          <ScheduleVsRealChart chartMode={chartMode} />
        </Col>

        <Col span={11}>
          <PreferenceChart chartMode={chartMode} />
        </Col>
      </Row>

      <Row gutter={8} justify="space-between">
        <Col span={11}>
          <OvertimeChart chartMode={chartMode} />
        </Col>

        <Col span={11}>
          <RevenueChart chartMode={chartMode} />
        </Col>
      </Row>
    </StatisticWrapper>
  );
};

export default connect(
  (state) => ({
    tenantId: state?.App?.user?.tenant?.id
  }),
  {
    actGetTimeReportStatistic
  }
)(Statistic);
