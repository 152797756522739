import { message } from 'antd';
import { takeLatest, all } from 'redux-saga/effects';
import {
  GET_EMPLOYEE_REASON_SETTING,
  SAVE_GET_EMPLOYEE_REASON_SETTING,
  UPDATE_EMPLOYEE_REASON_INFO,
  SAVE_UPDATE_EMPLOYEE_REASON_INFO,
  DELETE_EMPLOYEE_REASON_INFO,
  CREATE_EMPLOYEE_REASON,
  SAVE_CREATE_EMPLOYEE_REASON
} from 'redux/type';
import employeeReason from 'redux/api/employeeReason';
import { createRequestSaga } from './common';

const getEmployeeReasonSetting = createRequestSaga({
  request: employeeReason.getEmployeeReasonSetting,
  key: 'getEmployeeReasonSetting',
  success: [
    (res) => ({
      type: SAVE_GET_EMPLOYEE_REASON_SETTING,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: [
    (err) => message.error('Error occured when get employee reason setting')
  ]
});

const updateEmployeeReasonInfo = createRequestSaga({
  request: employeeReason.updateEmployeeReasonInfo,
  key: 'updateEmployeeReasonInfo',
  success: [
    (res) => ({
      type: SAVE_UPDATE_EMPLOYEE_REASON_INFO,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: []
});

const deleteEmployeeReasonInfo = createRequestSaga({
  request: employeeReason.deleteEmployeeReasonInfo,
  key: 'deleteEmployeeReasonInfo',
  functionSuccess: [],
  functionFailure: []
});

const createEmployeeReasonInfo = createRequestSaga({
  request: employeeReason.createEmployeeReasonInfo,
  key: 'createEmployeeReasonInfo',
  success: [
    (res) => ({
      type: SAVE_CREATE_EMPLOYEE_REASON,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: []
});

const saga = [
  function* fetchWatcher() {
    yield all([
      takeLatest(GET_EMPLOYEE_REASON_SETTING, getEmployeeReasonSetting),
      takeLatest(UPDATE_EMPLOYEE_REASON_INFO, updateEmployeeReasonInfo),
      takeLatest(DELETE_EMPLOYEE_REASON_INFO, deleteEmployeeReasonInfo),
      takeLatest(CREATE_EMPLOYEE_REASON, createEmployeeReasonInfo)
    ]);
  }
];

export default saga;
