import moment from 'moment';
let listRowShouldSkipSpan = [];

const MAX_ROW_SPAN = 5;

const noOfSpanRow = (params) => {
  let rowSpan = 1;

  const gridApi = params.api;
  // get current row ID
  const rowId = Number(params.node.id);
  const rowValue = params?.data?.date;

  for (let i = 1; i < MAX_ROW_SPAN; i++) {
    // get next row data
    const nextRowData = gridApi.getRowNode(rowId + i);
    // if 2 row is the same date then increase @rowSpan by 1
    if (rowValue === nextRowData?.data?.date) {
      listRowShouldSkipSpan.push(rowId + i);
      rowSpan += 1;
    }
  }

  return rowSpan;
};

const defaultHeader = (translate, listShiftType = []) => {
  const shiftPickerHeader = listShiftType.map((shiftType, index) => ({
    headerName: shiftType.id.toString(),
    field: shiftType.id.toString(),
    lockPosition: true,
    pinned: true,
    columnGroupShow: 'open',
    width: 55 + shiftType?.name?.length * 5,
    headerComponent: 'ShiftPickerHeader',
    cellRenderer: 'ShiftPickerCell',
    cellClass: 'grid-cell-centered',
    tooltipField: shiftType.name,
    index,
    resizable: true
  }));

  return [
    {
      headerName: translate('assignment.timeline'),
      headerGroupComponent: 'CustomHeaderGroup',
      children: [
        {
          headerName: translate('assignment.date'),
          field: 'date',
          pinned: true,
          lockPosition: true,
          width: 110,
          cellClass: (params) =>
            noOfSpanRow(params) !== 1
              ? 'grid-cell-centered cell-span'
              : 'grid-cell-centered',
          rowSpan: (params) =>
            listRowShouldSkipSpan.includes(+params.node.id) &&
            params?.data?.date
              ? 1
              : noOfSpanRow(params),
          cellRenderer: (params) => {
            if (!params?.data?.date) {
              return '';
            }

            const day = moment(params?.data?.date).format('dddd');
            return params?.data?.date + '<br/>' + day;
          }
        },
        {
          headerName: translate('assignment.start'),
          field: 'start_time',
          pinned: true,
          lockPosition: true,
          width: 75,
          cellClass: 'grid-cell-centered',
          valueFormatter: (params) => params.data.start_time
        },
        {
          headerName: translate('assignment.end'),
          field: 'end_time',
          pinned: true,
          lockPosition: true,
          width: 75,
          cellClass: 'grid-cell-centered',
          valueFormatter: (params) => params.data.end_time
        },
        ...shiftPickerHeader
      ]
    }
  ];
};

export default defaultHeader;
