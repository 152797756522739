import { SIGN_IN, LOG_OUT, SET_ACCESS_TOKEN } from '../type';

export const actSignIn = (...args) => {
  return {
    type: SIGN_IN,
    args
  };
};

export const actLogout = () => {
  return {
    type: LOG_OUT
  };
};

export const actSetAccessToken = (payload) => {
  return {
    type: SET_ACCESS_TOKEN,
    payload
  };
};
