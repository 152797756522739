import {
  SET_DASHBOARD_DATA,
  SET_DASHBOARD_EMPLOYEE_CHART,
  SET_DASHBOARD_SHIFTS_CHART,
  GET_DASHBOARD_PREFERENCES,
  GET_DASHBOARD_WEEKDAY_ALLOCTION,
  SET_DASHBOARD_WEEKDAY_ALLOCTION
} from '../type';

export const actSetDashboardData = (payload) => {
  return {
    type: SET_DASHBOARD_DATA,
    payload
  };
};

export const actSetDashboardEmployeeChart = (...args) => {
  return {
    type: SET_DASHBOARD_EMPLOYEE_CHART,
    args
  };
};

export const actSetDashboardShiftsChart = (...args) => {
  return {
    type: SET_DASHBOARD_SHIFTS_CHART,
    args
  };
};

export const actGetDashboardPreferences = (...args) => {
  return {
    type: GET_DASHBOARD_PREFERENCES,
    args
  };
};

export const actGetDashboardWeekdayAllocation = (...args) => {
  return {
    type: GET_DASHBOARD_WEEKDAY_ALLOCTION,
    args
  };
};

export const actSetWeeklySchedule = (data) => {
  return {
    type: SET_DASHBOARD_WEEKDAY_ALLOCTION,
    data
  };
};
