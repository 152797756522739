import { useEffect } from 'react';
import { Modal, Form, DatePicker, message, Select } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'component/index';
import {
  actSetModalTimeReportVisible,
  actSetSelectedTimeReport,
  actCreateTimeReport,
  actUpdateTimeReport
} from 'redux/action/timeReport';
import { actGetShiftTypeSetting } from 'redux/action/shiftTypeSetting';

const { Option } = Select;

const ModalTimeReport = (props) => {
  const { modalVisible, selectedTimeReport, tenantId, listShiftType } = props;
  const [form] = Form.useForm();
  const { translate } = useTranslation();
  const location = useLocation();
  const query = queryString.parse(location.search);
  const { employee } = query;

  useEffect(() => {
    props.actGetShiftTypeSetting({ tenantId });
  }, [tenantId]);

  useEffect(() => {
    if (!_.isEmpty(selectedTimeReport)) {
      form.setFieldsValue({
        start_date_time: moment.utc(selectedTimeReport.start_date_time),
        end_date_time: selectedTimeReport.end_date_time
          ? moment.utc(selectedTimeReport.end_date_time)
          : null,
        spot: selectedTimeReport?.spot?.id
      });
    } else {
      form.setFieldsValue({
        start_date_time: moment(),
        end_date_time: moment().add(1, 'hour'),
        spot: null
      });
    }
  }, [selectedTimeReport]);

  const onHideModal = () => {
    props.actSetModalTimeReportVisible(false);
    props.actSetSelectedTimeReport({});
  };

  const onSubmitForm = async () => {
    try {
      const { start_date_time, end_date_time, spot } =
        await form.validateFields(['start_date_time', 'end_date_time', 'spot']);

      const body = {
        start_date_time: start_date_time.format('YYYY-MM-DDTHH:mm:ss'),
        end_date_time: end_date_time.format('YYYY-MM-DDTHH:mm:ss'),
        shift: null,
        is_manual: true,
        spot: spot ? spot : null
      };

      if (_.isEmpty(selectedTimeReport)) {
        props.actCreateTimeReport(
          {
            tenantId,
            body: { ...body, employee: Number(employee) }
          },
          (err, res) => {
            if (!err) {
              onHideModal();
              message.success(translate('timeReport.createSuccess'));
            } else {
              message.error(err.message);
            }
          }
        );
      } else {
        props.actUpdateTimeReport(
          {
            tenantId,
            id: selectedTimeReport?.id,
            body
          },
          (err) => {
            if (!err) {
              onHideModal();
              message.success(translate('timeReport.updateSuccess'));
            } else {
              message.error(err.message);
            }
          }
        );
      }
    } catch (err) {}
  };

  return (
    <Modal
      title={
        _.isEmpty(selectedTimeReport)
          ? translate('timeReport.create')
          : translate('timeReport.update')
      }
      visible={modalVisible}
      cancelText={translate('cancel')}
      okText={
        _.isEmpty(selectedTimeReport)
          ? translate('create')
          : translate('update')
      }
      maskClosable={false}
      onOk={onSubmitForm}
      onCancel={onHideModal}
      forceRender={true}
      destroyOnClose={true}
      getContainer={false}
      style={{ top: '1rem' }}
    >
      <Form layout="vertical" form={form}>
        <Form.Item label={translate('timeReport.shiftType')} name="spot">
          <Select
            size="large"
            placeholder={translate('timeReport.chooseShiftType')}
          >
            <Option key={'null'} value={null}>
              --------------
            </Option>
            {_.map(listShiftType, (spot, index) => (
              <Option key={index} value={spot?.id}>
                {spot?.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={translate('timeReport.startTime')}
          rules={[
            {
              required: true,
              message: `${translate('timeReport.startTime')} ${translate(
                'isRequired'
              )}`
            }
          ]}
          name="start_date_time"
        >
          <DatePicker
            placeholder={translate('timeReport.pickATime')}
            style={{ width: '100%' }}
            showTime={true}
            format="YYYY-MM-DD HH:mm"
          />
        </Form.Item>

        <Form.Item
          label={translate('timeReport.endTime')}
          rules={[
            {
              required: true,
              message: `${translate('timeReport.endTime')} ${translate(
                'isRequired'
              )}`
            }
          ]}
          name="end_date_time"
        >
          <DatePicker
            placeholder={translate('timeReport.pickATime')}
            style={{ width: '100%' }}
            showTime={true}
            format="YYYY-MM-DD HH:mm"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default connect(
  (state) => ({
    selectedTimeReport: state.TimeReport.selectedTimeReport,
    modalVisible: state.TimeReport.modalVisible,
    tenantId: state?.App?.user?.tenant?.id,
    listShiftType: state.ShiftTypeSetting.listShiftType
  }),
  {
    actSetModalTimeReportVisible,
    actSetSelectedTimeReport,
    actCreateTimeReport,
    actUpdateTimeReport,
    actGetShiftTypeSetting
  }
)(ModalTimeReport);
