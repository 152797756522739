import { useContext } from 'react';
import { LanguageContext, dictionaryList, listLocale } from 'languages/index';

const useTranslation = () => {
  const { locale } = useContext(LanguageContext);

  const translate = (key) => {
    if (!dictionaryList[locale] || !dictionaryList[locale][key]) {
      console.warn(`Translation '${key}' for locale '${locale}' not found.`);
      return;
    }

    return (
      dictionaryList[locale][key] || dictionaryList[listLocale.en][key] || ''
    );
  };

  return {
    translate,
    locale
  };
};

export { useTranslation };
