import { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { STATISTIC_TYPE } from 'utils/constant';
import { StatisticItemWrapper } from './style';
import {
  actSetDashboardEmployeeChart,
  actSetDashboardShiftsChart
} from 'redux/action/dashboard';
import { useTranslation } from 'component/index';
import _ from 'lodash';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const LINE_CHART_COLOR = [
  '#eb4934',
  '#eb9334',
  '#74eb34',
  '#34ebd9',
  '#3452eb',
  '#AC06FC',
  '#0087FF',
  '#ab2c67',
  '#916bf2',
  '#678a6f',
  '#494f10',
  '#592222'
];
function GetDataset(data) {
  var dataset = {
    label: '',
    fill: false,
    backgroundColor: 'chartColor',
    borderColor: '',
    data: [],
    cubicInterpolationMode: 'monotone',
    yAxisID: 'y1'
  };
  for (const [key, value] of Object.entries(data)) {
    if (key === 'month') {
      dataset['label'] = value;
    } else {
      dataset['data'].push(value);
      dataset['backgroundColor'] =
        LINE_CHART_COLOR[parseInt(key.split('-')[1]) - 1];
      dataset['borderColor'] =
        LINE_CHART_COLOR[parseInt(key.split('-')[1]) - 1];
    }
  }
  return dataset;
}

const StatisticItem = (props) => {
  const { description, icon, type, shiftChartData, employeeChartData } = props;
  const data = {
    labels: [],
    datasets: []
  };
  const { translate, locale } = useTranslation();
  const [shiftChart, setShiftChart] = useState(data);
  const [employeeChart, setEmployeeChart] = useState(data);

  useEffect(() => {
    var shiftDataChart = {
      labels: [],
      datasets: []
    };
    if (shiftChartData && shiftChartData.length > 0) {
      for (var i = 1; i <= 31; i++) {
        shiftDataChart['labels'].push(i);
      }
      _.forEach(shiftChartData, (data) => {
        shiftDataChart['datasets'].push(GetDataset(data));
      });
    }
    setShiftChart(shiftDataChart);
  }, [shiftChartData]);

  useEffect(() => {
    var employeetDataChart = {
      labels: [],
      datasets: []
    };
    if (employeeChartData && employeeChartData.length > 0) {
      for (var i = 1; i <= 31; i++) {
        employeetDataChart['labels'].push(i);
      }
      _.forEach(employeeChartData, (data) => {
        employeetDataChart['datasets'].push(GetDataset(data));
      });
    }
    setEmployeeChart(employeetDataChart);
  }, [employeeChartData]);

  const getChartData = () => {
    if (type === STATISTIC_TYPE.NUMBER_OF_EMPLOYEE) {
      return employeeChart;
    } else {
      return shiftChart;
    }
  };

  return (
    <StatisticItemWrapper>
      <div className="detail">
        <div className="icon-wrapper">
          <img src={icon} alt="icon" />
        </div>

        <div className="statistic">
          <div className="description">{description}</div>
          <div className="heading">
            {/* <span className="bold">{renderHeading()}</span> */}
          </div>
        </div>
      </div>

      <div className="chart">
        <Line
          data={getChartData()}
          // config for options to hide legend, grid and axis https://stackoverflow.com/questions/36676263/chart-js-v2-hiding-grid-lines
          options={{
            plugins: {
              legend: {
                display: true,
                position: 'bottom',
                labels: {
                  usePointStyle: true,
                  boxWidth: 10,
                  filter: (data) => {
                    data['text'] = translate(
                      `dashboardEmployeeSchedule.${data['text']}`
                    );
                    return data;
                  }
                }
              },
              tooltip: {
                callbacks: {
                  label: (context) => {
                    const month = context?.dataset?.label;
                    const day = moment(Number(context?.label), 'DD');
                    const formattedDate = `${month} ${day.format('Do')}`;
                    const tooltip = `${formattedDate}: ${context.raw}`;

                    return tooltip;
                  },
                  title: () => ''
                }
              }
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                ticks: {
                  display: false,
                  showLabelBackdrop: false
                },
                display: false,
                grid: {
                  display: false,
                  drawBorder: false
                }
              },
              y1: {
                ticks: {
                  display: false,
                  showLabelBackdrop: false
                },
                grid: {
                  display: false,
                  drawBorder: false
                }
              },
              x: {
                ticks: {
                  display: false,
                  showLabelBackdrop: false
                },
                grid: {
                  display: false,
                  drawBorder: false
                }
              }
            }
          }}
        />
      </div>
    </StatisticItemWrapper>
  );
};

export default connect(
  (state) => {
    return {
      statistic: state.Dashboard.statistic,
      employeeChartData: state.Dashboard.employeeChartData,
      shiftChartData: state.Dashboard.shiftChartData,
      dashboardFromTime: state.App.dashboardFromTime,
      dashboardToTime: state.App.dashboardToTime
    };
  },
  {
    actSetDashboardEmployeeChart,
    actSetDashboardShiftsChart
  }
)(StatisticItem);
