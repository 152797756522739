import { Select, Tag, message } from 'antd';
import { useState, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import {
  actUpdatePreferenceAvail,
  actSetSelectedPreferenceAvail,
  actCreatePreferenceAvail,
  actDeletePreferenceAvail,
  actSaveOnDeleteEmployeePreference,
  actAfterDeletePreferenceAvail
} from 'redux/action/preference';
import { useTranslation } from 'component/index';

import { SelectAvailWrapper } from './style';
import { getListRotationForDay } from '../util';

const { Option } = Select;

const SelectAvail = (props) => {
  const {
    listEmployeeReason,
    selectedEmpReason,
    listAvail,
    tenantId,
    start,
    listRotation,
    selectedEmployee
  } = props;
  const [isEditing, setIsEditing] = useState(false);
  const selectEl = useRef(null);
  const { translate } = useTranslation();
  const location = useLocation();

  const toggleEdit = () => {
    setIsEditing(!isEditing);

    setTimeout(() => {
      if (!isEditing) {
        selectEl?.current?.focus();
      }
    }, 100);
  };

  const cellReason = useMemo(() => {
    return selectedEmpReason
      ? _.find(listEmployeeReason, {
          id: Number(selectedEmpReason)
        })
      : {};
  }, [selectedEmpReason]);

  const onChangeDayPreference = async (value) => {
    if (!value) return; // delete is called instead.

    let updateSuccess = true;
    if (listAvail?.length > 0) {
      // use the same way like <TimeItem /> component because @actUpdatePreferenceAvail need @selectedAvail
      props.actSetSelectedPreferenceAvail({
        availInfo: { ...listAvail?.[0], start }
      });
    }

    /* update exist avail */
    for (let i = 0; i < listAvail?.length; i++) {
      props.actUpdatePreferenceAvail(
        {
          tenantId,
          availId: listAvail?.[i]?.availId,
          body: {
            employee_reason: value
          }
        },
        (err, res) => {
          if (err) {
            updateSuccess = false;
            message.error(err?.message);
          }
        }
      );
    }

    /* Create new avail */
    const listRotationForDay = getListRotationForDay(
      listAvail,
      start,
      listRotation,
      listEmployeeReason
    );

    // get list rotation to send api create new avail
    const listRotationNotHaveAvail = _.filter(
      listRotationForDay,
      (rotation) =>
        !_.find(
          listAvail,
          (avail) =>
            avail?.startHour === rotation?.startHour &&
            avail?.endHour === rotation?.endHour
        )
    );

    const query = queryString.parse(location.search);
    const { employeeId } = query;
    for (let i = 0; i < listRotationNotHaveAvail?.length; i++) {
      const { start_date_time, end_date_time, rotation } =
        listRotationNotHaveAvail[i];
      const selectedEmpReason = _.find(listEmployeeReason, {
        id: Number(value)
      });

      props.actCreatePreferenceAvail(
        {
          tenantId,
          body: {
            version: 0,
            state: Number(selectedEmpReason?.state),
            employee_reason: value,
            start_date_time,
            end_date_time,
            // if in public view, use @selectedEmployee.employeeId, else if in admin private view, use @query.employeeId
            employee: !_.isEmpty(selectedEmployee)
              ? selectedEmployee?.employeeId
              : employeeId,
            message: '',
            rotation
          }
        },
        (err, res) => {
          if (err) {
            updateSuccess = false;
            message.error(err?.message);
          }
        }
      );
    }

    if (updateSuccess) {
      message.success(translate('preference.updateAvailSuccess'));
    }
  };

  const onDeleteAvailability = () => {
    let deleteSuccess = true;

    // if cell contain avail, delete all avail
    for (let i = 0; i < listAvail?.length; i++) {
      const availId = listAvail?.[i]?.availId;
      props.actDeletePreferenceAvail({ tenantId, availId }, (err, res) => {
        if (!err) {
          props.actSaveOnDeleteEmployeePreference({
            availId
          });
          props.actAfterDeletePreferenceAvail(listAvail?.[i]);
        } else {
          deleteSuccess = false;
        }
      });
    }

    if (deleteSuccess) {
      message.success(translate('preference.deleteSucess'));
    } else {
      message.error(translate('preference.deleteFail'));
    }
  };

  return (
    <SelectAvailWrapper>
      {isEditing ? (
        <span style={{ width: '100%' }}>
          <Select
            size="small"
            allowClear={true}
            ref={selectEl}
            onBlur={toggleEdit}
            style={{ width: '95%' }}
            value={selectedEmpReason}
            onChange={onChangeDayPreference}
            placeholder={translate('preference.pickAvalability')}
            defaultOpen={true}
            onClear={onDeleteAvailability}
          >
            {listEmployeeReason.map((empReason, index) => (
              <Option
                key={index}
                value={+empReason.id}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center'
                }}
              >
                <img
                  src={empReason.icon}
                  alt=""
                  style={{
                    width: '1.3rem',
                    height: '1.3rem',
                    marginRight: '1rem'
                  }}
                />

                <span>{empReason.name}</span>
              </Option>
            ))}
          </Select>
        </span>
      ) : typeof selectedEmpReason === 'number' ? (
        <Tag onClick={toggleEdit}>
          <span
            className="avail-icon"
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '0.3rem 0'
            }}
          >
            <img
              src={cellReason?.icon}
              alt=""
              style={{ width: '1.1rem', height: '1.1rem' }}
            />
          </span>
        </Tag>
      ) : (
        <Tag onClick={toggleEdit}>-</Tag>
      )}
    </SelectAvailWrapper>
  );
};

export default connect(
  (state) => ({
    listEmployeeReason: state.EmployeeReason.listEmployeeReason,
    tenantId: state?.App?.user?.tenant?.id,
    listRotation: state.RotationSetting.listRotation,
    selectedEmployee: state.Preference.selectedEmployee,
    tenantPreferences: state?.App?.tenant?.preferences
  }),
  {
    actUpdatePreferenceAvail,
    actSetSelectedPreferenceAvail,
    actCreatePreferenceAvail,
    actDeletePreferenceAvail,
    actSaveOnDeleteEmployeePreference,
    actAfterDeletePreferenceAvail
  }
)(SelectAvail);
