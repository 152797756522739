import _ from 'lodash';
import { useEffect, useRef } from 'react';
import { Modal, Form, Input, message } from 'antd';
import { connect } from 'react-redux';
import { useTranslation } from 'component/index';
import {
  actSetModalShiftGroupSettingVisible,
  actSetSelectedShiftGroupSetting,
  actCreateShiftGroup,
  actUpdateShiftGroup
} from 'redux/action/shiftGroupSetting';

const ShiftGroupSettingModal = (props) => {
  const { modalVisible, selectedShiftGroupSetting, tenantId } = props;

  const firstInput = useRef(null);
  const [form] = Form.useForm();
  const { translate } = useTranslation();

  useEffect(() => {
    const { name, description } = selectedShiftGroupSetting;

    form.setFieldsValue({
      name,
      description
    });
  }, [selectedShiftGroupSetting]);

  useEffect(() => {
    if (modalVisible) {
      firstInput.current.focus();
    }
  }, [modalVisible]);

  const onHideModal = () => {
    props.actSetModalShiftGroupSettingVisible(false);
    props.actSetSelectedShiftGroupSetting({});
  };

  const onSubmitForm = async () => {
    try {
      const { name, description } = await form.validateFields([
        'name',
        'description'
      ]);

      const shiftGroupInfo = {
        name,
        description
      };

      if (_.isEmpty(selectedShiftGroupSetting)) {
        props.actCreateShiftGroup(
          {
            tenantId,
            body: { ...shiftGroupInfo, version: 0 }
          },
          (err) => {
            if (!err) {
              onHideModal();
              message.success(translate('shiftGroup.createSuccess'));
            } else {
              message.error(translate('shiftGroup.createFail'));
            }
          }
        );
      } else {
        props.actUpdateShiftGroup(
          {
            tenantId,
            shiftGroupId: selectedShiftGroupSetting.id,
            body: shiftGroupInfo
          },
          (err) => {
            if (!err) {
              onHideModal();
              message.success(translate('shiftGroup.updateSuccess'));
            } else {
              message.error(translate('shiftGroup.updateFail'));
            }
          }
        );
      }
    } catch (err) {}
  };

  return (
    <Modal
      title={
        _.isEmpty(selectedShiftGroupSetting)
          ? translate('shiftGroup.createShiftGroup')
          : translate('shiftGroup.updateShiftGroup')
      }
      visible={modalVisible}
      cancelText={translate('cancel')}
      okText={
        _.isEmpty(selectedShiftGroupSetting)
          ? translate('create')
          : translate('update')
      }
      maskClosable={false}
      onOk={onSubmitForm}
      onCancel={onHideModal}
      forceRender={true}
      destroyOnClose={true}
      getContainer={false}
      style={{ top: '1rem' }}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          label={translate('shiftGroup.name')}
          rules={[
            {
              required: true,
              message: `${translate('shiftGroup.name')} ${translate(
                'isRequired'
              )}`
            }
          ]}
          name="name"
        >
          <Input ref={firstInput} />
        </Form.Item>
        <Form.Item
          label={translate('shiftGroup.description')}
          rules={[
            {
              required: true,
              message: `${translate('shiftGroup.description')} ${translate(
                'isRequired'
              )}`
            }
          ]}
          name="description"
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default connect(
  (state) => ({
    selectedShiftGroupSetting:
      state.ShiftGroupSetting.selectedShiftGroupSetting,
    modalVisible: state.ShiftGroupSetting.modalVisible,
    tenantId: state?.App?.user?.tenant?.id
  }),
  {
    actSetModalShiftGroupSettingVisible,
    actSetSelectedShiftGroupSetting,
    actCreateShiftGroup,
    actUpdateShiftGroup
  }
)(ShiftGroupSettingModal);
