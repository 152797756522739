import { Button } from 'antd';
import { connect } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';

import {
  actSetModalShiftGroupSettingVisible,
  actSetSelectedShiftGroupSetting
} from 'redux/action/shiftGroupSetting';
import { useTranslation } from 'component/index';
import ShiftGroupSettingTable from 'component/ShiftGroupSetting/ShiftGroupSettingTable';

import { ShiftGroupSettingPageWrapper } from './index.style';

const ShiftGroupSettingPage = (props) => {
  const { translate } = useTranslation();

  const onCreateShiftGroupSetting = () => {
    props.actSetModalShiftGroupSettingVisible(true);
    props.actSetSelectedShiftGroupSetting({});
  };

  return (
    <ShiftGroupSettingPageWrapper>
      <div className="header-wrapper">
        <div className="label">{translate('shiftGroup.header.shiftGroup')}</div>
        <div className="filter-wrapper">
          <Button
            Group="primary"
            icon={<PlusOutlined />}
            className="custom-button"
            size="large"
            onClick={onCreateShiftGroupSetting}
          >
            {translate('shiftGroup.addShiftGroup')}
          </Button>
        </div>
      </div>

      <div className="main-content">
        <ShiftGroupSettingTable />
      </div>
    </ShiftGroupSettingPageWrapper>
  );
};

export default connect((state) => ({}), {
  actSetModalShiftGroupSettingVisible,
  actSetSelectedShiftGroupSetting
})(ShiftGroupSettingPage);
