import {
  HeadingSection,
  FeatureSection,
  PlanningSection,
  CallToActionSection,
  Footer,
  TestimonialSection
} from 'component/Homepage';
import { HomePageWrapper } from './style';

const HomePage = () => {
  return (
    <HomePageWrapper>
      <HeadingSection />
      <FeatureSection />
      <TestimonialSection />
      <PlanningSection />
      <CallToActionSection />
      <Footer />
    </HomePageWrapper>
  );
};

export default HomePage;
