import styled from 'styled-components';

const PreferencePageWrapper = styled.div`
  font-size: 1.6rem;

  .header-wrapper {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid var(--color-border-gray);

    .action-wrapper {
      display: flex;
      flex-direction: column;

      .title {
        color: var(--color-text);
        text-transform: uppercase;
        font-size: 1.4rem;
        font-weight: 500;
        margin-bottom: 0.5rem;
      }

      .list-action {
        display: flex;

        .icon-wrapper {
          margin-right: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            height: 1.5rem;
            width: 1.5rem;
          }
        }
      }
    }

    .filter-wrapper {
      display: flex;
      justify-content: space-between;

      .search-bar {
        margin-right: 3rem;
        margin-left: 3rem;
      }

      .input-border {
        border-radius: 4px;
      }

      .filter-date {
        margin-right: 3rem;
        margin-left: 3rem;
      }
    }
  }

  .main-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .table-wrapper {
      flex-grow: 1;
    }
  }
`;

export { PreferencePageWrapper };
