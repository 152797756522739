import _ from 'lodash';
import moment from 'moment';
import { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Heatmap } from '@ant-design/charts';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { LOCALE } from 'languages/index';
import { useTranslation } from 'component/index';
import { WeeklyScheduleWrapper } from './style';

const HEIGHT_PER_EMPLOYEE = 40;

const WeeklySchedule = (props) => {
  const { listEmployee, weekdayAllocation } = props;
  const { translate, locale } = useTranslation();

  const [dayInWeek, setDayInWeek] = useState(
    Array.apply(null, Array(7)).map(function (_, i) {
      return moment(i, 'e')
        .startOf('week')
        .isoWeekday(i + 1)
        .format('ddd');
    })
  );

  const [weeklyAllocation, setWeeklyAllocation] = useState([]);

  useEffect(() => {
    var dayInWeek = [];
    if (locale === LOCALE.EN) {
      moment.locale('en-us');
    } else if (locale === LOCALE.JP) {
      moment.locale('ja');
    }
    dayInWeek = Array.apply(null, Array(7)).map(function (_, i) {
      return moment(i, 'e')
        .startOf('week')
        .isoWeekday(i + 1)
        .format('ddd');
    });
    setDayInWeek(dayInWeek);
  }, [locale]);

  const listEmployeeId = useMemo(() => {
    const uniqEmployeeIds = _.uniqBy(weekdayAllocation, 'employee').map(
      (item) => parseInt(item.employee)
    );

    const activeEmployeeIds = _.filter(
      uniqEmployeeIds,
      (empId) => _.find(listEmployee, { id: empId })?.is_active
    );

    return _.sortBy(activeEmployeeIds);
  }, [listEmployee, weekdayAllocation]);

  useEffect(() => {
    if (!_.isEmpty(weekdayAllocation) && !_.isEmpty(listEmployeeId)) {
      let listWeekdayAllocation = [];
      listEmployeeId.map((emp) => {
        let pickedData = _.filter(weekdayAllocation, (data) => {
          if (data.employee === emp) {
            data.day = data.day.substring(0, 3);
            return data;
          }
        });

        ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].forEach((day) => {
          const sameDayData = pickedData.filter((data) => {
            if (data.day === day) {
              listWeekdayAllocation.push(data);
              return data;
            }
          });

          if (sameDayData.length == 0) {
            const newData = {
              employee: emp,
              day: day,
              total: 0
            };
            listWeekdayAllocation.push(newData);
          }
        });
      });

      setWeeklyAllocation(listWeekdayAllocation);

      // Update chart height manualy.
      const heatmap = document.querySelector('#heatmap');
      const heatMapHeight = listEmployeeId.length * HEIGHT_PER_EMPLOYEE;
      heatmap.style.height = `${heatMapHeight}px`;
      heatmap.setAttribute('height', heatMapHeight);
    }
  }, [listEmployeeId, weekdayAllocation]);

  const metaEmployeeValues = useMemo(
    () => _.reverse(_.clone(listEmployeeId)),
    [listEmployeeId]
  );

  const config = {
    data: weeklyAllocation,
    autoFit: true,
    xField: 'day',
    yField: 'employee',
    colorField: 'total',
    shape: 'boundary-polygon',
    meta: {
      employee: {
        type: 'cat',
        values: metaEmployeeValues
      },
      day: { type: 'cat' },
      total: { sync: true }
    },
    yAxis: {
      grid: null,
      label: {
        formatter: (value) => {
          return `${_.find(listEmployee, { id: +value })?.name.substring(
            0,
            4
          )}...`;
        }
      }
    },
    tooltip: {
      showMarkers: false,
      formatter: (data) => ({
        title: `${_.find(listEmployee, { id: +data['employee'] })?.name}`,
        name: translate(`dashboardEmployeeSchedule.${data['day']}`),
        value: data['total']
      })
    },
    interactions: [{ type: 'element-active' }],
    xAxis: {
      tickLine: null,
      line: null,
      label: {
        formatter: (value) => translate(`dashboardEmployeeSchedule.${value}`)
      }
    },
    label: {
      style: {
        fill: '#000',
        shadowBlur: 2,
        shadowColor: 'rgba(0, 0, 0, .45)'
      }
    }
  };

  return (
    <WeeklyScheduleWrapper>
      <div className="heading">{translate('dashboard.weeklySchedule')}</div>

      <div className="list-day">
        {/* place an empty string here because heatmap have 8 columns, first column is employee name, others are day in week */}
        {['', ...dayInWeek].map((day, index) => (
          <div key={index}>{day}</div>
        ))}
      </div>

      <Scrollbars style={{ width: '100%', height: '100%' }}>
        <div className="chart-wrapper" id="heatmap">
          <Heatmap {...config} />
        </div>
      </Scrollbars>
    </WeeklyScheduleWrapper>
  );
};

export default connect((state) => ({
  weeklySchedule: state.Dashboard.weeklySchedule,
  weekdayAllocation: state.Dashboard.weekdayAllocation,
  listEmployee: state.EmployeeSetting.listEmployee
}))(WeeklySchedule);
