import React, { useEffect, useRef } from 'react';
import { Modal, Form, Input, message } from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import { useTranslation } from 'component/index';
import {
  actSetModalSkillSettingVisible,
  actSetSelectedSkillSetting,
  actCreateSkill,
  actUpdateSkillInfo
} from 'redux/action/skillSetting';

const SkillSettingModal = (props) => {
  const { modalVisible, selectedSkillSetting, tenantId } = props;

  const firstInput = useRef(null);
  const [form] = Form.useForm();
  const { translate } = useTranslation();

  useEffect(() => {
    const { name } = selectedSkillSetting;

    form.setFieldsValue({
      name
    });
  }, [selectedSkillSetting]);

  useEffect(() => {
    if (modalVisible) {
      firstInput.current.focus();
    }
  }, [modalVisible]);

  const onHideModal = () => {
    props.actSetModalSkillSettingVisible(false);
    props.actSetSelectedSkillSetting({});
  };

  const onSubmitForm = async () => {
    try {
      const { name } = await form.validateFields(['name']);

      const skillInfo = {
        name
      };

      if (_.isEmpty(selectedSkillSetting)) {
        props.actCreateSkill(
          {
            tenantId,
            body: { ...skillInfo, version: 0 }
          },
          (err) => {
            if (!err) {
              onHideModal();
              message.success(translate('skill.createSkillSuccess'));
            } else {
              message.error(translate('skill.createSkillFail'));
            }
          }
        );
      } else {
        props.actUpdateSkillInfo(
          {
            tenantId,
            skillId: selectedSkillSetting.id,
            body: skillInfo
          },
          (err) => {
            if (!err) {
              onHideModal();
              message.success(translate('skill.updateSkillSuccess'));
            } else {
              message.error(translate('skill.updateSkillFail'));
            }
          }
        );
      }
    } catch (err) {}
  };

  return (
    <Modal
      title={
        _.isEmpty(selectedSkillSetting)
          ? translate('skill.createSkillSetting')
          : translate('skill.updateSkillSetting')
      }
      visible={modalVisible}
      cancelText={translate('cancel')}
      okText={
        _.isEmpty(selectedSkillSetting)
          ? translate('create')
          : translate('update')
      }
      maskClosable={false}
      onOk={onSubmitForm}
      onCancel={onHideModal}
      forceRender={true}
      destroyOnClose={true}
      getContainer={false}
      style={{ top: '1rem' }}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          label={translate('skill.name')}
          rules={[
            {
              required: true,
              message: `${translate('skill.name')} ${translate('isRequired')}`
            }
          ]}
          name="name"
        >
          <Input ref={firstInput} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default connect(
  (state) => ({
    selectedSkillSetting: state.SkillSetting.selectedSkillSetting,
    modalVisible: state.SkillSetting.modalVisible,
    tenantId: state?.App?.user?.tenant?.id
  }),
  {
    actSetModalSkillSettingVisible,
    actSetSelectedSkillSetting,
    actCreateSkill,
    actUpdateSkillInfo
  }
)(SkillSettingModal);
