import {
  GET_ROTATION_SETTING,
  UPDATE_ROTATION_INFO,
  DELETE_ROTATION_INFO,
  SAVE_DELETE_ROTATION_INFO,
  CREATE_ROTATION,
  SEARCH_ROTATION,
  SET_SELECTED_ROTATION_SETTING,
  SET_MODAL_ROTATION_SETTING_VISIBLE,
  APPLY_ROTATION,
  SOLVE_ROTATION,
  GET_SOLVE_ROTATION_STATUS,
  TERMINATE_ROTATION,
  PUBLISH_ROTATION,
  GET_ASSIGN_SCORE,
  GET_EXCEL_FILE
} from 'redux/type';

export const actGetRotationSetting = (...args) => ({
  type: GET_ROTATION_SETTING,
  args
});

export const actUpdateRotationInfo = (...args) => ({
  type: UPDATE_ROTATION_INFO,
  args
});

export const actDeleteRotationInfo = (...args) => ({
  type: DELETE_ROTATION_INFO,
  args
});

export const actSaveDeleteRotationInfo = (payload) => ({
  type: SAVE_DELETE_ROTATION_INFO,
  payload
});

export const actSetSelectedRotationSetting = (payload) => ({
  type: SET_SELECTED_ROTATION_SETTING,
  payload
});

export const actSetModalRotationSettingVisible = (payload) => ({
  type: SET_MODAL_ROTATION_SETTING_VISIBLE,
  payload
});

export const actCreateRotation = (...args) => ({
  type: CREATE_ROTATION,
  args
});

export const actSearchRotation = (payload) => ({
  type: SEARCH_ROTATION,
  payload
});

export const actApplyRotation = (...args) => ({
  type: APPLY_ROTATION,
  args
});

export const actSolveRotation = (...args) => ({
  type: SOLVE_ROTATION,
  args
});

export const actGetSolveRotationStatus = (...args) => ({
  type: GET_SOLVE_ROTATION_STATUS,
  args
});

export const actTerminateRotation = (...args) => ({
  type: TERMINATE_ROTATION,
  args
});

export const actPublishRotation = (...args) => ({
  type: PUBLISH_ROTATION,
  args
});

export const actGetAssignmentScore = (...args) => ({
  type: GET_ASSIGN_SCORE,
  args
});

export const actGetExcelFile = (...args) => ({
  type: GET_EXCEL_FILE,
  args
});
