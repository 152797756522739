import { useEffect, useState } from 'react';
import _ from 'lodash';
import { Table, Popconfirm, Button, Input, message } from 'antd';
import { connect } from 'react-redux';
import { FaRegEdit } from 'react-icons/fa';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { RiDeleteBin6Line } from 'react-icons/ri';
import {
  actGetShiftTypeSetting,
  actSearchShiftType,
  actCreateShiftType,
  actUpdateShiftTypeInfo,
  actDeleteShiftTypeInfo,
  actSaveDeleteShiftTypeInfo,
  actSetModalShiftTypeSettingVisible,
  actSetSelectedShiftTypeSetting,
  actSetModalBreakPairVisible,
  actSetSelectedBreakPair
} from 'redux/action/shiftTypeSetting';
import { convertTableFilter, convertTableSorter } from 'utils/helper';
import { TableWrapper, TableActionWrapper } from 'component/style';
import { useTranslation } from 'component/index';
import ModalShiftTypeSetting from './ModalShiftTypeSetting';
import ModalBreakPair from './ModalBreakPair';

const columnConfig = ({
  onDeleteShiftTypeInfo,
  onEditShiftType,
  onCreateBreakPair,
  getColumnSearchProps,
  translate
}) => [
  {
    title: translate('shiftType.name'),
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    width: `${100 / 4}%`,
    ...getColumnSearchProps('name')
  },
  {
    title: translate('shiftType.breakTime'),
    dataIndex: 'break_time',
    key: 'break_time',
    sorter: true,
    width: `${100 / 4}%`,
    ...getColumnSearchProps('break_time')
  },
  {
    title: translate('shiftType.weight'),
    dataIndex: 'weight',
    key: 'weight',
    sorter: true,
    width: `${100 / 4}%`,
    ...getColumnSearchProps('weight')
  },
  {
    title: translate('shiftType.hourlyRate'),
    dataIndex: 'hourly_rate',
    key: 'hourly_rate',
    width: `${100 / 4}%`,
    render: (value, record) => (value ? `${value} ¥` : '')
  },
  {
    title: translate('table.actions'),
    dataIndex: 'actions',
    key: 'actions',
    width: `${100 / 3}%`,
    render: (value, record) => (
      <TableActionWrapper>
        <FaRegEdit
          className="edit-icon"
          onClick={() => onEditShiftType(record)}
        />

        <Popconfirm
          onConfirm={() => onDeleteShiftTypeInfo(record)}
          title={translate('message.confirmToDelete')}
          okText={translate('yes')}
          cancelText={translate('no')}
        >
          <RiDeleteBin6Line className="delete-icon" />
        </Popconfirm>

        <PlusOutlined
          className="clone-icon"
          onClick={() => onCreateBreakPair(record)}
        />
      </TableActionWrapper>
    )
  },
  {
    title: ''
  }
];

const ShiftTypeTable = (props) => {
  const { tenantId, listShiftType } = props;

  let searchInput = null;
  const [isLoading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const { translate } = useTranslation();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10
  });

  useEffect(() => {
    setLoading(true);
    props.actGetShiftTypeSetting({ tenantId }, (err, res) => {
      setLoading(false);
    });
  }, [tenantId]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={translate('typeAkeyWord')}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {translate('search')}
        </Button>

        <Button
          onClick={() => handleReset(clearFilters, confirm)}
          size="small"
          style={{ width: 90 }}
        >
          {translate('reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => value.toString(),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) => {
      return searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={!_.isNull(text) ? text.toString() : ''}
        />
      ) : (
        text
      );
    }
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText('');
    confirm();
  };

  const onEditShiftType = (record) => {
    props.actSetModalShiftTypeSettingVisible(true);
    props.actSetSelectedShiftTypeSetting(record);
  };

  const onCreateBreakPair = (record) => {
    props?.actSetModalBreakPairVisible(true);
    props?.actSetSelectedBreakPair({});
    props?.actSetSelectedShiftTypeSetting(record);
  };

  const onEditBreakPair = (record) => {
    props?.actSetModalBreakPairVisible(true);
    props?.actSetSelectedBreakPair(record);
  };

  const onDeleteBreakPair = (record) => {
    let listBreakPair =
      _.find(listShiftType, {
        id: record?.shiftTypeId
      })?.break_pairs || [];

    const indexOfBreakPair = _.findIndex(listBreakPair, { id: record?.id });
    listBreakPair = listBreakPair?.map((breakPair) => ({
      break_time: breakPair?.break_time,
      spot_right: breakPair?.spot_right?.id
    }));

    listBreakPair?.splice(indexOfBreakPair, 1);

    props.actUpdateShiftTypeInfo(
      {
        tenantId,
        spotId: record?.shiftTypeId,
        body: {
          break_pairs: listBreakPair
        }
      },
      (err) => {
        if (!err) {
          message.success(translate('shiftType.deleteBreakPairSuccess'));
        } else {
          message.error(translate('shiftType.updateFail'));
        }
      }
    );
  };

  const onDeleteShiftTypeInfo = (team) => {
    props.actDeleteShiftTypeInfo({ tenantId, spotId: team.id }, (err) => {
      if (!err) {
        props.actSaveDeleteShiftTypeInfo({ id: team.id });
        message.success(translate('shiftType.deleteSuccess'));
      } else {
        message.error(translate('shiftType.deleteFail'));
      }
    });
  };

  const onShowTotalData = () => {
    return `${listShiftType.length} ${translate('table.rowInTotal')}`;
  };

  const onTableChange = (pagination, filter, sorter) => {
    setPagination(pagination);
    const tableFilter = convertTableFilter(filter);
    const tableSorter = convertTableSorter(sorter);

    props.actSearchShiftType({ filter: tableFilter, sorter: tableSorter });
  };

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: translate('shiftType.name'),
        dataIndex: 'shiftName',
        key: 'shiftName',
        width: '25%',
        render: (text) => (
          <span
            style={{
              fontSize: '1.3rem',
              fontWeight: 600,
              paddingLeft: '5rem'
            }}
          >
            {text}
          </span>
        )
      },
      {
        title: translate('shiftType.breakTime'),
        dataIndex: 'breakTime',
        key: 'breakTime',
        width: '10%'
      },
      {
        title: translate('table.actions'),
        dataIndex: 'actions',
        key: 'actions',
        width: '10%',
        render: (value, record) => (
          <TableActionWrapper
            style={{
              display: 'flex',
              flexDirection: 'flex-start'
            }}
          >
            <FaRegEdit
              className="edit-icon"
              onClick={() => onEditBreakPair(record)}
            />

            <Popconfirm
              onConfirm={() => onDeleteBreakPair(record)}
              title={translate('message.confirmToDelete')}
              okText={translate('yes')}
              cancelText={translate('no')}
            >
              <RiDeleteBin6Line className="delete-icon" />
            </Popconfirm>
          </TableActionWrapper>
        )
      },
      {
        title: '',
        dataIndex: 'empty',
        key: 'empty',
        width: '55%'
      }
    ];

    const data = record?.break_pairs?.map((breakPair) => ({
      id: breakPair?.id,
      shiftName: `${record?.name} - ${breakPair?.spot_right?.name}`,
      breakTime: breakPair?.break_time,
      shiftTypeId: record?.id
    }));

    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        size="small"
        showHeader={false}
      />
    );
  };

  const columns = columnConfig({
    onDeleteShiftTypeInfo,
    onEditShiftType,
    onCreateBreakPair,
    getColumnSearchProps,
    translate
  });

  return (
    <TableWrapper>
      <Table
        columns={columns}
        rowKey={(data) => data.id}
        dataSource={listShiftType}
        expandable={{
          expandedRowRender,
          rowExpandable: (record) => record?.break_pairs?.length > 0,
          defaultExpandAllRows: true
        }}
        pagination={{
          ...pagination,
          showTotal: onShowTotalData,
          pageSizeOptions: [10, 20, 30, 40],
          showSizeChanger: true
        }}
        size="large"
        rowClassName="editable-row"
        loading={isLoading}
        onChange={onTableChange}
      />

      <ModalShiftTypeSetting />
      <ModalBreakPair />
    </TableWrapper>
  );
};

export default connect(
  (state) => ({
    tenantId: state?.App?.user?.tenant?.id,
    listShiftType: state.ShiftTypeSetting.listShiftType
  }),
  {
    actGetShiftTypeSetting,
    actSearchShiftType,
    actCreateShiftType,
    actUpdateShiftTypeInfo,
    actDeleteShiftTypeInfo,
    actSaveDeleteShiftTypeInfo,
    actSetModalShiftTypeSettingVisible,
    actSetSelectedShiftTypeSetting,
    actSetModalBreakPairVisible,
    actSetSelectedBreakPair
  }
)(ShiftTypeTable);
