import React, { useState } from 'react';
import { Input, Button } from 'antd';
import { connect } from 'react-redux';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import ContractTable from 'component/ContractSetting/ContractTable';
import {
  actSearchContract,
  actSetModalContractSettingVisible,
  actSetSelectedContractSetting
} from 'redux/action/contractSetting';
import { SEARCH_TABLE_TIMEOUT } from 'utils/constant';
import { useTranslation } from 'component/index';

import { ContractSettingPageWrapper } from './index.style';

let searchTimeout = null;

const ContractSettingPage = (props) => {
  const [searchText, setSearchText] = useState(false);
  const { translate } = useTranslation();

  const onSearchChange = (event) => {
    const { value } = event.target;

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchText(value);

    searchTimeout = setTimeout(() => {
      onSearch(value);
    }, SEARCH_TABLE_TIMEOUT);
  };

  const onCreateContractSetting = () => {
    props.actSetModalContractSettingVisible(true);
    props.actSetSelectedContractSetting({});
  };

  const onSearch = (searchValue) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    if (!!searchValue) {
      props.actSearchContract({
        filterOr: {
          name: searchValue,
          maximum_minutes_per_day: searchValue,
          maximum_minutes_per_week: searchValue,
          maximum_minutes_per_month: searchValue,
          maximum_minutes_per_year: searchValue,
          minimum_minutes_per_week: searchValue
        },
        sorter: {},
        filter: {}
      });
    } else {
      props.actSearchContract({
        filterOr: {}
      });
    }
  };

  return (
    <ContractSettingPageWrapper>
      <div className="header-wrapper">
        <div className="label">{translate('contract.header.contracts')}</div>
        <div className="filter-wrapper">
          <div className="search-bar">
            <Input
              prefix={<SearchOutlined />}
              size="large"
              className="input-border"
              style={{ width: '25rem' }}
              placeholder={translate('searchByKeyword')}
              onChange={onSearchChange}
              searchText={searchText}
              onPressEnter={() => onSearch(searchText)}
            />
          </div>

          <Button
            type="primary"
            icon={<PlusOutlined />}
            className="custom-button"
            size="large"
            onClick={onCreateContractSetting}
          >
            {translate('contract.addContract')}
          </Button>
        </div>
      </div>

      <div className="main-content">
        <ContractTable />
      </div>
    </ContractSettingPageWrapper>
  );
};

export default connect((state) => ({}), {
  actSearchContract,
  actSetModalContractSettingVisible,
  actSetSelectedContractSetting
})(ContractSettingPage);
