import { useState, useEffect } from 'react';
import { Button, DatePicker, Popconfirm, message, Modal } from 'antd';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import RotationTable from 'component/Rotation/RotationTable';
import {
  actSetModalRotationSettingVisible,
  actSetSelectedRotationSetting,
  actApplyRotation,
  actGetRotationSetting
} from 'redux/action/rotationSetting';
import { actGetListRotationTab } from 'redux/action/rotationTab';
import { actGetEmployeeSetting } from 'redux/action/employeeSetting';
import { actSetGlobalTime } from 'redux/action/grid';
import { useTranslation } from 'component/index';
import { RotationSettingPageWrapper } from './style';
import SettingsForm from './SettingsForm';
import RotationTab from './RotationTab/index';

const RotationSettingPage = (props) => {
  const { tenantId, first_draft_date, listRotation } = props;
  const { translate } = useTranslation();
  const [isPublishBtnLoading, setPublishBtnLoading] = useState(false);
  const [applyButtonWarning, setApplyWarning] = useState('');
  const [openSettingsForm, setOpenSettingsForm] = useState(false);
  const [selectedTab, setSelectedTab] = useState(null);

  useEffect(() => {
    props.actGetListRotationTab({ tenantId });
    props.actGetEmployeeSetting({ tenantId });
  }, [tenantId]);

  useEffect(() => {
    if (_.find(listRotation, { is_time_different: true })) {
      setApplyWarning(translate('rotation.applyConfirmTimeUpdate'));
    } else if (_.find(listRotation, { is_updated_after_applying: true })) {
      setApplyWarning(translate('rotation.ApplyMessagePinned'));
    } else {
      setApplyWarning(translate('rotation.applyConfirm'));
    }
  }, [listRotation, translate]);

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && +current.month() !== +moment(first_draft_date).month();
  };

  const onDateChange = (date) => {
    props.actSetGlobalTime(date.format('x'));
  };

  const onPublishRotation = () => {
    setPublishBtnLoading(true);

    props.actApplyRotation(
      {
        tenantId,
        body: {
          year: moment(first_draft_date).year(),
          month: moment(first_draft_date).month() + 1
        }
      },
      (err) => {
        setPublishBtnLoading(false);
        if (err) {
          message.error(err.message);
        } else {
          message.success(translate('rotation.applySucess'));
          props.actGetRotationSetting({
            tenantId,
            year: moment(first_draft_date).year(),
            month: moment(first_draft_date).month() + 1
          });
        }
      }
    );
  };

  return (
    <RotationSettingPageWrapper>
      <div className="header-wrapper">
        <div className="label">{translate('rotation.header.rotation')}</div>

        <div className="filter-wrapper">
          <div className="filter-date">
            <DatePicker
              size="large"
              picker="month"
              format="MMMM YYYY"
              className="input-border"
              style={{ width: '25rem' }}
              disabledDate={disabledDate}
              onChange={onDateChange}
              value={moment(first_draft_date)}
              allowClear={false}
            />
          </div>

          <Popconfirm
            onConfirm={onPublishRotation}
            title={applyButtonWarning}
            okText={translate('yes')}
            cancelText={translate('no')}
            placement="bottom"
          >
            <Button
              type="primary"
              size="large"
              className="custom-button"
              loading={isPublishBtnLoading}
              style={{ marginRight: '2.5rem' }}
            >
              {translate('rotation.apply')}
            </Button>
          </Popconfirm>
        </div>
      </div>

      <div className="list-tab">
        <RotationTab
          setOpenSettingsForm={setOpenSettingsForm}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </div>

      <div className="main-content">
        <RotationTable tabId={selectedTab} />
      </div>

      <Modal
        title={translate('rotation.settings')}
        visible={openSettingsForm}
        OpenSettingsForm={translate('cancel')}
        maskClosable={false}
        footer={null}
        onCancel={() => setOpenSettingsForm(false)}
        destroyOnClose={true}
        width="60rem"
      >
        <SettingsForm onSubmit={() => setOpenSettingsForm(false)} />
      </Modal>
    </RotationSettingPageWrapper>
  );
};

export default connect(
  (state) => ({
    globalTime: state.App.globalTime,
    tenantId: state?.App?.user?.tenant?.id,
    first_draft_date: state.App.first_draft_date,
    listRotation: state.RotationSetting.listRotation
  }),
  {
    actSetModalRotationSettingVisible,
    actSetSelectedRotationSetting,
    actSetGlobalTime,
    actApplyRotation,
    actGetRotationSetting,
    actGetListRotationTab,
    actGetEmployeeSetting
  }
)(RotationSettingPage);
