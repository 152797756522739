import { PURGE } from 'redux-persist';
import _ from 'lodash';
import {
  SAVE_GET_EMPLOYEE_SETTING,
  SAVE_DELETE_EMPLOYEE_INFO,
  SAVE_CREATE_EMPLOYEE,
  SEARCH_EMPLOYEE,
  SET_SELECTED_EMPLOYEE_SETTING,
  SET_MODAL_EMPLOYEE_SETTING_VISIBLE,
  SAVE_UPDATE_EMPLOYEE_INFO,
  SAVE_LIST_ASSIGNMENT_COLUMN,
  SEARCH_EMPLOYEE_ANTI_PATTERN,
  SAVE_GET_EMPLOYEE_BY_ID
} from '../type';
import { filterDataOnTable } from 'utils/helper';
import { NOT_SENT_EMAIL } from 'utils/constant';
import { update, parseEmployeeInfo } from './helper';

const initialState = {
  listEmployee: [],
  listData: [],
  filter: {},
  sorter: {},
  filterOr: {},
  modalVisible: false,
  selectedEmployeeSetting: {},
  searchEmployee: []
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case SAVE_GET_EMPLOYEE_SETTING: {
      const listEmployee = payload.map((emp) => {
        const empInfo = parseEmployeeInfo(emp);
        return empInfo;
      });

      return {
        ...state,
        listEmployee: listEmployee || [],
        listData: listEmployee || []
      };
    }

    case SAVE_DELETE_EMPLOYEE_INFO:
      return update(state, {
        listEmployee: { $updateOrDelete: [payload.id] },
        listData: { $updateOrDelete: [payload.id] }
      });

    case SAVE_UPDATE_EMPLOYEE_INFO:
      return update(state, {
        listEmployee: {
          $updateOrDelete: [payload.id, parseEmployeeInfo(payload)]
        },
        listData: { $updateOrDelete: [payload.id, parseEmployeeInfo(payload)] }
      });

    case SAVE_CREATE_EMPLOYEE:
      const employee = parseEmployeeInfo(payload);

      return {
        ...state,
        listEmployee: [employee, ...state.listEmployee],
        listData: [employee, ...state.listData]
      };

    case SEARCH_EMPLOYEE:
      const { sorter, filter, emailStatus, matchExact } = payload;
      const filterOr = payload.filterOr || state.filterOr;

      let dataSource = [];

      if (emailStatus) {
        dataSource = state.listData.map((emp) => {
          const empEmailStatus = _.find(
            emailStatus,
            (obj) =>
              obj?.employee?.id === emp.id && obj?.employee?.name === emp.name
          );

          return {
            ...emp,
            emailStatus: empEmailStatus?.email_status || NOT_SENT_EMAIL
          };
        });
      } else {
        dataSource = state.listData;
      }

      return {
        ...state,
        filter,
        sorter: !_.isEmpty(sorter) ? sorter : state.sorter,
        filterOr: filterOr,
        listEmployee: filterDataOnTable({
          listData: dataSource,
          filter,
          filterOr: filterOr || {},
          sorter,
          matchExact
        })
      };

    case SAVE_LIST_ASSIGNMENT_COLUMN:
      return {
        ...state,
        listEmployee: payload
      };

    case SET_SELECTED_EMPLOYEE_SETTING:
      return {
        ...state,
        selectedEmployeeSetting: payload
      };

    case SAVE_GET_EMPLOYEE_BY_ID:
      return {
        ...state,
        selectedEmployeeSetting: parseEmployeeInfo(payload)
      };

    case SET_MODAL_EMPLOYEE_SETTING_VISIBLE:
      return {
        ...state,
        modalVisible: payload
      };

    case SEARCH_EMPLOYEE_ANTI_PATTERN:
      return {
        ...state,
        searchEmployee: payload
      };

    default:
      return state;
  }
};

export default reducer;
