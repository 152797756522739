import { connect } from 'react-redux';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { MONTH_STRING } from 'utils/constant';
import { useTranslation } from 'component/index';
import { CHART_MODE } from 'screen/TimeReport';
import { ScheduleVsRealWrapper } from './style';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ScheduleVsReal = (props) => {
  const { scheduled, chartMode } = props;
  const { translate } = useTranslation();

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true
      },
      title: {
        display: false
      }
    },
    scales: {
      x: {
        stacked: chartMode === CHART_MODE.STACKED
      },
      y: {
        stacked: chartMode === CHART_MODE.STACKED,
        ticks: {
          beginAtZero: true,
          callback: function (value) {
            return `${value} ${translate('timeReport.hours')}`;
          }
        }
      }
    }
  };

  const labels = scheduled?.map((item) =>
    translate('dashboardEmployeeSchedule.' + MONTH_STRING[item?.month - 1])
  );

  const data = {
    labels,
    datasets: [
      {
        label: 'Schedule',
        data: scheduled?.map((item) => item?.scheduled),
        backgroundColor: 'rgb(94, 145, 214)'
      },
      {
        label: 'Real',
        data: scheduled?.map((item) => item?.real),
        backgroundColor: 'rgb(214, 94, 148)'
      }
    ]
  };

  return (
    <ScheduleVsRealWrapper>
      <div className="title">{translate('timeReport.scheduledVsReal')}</div>
      <Bar options={options} data={data} />
    </ScheduleVsRealWrapper>
  );
};

export default connect(
  (state) => ({
    scheduled: state?.TimeReport?.statistic?.scheduled || []
  }),
  {}
)(ScheduleVsReal);
