import { PURGE } from 'redux-persist';
import _ from 'lodash';
import {
  SAVE_GET_ROTATION_SETTING,
  SAVE_DELETE_ROTATION_INFO,
  SAVE_CREATE_ROTATION,
  SEARCH_ROTATION,
  SET_SELECTED_ROTATION_SETTING,
  SET_MODAL_ROTATION_SETTING_VISIBLE,
  SAVE_UPDATE_ROTATION_INFO,
  SAVE_ASSIGN_SCORE
} from '../type';
import { filterDataOnTable } from 'utils/helper';
import { update, parseRotation } from './helper';

const initialState = {
  listRotation: [],
  listData: [],
  filter: {},
  sorter: {},
  filterOr: {},
  modalVisible: false,
  selectedRotationSetting: {},
  assignmentScore: {}
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case SAVE_GET_ROTATION_SETTING:
      const listRotation = payload.map((rotation) => parseRotation(rotation));

      return {
        ...state,
        listRotation,
        listData: listRotation
      };

    case SAVE_DELETE_ROTATION_INFO:
      return update(state, {
        listRotation: { $updateOrDelete: [payload.id] },
        listData: { $updateOrDelete: [payload.id] }
      });

    case SAVE_UPDATE_ROTATION_INFO:
      return update(state, {
        listRotation: {
          $updateOrDelete: [payload.id, parseRotation(payload)]
        },
        listData: { $updateOrDelete: [payload.id, parseRotation(payload)] }
      });

    case SAVE_CREATE_ROTATION:
      return {
        ...state,
        listRotation: [parseRotation(payload), ...state.listRotation],
        listData: [parseRotation(payload), ...state.listData]
      };

    case SEARCH_ROTATION:
      const { sorter, filter } = payload;
      const filterOr = payload.filterOr || state.filterOr;

      return {
        ...state,
        filter,
        sorter: !_.isEmpty(sorter) ? sorter : state.sorter,
        filterOr: filterOr,
        listRotation: filterDataOnTable({
          listData: state.listData,
          filter,
          filterOr: filterOr || {},
          sorter
        })
      };

    case SET_SELECTED_ROTATION_SETTING:
      return {
        ...state,
        selectedRotationSetting: payload
      };

    case SET_MODAL_ROTATION_SETTING_VISIBLE:
      return {
        ...state,
        modalVisible: payload
      };

    case SAVE_ASSIGN_SCORE:
      return {
        ...state,
        assignmentScore: payload
      };

    default:
      return state;
  }
};

export default reducer;
