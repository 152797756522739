import styled from 'styled-components';

const FeatureSectionWrapper = styled.div`
  margin-top: 25rem;

  .main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .heading {
      font-size: 4rem;
      font-weight: 550;
      color: var(--color-text);
      margin-bottom: 3rem;
    }

    .list-feature {
      width: 80%;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
    }
  }
`;

const FeatureItemWrapper = styled.div`
  flex-basis: 33.3333%;
  padding: 0 5rem;
  margin-bottom: 3rem;

  .main {
    padding: 4rem 2rem;
    box-shadow: 0px -1px 11px #0000000f;

    .icon-wrapper {
      margin-bottom: 1.5rem;
    }

    .title {
      margin-bottom: 0.6rem;
      color: var(--color-text);
      font-size: 2.1rem;
      text-align: center;
    }

    .description {
      font-size: 1.5rem;
      color: var(--color-text);
      font-weight: 300;
      text-align: center;
    }
  }
`;

export { FeatureSectionWrapper, FeatureItemWrapper };
