import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Table, Popconfirm, message } from 'antd';
import { connect } from 'react-redux';
import { FaRegEdit } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';

import {
  actGetShiftGroup,
  actCreateShiftGroup,
  actDeleteShiftGroup,
  actSaveDeleteShiftGroup,
  actSetModalShiftGroupSettingVisible,
  actSetSelectedShiftGroupSetting
} from 'redux/action/shiftGroupSetting';
import { TableWrapper, TableActionWrapper } from 'component/style';
import { useTranslation } from 'component/index';

import ModalShiftGroupSetting from './ModalShiftGroupSetting';

const columnConfig = ({ onDeleteShiftGroup, onEditShiftGroup, translate }) => [
  {
    title: translate('shiftGroup.name'),
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    width: `${100 / 4}%`
  },
  {
    title: translate('shiftGroup.description'),
    dataIndex: 'description',
    key: 'description',
    sorter: true,
    width: `${100 / 2}%`
  },
  {
    title: translate('table.actions'),
    dataIndex: 'actions',
    key: 'actions',
    width: `${100 / 4}%`,
    render: (value, record) => (
      <TableActionWrapper>
        <FaRegEdit
          className="edit-icon"
          onClick={() => onEditShiftGroup(record)}
        />

        <Popconfirm
          onConfirm={() => onDeleteShiftGroup(record)}
          title={translate('message.confirmToDelete')}
          okText={translate('yes')}
          cancelText={translate('no')}
        >
          <RiDeleteBin6Line className="delete-icon" />
        </Popconfirm>
      </TableActionWrapper>
    )
  },
  {
    title: ''
  }
];

const ShiftGroupTable = (props) => {
  const { tenantId, listShiftGroup } = props;
  const [isLoading, setLoading] = useState(false);
  const { translate } = useTranslation();

  useEffect(() => {
    setLoading(true);
    props.actGetShiftGroup({ tenantId }, (err, res) => {
      setLoading(false);
    });
  }, [tenantId]);

  const onEditShiftGroup = (record) => {
    props.actSetModalShiftGroupSettingVisible(true);
    props.actSetSelectedShiftGroupSetting(record);
  };

  const onDeleteShiftGroup = (shiftGroup) => {
    props.actDeleteShiftGroup(
      { tenantId, shiftGroupId: shiftGroup.id },
      (err) => {
        if (!err) {
          props.actSaveDeleteShiftGroup({ id: shiftGroup.id });
          message.success(translate('shiftGroup.deleteSuccess'));
        } else {
          message.error(translate('shiftGroup.deleteFail'));
        }
      }
    );
  };

  const columns = columnConfig({
    onDeleteShiftGroup,
    onEditShiftGroup,
    translate
  });

  return (
    <TableWrapper>
      <Table
        columns={columns}
        rowKey={(data) => data.id}
        dataSource={listShiftGroup}
        size="large"
        rowClassName="editable-row"
        loading={isLoading}
      />

      <ModalShiftGroupSetting />
    </TableWrapper>
  );
};

export default connect(
  (state) => ({
    tenantId: state?.App?.user?.tenant?.id,
    listShiftGroup: state.ShiftGroupSetting.listShiftGroup
  }),
  {
    actGetShiftGroup,
    actCreateShiftGroup,
    actDeleteShiftGroup,
    actSaveDeleteShiftGroup,
    actSetModalShiftGroupSettingVisible,
    actSetSelectedShiftGroupSetting
  }
)(ShiftGroupTable);
