import {
  GET_PROFILE_DETAILS,
  SAVE_PROFILE_DETAILS,
  UPDATE_PROFILE_DETAILS,
  SET_MODAL_PROFILE_VISIBLE,
  UPDATE_PASSWORD,
  UPDATE_DEFAULT_PREFERENCE,
  GET_PDF_DOCUMENTATION,
  GET_RULE_STATUS,
  UPDATE_RULE_STATUS,
  RESET_RULE_STATUS
} from 'redux/type';

export const actGetProfileDetails = (...args) => ({
  type: GET_PROFILE_DETAILS,
  args
});

export const actSaveProfileDetails = (payload) => ({
  type: SAVE_PROFILE_DETAILS,
  payload
});

export const actUpdateProfileDetails = (...args) => ({
  type: UPDATE_PROFILE_DETAILS,
  args
});
export const actSetModalProfileVisible = (payload) => ({
  type: SET_MODAL_PROFILE_VISIBLE,
  payload
});
export const actUpdatePassword = (...args) => ({
  type: UPDATE_PASSWORD,
  args
});

export const actUpdateDefaultPreference = (...args) => ({
  type: UPDATE_DEFAULT_PREFERENCE,
  args
});

export const actGetPdfDocumentation = (...args) => ({
  type: GET_PDF_DOCUMENTATION,
  args
});

export const actGetRuleAndStatus = (...args) => ({
  type: GET_RULE_STATUS,
  args
});

export const actUpdateRuleAndStatus = (...args) => ({
  type: UPDATE_RULE_STATUS,
  args
});

export const actResetRuleAndStatus = (...args) => ({
  type: RESET_RULE_STATUS,
  args
});
