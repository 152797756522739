import styled from 'styled-components';

const ViewPreferencesPageWrapper = styled.div`
  padding: 10vh 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;

  .title {
    align-self: flex-start;
    margin-bottom: 1rem;
    margin-top: -2rem;
    font-size: 2rem;
    font-style: italic;
    display: flex;

    .emp-name {
      margin-right: auto;
    }

    span {
      font-weight: 700;
    }
  }

  .grid-wrapper {
    width: 100%;
    height: 100%;
  }
  .action_wrap {
    flex-basis: 10%;
  }
  .grid__cont {
    flex-basis: 90%;
  }
  .action-item1 {
    display: flex;
    margin-top: 20px;
  }

  .img-icon {
    height: 2rem;
    width: 2rem;
    margin-right: 10px;
  }

  .spinner {
    height: 100%;
    margin-top: 20%;
    text-align: center;
  }
`;

const PreferenceNotFoundWrapper = styled.div`
  width: 20rem;
  height: 20rem;
  height: 100%;
  width: 100%;
`;

export { ViewPreferencesPageWrapper, PreferenceNotFoundWrapper };
