import styled from 'styled-components';

const ConfirmPreferenceWrapper = styled.div`
  .preference {
    width: 100%;
    font-size: 16px;
    border: 1px solid #eee;
  }

  .preference th,
  td {
    vertical-align: top;
    padding: 5px;
    align-items: left;
  }

  .preference th {
    font-style: italic;
    font-weight: 400;
  }

  .calendar {
    width: 1200px;
    font-size: 16px;
  }

  .calendar tr,
  .calendar td {
    border: 1px solid #ddd;
  }

  .calendar th {
    border: 1px solid #ddd;
    text-align: center;
    font-size: 16px !important;
    font-weight: 600 !important;
    font-style: normal;
  }

  .calendar td {
    min-height: 100%;
    vertical-align: top;
    padding: 5px 0px 0px 5px;
    width: 200px;
  }

  .calendar ul {
    display: block;
    height: 100%;
    padding: 0px 5px 0px 5px;
    list-style-type: none;
    padding-inline-start: 0;
  }

  .calendar li {
    font-size: 12px;
    align-items: center;
    text-align: center;
    margin: 5px;
    border-radius: 3px;
    border: 1px solid #767a7c;
    border-left: 8px solid #767a7c;
    background-color: #ebebeb;
    cursor: pointer;
    position: relative;
    padding: 5px;
  }

  .month {
    font-size: 25px;
  }

  .date {
    font-size: 16px;
    display: flex;
    justify-content: flex-end !important;
    margin: 5px;
  }

  a {
    color: #17a2b8;
  }
`;
const PreferenceGridWrapper = styled.div`
  max-width: 100%;

  .day-cell {
    color: var(--color-text);
    font-size: 1.4rem;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 1rem;
  }

  .gray-cell {
    background: repeating-linear-gradient(
      -45deg,
      #f2f2f2,
      #f2f2f2 10px,
      white 10px,
      white 20px
    ) !important;
    box-sizing: border-box;
    cursor: not-allowed;
  }

  .light-gray-cell {
    background-color: rgba(0, 0, 0, 0.06) !important;
  }

  .holiday {
    background-color: rgba(230, 129, 150, 0.2) !important;
  }
`;

const CustomCellWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const TimeItemWrapper = styled.div`
  color: var(--color-text);
  margin-bottom: 1.5rem;
  border-radius: 3px;
  border: 1px solid ${({ itemBorderColor }) => itemBorderColor};
  border-left: 8px solid ${({ itemBorderColor }) => itemBorderColor};
  background-color: ${({ itemBackgroundColor }) => itemBackgroundColor};
  position: relative;
  padding: 1rem 0.7rem;
  cursor: ${({ isDisable }) => (isDisable ? 'not-allowed' : 'pointer')};

  .image-wrapper {
    margin-left: 1rem;
    margin-right: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      height: 1rem;
      width: 1rem;
    }
  }

  .main {
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-wrapper {
      display: flex;
      align-items: flex-start;
      position: absolute;
      top: 0.3rem;
      left: 0.3rem;
      font-size: 1.2rem;
    }
  }

  .change-status {
    display: flex;
    margin-top: 0.3rem;
    align-items: center;
    justify-content: center;
  }
`;

export {
  ConfirmPreferenceWrapper,
  PreferenceGridWrapper,
  CustomCellWrapper,
  TimeItemWrapper
};
