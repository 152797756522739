import styled from 'styled-components';

const ModalExportWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .group {
    flex-basic: 45%;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;

    img {
      width: 100%;
      margin-bottom: 1rem;
    }
  }
`;

export { ModalExportWrapper };
