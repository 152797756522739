import React, { useEffect, useRef, useState } from 'react';
import { Modal, Form, Input, Select, message } from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import { useTranslation } from 'component/index';
import {
  actSetModalEmployeeReasonVisible,
  actSetSelectedEmployeeReason,
  actCreateEmployeeReason,
  actUpdateEmployeeReasonInfo
} from 'redux/action/employeeReason';
import { LIST_REASON_STATE, ICON_CONFIG } from 'utils/constant';

const { Option } = Select;

const EmployeeReasonModal = (props) => {
  const { modalVisible, selectedEmployeeReason, tenantId, listEmployeeReason } =
    props;

  const firstInput = useRef(null);
  const [listAvailableIcon, setListAvailableIcon] = useState([]);
  const [form] = Form.useForm();
  const { translate } = useTranslation();

  useEffect(() => {
    const { name, state, icon } = selectedEmployeeReason;
    let tmpListIcon;
    if (!_.isEmpty(selectedEmployeeReason)) {
      tmpListIcon = ICON_CONFIG.filter(
        (item) => !_.find(listEmployeeReason, { icon: item }) || item === icon
      );
    } else {
      tmpListIcon = ICON_CONFIG.filter(
        (item) => !_.find(listEmployeeReason, { icon: item })
      );
    }

    setListAvailableIcon(tmpListIcon);

    const indexOfIcon = _.findIndex(ICON_CONFIG, (item) => item === icon);

    form.setFieldsValue({
      name,
      state: state ? parseInt(state) : null,
      iconIndex: indexOfIcon !== -1 ? indexOfIcon : null
    });
  }, [listEmployeeReason, selectedEmployeeReason]);

  useEffect(() => {
    if (modalVisible) {
      firstInput.current.focus();
    }
  }, [modalVisible]);

  const onHideModal = () => {
    props.actSetModalEmployeeReasonVisible(false);
    props.actSetSelectedEmployeeReason({});
  };

  const onSubmitForm = async () => {
    try {
      const { name, state, iconIndex } = await form.validateFields([
        'name',
        'state',
        'iconIndex'
      ]);

      const employeeReasonInfo = {
        name,
        state,
        icon: ICON_CONFIG[iconIndex],
        tenant: tenantId
      };

      if (_.isEmpty(selectedEmployeeReason)) {
        props.actCreateEmployeeReason(
          {
            tenantId,
            body: employeeReasonInfo
          },
          (err) => {
            if (!err) {
              onHideModal();
              message.success(translate('employeeReason.createInfoSucess'));
            } else {
              message.error(err.message);
            }
          }
        );
      } else {
        props.actUpdateEmployeeReasonInfo(
          {
            tenantId,
            employeereasonId: selectedEmployeeReason.id,
            body: employeeReasonInfo
          },
          (err) => {
            if (!err) {
              onHideModal();
              message.success(translate('employeeReason.updateInfoSuccess'));
            } else {
              message.error(err.message);
            }
          }
        );
      }
    } catch (err) {}
  };

  return (
    <Modal
      title={
        _.isEmpty(selectedEmployeeReason)
          ? translate('employeeReason.createEmployeeReason')
          : translate('employeeReason.updateEmployeeReason')
      }
      visible={modalVisible}
      cancelText={translate('cancel')}
      okText={
        _.isEmpty(selectedEmployeeReason)
          ? translate('create')
          : translate('update')
      }
      maskClosable={false}
      onOk={onSubmitForm}
      onCancel={onHideModal}
      forceRender={true}
      destroyOnClose={true}
      getContainer={false}
      style={{ top: '1rem' }}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          label={translate('employeeReason.name')}
          rules={[
            {
              required: true,
              message: `${translate('employeeReason.name')} ${translate(
                'isRequired'
              )}`
            }
          ]}
          name="name"
        >
          <Input ref={firstInput} />
        </Form.Item>

        <Form.Item
          label={translate('employeeReason.state')}
          rules={[
            {
              required: true,
              message: `${translate('employeeReason.state')} ${translate(
                'isRequired'
              )}`
            }
          ]}
          name="state"
        >
          <Select
            style={{ width: '100%' }}
            placeholder="Pick a state"
            disabled={selectedEmployeeReason.default}
          >
            {LIST_REASON_STATE.map((state, index) => (
              <Option value={index} key={index}>
                {state}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={translate('employeeReason.icon')}
          name="iconIndex"
          rules={[
            {
              required: true,
              message: `${translate('employeeReason.icon')} ${translate(
                'isRequired'
              )}`
            }
          ]}
        >
          <Select
            style={{ width: '100%' }}
            placeholder="Pick a icon"
            disabled={selectedEmployeeReason.default}
          >
            {listAvailableIcon.map((iconUrl, index) => (
              <Option key={index} value={ICON_CONFIG.indexOf(iconUrl)}>
                <img
                  src={iconUrl}
                  style={{
                    width: '1.5rem',
                    height: '1.5rem',
                    marginRight: '1.5rem'
                  }}
                  alt=""
                />
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default connect(
  (state) => ({
    selectedEmployeeReason: state.EmployeeReason.selectedEmployeeReason,
    modalVisible: state.EmployeeReason.modalVisible,
    listEmployeeReason: state.EmployeeReason.listEmployeeReason,
    tenantId: state?.App?.user?.tenant?.id
  }),
  {
    actSetModalEmployeeReasonVisible,
    actSetSelectedEmployeeReason,
    actCreateEmployeeReason,
    actUpdateEmployeeReasonInfo
  }
)(EmployeeReasonModal);
