import { useEffect, useState, Fragment, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import moment from 'moment';
import _ from 'lodash';

import { actGetEmployeePreference } from 'redux/action/preference';
import { actSetAccessToken } from 'redux/action/auth';
import { LanguageContext } from 'languages/index';
import { useTranslation } from 'component/index';
import PreferenceGrid from 'component/Preference';
import LanguageSwitcher from 'component/LanguageSwitcher';
import { MONTH_NAME } from 'utils/constant';

import { ViewPreferencesPageWrapper } from './style';
import PreferenceNotFound from './PreferenceNotFound';

const ViewPreference = (props) => {
  const { translate } = useTranslation();
  const { search } = useLocation();

  const { selectedEmployee, listEmployeeReason } = props;
  const { hashCode } = useParams();

  const [mode, setMode] = useState();
  const [hashTime, setHashTime] = useState();
  const [showGrid, setShowGrid] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const [isLinkExpired, setIsLinkExpired] = useState(false);

  const locale = new URLSearchParams(search).get('locale');
  const { onChangeLocale } = useContext(LanguageContext);

  useEffect(() => {
    if (Boolean(locale)) {
      // update global context for locale
      onChangeLocale(locale);
    }
  }, [locale]);

  const renderListAction = () =>
    listEmployeeReason.map((item, index) => (
      <div className="action-item1" key={index}>
        <div className="icon-wrapper">
          <img className="img-icon" src={item.icon} alt="" />
        </div>

        <div className="action-label">{item.name}</div>
      </div>
    ));

  useEffect(() => {
    if (hashCode) {
      props.actGetEmployeePreference(
        {
          hashCode
        },
        (err, res) => {
          props?.actSetAccessToken(res?.access_token);
          setSpinner(false);
          setShowGrid(true);

          if (res) {
            const monthIndex = MONTH_NAME.indexOf(res.month);
            const newHashTime = moment().month(monthIndex).year(res.year);
            setHashTime(newHashTime.format('x'));
            setMode(res?.mode);
          } else {
            setIsLinkExpired(true);
          }
        }
      );
    }
  }, [hashCode]);

  return (
    <ViewPreferencesPageWrapper>
      {!showGrid && (
        <div className="spinner">
          <Spin spinning={spinner} size="large" />
        </div>
      )}

      {showGrid && (
        <div style={{ display: 'flex', width: '100%' }}>
          {!isLinkExpired ? (
            <Fragment>
              <div className="action_wrap">
                <div className="title">
                  {translate('preference.header.action')}
                </div>

                <div className="list-action1">{renderListAction()}</div>
              </div>

              <div className="grid__cont">
                <div className="title">
                  <div className="emp-name">
                    Employee {_.startCase(mode)}:{' '}
                    <span>{selectedEmployee?.name}</span>
                  </div>

                  <LanguageSwitcher />
                </div>

                <div className="grid-wrapper">
                  <PreferenceGrid
                    hashTime={hashTime}
                    employeeId={selectedEmployee?.employeeId || ''}
                    editable={mode ? mode === 'preferences' : true}
                  />
                </div>
              </div>
            </Fragment>
          ) : (
            <PreferenceNotFound />
          )}
        </div>
      )}
    </ViewPreferencesPageWrapper>
  );
};

export default connect(
  (state) => ({
    tenantId: state?.App?.user?.tenant?.id,
    selectedEmployee: state.Preference.selectedEmployee,
    listEmployeeReason: state.EmployeeReason.listEmployeeReason
  }),
  {
    actGetEmployeePreference,
    actSetAccessToken
  }
)(ViewPreference);
