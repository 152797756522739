import { Result } from 'antd';
import { useTranslation } from 'component/index';
import { PreferenceNotFoundWrapper } from './style';

const PreferenceNotFound = () => {
  const { translate } = useTranslation();

  return (
    <PreferenceNotFoundWrapper>
      <Result status="404" title={translate('preference.linkExpired')} />
    </PreferenceNotFoundWrapper>
  );
};

export default PreferenceNotFound;
