import { fork, all } from 'redux-saga/effects';
import auth from './auth';
import grid from './grid';
import preference from './preference';
import employeeSetting from './employeeSetting';
import skillSetting from './skillSetting';
import contractSetting from './contractSetting';
import shiftTypeSetting from './shiftTypeSetting';
import shiftGroupSetting from './shiftGroupSetting';
import employeeReason from './employeeReason';
import employeeSkillSet from './employeeSkillSet';
import sendEmail from './sendEmail';
import rotationSetting from './rotationSetting';
import profileData from './profile';
import wizard from './wizard';
import rotationTab from './rotationTab';
import tenant from './tenant';
import timeReport from './timeReport';

const rootSaga = function* () {
  yield all([
    ...grid.map((watcher) => fork(watcher)),
    ...auth.map((watcher) => fork(watcher)),
    ...preference.map((watcher) => fork(watcher)),
    ...employeeSetting.map((watcher) => fork(watcher)),
    ...skillSetting.map((watcher) => fork(watcher)),
    ...contractSetting.map((watcher) => fork(watcher)),
    ...shiftTypeSetting.map((watcher) => fork(watcher)),
    ...shiftGroupSetting.map((watcher) => fork(watcher)),
    ...employeeReason.map((watcher) => fork(watcher)),
    ...employeeSkillSet.map((watcher) => fork(watcher)),
    ...sendEmail.map((watcher) => fork(watcher)),
    ...rotationSetting.map((watcher) => fork(watcher)),
    ...profileData.map((watcher) => fork(watcher)),
    ...wizard.map((watcher) => fork(watcher)),
    ...rotationTab.map((watcher) => fork(watcher)),
    ...tenant.map((watcher) => fork(watcher)),
    ...timeReport.map((watcher) => fork(watcher))
  ]);
};

export default rootSaga;
