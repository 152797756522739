import { connect } from 'react-redux';
import { useEffect } from 'react';
import _ from 'lodash';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { MONTH_STRING, CHART_PALETTE_COLORS } from 'utils/constant';
import { useTranslation } from 'component/index';
import { actGetEmployeeReasonSetting } from 'redux/action/employeeReason';
import { PreferenceChartWrapper } from './style';
import { CHART_MODE } from 'screen/TimeReport';
import CustomLegend from './CustomLegend';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const PreferenceChart = (props) => {
  const { preference, listEmployeeReason, tenantId, chartMode } = props;
  const { translate } = useTranslation();

  useEffect(() => {
    props.actGetEmployeeReasonSetting({ tenantId });
  }, [tenantId]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: true
      }
    },
    scales: {
      x: {
        stacked: chartMode === CHART_MODE.STACKED
      },
      y: {
        stacked: chartMode === CHART_MODE.STACKED
      }
    }
  };

  const labels = preference?.map((item) =>
    translate('dashboardEmployeeSchedule.' + MONTH_STRING[item?.month - 1])
  );

  const listKey = [];
  preference?.forEach((item) => {
    Object.keys(item)?.map((key) => {
      if (!listKey?.includes(key) && key !== 'month') {
        listKey.push(key);
      }
    });
  });

  const data = {
    labels,
    datasets: listKey?.map((key, index) => {
      const empReason = _.find(listEmployeeReason, { id: Number(key) });

      return {
        label: empReason?.name,
        data: preference?.map((item) => Number(item[key])),
        backgroundColor:
          CHART_PALETTE_COLORS[index % CHART_PALETTE_COLORS.length]
      };
    })
  };

  return (
    <PreferenceChartWrapper>
      <div className="title">{translate('timeReport.preference')}</div>
      <div className="legend">
        {listKey?.map((key, index) => (
          <CustomLegend
            icon={_.find(listEmployeeReason, { id: Number(key) })?.icon}
            label={_.find(listEmployeeReason, { id: Number(key) })?.name}
            color={CHART_PALETTE_COLORS[index % CHART_PALETTE_COLORS.length]}
            key={index}
          />
        ))}
      </div>
      <Bar options={options} data={data} />
    </PreferenceChartWrapper>
  );
};

export default connect(
  (state) => ({
    preference: state?.TimeReport?.statistic?.preference || [],
    tenantId: state?.App?.user?.tenant?.id,
    listEmployeeReason: state.EmployeeReason.listEmployeeReason
  }),
  { actGetEmployeeReasonSetting }
)(PreferenceChart);
