import React, { useState } from 'react';
import { Modal, Steps } from 'antd';
import { connect } from 'react-redux';
import WizardSignup from './WizardSignup';
import WizardSetup from './WizardSetup';
import Preview from './Preview';
import Finish from './Finish';
import { useTranslation } from 'component';
import { actDismissWizard } from 'redux/action/wizard';

const { Step } = Steps;

const WizardFormModal = (props) => {
  const { isWizardSetupComplete, dismissWizard } = props;
  const [current, setCurrent] = useState(0);
  const { translate } = useTranslation();

  const next = () => setCurrent(current + 1);

  const prev = () => setCurrent(current - 1);

  const steps = [
    {
      title: translate('wizard.signup'),
      content: <WizardSignup current={current} next={next} />
    },
    {
      title: translate('wizard.setup'),
      content: <WizardSetup current={current} next={next} prev={prev} />
    },
    {
      title: translate('wizard.preview'),
      content: <Preview next={next} prev={prev} />
    },
    {
      title: translate('wizard.finish'),
      content: <Finish />
    }
  ];

  return (
    <Modal
      visible={!dismissWizard && !isWizardSetupComplete}
      footer={null}
      onCancel={() => props.actDismissWizard(true)}
      style={{ top: 20 }}
    >
      <Steps current={current} style={{ marginTop: '2.5rem' }}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
    </Modal>
  );
};

export default connect(
  (state) => ({
    isWizardSetupComplete: state?.App?.user?.tenant?.wizard_setup_completed,
    dismissWizard: state?.App?.dismissWizard
  }),
  {
    actDismissWizard
  }
)(WizardFormModal);
