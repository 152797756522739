import { FeatureItemWrapper } from './style';

const FeatureItem = (props) => {
  const { feature } = props;

  return (
    <FeatureItemWrapper>
      <div className="main">
        <div className="icon-wrapper">
          <img src={feature.icon} alt="feature icon" />
        </div>

        <div className="title">{feature.title}</div>
        <div className="description">{feature.description}</div>
      </div>
    </FeatureItemWrapper>
  );
};

export default FeatureItem;
