import {
  SET_SELECTED_ROTATION,
  SET_MODAL_ASSIGN_EMPLOYEE_OPEN,
  GET_SKILL_VIEW,
  UPDATE_SKILL_VIEW,
  DELETE_SHIFT_IN_SKILL_VIEW,
  SAVE_DELETE_SHIFT_IN_SKILL_VIEW
} from 'redux/type';

export const actGetSkillView = (...args) => ({
  type: GET_SKILL_VIEW,
  args
});

export const actSetSelectedRotation = (payload) => ({
  type: SET_SELECTED_ROTATION,
  payload
});

export const actSetModalAssignEmployeeOpen = (payload) => {
  return {
    type: SET_MODAL_ASSIGN_EMPLOYEE_OPEN,
    payload
  };
};

export const actUpdateSKillView = (payload) => {
  return {
    type: UPDATE_SKILL_VIEW,
    payload
  };
};

export const actDeleteShiftInSKillView = (...args) => {
  return {
    type: DELETE_SHIFT_IN_SKILL_VIEW,
    args
  };
};

export const actSaveDeleteShiftInSKillView = (payload) => {
  return {
    type: SAVE_DELETE_SHIFT_IN_SKILL_VIEW,
    payload
  };
};
