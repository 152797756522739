import { message } from 'antd';
import { takeLatest, all } from 'redux-saga/effects';
import {
  GET_CONTRACT_SETTING,
  SAVE_GET_CONTRACT_SETTING,
  UPDATE_CONTRACT_INFO,
  SAVE_UPDATE_CONTRACT_INFO,
  DELETE_CONTRACT_INFO,
  CREATE_CONTRACT,
  SAVE_CREATE_CONTRACT
} from 'redux/type';
import contractSettingAPI from 'redux/api/contractSetting';
import { createRequestSaga } from './common';

const getContractSetting = createRequestSaga({
  request: contractSettingAPI.getContractSetting,
  key: 'getContractSetting',
  success: [
    (res) => ({
      type: SAVE_GET_CONTRACT_SETTING,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: [
    (err) => message.error('Error occured when get contract setting')
  ]
});

const updateContractInfo = createRequestSaga({
  request: contractSettingAPI.updateContractInfo,
  key: 'updateContractInfo',
  success: [
    (res) => ({
      type: SAVE_UPDATE_CONTRACT_INFO,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: []
});

const deleteContractInfo = createRequestSaga({
  request: contractSettingAPI.deleteContractInfo,
  key: 'deleteContractInfo',
  functionSuccess: [],
  functionFailure: []
});

const createContractInfo = createRequestSaga({
  request: contractSettingAPI.createContractInfo,
  key: 'createContractInfo',
  success: [
    (res) => ({
      type: SAVE_CREATE_CONTRACT,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: []
});

const saga = [
  function* fetchWatcher() {
    yield all([
      takeLatest(GET_CONTRACT_SETTING, getContractSetting),
      takeLatest(UPDATE_CONTRACT_INFO, updateContractInfo),
      takeLatest(DELETE_CONTRACT_INFO, deleteContractInfo),
      takeLatest(CREATE_CONTRACT, createContractInfo)
    ]);
  }
];

export default saga;
