import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import _ from 'lodash';
import { DndProvider } from 'react-dnd';
import { connect } from 'react-redux';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { actUpdateRotationTab } from 'redux/action/rotationTab';
import DraggableTabNode from './DraggableTabPane';

const DraggableTabs = (props) => {
  const {
    children,
    listRotationTab,
    tenantId,
    actUpdateRotationTab,
    ...restProps
  } = props;
  const [tabOrder, setTabOrder] = useState([]); // tabId sort by @order of Tab

  useEffect(() => {
    const sortedTab = _.sortBy(listRotationTab, 'order'); // sort tab by order
    setTabOrder(sortedTab?.map((tab) => tab?.id?.toString()));
  }, [listRotationTab]);

  const moveTabNode = (dragKey, hoverKey) => {
    const dragIndex = tabOrder?.indexOf(dragKey);
    const hoverIndex = tabOrder?.indexOf(hoverKey);

    // do not need update state @tabOrder here, state is automatically update in @useEffect
    onUpdateTabOrder(dragKey, hoverIndex);
    onUpdateTabOrder(hoverKey, dragIndex);
  };

  const onUpdateTabOrder = (tabId, newOrder) => {
    actUpdateRotationTab({
      tenantId,
      tabId: Number(tabId),
      body: { order: newOrder }
    });
  };

  const renderTabBar = (props, DefaultTabBar) => (
    <DefaultTabBar {...props}>
      {(node) => (
        <DraggableTabNode
          key={node.key}
          index={node.key}
          moveNode={moveTabNode}
        >
          {node}
        </DraggableTabNode>
      )}
    </DefaultTabBar>
  );

  // @tabNodes contains React DOM of each tab sorted by order of tab
  const tabNodes = [];
  tabOrder?.forEach((tabId) => {
    React.Children.forEach(children, (node) => {
      if (node?.key?.toString() === tabId) {
        tabNodes.push(node);
      }
    });
  });

  return (
    <DndProvider backend={HTML5Backend}>
      <Tabs renderTabBar={renderTabBar} {...restProps}>
        {tabNodes}
      </Tabs>
    </DndProvider>
  );
};

export default connect(
  (state) => ({
    listRotationTab: state?.RotationTab?.listRotationTab || [],
    tenantId: state?.App?.user?.tenant?.id
  }),
  { actUpdateRotationTab }
)(DraggableTabs);
