import API from './common';
import BlobAPI from './blobapi';

const api = {
  getRotationSetting: ({ tenantId, month, year }) =>
    API('get', `${tenantId}/rotation/?month=${month}&year=${year}`),
  createRotationInfo: ({ tenantId, body }) =>
    API('post', `${tenantId}/rotation/`, body),
  updateRotationInfo: ({ tenantId, body, rotationId }) =>
    API('put', `${tenantId}/rotation/${rotationId}/`, body),
  deleteRotationInfo: ({ tenantId, rotationId }) =>
    API('delete', `${tenantId}/rotation/${rotationId}/`),
  applyRotation: ({ tenantId, body }) =>
    API('post', `${tenantId}/rotation/apply/`, body),
  solveRotation: ({ tenantId, body }) =>
    API('post', `${tenantId}/rotation/solve/`, body),
  getSolveRotationStatus: ({ tenantId }) =>
    API('get', `${tenantId}/rotation/solver_status/`),
  terminateRotation: ({ tenantId }) =>
    API('post', `${tenantId}/rotation/terminate/`),
  publishRotation: ({ tenantId, body }) =>
    API('post', `${tenantId}/rotation/publish/`, body),
  getAssignmentScore: ({ tenantId, year, month } = {}) =>
    API('get', `${tenantId}/roster/score/?year=${year}&month=${month}`),
  getExcelFile: ({ tenantId, month, year, pivot } = {}) =>
    BlobAPI(
      'get',
      `${tenantId}/shift/excel?month=${month}&year=${year}&pivot=${pivot}`
    )
};

export default api;
