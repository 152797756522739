import API from './common';

const api = {
  getTenantInfo: ({ tenantId }) => API('get', `/tenant/${tenantId}`),
  getFirstDraftDate: ({ tenantId }) => API('get', `${tenantId}/roster/`),
  updateTenantInfo: ({ tenantId, body }) =>
    API('post', `/tenant/${tenantId}/configure/`, body)
};

export default api;
