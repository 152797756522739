import styled from 'styled-components';

const DashboardPageWrapper = styled.div`
  font-size: 1.6rem;

  .header-wrapper {
    margin-bottom: 2rem;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 1.5rem;

    .filter-wrapper {
      display: flex;
      justify-content: space-between;
    }
  }

  .main {
    .statistic-wrapper {
      display: flex;
      justify-content: space-between;
    }

    .schedule-wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 4rem;

      .employee-schedule {
        flex-basis: 65%;
        margin-right: 2rem;
      }

      .weekly-schedule {
        flex-basis: 35%;
      }
    }
  }
`;

export { DashboardPageWrapper };
