import styled from 'styled-components';

const ScheduleVsRealWrapper = styled.div`
  .title {
    font-weight: 500;
    font-size: 2rem;
    margin-bottom: 2rem;
  }
`;

export { ScheduleVsRealWrapper };
