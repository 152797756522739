import { useState, useEffect } from 'react';
import { Layout, Row, Col } from 'antd';
import { AiOutlineMenuUnfold, AiOutlineMenuFold } from 'react-icons/ai';
import _ from 'lodash';
import LanguageSwitcher from 'component/LanguageSwitcher';
import { useTranslation } from 'component/index';
import { actGetTenantInfo, actGetFirstDraftDate } from 'redux/action/tenant';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { actSetGridFullScreen } from 'redux/action/grid';
import { actSetSidebarClose } from 'redux/action/layout';
import Sidebar from './Sidebar';
import UserInfo from './UserInfo';
import { DashboardLayoutWrapper, ContentWrapper } from './index.style';

const { Header, Content, Footer } = Layout;

export const siderWidth = 220;
export const siderCollapseWidth = 50;
const mobileBreakPoint = 700;
let hoverTimeout = null;

const DashBoardLayout = (props) => {
  const {
    children,
    token,
    tenant,
    tenantId,
    first_draft_date,
    isGridFullScreen,
    isSidebarClose
  } = props;

  const [isShowDrawer, setIsShowDrawer] = useState(false);
  const [layoutMargin, setLayoutMargin] = useState(siderWidth);
  const [isMobileSize, setIsMobileSize] = useState(false);
  const { translate } = useTranslation();
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    if (pathname !== '/assignments') {
      props.actSetGridFullScreen(false);
    }
  }, [pathname]);

  useEffect(() => {
    props.actSetGridFullScreen(false);
  }, []);

  useEffect(() => {
    setLayoutMargin(isSidebarClose ? siderCollapseWidth : siderWidth);
  }, [isSidebarClose]);

  useEffect(() => {
    if (token) {
      if (_.isEmpty(tenant)) {
        props.actGetTenantInfo({ tenantId });
      }

      if (!first_draft_date) {
        props.actGetFirstDraftDate({ tenantId });
      }
    }
  }, [token, tenantId, first_draft_date, tenant]);

  const updateWindowDimension = () => {
    if (!isMobileSize && window.innerWidth < mobileBreakPoint) {
      setIsMobileSize(true);
      setLayoutMargin(0);
    }

    if (isMobileSize && window.innerWidth > mobileBreakPoint) {
      setIsMobileSize(false);
      setLayoutMargin(layoutMargin + siderCollapseWidth);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowDimension);
    if (window.innerWidth < mobileBreakPoint) {
      setIsMobileSize(true);
      setLayoutMargin(0);
    }

    return () => {
      window.removeEventListener('resize', updateWindowDimension);
    };
  }, []);

  const onCollapse = () => {
    if (isMobileSize) {
      setIsShowDrawer(true);
    } else {
      setLayoutMargin(!isSidebarClose ? siderCollapseWidth : siderWidth);
      props.actSetSidebarClose(!isSidebarClose);
    }
  };

  const onHoverSidebar = () => {
    if (isSidebarClose) {
      if (hoverTimeout) {
        clearTimeout(hoverTimeout);
      } else {
        setTimeout(() => {
          props.actSetSidebarClose(false);
        }, 300);
      }
    }
  };

  return (
    <DashboardLayoutWrapper isSidebarClose={isSidebarClose}>
      <Layout className="main-layout">
        {!isGridFullScreen ? (
          <div onMouseOver={onHoverSidebar}>
            <Sidebar
              isMobileSize={isMobileSize}
              isShowDrawer={isShowDrawer}
              setIsShowDrawer={setIsShowDrawer}
            />
          </div>
        ) : null}

        <Layout
          style={{
            marginLeft: !isGridFullScreen ? layoutMargin : 0,
            minHeight: '100vh',
            position: 'relative'
          }}
        >
          {!isGridFullScreen ? (
            <Header className="header-normal">
              <Row
                type="flex"
                justify={isMobileSize ? 'start' : 'space-between'}
                align="center"
              >
                <div className="header-icon">
                  {isSidebarClose ? (
                    <AiOutlineMenuUnfold onClick={onCollapse} />
                  ) : (
                    <AiOutlineMenuFold onClick={onCollapse} />
                  )}
                </div>

                <Col span={12} lg={11} md={17} sm={22} xs={17}>
                  <Row
                    type="flex"
                    justify="end"
                    gutter={32}
                    style={{ margin: 0 }}
                  >
                    {/* <NofiticationBadge /> */}
                    <UserInfo />

                    <span style={{ marginLeft: '2rem', marginRight: '2rem' }}>
                      <LanguageSwitcher />
                    </span>
                  </Row>
                </Col>
              </Row>
            </Header>
          ) : null}

          <Content
            style={{
              margin: !isGridFullScreen ? '16px 16px' : '0',
              overflow: 'initial'
            }}
          >
            <ContentWrapper isGridFullScreen={isGridFullScreen}>
              {children}
            </ContentWrapper>
          </Content>

          {!isGridFullScreen ? (
            <Footer style={{ textAlign: 'center' }}>
              {`${translate(
                'copyright'
              )} © ${new Date().getFullYear()}, YoteiPro, Inc. - ${translate(
                'allRightReserverd'
              )}`}
            </Footer>
          ) : null}
        </Layout>
      </Layout>
    </DashboardLayoutWrapper>
  );
};

export default connect(
  (state) => ({
    token: state.App.token,
    tenant: state.App.tenant,
    first_draft_date: state.App.first_draft_date,
    tenantId: state.App?.user?.tenant?.id,
    isGridFullScreen: state.App.isGridFullScreen,
    isSidebarClose: state.App.isSidebarClose
  }),
  {
    actGetTenantInfo,
    actGetFirstDraftDate,
    actSetGridFullScreen,
    actSetSidebarClose
  }
)(DashBoardLayout);
