import { takeLatest, all, takeEvery } from 'redux-saga/effects';
import {
  UPDATE_PREFERENCE_AVAIL,
  SAVE_UPDATE_PREFERENCE_AVAIL,
  CREATE_PREFERENCE_AVAIL,
  SAVE_CREATE_PREFERENCE_AVAIL,
  GET_EMPLOYEE_PREFERENCE,
  SAVE_GET_EMPLOYEE_PREFERENCE,
  DELETE_PREFERENCE_AVAIL,
  SAVE_PREFERECE_TENANT,
  GET_DASHBOARD_PREFERENCES
} from 'redux/type';
import gridAPI from 'redux/api/grid';
import { createRequestSaga } from './common';
import { store } from '../store';

const getEmployeePreference = createRequestSaga({
  request: gridAPI.getEmployeePreference,
  key: 'getEmployeePreference',
  success: [
    (res) => {
      const { EmployeeReason } = store.getState();
      const { listEmployeeReason = [] } = EmployeeReason;

      return {
        type: SAVE_GET_EMPLOYEE_PREFERENCE,
        payload: { ...res, listEmployeeReason }
      };
    },
    (res) => {
      return {
        type: SAVE_PREFERECE_TENANT,
        payload: res.tenant
      };
    }
  ],
  functionSuccess: [],
  functionFailure: []
});

const updatePreferenceAvailability = createRequestSaga({
  request: gridAPI.updateAvailability,
  key: 'updatePreferenceAvailability',
  success: [
    (res) => {
      const { EmployeeReason } = store.getState();
      const { listEmployeeReason = [] } = EmployeeReason;

      return {
        type: SAVE_UPDATE_PREFERENCE_AVAIL,
        payload: { ...res, listEmployeeReason }
      };
    }
  ],
  functionSuccess: [],
  functionFailure: []
});

const deletePreferenceAvailability = createRequestSaga({
  request: gridAPI.deleteAvailability,
  key: 'deletePreferenceAvailability',
  success: [],
  functionSuccess: [],
  functionFailure: []
});

const createPreferenceAvailability = createRequestSaga({
  request: gridAPI.createAvailability,
  key: 'createPreferenceAvailability',
  success: [
    (res) => {
      const { EmployeeReason } = store.getState();
      const { listEmployeeReason = [] } = EmployeeReason;

      return {
        type: SAVE_CREATE_PREFERENCE_AVAIL,
        payload: { ...res, listEmployeeReason }
      };
    }
  ],
  functionSuccess: [],
  functionFailure: []
});

const getPreferences = createRequestSaga({
  request: gridAPI.getPreferences,
  key: 'getPreferences',
  success: [
    (res) => {
      return {
        type: GET_DASHBOARD_PREFERENCES,
        payload: res
      };
    }
  ],
  functionSuccess: [],
  functionFailure: []
});

const saga = [
  function* fetchWatcher() {
    yield all([
      takeEvery(UPDATE_PREFERENCE_AVAIL, updatePreferenceAvailability), // allow to send multiple update requests
      takeEvery(CREATE_PREFERENCE_AVAIL, createPreferenceAvailability), // allow to send multiple create requests
      takeLatest(GET_EMPLOYEE_PREFERENCE, getEmployeePreference),
      takeLatest(DELETE_PREFERENCE_AVAIL, deletePreferenceAvailability),
      takeEvery(GET_DASHBOARD_PREFERENCES, getPreferences)
    ]);
  }
];

export default saga;
