import styled from 'styled-components';

const CallToActionSectionWrapper = styled.div`
  margin-top: -20vh;
  background-image: ${({ bgUrl }) => `url(${bgUrl})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top right;

  .main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40vh;
    padding-top: 10vh;

    .content {
      flex-basis: 70%;

      .title {
        font-size: 2.3rem;
        font-weight: 500;
        color: white;
        margin-bottom: 2rem;
      }

      .description {
        color: white;
        font-size: 1.6rem;
        font-weight: 300;
      }
    }
  }
`;

export { CallToActionSectionWrapper };
