import { useMemo, Fragment } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Tooltip, Tag, Popover } from 'antd';
import {
  AimOutlined,
  ArrowRightOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import {
  actSetSelectedPreferenceAvail,
  actSetModalPreferenceVisible
} from 'redux/action/preference';
import { LIST_TAG_COLOR } from 'utils/constant';
import { useTranslation } from 'component/index';
import { TimeItemWrapper } from './index.style';

const TimeItem = (props) => {
  const {
    label,
    itemBorderColor,
    itemBackgroundColor,
    availInfo,
    message,
    shiftTeamName,
    listShiftType,
    editable,
    isDisable,
    isViewConfirmed,
    listEmployeeReason,
    listSnapshot,
    employeeId,
    rotation
  } = props;
  const { translate } = useTranslation();

  const STATUS_CHANGE = {
    ADD: 'ADD',
    REMOVE: 'REMOVE'
  };

  const STATUS_VALUE = {
    ADD: translate('preference.added'),
    REMOVE: translate('preference.removed')
  };

  // if exist snapshot for this rotation
  const originAvail = useMemo(() => {
    return (
      _.findLast(listSnapshot, {
        rotation: rotation,
        start_date_time: availInfo.start_date_time,
        employee: +employeeId
      }) ||
      _.findLast(listSnapshot, {
        start_date_time: availInfo.start_date_time,
        end_date_time: availInfo.end_date_time,
        employee: +employeeId
      })
    );
  }, [listSnapshot, availInfo]);

  const onShowModal = () => {
    if (editable && !isDisable) {
      props.actSetModalPreferenceVisible(true);
      props.actSetSelectedPreferenceAvail({ availInfo });
    }
  };

  const originAvailIcon = useMemo(() => {
    return _.find(listEmployeeReason, {
      id: originAvail?.employee_reason
    })?.icon;
  }, [listEmployeeReason, originAvail]);

  const shiftTeamIndex = _.findIndex(listShiftType, {
    name: shiftTeamName
  });

  const shiftTeamColor = LIST_TAG_COLOR[shiftTeamIndex % LIST_TAG_COLOR.length];

  const statusText = useMemo(() => {
    if (availInfo?.employee_reason !== originAvail?.employee_reason) {
      if (!availInfo?.employee_reason && originAvail) {
        // if remove avail
        return STATUS_CHANGE.REMOVE;
      } else if (availInfo?.employee_reason && !originAvail) {
        // if add avail
        return STATUS_CHANGE.ADD;
      }
    }

    // else if update avail to other avail
    return null;
  }, [availInfo, originAvail]);

  const shouldRenderPreviousRotation =
    originAvailIcon &&
    isViewConfirmed &&
    availInfo.employee_reason !== originAvail?.employee_reason;

  const ListShiftType = () =>
    availInfo?.spots?.map((item, index) => {
      const shiftType = _.find(listShiftType, { id: item });
      const shiftTypeIndex = _.findIndex(listShiftType, { id: item });
      const shiftTypeColor =
        LIST_TAG_COLOR[shiftTypeIndex % LIST_TAG_COLOR.length];

      return (
        <div key={index}>
          <Tag
            color={shiftTypeColor}
            style={{ height: '1.5rem', marginBottom: '0' }}
          />

          <span>{shiftType?.name}</span>
        </div>
      );
    });

  return (
    <TimeItemWrapper
      itemBorderColor={itemBorderColor}
      itemBackgroundColor={itemBackgroundColor}
      onClick={onShowModal}
      isDisable={isDisable}
    >
      <div className="main">
        {shiftTeamName && <Tag color={shiftTeamColor}>{shiftTeamName}</Tag>}

        {message && (
          <div className="icon-wrapper" disable>
            <Tooltip title={message}>
              <InfoCircleOutlined />
            </Tooltip>
          </div>
        )}

        <span>{label}</span>

        {availInfo.iconUrl && (
          <Popover
            content={
              !_.isEmpty(availInfo?.spots)
                ? ListShiftType
                : translate('preference.allShiftTypes')
            }
          >
            <span className="image-wrapper">
              {availInfo.iconUrl && <img src={availInfo.iconUrl} alt="" />}
            </span>
          </Popover>
        )}

        {!_.isEmpty(availInfo?.spots) && (
          <Popover content={ListShiftType}>
            <AimOutlined />
          </Popover>
        )}
      </div>

      {isViewConfirmed && (statusText || shouldRenderPreviousRotation) ? (
        <div className="change-status">
          {statusText && isViewConfirmed ? (
            <Fragment>
              <span
                style={{
                  color:
                    statusText === STATUS_CHANGE.ADD
                      ? 'var(--color-dark-green)'
                      : 'var(--color-red)',
                  lineHeight: '1.2rem'
                }}
              >
                {STATUS_VALUE[statusText]}
              </span>

              <span className="image-wrapper">
                <img src={availInfo.iconUrl || originAvailIcon} alt="" />
              </span>
            </Fragment>
          ) : null}

          {shouldRenderPreviousRotation && !statusText ? (
            <Fragment>
              <span className="image-wrapper">
                <img src={originAvailIcon} alt="" />
              </span>

              <ArrowRightOutlined />

              <span className="image-wrapper">
                {availInfo.iconUrl ? (
                  <img src={availInfo.iconUrl} alt="" />
                ) : null}
              </span>
            </Fragment>
          ) : null}
        </div>
      ) : null}
    </TimeItemWrapper>
  );
};

export default connect(
  (state) => ({
    listShiftType: state.ShiftTypeSetting.listShiftType,
    isViewConfirmed: state.Preference.isViewConfirmed,
    listEmployeeReason: state.EmployeeReason.listEmployeeReason,
    listSnapshot: state.Preference.listSnapshot
  }),
  {
    actSetSelectedPreferenceAvail,
    actSetModalPreferenceVisible
  }
)(TimeItem);
