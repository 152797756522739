import { connect } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { Modal, Form, Input, Button, message } from 'antd';
import { useTranslation } from 'component/index';
import { actRequestADemo } from 'redux/action/sendEmail';

const { TextArea } = Input;

const ModalRequestDemo = (props) => {
  const { modalVisible, onCloseModal } = props;
  const [isButtonLoading, setButtonLoading] = useState(false);
  const firstInput = useRef(null);
  const { translate } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    if (modalVisible) {
      firstInput.current.focus();

      form.setFieldsValue({
        phone: '',
        email: '',
        healthcareCenter: '',
        note: ''
      });
    }
  }, [modalVisible]);

  const onSubmitForm = async () => {
    try {
      const { phone, email, healthcareCenter, note } =
        await form.validateFields([
          'phone',
          'email',
          'healthcareCenter',
          'note'
        ]);

      setButtonLoading(true);

      props.actRequestADemo(
        {
          body: {
            phone,
            email,
            healthcareCenter,
            note
          }
        },
        (err, res) => {
          setButtonLoading(false);
          if (!err) {
            message.success(
              translate('homepage.callToAction.sendEmailSuccess')
            );
            onCloseModal();
          }

          if (err) {
            message.error(err.message);
          }
        }
      );
    } catch (err) {}
  };

  return (
    <Modal
      title={translate('homepage.callToAction.requestADemo')}
      visible={modalVisible}
      footer={
        <Button type="primary" loading={isButtonLoading} onClick={onSubmitForm}>
          {translate('send')}
        </Button>
      }
      maskClosable={false}
      onCancel={onCloseModal}
      forceRender={true}
      destroyOnClose={true}
      getContainer={false}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          label={translate('homepage.callToAction.phone')}
          rules={[
            {
              required: true,
              message: `${translate('homepage.callToAction.phone')} ${translate(
                'isRequired'
              )}`
            }
          ]}
          name="phone"
        >
          <Input
            ref={firstInput}
            placeholder={translate('homepage.callToAction.enterYourPhone')}
          />
        </Form.Item>

        <Form.Item
          label={translate('homepage.callToAction.email')}
          rules={[
            {
              required: true,
              message: `${translate('homepage.callToAction.email')} ${translate(
                'isRequired'
              )}`
            },
            {
              type: 'email',
              message: translate('homepage.callToAction.emailError')
            }
          ]}
          name="email"
        >
          <Input
            placeholder={translate('homepage.callToAction.enterYourEmail')}
          />
        </Form.Item>

        <Form.Item
          label={translate('homepage.callToAction.healthcareCenter')}
          rules={[
            {
              required: true,
              message: `${translate(
                'homepage.callToAction.healthcareCenter'
              )} ${translate('isRequired')}`
            }
          ]}
          name="healthcareCenter"
        >
          <Input
            placeholder={translate(
              'homepage.callToAction.enterHealthcareCenter'
            )}
          />
        </Form.Item>

        <Form.Item label={translate('homepage.callToAction.note')} name="note">
          <TextArea
            placeholder={translate('homepage.callToAction.writeSomeNote')}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default connect((state) => ({}), { actRequestADemo })(ModalRequestDemo);
