import {
  GET_EMPLOYEE_REASON_SETTING,
  UPDATE_EMPLOYEE_REASON_INFO,
  DELETE_EMPLOYEE_REASON_INFO,
  SAVE_DELETE_EMPLOYEE_REASON_INFO,
  CREATE_EMPLOYEE_REASON,
  SEARCH_EMPLOYEE_REASON,
  SET_SELECTED_EMPLOYEE_REASON,
  SET_MODAL_EMPLOYEE_REASON_VISIBLE
} from 'redux/type';

export const actGetEmployeeReasonSetting = (...args) => ({
  type: GET_EMPLOYEE_REASON_SETTING,
  args
});

export const actUpdateEmployeeReasonInfo = (...args) => ({
  type: UPDATE_EMPLOYEE_REASON_INFO,
  args
});

export const actDeleteEmployeeReasonInfo = (...args) => ({
  type: DELETE_EMPLOYEE_REASON_INFO,
  args
});

export const actSaveDeleteEmployeeReasonInfo = (payload) => ({
  type: SAVE_DELETE_EMPLOYEE_REASON_INFO,
  payload
});

export const actCreateEmployeeReason = (...args) => ({
  type: CREATE_EMPLOYEE_REASON,
  args
});

export const actSearchEmployeeReason = (payload) => ({
  type: SEARCH_EMPLOYEE_REASON,
  payload
});

export const actSetSelectedEmployeeReason = (payload) => ({
  type: SET_SELECTED_EMPLOYEE_REASON,
  payload
});

export const actSetModalEmployeeReasonVisible = (payload) => ({
  type: SET_MODAL_EMPLOYEE_REASON_VISIBLE,
  payload
});
