import styled from 'styled-components';

const EmployeeGridWrapper = styled.div`
  width: 100%;
  height: 100%;
  font-size: 1.6rem;
  display: flex;
  flex-direction: column;

  .terminate-btn {
    background: #ff4d4f !important;
    border: none;
  }

  .score_val {
    margin-left: 7px;
  }

  .score__cont {
    display: flex;

    .hard__score {
      width: 85px;
      height: 35px;
      border: 3.5px solid red;
      text-align: center;
      margin-right: 5px;
      padding: 2px;
    }

    .medium__score {
      width: 85px;
      height: 35px;
      border: 3.5px solid yellow;
      text-align: center;
      margin-right: 5px;
      padding: 2px;
    }

    .soft__score {
      width: 85px;
      height: 35px;
      border: 3.5px solid green;
      text-align: center;
      padding: 2px;
    }
  }

  .score_lable {
    font-weight: bold;
  }

  .avail-message {
    position: absolute;
    bottom: 0.5rem;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .shift-picker-cell {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      border: 1px dotted gray;
    }
  }

  .drag-item {
    cursor: pointer;
  }

  .grid-cell-centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cell-span {
    background: white;
    border-left: 1px solid lightgrey !important;
    border-right: 1px solid lightgrey !important;
    border-bottom: 1px solid lightgrey !important;
  }

  .team-header--even {
    background-color: var(--color-border-blue);
  }

  .team-header--odd {
    background-color: var(--color-ternary);
  }
`;

export { EmployeeGridWrapper };
