import {
  GET_SKILL_SETTING,
  UPDATE_SKILL_INFO,
  DELETE_SKILL_INFO,
  SAVE_DELETE_SKILL_INFO,
  CREATE_SKILL,
  SEARCH_SKILL,
  SET_SELECTED_SKILL_SETTING,
  SET_MODAL_SKILL_SETTING_VISIBLE
} from 'redux/type';

export const actGetSkillSetting = (...args) => ({
  type: GET_SKILL_SETTING,
  args
});

export const actUpdateSkillInfo = (...args) => ({
  type: UPDATE_SKILL_INFO,
  args
});

export const actDeleteSkillInfo = (...args) => ({
  type: DELETE_SKILL_INFO,
  args
});

export const actSaveDeleteSkillInfo = (payload) => ({
  type: SAVE_DELETE_SKILL_INFO,
  payload
});

export const actCreateSkill = (...args) => ({
  type: CREATE_SKILL,
  args
});

export const actSearchSkill = (payload) => ({
  type: SEARCH_SKILL,
  payload
});

export const actSetSelectedSkillSetting = (payload) => ({
  type: SET_SELECTED_SKILL_SETTING,
  payload
});

export const actSetModalSkillSettingVisible = (payload) => ({
  type: SET_MODAL_SKILL_SETTING_VISIBLE,
  payload
});
