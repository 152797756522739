//  Request
export const MARK_REQUEST_PENDING = 'request/requestPending';
export const MARK_REQUEST_SUCCESS = 'request/requestSuccess';
export const MARK_REQUEST_FAILED = 'request/requestFailed';
export const MARK_REQUEST_CANCELLED = 'request/requestCancelled';
export const INVOKE_CALLBACK = 'app/invokeCallBack';

// App
export const SIGN_IN = 'SIGN_IN';
export const SAVE_SIGN_IN = 'SAVE_SIGN_IN';
export const LOG_OUT = 'LOG_OUT';
export const GET_TENANT_INFO = 'GET_TENANT_INFO';
export const SAVE_GET_TENANT_INFO = 'SAVE_GET_TENANT_INFO';
export const UPDATE_TENANT_INFO = 'UPDATE_TENANT_INFO';
export const GET_FIRST_DRAFT_DATE = 'GET_FIRST_DRAFT_DATE';
export const SAVE_GET_FIRST_DRAFT_DATE = 'SAVE_GET_FIRST_DRAFT_DATE';
export const SET_SIDEBAR_CLOSE = 'SET_SIDEBAR_CLOSE';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';

// grid
export const SAVE_LIST_DATA = 'SAVE_LIST_DATA';
export const SAVE_LIST_ASSIGNMENT_COLUMN = 'SAVE_LIST_ASSIGNMENT_COLUMN';
export const SET_STATISTIC_ROW = 'SET_STATISTIC_ROW';
export const SAVE_DATA_BY_COLUMN = 'SAVE_DATA_BY_COLUMN';
export const SAVE_SPOT = 'SAVE_SPOT';
export const SET_COLLAPSE_STATISTIC = 'SET_COLLAPSE_STATISTIC';
export const RESET_GRID_DATA = 'RESET_GRID_DATA';
export const SET_GLOBAL_TIME = 'SET_GLOBAL_TIME';
export const SET_DASHBOARD_FROM_TIME = 'SET_DASHBOARD_FROM_TIME';
export const SET_DASHBOARD_TO_TIME = 'SET_DASHBOARD_TO_TIME';
export const SET_GRID_FULLSCREEN = 'SET_GRID_FULLSCREEN';
export const GET_EMAIL_STATUS_CHECK = 'GET_EMAIL_STATUS_CHECK';
export const SAVE_EMAIL_STATUS_CHECK = 'SAVE_EMAIL_STATUS_CHECK';
export const SET_AVAIL_EDITABLE = 'SET_AVAIL_EDITABLE';
export const SET_GRID_VIEW_MODE = 'SET_GRID_VIEW_MODE';
export const SET_MODAL_ASSIGN_EMPLOYEE_OPEN = 'SET_MODAL_ASSIGN_EMPLOYEE_OPEN';
export const SET_SELECTED_SHIFT_TYPE = 'SET_SELECTED_SHIFT_TYPE';
export const SET_SELECTED_SHIFT_GROUP = 'SET_SELECTED_SHIFT_GROUP';
export const GET_ASSIGNMENT_DATA = 'GET_ASSIGNMENT_DATA';
export const SAVE_GET_ASSIGNMENT_DATA = 'SAVE_GET_ASSIGNMENT_DATA';
export const SET_REFRESH_ASSIGNMENT = 'SET_REFRESH_ASSIGNMENT';
export const SET_DASHBOARD_ASSIGNMENT_DATA = 'SET_DASHBOARD_ASSIGNMENT_DATA';

// shift
export const CREATE_SHIFT = 'CREATE_SHIFT';
export const SAVE_CREATE_SHIFT = 'SAVE_CREATE_SHIFT';
export const UPDATE_SHIFT = 'UPDATE_SHIFT';
export const SAVE_UPDATE_SHIFT = 'SAVE_UPDATE_SHIFT';
export const PIN_UNPIN_SHIFT = 'PIN_UNPIN_SHIFT';
export const SAVE_PIN_UNPIN_SHIFT = 'SAVE_PIN_UNPIN_SHIFT';
export const DELETE_SHIFT = 'DELETE_SHIFT';
export const SAVE_DELETE_SHIFT = 'SAVE_DELETE_SHIFT';

// avail
export const UPDATE_AVAILABILITY = 'UPDATE_AVAILABILITY';
export const SAVE_UPDATE_AVAILABILITY = 'SAVE_UPDATE_AVAILABILITY';
export const CREATE_AVAILABILITY = 'CREATE_AVAILABILITY';
export const SAVE_CREATE_AVAILABILITY = 'SAVE_CREATE_AVAILABILITY';
export const GET_USER_AVAILABILITY = 'GET_USER_AVAILABILITY';
export const SAVE_GET_USER_AVAILABILITY = 'SAVE_GET_USER_AVAILABILITY';
export const DELETE_PREFERENCE_AVAIL = 'DELETE_PREFERENCE_AVAIL';
export const SAVE_ON_DELETE_EMPLOYEE_PREF = 'SAVE_ON_DELETE_EMPLOYEE_PREF';

// employee setting
export const GET_EMPLOYEE_SETTING = 'GET_EMPLOYEE_SETTING';
export const SAVE_GET_EMPLOYEE_SETTING = 'SAVE_GET_EMPLOYEE_SETTING';
export const UPDATE_EMPLOYEE_INFO = 'UPDATE_EMPLOYEE_INFO';
export const SAVE_UPDATE_EMPLOYEE_INFO = 'SAVE_UPDATE_EMPLOYEE_INFO';
export const DELETE_EMPLOYEE_INFO = 'DELETE_EMPLOYEE_INFO';
export const SAVE_DELETE_EMPLOYEE_INFO = 'SAVE_DELETE_EMPLOYEE_INFO';
export const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE';
export const SAVE_CREATE_EMPLOYEE = 'SAVE_CREATE_EMPLOYEE';
export const SEARCH_EMPLOYEE = 'SEARCH_EMPLOYEE';
export const SET_SELECTED_EMPLOYEE_SETTING = 'SET_SELECTED_EMPLOYEE_SETTING';
export const SET_MODAL_EMPLOYEE_SETTING_VISIBLE =
  'SET_MODAL_EMPLOYEE_SETTING_VISIBLE';
export const GET_EMPLOYEE_SHIFTS = 'GET_EMPLOYEE_SHIFTS';
export const SAVE_GET_EMPLOYEE_SHIFTS = 'SAVE_GET_EMPLOYEE_SHIFTS';
export const UPDATE_EMPLOYEE_INACTIVE = 'UPDATE_EMPLOYEE_INACTIVE';
export const SEARCH_EMPLOYEE_ANTI_PATTERN = 'SEARCH_EMPLOYEE_ANTI_PATTERN';
export const SAVE_GET_TIME_REPORTING = 'SAVE_GET_TIME_REPORTING';
export const GET_EMPLOYEE_BY_ID = 'GET_EMPLOYEE_BY_ID';
export const SAVE_GET_EMPLOYEE_BY_ID = 'SAVE_GET_EMPLOYEE_BY_ID';

// time reporting
export const GET_TIME_REPORTING = 'GET_TIME_REPORTING';
export const SET_MODAL_TIME_REPORT_VISIBLE = 'SET_MODAL_TIME_REPORT_VISIBLE';
export const UPDATE_TIME_REPORT = 'UPDATE_TIME_REPORT';
export const SAVE_UPDATE_TIME_REPORT = 'SAVE_UPDATE_TIME_REPORT';
export const DELETE_TIME_REPORT = 'DELETE_TIME_REPORT';
export const SAVE_DELETE_TIME_REPORT = 'SAVE_DELETE_TIME_REPORT';
export const CREATE_TIME_REPORT = 'CREATE_TIME_REPORT';
export const SAVE_CREATE_TIME_REPORT = 'SAVE_CREATE_TIME_REPORT';
export const SET_SELECTED_TIME_REPORT = 'SET_SELECTED_TIME_REPORT';
export const GET_TIME_REPORT_STATISTIC = 'GET_TIME_REPORT_STATISTIC';
export const SAVE_GET_TIME_REPORT_STATISTIC = 'SAVE_GET_TIME_REPORT_STATISTIC';

// skill setting
export const GET_SKILL_SETTING = 'GET_SKILL_SETTING';
export const SAVE_GET_SKILL_SETTING = 'SAVE_GET_SKILL_SETTING';
export const UPDATE_SKILL_INFO = 'UPDATE_SKILL_INFO';
export const SAVE_UPDATE_SKILL_INFO = 'SAVE_UPDATE_SKILL_INFO';
export const DELETE_SKILL_INFO = 'DELETE_SKILL_INFO';
export const SAVE_DELETE_SKILL_INFO = 'SAVE_DELETE_SKILL_INFO';
export const CREATE_SKILL = 'CREATE_SKILL';
export const SAVE_CREATE_SKILL = 'SAVE_CREATE_SKILL';
export const SEARCH_SKILL = 'SEARCH_SKILL';
export const SET_SELECTED_SKILL_SETTING = 'SET_SELECTED_SKILL_SETTING';
export const SET_MODAL_SKILL_SETTING_VISIBLE =
  'SET_MODAL_SKILL_SETTING_VISIBLE';

// contract setting
export const GET_CONTRACT_SETTING = 'GET_CONTRACT_SETTING';
export const SAVE_GET_CONTRACT_SETTING = 'SAVE_GET_CONTRACT_SETTING';
export const UPDATE_CONTRACT_INFO = 'UPDATE_CONTRACT_INFO';
export const SAVE_UPDATE_CONTRACT_INFO = 'SAVE_UPDATE_CONTRACT_INFO';
export const DELETE_CONTRACT_INFO = 'DELETE_CONTRACT_INFO';
export const SAVE_DELETE_CONTRACT_INFO = 'SAVE_DELETE_CONTRACT_INFO';
export const CREATE_CONTRACT = 'CREATE_CONTRACT';
export const SAVE_CREATE_CONTRACT = 'SAVE_CREATE_CONTRACT';
export const SEARCH_CONTRACT = 'SEARCH_CONTRACT';
export const SET_SELECTED_CONTRACT_SETTING = 'SET_SELECTED_CONTRACT_SETTING';
export const SET_MODAL_CONTRACT_SETTING_VISIBLE =
  'SET_MODAL_CONTRACT_SETTING_VISIBLE';

// shift type setting
export const SEARCH_SHIFT_TYPE = 'SEARCH_SHIFT_TYPE';
export const GET_SHIFT_TYPE_SETTING = 'GET_SHIFT_TYPE_SETTING';
export const SAVE_GET_SHIFT_TYPE_SETTING = 'SAVE_GET_SHIFT_TYPE_SETTING';
export const SET_UNSAVED_SHIFT_TYPE = 'SET_UNSAVED_SHIFT_TYPE';
export const SET_EDITING_SHIFT_TYPE = 'SET_EDITING_SHIFT_TYPE';
export const UPDATE_SHIFT_TYPE_INFO = 'UPDATE_SHIFT_TYPE_INFO';
export const SAVE_UPDATE_SHIFT_TYPE_INFO = 'SAVE_UPDATE_SHIFT_TYPE_INFO';
export const DELETE_SHIFT_TYPE_INFO = 'DELETE_SHIFT_TYPE_INFO';
export const SAVE_DELETE_SHIFT_TYPE_INFO = 'SAVE_DELETE_SHIFT_TYPE_INFO';
export const ADD_NEW_SHIFT_TYPE_ROW = 'ADD_NEW_SHIFT_TYPE_ROW';
export const CREATE_SHIFT_TYPE = 'CREATE_SHIFT_TYPE';
export const SAVE_CREATE_SHIFT_TYPE = 'SAVE_CREATE_SHIFT_TYPE';
export const SET_SELECTED_SHIFT_TYPE_SETTING =
  'SET_SELECTED_SHIFT_TYPE_SETTING';
export const SET_MODAL_SHIFT_TYPE_SETTING_VISIBLE =
  'SET_MODAL_SHIFT_TYPE_SETTING_VISIBLE';
export const SET_SELECTED_BREAK_PAIR = 'SET_SELECTED_BREAK_PAIR';
export const SET_MODAL_BREAK_PAIR_VISIBLE = 'SET_MODAL_BREAK_PAIR_VISIBLE';

// shift group setting
export const GET_SHIFT_GROUP = 'GET_SHIFT_GROUP';
export const UPDATE_SHIFT_GROUP = 'UPDATE_SHIFT_GROUP';
export const SAVE_UPDATE_SHIFT_GROUP = 'SAVE_UPDATE_SHIFT_GROUP';
export const DELETE_SHIFT_GROUP = 'DELETE_SHIFT_GROUP';
export const SAVE_DELETE_SHIFT_GROUP = 'SAVE_DELETE_SHIFT_GROUP';
export const CREATE_SHIFT_GROUP = 'CREATE_SHIFT_GROUP';
export const SAVE_CREATE_SHIFT_GROUP = 'SAVE_CREATE_SHIFT_GROUP';
export const SET_SELECTED_SHIFT_GROUP_SETTING =
  'SET_SELECTED_SHIFT_GROUP_SETTING';
export const SET_MODAL_SHIFT_GROUP_SETTING_VISIBLE =
  'SET_MODAL_SHIFT_GROUP_SETTING_VISIBLE';

// employee reason
export const GET_EMPLOYEE_REASON_SETTING = 'GET_EMPLOYEE_REASON_SETTING';
export const SAVE_GET_EMPLOYEE_REASON_SETTING =
  'SAVE_GET_EMPLOYEE_REASON_SETTING';
export const UPDATE_EMPLOYEE_REASON_INFO = 'UPDATE_EMPLOYEE_REASON_INFO';
export const SAVE_UPDATE_EMPLOYEE_REASON_INFO =
  'SAVE_UPDATE_EMPLOYEE_REASON_INFO';
export const DELETE_EMPLOYEE_REASON_INFO = 'DELETE_EMPLOYEE_REASON_INFO';
export const SAVE_DELETE_EMPLOYEE_REASON_INFO =
  'SAVE_DELETE_EMPLOYEE_REASON_INFO';
export const CREATE_EMPLOYEE_REASON = 'CREATE_EMPLOYEE_REASON';
export const SAVE_CREATE_EMPLOYEE_REASON = 'SAVE_CREATE_EMPLOYEE_REASON';
export const SEARCH_EMPLOYEE_REASON = 'SEARCH_EMPLOYEE_REASON';
export const SET_SELECTED_EMPLOYEE_REASON = 'SET_SELECTED_EMPLOYEE_REASON';
export const SET_MODAL_EMPLOYEE_REASON_VISIBLE =
  'SET_MODAL_EMPLOYEE_REASON_VISIBLE';

// employee skill set
export const CREATE_EMPLOYEE_SKILL_SET = 'CREATE_EMPLOYEE_SKILL_SET';
export const SAVE_CREATE_EMPLOYEE_SKILL_SET = 'SAVE_CREATE_EMPLOYEE_SKILL_SET';
export const DELETE_EMPLOYEE_SKILL_SET = 'DELETE_EMPLOYEE_SKILL_SET';
export const SAVE_DELETE_EMPLOYEE_SKILL_SET = 'SAVE_DELETE_EMPLOYEE_SKILL_SET';

// send email
export const SET_SELECTED_EMPLOYEE_EMAIL = 'SET_SELECTED_EMPLOYEE_EMAIL';
export const SET_DATE_SEND_EMAIL = 'SET_DATE_SEND_EMAIL';
export const SEND_EMAIL = 'SEND_EMAIL';
export const SET_SEND_EMAIL_STATUS = 'SET_SEND_EMAIL_STATUS';
export const REQUEST_A_DEMO = 'REQUEST_A_DEMO';

// preference avail
export const SET_SELECTED_PREFERENCE_AVAIL = 'SET_SELECTED_PREFERENCE_AVAIL';
export const UPDATE_PREFERENCE_AVAIL = 'UPDATE_PREFERENCE_AVAIL';
export const SAVE_UPDATE_PREFERENCE_AVAIL = 'SAVE_UPDATE_PREFERENCE_AVAIL';
export const CREATE_PREFERENCE_AVAIL = 'CREATE_PREFERENCE_AVAIL';
export const SAVE_CREATE_PREFERENCE_AVAIL = 'SAVE_CREATE_PREFERENCE_AVAIL';
export const SET_MODAL_PREFERENCE_VISIBLE = 'SET_MODAL_PREFERENCE_VISIBLE';
export const GET_EMPLOYEE_PREFERENCE = 'GET_EMPLOYEE_PREFERENCE';
export const SAVE_GET_EMPLOYEE_PREFERENCE = 'SAVE_GET_EMPLOYEE_PREFERENCE';
export const SAVE_PREFERECE_TENANT = 'SAVE_PREFERECE_TENANT';
export const SET_IS_VIEW_CONFIRMED = 'SET_IS_VIEW_CONFIRMED';
export const SAVE_LIST_PREFERENCE_SNAPSHOT = 'SAVE_LIST_PREFERENCE_SNAPSHOT';
export const AFTER_DELETE_PREFERENCE_AVAIL = 'AFTER_DELETE_PREFERENCE_AVAIL';

// rotation setting
export const GET_ROTATION_SETTING = 'GET_ROTATION_SETTING';
export const SAVE_GET_ROTATION_SETTING = 'SAVE_GET_ROTATION_SETTING';
export const UPDATE_ROTATION_INFO = 'UPDATE_ROTATION_INFO';
export const SAVE_UPDATE_ROTATION_INFO = 'SAVE_UPDATE_ROTATION_INFO';
export const DELETE_ROTATION_INFO = 'DELETE_ROTATION_INFO';
export const SAVE_DELETE_ROTATION_INFO = 'SAVE_DELETE_ROTATION_INFO';
export const CREATE_ROTATION = 'CREATE_ROTATION';
export const SAVE_CREATE_ROTATION = 'SAVE_CREATE_ROTATION';
export const SEARCH_ROTATION = 'SEARCH_ROTATION';
export const SET_SELECTED_ROTATION_SETTING = 'SET_SELECTED_ROTATION_SETTING';
export const SET_MODAL_ROTATION_SETTING_VISIBLE =
  'SET_MODAL_ROTATION_SETTING_VISIBLE';
export const APPLY_ROTATION = 'APPLY_ROTATION';
export const SOLVE_ROTATION = 'SOLVE_ROTATION';
export const GET_SOLVE_ROTATION_STATUS = 'GET_SOLVE_ROTATION_STATUS';
export const SAVE_GET_SOLVE_ROTATION_STATUS = 'SAVE_GET_SOLVE_ROTATION_STATUS';
export const TERMINATE_ROTATION = 'TERMINATE_ROTATION';
export const PUBLISH_ROTATION = 'PUBLISH_ROTATION';
export const SAVE_TERMINATE_ROTATION = 'SAVE_GTERMINATE_ROTATION';
export const SAVE_ASSIGN_SCORE = 'SAVE_ASSIGN_SCORE';
export const GET_ASSIGN_SCORE = 'GET_ASSIGN_SCORE';

// profile
export const GET_PROFILE_DETAILS = 'GET_PROFILE_DETAILS';
export const SAVE_PROFILE_DETAILS = 'SAVE_PROFILE_DETAILS';
export const UPDATE_PROFILE_DETAILS = 'UPDATE_PROFILE_DETAILS';
export const SET_MODAL_PROFILE_VISIBLE = 'SET_MODAL_PROFILE_VISIBLE';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_DEFAULT_PREFERENCE = 'UPDATE_DEFAULT_PREFERENCE';
export const SAVE_DEFAULT_PREFERENCE = 'SAVE_DEFAULT_PREFERENCE';
export const GET_PDF_DOCUMENTATION = 'GET_PDF_DOCUMENTATION';
export const GET_RULE_STATUS = 'GET_RULE_STATUS';
export const UPDATE_RULE_STATUS = 'UPDATE_RULE_STATUS';
export const RESET_RULE_STATUS = 'RESET_RULE_STATUS';

// excel file
export const GET_EXCEL_FILE = 'GET_EXCEL_FILE';

// wizard
export const SET_SIGNUP_DATA = 'SET_SIGNUP_DATA';
export const SET_SETUP_DATA = 'SET_SETUP_DATA';
export const SUBMIT_WIZARD_INFO = 'SUBMIT_WIZARD_INFO';
export const CHANGE_WIZARD_STATUS = 'CHANGE_WIZARD_STATUS';
export const VALIDATE_PASSWORD = 'VALIDATE_PASSWORD';
export const DISMISS_WIZARD = 'DISMISS_WIZARD';

export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA';
export const SET_DASHBOARD_EMPLOYEE_CHART = 'SET_DASHBOARD_EMPLOYEE_SHEDULE';
export const SET_DASHBOARD_SHIFTS_CHART = 'SET_DASHBOARD_SHIFTS_CHART';
export const GET_DASHBOARD_PREFERENCES = 'GET_DASHBOARD_PREFERENCES';
export const GET_DASHBOARD_WEEKDAY_ALLOCTION =
  'GET_DASHBOARD_WEEKDAY_ALLOCTION';
export const SET_DASHBOARD_WEEKDAY_ALLOCTION =
  'SET_DASHBOARD_WEEKDAY_ALLOCTION';

// Skill view
export const SET_SELECTED_ROTATION = 'SET_SELECTED_ROTATION';
export const GET_SKILL_VIEW = 'GET_SKILL_VIEW';
export const SAVE_GET_SKILL_VIEW = 'SAVE_GET_SKILL_VIEW';
export const UPDATE_SKILL_VIEW = 'UPDATE_SKILL_VIEW';
export const DELETE_SHIFT_IN_SKILL_VIEW = 'DELETE_SHIFT_IN_SKILL_VIEW';
export const SAVE_DELETE_SHIFT_IN_SKILL_VIEW =
  'SAVE_DELETE_SHIFT_IN_SKILL_VIEW';

// rotation tab
export const GET_LIST_ROTATION_TAB = 'GET_LIST_ROTATION_TAB';
export const SAVE_GET_LIST_ROTATION_TAB = 'SAVE_GET_LIST_ROTATION_TAB';
export const GET_ONE_ROTATION_TAB = 'GET_ONE_ROTATION_TAB';
export const SAVE_GET_ONE_ROTATION_TAB = 'SAVE_GET_ONE_ROTATION_TAB';
export const CREATE_ROTATION_TAB = 'CREATE_ROTATION_TAB';
export const SAVE_CREATE_ROTATION_TAB = 'SAVE_CREATE_ROTATION_TAB';
export const UPDATE_ROTATION_TAB = 'UPDATE_ROTATION_TAB';
export const SAVE_UPDATE_ROTATION_TAB = 'SAVE_UPDATE_ROTATION_TAB';
export const DELETE_ROTATION_TAB = 'DELETE_ROTATION_TAB';
export const SAVE_DELETE_ROTATION_TAB = 'SAVE_DELETE_ROTATION_TAB';
