import React, { useState, useEffect } from 'react';
import { Input, Button, DatePicker, Form, Steps } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';
import {
  EmployeeTable,
  ModalSuccess,
  ConfirmationDialog
} from 'component/SendEmail/index';
import { actSetGlobalTime, actEmailStatusCheck } from 'redux/action/grid';
import { actSearchEmployee } from 'redux/action/employeeSetting';
import { actSetDateSendEmail } from 'redux/action/sendEmail';
import { SEARCH_TABLE_TIMEOUT, EMAIL_STATUS } from 'utils/constant';
import { useTranslation } from 'component/index';
import { SendEmailPageWrapper } from './style';

const { Step } = Steps;
const FormItem = Form.Item;
let searchTimeout = null;

const SendEmailPage = (props) => {
  const {
    listSelectedEmployee,
    globalTime,
    first_draft_date,
    tenantId,
    sendEmailStatus
  } = props;

  const [isConfirmDialogVisible, setConfirmDialogVisible] = useState(false);
  const [isModalSuccessVisible, setModalSuccessVisible] = useState(false);
  const [searchText, setSearchText] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [isSendEmailBtnDisable, setSendEmailBtnDisable] = useState(false);
  const [form] = Form.useForm();
  const { translate } = useTranslation();

  useEffect(() => {
    if (+moment(first_draft_date).format('x') > +globalTime) {
      setSendEmailBtnDisable(true);
    } else {
      setSendEmailBtnDisable(false);
    }
  }, [globalTime, first_draft_date]);

  useEffect(() => {
    if (sendEmailStatus === EMAIL_STATUS.NOT_SENT) {
      setCurrentStep(0);
    } else if (sendEmailStatus === EMAIL_STATUS.PREFERENCES) {
      setCurrentStep(1);
    } else if (sendEmailStatus === EMAIL_STATUS.CONFIRM_PREFERENCES) {
      setCurrentStep(2);
    } else if (sendEmailStatus === EMAIL_STATUS.ASSIGNMENTS) {
      setCurrentStep(2);
    }
  }, [sendEmailStatus]);

  useEffect(() => {
    props.actEmailStatusCheck({
      tenantId,
      year: moment(+globalTime).year(),
      month: moment(+globalTime).month() + 1
    });
  }, [globalTime, tenantId]);

  useEffect(() => {
    form.setFieldsValue({
      date: globalTime ? moment(+globalTime) : moment()
    });
  }, [globalTime]);

  const onToggleDialog = () => {
    setConfirmDialogVisible(!isConfirmDialogVisible);
  };

  const onToggleModalSucess = () => {
    setModalSuccessVisible(!isModalSuccessVisible);
  };

  const onSearchChange = (event) => {
    const { value } = event.target;

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchText(value);

    searchTimeout = setTimeout(() => {
      onSearch(value);
    }, SEARCH_TABLE_TIMEOUT);
  };

  const onSendEmail = async () => {
    try {
      const { date } = await form.validateFields(['date']);

      props.actSetDateSendEmail({
        month: date.format('MM'),
        year: date.format('YYYY')
      });
      onToggleDialog();
    } catch (err) {}
  };

  const onSearch = (searchValue) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    if (!!searchValue) {
      props.actSearchEmployee({
        filterOr: {
          name: searchValue,
          contractName: searchValue,
          team: searchValue,
          email: searchValue
        },
        sorter: {},
        filter: {}
      });
    } else {
      props.actSearchEmployee({
        filterOr: {}
      });
    }
  };

  return (
    <SendEmailPageWrapper>
      <div className="header-wrapper">
        <div className="label">{translate('sendEmail.header.sendEmail')}</div>

        <Form layout="horizontal" form={form}>
          <div className="filter-wrapper">
            <div className="search-bar">
              <Input
                prefix={<SearchOutlined />}
                size="large"
                className="input-border"
                style={{ width: '25rem' }}
                placeholder={translate('searchByKeyword')}
                onChange={onSearchChange}
                searchText={searchText}
                onPressEnter={() => onSearch(searchText)}
              />
            </div>
            <div className="filter-date">
              <FormItem
                rules={[
                  {
                    required: true,
                    message: translate('pleaseSelectADate')
                  }
                ]}
                name="date"
                style={{ marginBottom: 0 }}
              >
                <DatePicker
                  size="large"
                  picker="month"
                  className="input-border"
                  style={{ width: '25rem' }}
                  format="MMMM YYYY"
                  placeholder={translate('sendEmail.selectMonth')}
                  onChange={(date) => props.actSetGlobalTime(date.format('x'))}
                />
              </FormItem>
            </div>

            <Button
              type="primary"
              className="custom-button"
              size="large"
              disabled={
                isSendEmailBtnDisable || listSelectedEmployee.length === 0
              }
              onClick={onSendEmail}
            >
              {translate('sendEmail.sendEmail')}
            </Button>
          </div>
        </Form>
      </div>

      <div className="step-wrapper">
        <Steps current={currentStep} size="small" labelPlacement="horizontal">
          <Step title={translate('sendEmail.sendPreference')} />
          <Step title={translate('sendEmail.confirmPreference')} />
          <Step title={translate('sendEmail.sendAssignment')} />
        </Steps>
      </div>

      <div className="main-content">
        <EmployeeTable />

        <ConfirmationDialog
          isModalVisible={isConfirmDialogVisible}
          onHideModal={onToggleDialog}
          onToggleModalSucess={onToggleModalSucess}
          currentStep={currentStep}
        />

        <ModalSuccess
          isModalVisible={isModalSuccessVisible}
          onHideModal={onToggleModalSucess}
        />
      </div>
    </SendEmailPageWrapper>
  );
};

export default connect(
  (state) => ({
    listSelectedEmployee: state.SendEmail.listSelectedEmployee,
    sendEmailStatus: state.SendEmail.sendEmailStatus,
    first_draft_date: state.App.first_draft_date,
    globalTime: state.App.globalTime,
    tenantId: state?.App?.user?.tenant?.id
  }),
  {
    actSearchEmployee,
    actSetDateSendEmail,
    actSetGlobalTime,
    actEmailStatusCheck
  }
)(SendEmailPage);
