import { useEffect, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Input, Form, Button, message } from 'antd';
import { useTranslation } from 'component/index';
import {
  actGetProfileDetails,
  actUpdateProfileDetails,
  actUpdateDefaultPreference,
  actSetModalProfileVisible
} from 'redux/action/profile';
import UpdatePasswordModal from 'component/Profile/UpdatePasswordModal';

const UserProfile = (props) => {
  const { translate } = useTranslation();
  const { tenant_name, profiledata, user } = props;
  const [form] = Form.useForm();
  const [updateLoading, setUpdateLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      tenantName: tenant_name || '',
      joinDate: profiledata?.date_joined?.split('T')[0],
      firstName: profiledata?.first_name || '',
      lastName: profiledata?.last_name || '',
      email: profiledata?.email || ''
    });
  }, [tenant_name, profiledata]);

  useEffect(() => {
    if (user?.tenant?.id && user?.id) {
      props.actGetProfileDetails({ tenantId: user.tenant.id, userId: user.id });
    }
  }, [user.tenant.id, user.id]);

  const openModal = () => {
    props.actSetModalProfileVisible(true);
  };

  const onUpdateProfile = async () => {
    try {
      const { firstName, lastName, email } = await form.validateFields([
        'firstName',
        'lastName',
        'email'
      ]);
      setUpdateLoading(true);

      props.actUpdateProfileDetails(
        {
          tenantId: user.tenant.id,
          userId: user.id,
          body: {
            first_name: firstName,
            last_name: lastName,
            email: email
          }
        },
        (err) => {
          setUpdateLoading(false);

          if (err) {
            message.error(err.message);
          } else {
            message.success(translate('profile.profSucess'));
          }
        }
      );
    } catch (err) {}
  };

  return (
    <Fragment>
      <Form layout="vertical" form={form} style={{ width: '40rem' }}>
        <Form.Item label={translate('profile.tenantName')} name="tenantName">
          <Input disabled />
        </Form.Item>

        <Form.Item label={translate('profile.joinDate')} name="joinDate">
          <Input disabled />
        </Form.Item>

        <Form.Item label={translate('profile.firstNamePlace')} name="firstName">
          <Input />
        </Form.Item>

        <Form.Item label={translate('profile.lastNamePlace')} name="lastName">
          <Input />
        </Form.Item>

        <Form.Item label={translate('profile.emailPlace')} name="email">
          <Input />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            className="custom-button"
            size="large"
            onClick={onUpdateProfile}
            style={{ marginRight: '1.5rem' }}
            loading={updateLoading}
          >
            {translate('profile.update')}
          </Button>

          <Button
            type="primary"
            className="custom-button"
            size="large"
            onClick={openModal}
          >
            {translate('profile.updatePassord')}
          </Button>
        </Form.Item>
      </Form>

      <UpdatePasswordModal />
    </Fragment>
  );
};

export default connect(
  (state) => ({
    tenant_name: state.App.tenant.name,
    user: state.App.user,
    first_draft_date: state.App.first_draft_date,
    globalTime: state.App.globalTime,
    profiledata: state.Profile.data
  }),
  {
    actGetProfileDetails,
    actUpdateProfileDetails,
    actUpdateDefaultPreference,
    actSetModalProfileVisible
  }
)(UserProfile);
