import { PURGE } from 'redux-persist';
import _ from 'lodash';
import {
  SAVE_GET_SKILL_SETTING,
  SAVE_DELETE_SKILL_INFO,
  SAVE_CREATE_SKILL,
  SEARCH_SKILL,
  SAVE_UPDATE_SKILL_INFO,
  SET_SELECTED_SKILL_SETTING,
  SET_MODAL_SKILL_SETTING_VISIBLE
} from '../type';
import { filterDataOnTable } from 'utils/helper';
import { update } from './helper';

const initialState = {
  listSkill: [],
  listData: [],
  filter: {},
  sorter: {},
  filterOr: {},
  modalVisible: false,
  selectedSkillSetting: {}
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case SAVE_GET_SKILL_SETTING:
      return {
        ...state,
        listSkill: (payload && payload.results) || [],
        listData: (payload && payload.results) || []
      };

    case SAVE_DELETE_SKILL_INFO:
      return update(state, {
        listSkill: { $updateOrDelete: [payload.id] },
        listData: { $updateOrDelete: [payload.id] }
      });

    case SAVE_CREATE_SKILL:
      return {
        ...state,
        listSkill: [payload, ...state.listSkill],
        listData: [payload, ...state.listData]
      };

    case SAVE_UPDATE_SKILL_INFO:
      return update(state, {
        listSkill: {
          $updateOrDelete: [payload.id, payload]
        },
        listData: { $updateOrDelete: [payload.id, payload] }
      });

    case SEARCH_SKILL:
      const { filter, sorter } = payload;
      const filterOr = payload.filterOr || state.filterOr;

      return {
        ...state,
        filter,
        sorter: !_.isEmpty(sorter) ? sorter : state.sorter,
        filterOr: filterOr,
        listSkill: filterDataOnTable({
          listData: state.listData,
          filter,
          filterOr: filterOr || {},
          sorter
        })
      };

    case SET_SELECTED_SKILL_SETTING:
      return {
        ...state,
        selectedSkillSetting: payload
      };

    case SET_MODAL_SKILL_SETTING_VISIBLE:
      return {
        ...state,
        modalVisible: payload
      };

    default:
      return state;
  }
};

export default reducer;
