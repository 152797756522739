import styled from 'styled-components';

const DateSelectWrapper = styled.div`
  padding: 0.5rem 1rem;
  border-radius: 0.3rem;
  background-color: var(--color-light-green);
  margin-right: 1rem;

  .date {
    margin-right: 1rem;
    font-weight: 500;
  }
`;

export { DateSelectWrapper };
