import _ from 'lodash';

const onDrag = (event, cellValue) => {
  event.dataTransfer.setData('cellValue', JSON.stringify(cellValue));
};

const getGridDataByColumn = (listData) => {
  let dataByColumnObject = {};

  listData.forEach((rowData) => {
    Object.keys(rowData).forEach((colName) => {
      // if column information does not appear in @dataByColumnObject, initialize information about that column
      if (
        colName !== 'date' &&
        colName !== 'start_time' &&
        colName !== 'end_time'
      ) {
        if (!dataByColumnObject[colName]) {
          dataByColumnObject[colName] = { shifts: [], avails: [] };

          if (rowData[colName].shifts) {
            const listShift = rowData[colName].shifts.map((shift) => ({
              start_time: rowData.start_time,
              end_time: rowData.end_time,
              shift,
              date: rowData.date
            }));

            dataByColumnObject[colName].shifts = [...listShift];
          }

          if (rowData[colName].availability) {
            dataByColumnObject[colName].avails = [
              {
                start_time: rowData.start_time,
                end_time: rowData.end_time,
                availability: rowData[colName].availability,
                date: rowData.date
              }
            ];
          }
        } else {
          // else if columns exists in @dataByColumnObject, concat shifts information to shifts array of that column @colName
          if (rowData[colName].shifts) {
            const listShift = rowData[colName].shifts.map((shift) => ({
              start_time: rowData.start_time,
              end_time: rowData.end_time,
              shift,
              date: rowData.date
            }));

            dataByColumnObject[colName].shifts = [
              ...dataByColumnObject[colName].shifts,
              ...listShift
            ];
          }

          // else if columns exists in @dataByColumnObject, concat reason information to reason array of that column @colName
          if (rowData[colName].availability) {
            dataByColumnObject[colName].avails = [
              ...dataByColumnObject[colName].avails,
              {
                start_time: rowData.start_time,
                end_time: rowData.end_time,
                availability: rowData[colName].availability,
                date: rowData.date
              }
            ];
          }
        }
      }
    });
  });

  return dataByColumnObject;
};

const getRowStatistic = (dataByColumn, listTimeline) => {
  // get statistic for each employee
  let summaryOfColumn = {};

  // get statistic for shifts
  Object.keys(dataByColumn).forEach((empId) => {
    if (!summaryOfColumn[empId]) {
      summaryOfColumn[empId] = {};
    }

    dataByColumn[empId].shifts.forEach((shiftDetail) => {
      const start_time = shiftDetail.start_time;
      const end_time = shiftDetail.end_time;
      const timeStamp = `${start_time}-${end_time}`;

      if (!summaryOfColumn[empId][timeStamp]) {
        summaryOfColumn[empId][timeStamp] = [];
      }

      summaryOfColumn[empId][timeStamp].push(shiftDetail?.shift?.spot);
    });
  });

  const statistics = listTimeline.map((timeline) => {
    const { start_time, end_time } = timeline;
    const timeStamp = `${start_time}-${end_time}`;
    let employeeStats = {};

    Object.keys(summaryOfColumn).forEach((employeeId) => {
      if (!employeeStats[employeeId]) {
        employeeStats[employeeId] = 0;
      }

      const stats = summaryOfColumn[employeeId];
      if (stats[timeStamp]) {
        employeeStats[employeeId] += stats[timeStamp].length;
      }
    });

    return {
      start_time,
      end_time,
      ...employeeStats
    };
  });

  return statistics;
};

const getListTimeLine = (listRotation) => {
  const listTimeline = [];

  listRotation.forEach((rotation) => {
    const { start_time, end_time } = rotation;

    if (
      _.findIndex(listTimeline, {
        start_time,
        end_time
      }) === -1
    ) {
      listTimeline.push({
        start_time,
        end_time
      });
    }
  });

  return _.sortBy(listTimeline, 'start_time');
};

export { onDrag, getGridDataByColumn, getRowStatistic, getListTimeLine };
