import { takeLatest, all } from 'redux-saga/effects';
import { SAVE_SIGN_IN, SIGN_IN } from '../type';
import authAPI from 'redux/api/auth';
import { createRequestSaga } from './common';

const signIn = createRequestSaga({
  request: authAPI.signIn,
  key: 'signIn',
  success: [
    (res) => ({
      type: SAVE_SIGN_IN,
      payload: res
    })
  ],
  failure: []
});

const saga = [
  function* fetchWatcher() {
    yield all([takeLatest(SIGN_IN, signIn)]);
  }
];

export default saga;
