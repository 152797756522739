import { PURGE } from 'redux-persist';
import _ from 'lodash';
import moment from 'moment';
import {
  SAVE_GET_USER_AVAILABILITY,
  SET_SELECTED_PREFERENCE_AVAIL,
  SAVE_UPDATE_PREFERENCE_AVAIL,
  SAVE_CREATE_PREFERENCE_AVAIL,
  SET_MODAL_PREFERENCE_VISIBLE,
  SAVE_GET_EMPLOYEE_PREFERENCE,
  SAVE_GET_EMPLOYEE_SHIFTS,
  SAVE_ON_DELETE_EMPLOYEE_PREF,
  SET_IS_VIEW_CONFIRMED,
  SAVE_LIST_PREFERENCE_SNAPSHOT,
  AFTER_DELETE_PREFERENCE_AVAIL
} from '../type';
import { update, getPreferenceGridData } from './helper';

const initialState = {
  listAvailability: [],
  isModalVisible: false,
  selectedAvail: {},
  selectedEmployee: {},
  selectedMonth: null,
  employeeShifts: null,
  isViewConfirmed: false,
  listSnapshot: []
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case SAVE_GET_EMPLOYEE_PREFERENCE: {
      const { preferences = [], month, name } = payload;
      const { listAvailability } = getPreferenceGridData(
        preferences,
        moment().month(month)
      );

      return {
        ...state,
        listAvailability,
        selectedEmployee: { employeeId: payload.id, name },
        selectedMonth: moment().month(month)
      };
    }

    case SAVE_GET_USER_AVAILABILITY: {
      const { listAvailability } = getPreferenceGridData(
        payload?.listData || [],
        moment(+payload.globalTime)
      );

      return {
        ...state,
        listAvailability,
        selectedMonth: null
      };
    }

    case SET_SELECTED_PREFERENCE_AVAIL:
      return {
        ...state,
        selectedAvail: (payload && payload.availInfo) || {}
      };

    case SET_MODAL_PREFERENCE_VISIBLE:
      return {
        ...state,
        isModalVisible: payload
      };

    case SAVE_UPDATE_PREFERENCE_AVAIL: {
      const {
        employee_reason,
        message = '',
        listEmployeeReason,
        spots = []
      } = payload;
      const foundedEmpReason = _.find(listEmployeeReason, {
        id: employee_reason
      });

      const indexOfDate = _.findIndex(state.listAvailability, {
        start: state.selectedAvail.start
      });
      const selectedCell = state.listAvailability[indexOfDate];
      const indexOfAvail = _.findIndex(selectedCell.listAvail, {
        availId: payload.id
      });

      const tempCell = _.cloneDeep(selectedCell);
      tempCell.listAvail[indexOfAvail].avail = foundedEmpReason?.name || '';
      tempCell.listAvail[indexOfAvail].message = message;
      tempCell.listAvail[indexOfAvail].employee_reason = employee_reason;
      tempCell.listAvail[indexOfAvail].spots = spots;

      return update(state, {
        listAvailability: {
          [indexOfDate]: {
            $set: tempCell
          }
        }
      });
    }

    case SAVE_CREATE_PREFERENCE_AVAIL: {
      const {
        start_date_time,
        end_date_time,
        employee,
        message,
        employee_reason,
        listEmployeeReason,
        spots
      } = payload;

      const foundedEmpReason = _.find(listEmployeeReason, {
        id: employee_reason
      });

      const startTime = moment.utc(start_date_time);
      const endTime = moment.utc(end_date_time);

      const startHour = startTime.format('HH:mm');
      const endHour = endTime.format('HH:mm');
      const start = `${startTime.format('YYYY-MM-DD')}T00:00:00Z`;
      const tempListAvail = [...state.listAvailability];

      const indexOfDate = _.findIndex(tempListAvail, {
        start
      });
      const availInfo = {
        startHour,
        endHour,
        employee_reason: foundedEmpReason?.id,
        availId: payload.id,
        start_date_time,
        end_date_time,
        employee,
        message,
        spots
      };

      if (indexOfDate === -1) {
        tempListAvail.push({
          start,
          listAvail: [availInfo]
        });
      } else {
        tempListAvail[indexOfDate].listAvail.push(availInfo);
        tempListAvail[indexOfDate].listAvail = _.sortBy(
          tempListAvail[indexOfDate].listAvail,
          'startHour'
        );
      }

      return {
        ...state,
        listAvailability: tempListAvail,
        listData: tempListAvail
      };
    }

    case SAVE_GET_EMPLOYEE_SHIFTS:
      return {
        ...state,
        employeeShifts: payload.map((shiftInfo) => ({
          start_date_time: shiftInfo?.start_date_time,
          end_date_time: shiftInfo?.end_date_time,
          spotName: shiftInfo?.spot?.name || '',
          id: shiftInfo?.id
        }))
      };

    case SAVE_ON_DELETE_EMPLOYEE_PREF:
      const listAvail = _.cloneDeep(state.listAvailability);
      return {
        ...state,
        listAvailability: listAvail.map((item) => {
          return {
            ...item,
            listAvail: item.listAvail.filter(
              (item) => +item.availId !== +payload
            )
          };
        })
      };

    case SET_IS_VIEW_CONFIRMED:
      return {
        ...state,
        isViewConfirmed: payload
      };

    case SAVE_LIST_PREFERENCE_SNAPSHOT:
      return {
        ...state,
        listSnapshot: payload
      };

    case AFTER_DELETE_PREFERENCE_AVAIL:
      const tempListSnapshot = [...state.listSnapshot];

      if (
        !_.find(state.listSnapshot, {
          employee: payload.employee,
          start_date_time: payload.start_date_time,
          end_date_time: payload.end_date_time
        })
      ) {
        tempListSnapshot.push({
          employee: payload.employee,
          start_date_time: payload.start_date_time,
          end_date_time: payload.end_date_time,
          employee_reason: payload.employee_reason
        });
      }

      return {
        ...state,
        listSnapshot: tempListSnapshot
      };

    default:
      return state;
  }
};

export default reducer;
