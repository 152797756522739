import React, { useEffect, useState } from 'react';
import { Table, Popconfirm, Button, Input, Tag, message } from 'antd';
import { connect } from 'react-redux';
import { FaRegEdit } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import {
  actGetContractSetting,
  actUpdateContractInfo,
  actDeleteContractInfo,
  actSaveDeleteContractInfo,
  actCreateContract,
  actSearchContract,
  actSetModalContractSettingVisible,
  actSetSelectedContractSetting
} from 'redux/action/contractSetting';

import { convertTableFilter, convertTableSorter } from 'utils/helper';
import { TableWrapper, TableActionWrapper } from 'component/style';
import { useTranslation } from 'component/index';
import ContractSettingModal from './ContractSettingModal';

const columnConfig = ({
  onDeleteContractInfo,
  getColumnSearchProps,
  translate,
  onEditContract
}) => [
  {
    title: translate('contract.name'),
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    width: '13%',
    ...getColumnSearchProps('name')
  },
  {
    title: translate('contract.maxMinutePerDay'),
    dataIndex: 'maximum_minutes_per_day',
    key: 'maximum_minutes_per_day',
    sorter: true,
    width: '14%',
    ...getColumnSearchProps('maximum_minutes_per_day'),
    render: (value) => value || ''
  },
  {
    title: translate('contract.maxMinutePerWeek'),
    dataIndex: 'maximum_minutes_per_week',
    key: 'maximum_minutes_per_week',
    sorter: true,
    width: '14%',
    ...getColumnSearchProps('maximum_minutes_per_week'),
    render: (value) => value || ''
  },
  {
    title: translate('contract.maxMinutePerMonth'),
    dataIndex: 'maximum_minutes_per_month',
    key: 'maximum_minutes_per_month',
    sorter: true,
    width: '14%',
    required: false,
    ...getColumnSearchProps('maximum_minutes_per_month'),
    render: (value) => value || ''
  },
  /*{
    title: translate('contract.maxMinutePerYear'),
    dataIndex: 'maximum_minutes_per_year',
    key: 'maximum_minutes_per_year',
    sorter: true,
    width: '14%',
    ...getColumnSearchProps('maximum_minutes_per_year'),
    render: (value) => value || ''
  },*/
  {
    title: translate('contract.Shift_type'),
    dataIndex: 'contract_spot',
    key: 'Shift_Type',
    style: { marginTop: '0px' },
    align: 'center',
    // width:100 ,
    // render:spot=>spot.map(spot=><div>{spot.spot_type.name}</div>)
    render: (spot) => {
      if (spot) {
        return spot.map((spot, index) => (
          <div>
            <Tag color="purple" style={{ marginBottom: '5px' }} key={index}>
              {spot.spot_type.name}
            </Tag>
          </div>
        ));
      }

      return null;
    }
  },

  {
    title: translate('contract.Shift_count'),
    dataIndex: 'contract_spot',
    key: 'Shift_Count',
    align: 'center',
    // width: 50,
    render: (spot) =>
      spot.map((spot, index) => (
        <div key={index} style={{ marginBottom: '5px' }}>
          {spot.spot_type_count}
        </div>
      ))
  },

  {
    title: translate('table.actions'),
    dataIndex: 'actions',
    key: 'actions',
    width: '8%',
    render: (value, record) => (
      <TableActionWrapper>
        <FaRegEdit
          className="edit-icon"
          onClick={() => onEditContract(record)}
        />

        <Popconfirm
          onConfirm={() => onDeleteContractInfo(record)}
          title={translate('message.confirmToDelete')}
          okText={translate('yes')}
          cancelText={translate('no')}
        >
          <RiDeleteBin6Line className="delete-icon" />
        </Popconfirm>
      </TableActionWrapper>
    )
  }
];

const ContractTable = (props) => {
  const { tenantId, listContract } = props;

  let searchInput = null;
  const [isLoading, setLoading] = useState(false);
  const [searchText, onSetSearchText] = useState([]);
  const { translate } = useTranslation();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10
  });

  useEffect(() => {
    setLoading(true);
    props.actGetContractSetting({ tenantId }, (err, res) => {
      setLoading(false);
    });
  }, [tenantId]);

  const getColumnSearchProps = () => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={translate('typeAkeyWord')}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {translate('search')}
        </Button>

        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          {translate('reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => value.toString(),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text) => {
      return (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={searchText}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      );
    }
  });

  const handleSearch = (selectedKeys, confirm) => {
    onSetSearchText(selectedKeys);
    confirm();
  };

  const handleReset = (clearFilters) => {
    onSetSearchText([]);
    clearFilters();
  };

  const onEditContract = (record) => {
    props.actSetSelectedContractSetting(record);
    props.actSetModalContractSettingVisible(true);
  };

  const onShowTotalData = () => {
    return `${listContract.length} ${translate('table.rowInTotal')}`;
  };

  const onDeleteContractInfo = (contract) => {
    props.actDeleteContractInfo(
      { tenantId, contractId: contract.id },
      (err) => {
        if (!err) {
          props.actSaveDeleteContractInfo({ id: contract.id });
          message.success(translate('contract.deleteSuccess'));
        } else {
          message.error(err.message);
        }
      }
    );
  };

  const onTableChange = (pagination, filter, sorter) => {
    setPagination(pagination);
    const tableFilter = convertTableFilter(filter);
    const tableSorter = convertTableSorter(sorter);

    props.actSearchContract({ filter: tableFilter, sorter: tableSorter });
  };

  const columns = columnConfig({
    onEditContract,
    onDeleteContractInfo,
    getColumnSearchProps,
    translate
  });

  return (
    <TableWrapper>
      <Table
        columns={columns}
        rowKey={(data) => data.id}
        dataSource={listContract}
        pagination={{
          ...pagination,
          showTotal: onShowTotalData,
          pageSizeOptions: [10, 20, 30, 40],
          showSizeChanger: true
        }}
        size="large"
        rowClassName="editable-row"
        loading={isLoading}
        onChange={onTableChange}
      />

      <ContractSettingModal />
    </TableWrapper>
  );
};

export default connect(
  (state) => ({
    tenantId: state?.App?.user?.tenant?.id,
    unSavedContract: state.ContractSetting.unSavedContract,
    listContract: state.ContractSetting.listContract
  }),
  {
    actGetContractSetting,
    actUpdateContractInfo,
    actDeleteContractInfo,
    actSaveDeleteContractInfo,
    actCreateContract,
    actSearchContract,
    actSetModalContractSettingVisible,
    actSetSelectedContractSetting
  }
)(ContractTable);
