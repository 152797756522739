import API from './common';

const api = {
  getProfileDetails: ({ tenantId, userId } = {}) =>
    API('get', `${tenantId}/user/${userId}`),
  updateProfileDetails: ({ tenantId, userId, body } = {}) =>
    API('put', `${tenantId}/user/${userId}/update`, body),
  updatePassword: ({ body } = {}) => API('post', `user/reset-password/`, body),
  updateDefaultPreference: ({ tenantId, body } = {}) =>
    API('post', `tenant/${tenantId}/configure/`, body),
  getPdfDocumentationApi: ({ tenantId }) =>
    API('get', `${tenantId}/user/profile-docs`),
  getRuleAndStatusApi: ({ tenantId }) =>
    API('get', `${tenantId}/user/rules-and-status`),
  updateRuleAndStatusApi: ({ tenantId, body }) =>
    API('post', `${tenantId}/user/rules-and-status`, body),
  resetRuleAndStatusApi: ({ tenantId, body }) =>
    API('post', `${tenantId}/user/rules-and-status/reset`, body)
};

export default api;
