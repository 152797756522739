import { PURGE } from 'redux-persist';
import _ from 'lodash';
import {
  SAVE_GET_CONTRACT_SETTING,
  SAVE_DELETE_CONTRACT_INFO,
  SAVE_CREATE_CONTRACT,
  SEARCH_CONTRACT,
  SAVE_UPDATE_CONTRACT_INFO,
  SET_SELECTED_CONTRACT_SETTING,
  SET_MODAL_CONTRACT_SETTING_VISIBLE
} from '../type';
import { filterDataOnTable } from 'utils/helper';
import { update, parseContractInfo } from './helper';

const initialState = {
  listContract: [],
  listData: [],
  filter: {},
  sorter: {},
  filterOr: {},
  modalVisible: false,
  selectedContractSetting: {}
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case SAVE_GET_CONTRACT_SETTING:
      const listContract =
        payload && payload.results.map((record) => parseContractInfo(record));

      return {
        ...state,
        listContract: listContract || [],
        listData: listContract || []
      };

    case SAVE_DELETE_CONTRACT_INFO:
      return update(state, {
        listContract: { $updateOrDelete: [payload.id] },
        listData: { $updateOrDelete: [payload.id] }
      });

    case SAVE_CREATE_CONTRACT: {
      const contract = parseContractInfo(payload);

      return {
        ...state,
        listContract: [contract, ...state.listContract],
        listData: [contract, ...state.listData]
      };
    }

    case SAVE_UPDATE_CONTRACT_INFO: {
      const contract = parseContractInfo(payload);

      return update(state, {
        listContract: {
          $updateOrDelete: [payload.id, contract]
        },
        listData: { $updateOrDelete: [payload.id, contract] }
      });
    }

    case SEARCH_CONTRACT:
      const { filter, sorter } = payload;
      const filterOr = payload.filterOr || state.filterOr;

      return {
        ...state,
        filter,
        sorter: !_.isEmpty(sorter) ? sorter : state.sorter,
        filterOr: filterOr,
        listContract: filterDataOnTable({
          listData: state.listData,
          filter,
          filterOr: filterOr || {},
          sorter
        })
      };

    case SET_SELECTED_CONTRACT_SETTING:
      return {
        ...state,
        selectedContractSetting: payload
      };

    case SET_MODAL_CONTRACT_SETTING_VISIBLE:
      return {
        ...state,
        modalVisible: payload
      };

    default:
      return state;
  }
};

export default reducer;
