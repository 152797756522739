import API from './common';
function convertToQueryString(params) {
  const queryString = new URLSearchParams(params).toString();
  return queryString;
}

const api = {
  // grid data
  getAssignmentData: ({ tenantId, month, year }) =>
    API('get', `/${tenantId}/shift/assignments/?month=${month}&year=${year}`),
  //dashboard
  getEmployeeChartData: ({
    tenantId,
    from_month,
    to_month,
    from_year,
    to_year
  }) =>
    API(
      'get',
      `/${tenantId}/shift/dashboard/employee-chart/?from_month=${from_month}&to_month=${to_month}&from_year=${from_year}&to_year=${to_year}`
    ),
  getShiftsChartData: ({
    tenantId,
    from_month,
    to_month,
    from_year,
    to_year
  }) =>
    API(
      'get',
      `/${tenantId}/shift/dashboard/shift-chart/?from_month=${from_month}&to_month=${to_month}&from_year=${from_year}&to_year=${to_year}`
    ),
  getPreferences: ({ tenantId, from_month, to_month, from_year, to_year }) =>
    API(
      'get',
      `/${tenantId}/shift/dashboard/preference/?from_month=${from_month}&to_month=${to_month}&from_year=${from_year}&to_year=${to_year}`
    ),

  getWeekdayAllocation: ({
    tenantId,
    from_month,
    to_month,
    from_year,
    to_year
  }) =>
    API(
      'get',
      `/${tenantId}/shift/dashboard/weekdays-employee-schedule/?from_month=${from_month}&to_month=${to_month}&from_year=${from_year}&to_year=${to_year}`
    ),
  // shift
  createShift: ({ body, tenantId }) =>
    API('post', `/${tenantId}/shift/add`, body),
  updateShift: ({ body, tenantId, shiftId }) =>
    API('put', `/${tenantId}/shift/${shiftId}/update`, body),
  deleteShift: ({ tenantId, shiftId }) =>
    API('delete', `/${tenantId}/shift/${shiftId}`, {}),

  // availability
  createAvailability: ({ tenantId, body }) =>
    API('post', `/${tenantId}/employee/availability/add`, body),
  updateAvailability: ({ body, tenantId, availId }) =>
    API('put', `/${tenantId}/employee/availability/${availId}/update`, body),

  deleteAvailability: ({ tenantId, availId }) =>
    API('delete', `/${tenantId}/employee/availability/${availId}`),

  // preference
  getUserAvailability: ({ tenantId, employeeId, month, year }) =>
    API(
      'get',
      `/${tenantId}/employee/availability/?employee_id=${employeeId}&month=${month}&year=${year}`
    ),

  // employee preference
  getEmployeePreference: ({ hashCode }) =>
    API('get', `/hash_check/${hashCode}/`),

  getEmailStatusCheck: ({
    tenantId,
    month,
    from_month,
    to_month,
    year,
    from_year,
    to_year
  }) => {
    if (month && year) {
      var params = { month, year };
      var query = convertToQueryString(params);
    } else {
      var params = { from_month, to_month, from_year, to_year };
      var query = convertToQueryString(params);
    }
    return API('get', `${tenantId}/send_email/status/?${query}`);
  },
  // skill view
  getSkillView: ({ tenantId, month, year, spot }) =>
    API(
      'get',
      `/${tenantId}/shift/assignments/skills?spot=${spot}&month=${month}&year=${year}`
    )
};

export default api;
