import React, { useEffect, useState, useMemo } from 'react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { Tooltip, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { LIST_TAG_COLOR, EMAIL_STATUS_COLOR } from 'utils/constant';
import { actSetCollapseStatisitc } from 'redux/action/grid';
import {
  CustomHeaderGroupWrapper,
  EmployeeHeaderWrapper,
  StatisticByRankWrapper
} from './style';
import { useTranslation } from 'component/index';
import { onDrag } from 'component/EmployeeGrid/util';

let CustomHeaderGroup = (props) => {
  const { isStatisticCollapse } = props;
  const [expandState, setExpandState] = useState('collapsed');
  const { translate } = useTranslation();

  const onToggleStatisticView = () => {
    props.actSetCollapseStatisitc(!isStatisticCollapse);
  };

  const expandOrCollapse = () => {
    let currentState = props.columnGroup.getOriginalColumnGroup().isExpanded();
    props.setExpanded(!currentState);
  };

  const syncExpandButtons = () => {
    setExpandState(
      props.columnGroup.getOriginalColumnGroup().isExpanded()
        ? 'expanded'
        : 'collapsed'
    );
  };

  useEffect(() => {
    props.columnGroup
      .getOriginalColumnGroup()
      .addEventListener('expandedChanged', syncExpandButtons);
    syncExpandButtons();

    return () => {
      props.columnGroup
        .getOriginalColumnGroup()
        .removeEventListener('expandedChanged', syncExpandButtons);
    };
  }, []);

  return (
    <div className="ag-header-group-cell-label">
      <CustomHeaderGroupWrapper>
        <div className="customHeaderLabel">{props.displayName}</div>

        <Tooltip
          title={
            isStatisticCollapse
              ? translate('assignment.expandColumn')
              : translate('assignment.collapseColumn')
          }
        >
          <div
            className={`customExpandButton ${expandState}`}
            onClick={() => expandOrCollapse()}
          >
            <AiOutlineArrowRight />
          </div>
        </Tooltip>

        <Tooltip
          title={
            isStatisticCollapse
              ? translate('assignment.viewStatistic')
              : translate('assignment.hideStatistic')
          }
        >
          <div className="collapse-icon" onClick={onToggleStatisticView}>
            {isStatisticCollapse ? <IoMdArrowDropdown /> : <IoMdArrowDropup />}
          </div>
        </Tooltip>
      </CustomHeaderGroupWrapper>
    </div>
  );
};

CustomHeaderGroup = connect(
  (state) => ({ isStatisticCollapse: state.Grid.isStatisticCollapse }),
  { actSetCollapseStatisitc }
)(CustomHeaderGroup);

let ShiftPickerHeader = (props) => {
  const { column, listShiftType, displayName } = props;
  const { colDef } = column;

  const shiftTypeInfo = useMemo(() => {
    return _.find(listShiftType, { id: +displayName }) || {};
  }, [listShiftType]);

  return (
    <Tooltip title={shiftTypeInfo?.name}>
      <Tag
        color={LIST_TAG_COLOR[colDef.index % LIST_TAG_COLOR.length]}
        draggable={true}
        className="drag-item"
        onDragStart={(event) => onDrag(event, { value: +displayName })}
      >
        {shiftTypeInfo?.name}
      </Tag>
    </Tooltip>
  );
};

ShiftPickerHeader = connect((state) => ({
  listShiftType: state.ShiftTypeSetting.listShiftType
}))(ShiftPickerHeader);

const StatisticByRank = (props) => {
  const { countByRank } = props;

  return (
    <StatisticByRankWrapper>
      {Object.keys(countByRank).map((key, index) => (
        <div className="statistic-item" key={index}>
          <div className="rank-name">{key}:</div>
          <div className="number">{countByRank[key]}</div>
        </div>
      ))}
    </StatisticByRankWrapper>
  );
};

let ShiftPickerCell = (props) => {
  const { data, colDef, listEmployee } = props;
  const rowData = data;
  let sum = 0;
  const countByRank = {};

  Object.keys(rowData).forEach((key) => {
    if (typeof rowData[key] === 'object') {
      const employee = _.find(listEmployee, { id: +key });
      const rank = employee?.rank;

      const listShiftInCell = rowData[key].shifts || [];

      if (!_.isNull(rank)) {
        listShiftInCell.forEach((shift) => {
          if (!countByRank[rank]) {
            countByRank[rank] = 0;
          }

          if (shift?.spot === +colDef.field) {
            sum += 1;
            countByRank[rank] += 1;
          }
        });
      }
    }
  });

  return _.isEmpty(countByRank) ? (
    <div className="shift-picker-cell">{sum > 0 ? sum : ''}</div>
  ) : (
    <Tooltip
      placement="top"
      title={<StatisticByRank countByRank={countByRank} />}
    >
      <div className="shift-picker-cell">{sum > 0 ? sum : ''}</div>
    </Tooltip>
  );
};

ShiftPickerCell = connect((state) => ({
  listEmployee: state.EmployeeSetting.listEmployee
}))(ShiftPickerCell);

let EmployeeHeader = (props) => {
  const { column, listEmployee, emailStatus } = props;

  const employeeInfo = useMemo(() => {
    return _.find(listEmployee, { id: +column?.colDef?.field }) || {};
  }, [listEmployee, column]);

  const emailStatusInfo = useMemo(() => {
    return (
      _.find(
        emailStatus,
        (obj) => obj.employee.id === +column?.colDef?.field
      ) || {}
    );
  }, [emailStatus, column]);

  return (
    <EmployeeHeaderWrapper>
      <Link to={`/preferences?employeeId=${employeeInfo?.id}`}>
        {employeeInfo?.name}
      </Link>

      <div className="email_delivery_cont">
        <div className="rank">
          {employeeInfo?.rank && employeeInfo.rank.substring(0, 10)}
        </div>

        <div
          style={{ display: 'flex', marginTop: '0.5rem', marginLeft: 'auto' }}
        >
          <Tooltip title={emailStatusInfo?.email_status || 'not sent'}>
            <div
              className="email_circle"
              style={{
                backgroundColor: emailStatusInfo?.email_status
                  ? EMAIL_STATUS_COLOR[emailStatusInfo?.email_status]
                  : 'black'
              }}
            />
          </Tooltip>
        </div>
      </div>
    </EmployeeHeaderWrapper>
  );
};

EmployeeHeader = connect((state) => ({
  listEmployee: state.EmployeeSetting.listEmployee,
  emailStatus: state.Grid.emailStatus
}))(EmployeeHeader);

export {
  CustomHeaderGroup,
  EmployeeHeader,
  ShiftPickerHeader,
  ShiftPickerCell
};
