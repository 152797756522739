import API from './common';

const api = {
  createEmployeeSkillSet: ({ tenantId, body }) =>
    API('post', `${tenantId}/esps/add`, body),
  deleteEmployeeSkillSet: ({ tenantId, skillId }) =>
    API('delete', `${tenantId}/esps/${skillId}`)
};

export default api;
