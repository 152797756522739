import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import LanguageSwitcher from 'component/LanguageSwitcher';
import bgUrl from 'assets/header-bg.svg';
import headerIcon from 'assets/header-icon.svg';
import { useTranslation } from 'component/index';
import { HeadingSectionWrapper } from './style';

const HeadingSection = () => {
  const { translate } = useTranslation();

  const onScroolToFeatureSection = () => {
    const featureSection = document.getElementById('feature');
    featureSection.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <HeadingSectionWrapper bgUrl={bgUrl}>
      <Row type="flex" justify="center">
        <Col xxl={18} xl={20} lg={23} md={24} sm={24} xs={24}>
          <div className="main">
            <div className="navigation">
              <div className="logo">
                <span>予定PRO</span>
              </div>

              <Link to="/log-in">
                <button className="login-button">
                  <span>{translate('homepage.login')}</span>
                </button>
              </Link>

              <span style={{ marginLeft: '2rem' }}>
                <LanguageSwitcher />
              </span>
            </div>

            <div className="content">
              <h1>{translate('homepage.heading')}</h1>
              <p>{translate('homepage.subHeading')}</p>

              <div
                className="primary-button"
                onClick={onScroolToFeatureSection}
              >
                {translate('homepage.features')}
              </div>
            </div>

            <div className="heading-icon">
              <img src={headerIcon} alt="heading icon" />
            </div>
          </div>
        </Col>
      </Row>
    </HeadingSectionWrapper>
  );
};

export default HeadingSection;
