import { useEffect, useState } from 'react';
import { Table, Popconfirm, Button, Input, Tag, Row, message } from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import { FaRegEdit } from 'react-icons/fa';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { RiDeleteBin6Line } from 'react-icons/ri';
import Highlighter from 'react-highlight-words';
import { SearchOutlined, CopyOutlined } from '@ant-design/icons';
import {
  actGetRotationSetting,
  actUpdateRotationInfo,
  actDeleteRotationInfo,
  actSaveDeleteRotationInfo,
  actCreateRotation,
  actSearchRotation,
  actSetModalRotationSettingVisible,
  actSetSelectedRotationSetting
} from 'redux/action/rotationSetting';
import { actGetShiftGroup } from 'redux/action/shiftGroupSetting';
import { actGetShiftTypeSetting } from 'redux/action/shiftTypeSetting';
import { actGetSkillSetting } from 'redux/action/skillSetting';
import { useTranslation } from 'component/index';
import { convertTableFilter, convertTableSorter } from 'utils/helper';
import { TableWrapper, TableActionWrapper } from 'component/style';
import RotationModal from './RotationModal';
import { renderSkillSetTags } from './util';

const columnConfig = ({
  onDeleteRotationInfo,
  getColumnSearchProps,
  onEditRotation,
  translate,
  listShiftType,
  listSkill,
  listEmployee,
  onCloneRotation
}) => [
  {
    title: translate('rotation.startTime'),
    dataIndex: 'start_time',
    key: 'start_time',
    width: '10%',
    sorter: true,
    ...getColumnSearchProps('start_time')
  },
  {
    title: translate('rotation.endTime'),
    dataIndex: 'end_time',
    key: 'end_time',
    width: '10%',
    sorter: true,
    ...getColumnSearchProps('end_time')
  },
  {
    title: translate('rotation.shiftType'),
    dataIndex: 'spot',
    key: 'spot',
    sorter: true,
    width: '10%',
    render: (spot) => {
      if (spot) {
        const foundedShiftType = _.find(listShiftType, { id: spot }) || {};

        return <Tag color="purple">{foundedShiftType.name}</Tag>;
      }

      return null;
    }
  },
  {
    title: translate('rotation.skills'),
    dataIndex: 'skills',
    key: 'skills',
    sorter: true,
    width: '30%',
    render: (skillGroup) => {
      if (skillGroup) {
        return skillGroup.map((listSkillSet, indexOfGroup) => {
          return (
            <Row style={{ marginBottom: '1rem' }} key={indexOfGroup}>
              <span style={{ marginRight: '1rem' }}>{`Employee ${
                indexOfGroup + 1
              }:`}</span>

              <span>{renderSkillSetTags(listSkillSet, listSkill, true)}</span>
            </Row>
          );
        });
      }

      return null;
    }
  },
  {
    title: translate('rotation.preferredEmployee'),
    dataIndex: 'preferred_employees',
    key: 'preferred_employees',
    sorter: true,
    width: '15%',
    render: (preferred_employees) =>
      preferred_employees?.map((employeeId, index) => {
        if (employeeId === null) {
          return (
            <div key={index} style={{ marginBottom: '1rem' }}>
              <Tag color="purple">-</Tag>
            </div>
          );
        }

        const empInfo = _.find(listEmployee, { id: employeeId });

        return (
          <div key={index} style={{ marginBottom: '1rem' }}>
            <Tag color="purple">{empInfo?.name}</Tag>
          </div>
        );
      })
  },
  {
    title: translate('rotation.shiftGroup'),
    dataIndex: 'shift_group',
    key: 'shift_group',
    width: '10%',
    sorter: true,
    ...getColumnSearchProps('shift_group'),
    render: (shiftGroup) => shiftGroup?.name
  },
  {
    title: translate('table.actions'),
    dataIndex: 'actions',
    key: 'actions',
    width: '10%',
    render: (value, record, index) => (
      <TableActionWrapper>
        <FaRegEdit
          className="edit-icon"
          onClick={() => onEditRotation(record)}
        />

        <Popconfirm
          onConfirm={() => onDeleteRotationInfo(record)}
          title={translate('message.confirmToDelete')}
          okText={translate('yes')}
          cancelText={translate('no')}
        >
          <RiDeleteBin6Line className="delete-icon" />
        </Popconfirm>

        <CopyOutlined
          className="clone-icon"
          onClick={() => onCloneRotation(record)}
        />
      </TableActionWrapper>
    )
  }
];

const RotationTable = (props) => {
  const {
    tenantId = 1,
    listRotation,
    globalTime,
    listShiftType,
    listSkill,
    tabId,
    listEmployee
  } = props;

  let searchInput = null;
  const [isLoading, setLoading] = useState(false);
  const [searchText, onSetSearchText] = useState([]);
  const { translate } = useTranslation();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10
  });

  const tabRotations = listRotation.filter(
    (rotation) => rotation?.tab?.id === +tabId
  );

  const getRotationSetting = () => {
    props.actGetRotationSetting(
      {
        tenantId,
        year: moment(+globalTime).year(),
        month: moment(+globalTime).month() + 1
      },
      (err, res) => {
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    setLoading(true);

    props.actGetShiftTypeSetting({
      tenantId
    });
    props.actGetSkillSetting({ tenantId });
    props.actGetShiftGroup({ tenantId });
    getRotationSetting();
  }, [globalTime, tenantId]);

  const getColumnSearchProps = () => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={translate('typeAkeyWord')}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {translate('search')}
        </Button>

        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          {translate('reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => value.toString(),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text) => {
      return (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={searchText}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      );
    }
  });

  const handleSearch = (selectedKeys, confirm) => {
    onSetSearchText(selectedKeys);
    confirm();
  };

  const handleReset = (clearFilters) => {
    onSetSearchText([]);
    clearFilters();
  };

  const onEditRotation = (record) => {
    props.actSetModalRotationSettingVisible(true);
    props.actSetSelectedRotationSetting(record);
  };

  const onShowTotalData = () => {
    return `${tabRotations.length} ${translate('table.rowInTotal')}`;
  };

  const onDeleteRotationInfo = (rotation) => {
    props.actDeleteRotationInfo(
      { tenantId, rotationId: rotation.id },
      (err) => {
        if (!err) {
          props.actSaveDeleteRotationInfo({ id: rotation.id });
          message.success(translate('rotation.deleteSuccess'));
        } else {
          message.error(err.message);
        }
      }
    );
  };

  const onTableChange = (pagination, filter, sorter) => {
    setPagination(pagination);
    const tableFilter = convertTableFilter(filter);
    const tableSorter = convertTableSorter(sorter);

    props.actSearchRotation({ filter: tableFilter, sorter: tableSorter });
  };

  const onCloneRotation = (rotationInfo) => {
    props.actCreateRotation(
      {
        tenantId,
        body: {
          ..._.omit(rotationInfo, ['id']),
          shift_group: rotationInfo?.shift_group?.id,
          tab: rotationInfo?.tab?.id,
          start_time: '00:00',
          end_time: '00:00'
        }
      },
      (err, res) => {
        if (!err) {
          message.success(translate('rotation.cloneSuccess'));
          getRotationSetting();
        } else {
          message.error(err.message);
        }
      }
    );
  };

  const onCreateRotation = () => {
    props.actSetModalRotationSettingVisible(true);
    props.actSetSelectedRotationSetting({});
  };

  const columns = columnConfig({
    onDeleteRotationInfo,
    getColumnSearchProps,
    onEditRotation,
    translate,
    listShiftType,
    listSkill,
    listEmployee,
    onCloneRotation
  });

  return (
    <TableWrapper>
      <Table
        columns={columns}
        rowKey={(data) => data.id}
        dataSource={tabRotations}
        pagination={{
          ...pagination,
          showTotal: onShowTotalData,
          pageSizeOptions: [10, 20, 30, 40],
          showSizeChanger: true
        }}
        size="large"
        rowClassName="editable-row"
        loading={isLoading}
        onChange={onTableChange}
        footer={() => (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            className="custom-button"
            size="large"
            onClick={onCreateRotation}
          >
            {translate('rotation.addRotation')}
          </Button>
        )}
      />

      <RotationModal tabId={tabId} onSuccess={() => getRotationSetting()} />
    </TableWrapper>
  );
};

export default connect(
  (state) => ({
    tenantId: state?.App?.user?.tenant?.id,
    listRotation: state.RotationSetting.listRotation,
    globalTime: state.App.globalTime,
    listShiftType: state.ShiftTypeSetting.listShiftType,
    listSkill: state.SkillSetting.listSkill,
    listEmployee: state.EmployeeSetting.listEmployee,
    listShiftGroup: state.ShiftGroupSetting.listShiftGroup
  }),
  {
    actGetSkillSetting,
    actGetRotationSetting,
    actUpdateRotationInfo,
    actDeleteRotationInfo,
    actSaveDeleteRotationInfo,
    actCreateRotation,
    actSearchRotation,
    actSetModalRotationSettingVisible,
    actSetSelectedRotationSetting,
    actGetShiftTypeSetting,
    actGetShiftGroup
  }
)(RotationTable);
