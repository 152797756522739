import { useEffect, useState } from 'react';
import { Button, DatePicker, message, Table } from 'antd';
import moment from 'moment';
import { connect } from 'react-redux';
import _ from 'lodash';
import { useTranslation } from 'component/index';
import WizardFormModal from 'component/WizardForm/WizardFormModal';
import StatisticItem from 'component/DashboardLayout/Dashboard/StatisticItem';
import WeekySchedule from 'component/DashboardLayout/Dashboard/WeeklySchedule';
import EmployeeSchedule from 'component/DashboardLayout/Dashboard/EmployeeSchedule';
import { actGetRotationSetting } from 'redux/action/rotationSetting';
import { actGetEmployeeSetting } from 'redux/action/employeeSetting';
import { actGetShiftTypeSetting } from 'redux/action/shiftTypeSetting';
import { actGetEmployeeReasonSetting } from 'redux/action/employeeReason';
import {
  actSetDashboardFromTime,
  actSetDashboardToTime,
  actGetAssignmentData,
  actEmailStatusCheck
} from 'redux/action/grid';
import { actGetDashboardPreferences } from 'redux/action/dashboard';
import {
  actSetDashboardData,
  actSetDashboardEmployeeChart,
  actSetDashboardShiftsChart,
  actGetDashboardWeekdayAllocation
} from 'redux/action/dashboard';
import { actSetSidebarClose } from 'redux/action/layout';
import { actDismissWizard } from 'redux/action/wizard';
import {
  STATISTIC_TYPE,
  EMAIL_STATUS_COLOR,
  NOT_SENT_EMAIL
} from 'utils/constant';
import { DashboardPageWrapper } from './style';
import svg1 from 'assets/svg-1.svg';
import svg2 from 'assets/svg-2.svg';

const DashboardPage = (props) => {
  const {
    dashboardFromTime,
    dashboardToTime,
    tenantId,
    listEmployee,
    statistic,
    emailStatus,
    isWizardSetupComplete,
    dismissWizard
  } = props;
  const { translate } = useTranslation();
  const [FromTime, setFromMonth] = useState(moment(+dashboardFromTime));
  const [ToTime, setToMonth] = useState(moment(+dashboardToTime));

  useEffect(() => {
    let to_date = moment(+ToTime).format('YYYY MM DD');
    let from_date = moment(+FromTime).format('YYYY MM DD');
    let month_diff = moment(to_date).diff(moment(from_date), 'months', true);

    if (moment(+FromTime).isSameOrBefore(moment(+ToTime)) && month_diff <= 12) {
      props.actSetDashboardEmployeeChart({
        tenantId,
        from_month: moment(+FromTime).month() + 1,
        to_month: moment(+ToTime).month() + 1,
        from_year: moment(+FromTime).year(),
        to_year: moment(+ToTime).year()
      });
      props.actSetDashboardShiftsChart({
        tenantId,
        from_month: moment(+FromTime).month() + 1,
        to_month: moment(+ToTime).month() + 1,
        from_year: moment(+FromTime).year(),
        to_year: moment(+ToTime).year()
      });
      props.actGetDashboardPreferences({
        tenantId,
        from_month: moment(+FromTime).month() + 1,
        to_month: moment(+ToTime).month() + 1,
        from_year: moment(+FromTime).year(),
        to_year: moment(+ToTime).year()
      });
      props.actGetDashboardWeekdayAllocation({
        tenantId,
        from_month: moment(+FromTime).month() + 1,
        to_month: moment(+ToTime).month() + 1,
        from_year: moment(+FromTime).year(),
        to_year: moment(+ToTime).year()
      });
      props.actEmailStatusCheck({
        tenantId,
        from_month: moment(+FromTime).month() + 1,
        to_month: moment(+ToTime).month() + 1,
        from_year: moment(+FromTime).year(),
        to_year: moment(+ToTime).year()
      });
    }
  }, [tenantId]);

  useEffect(() => {
    props.actSetSidebarClose(true);
    props.actDismissWizard(false);
    setFromMonth(moment(+dashboardFromTime).format('x'));
    setToMonth(moment(+dashboardToTime).format('x'));
  }, []);

  useEffect(() => {
    const emailStatisStatistic = {};
    emailStatus.forEach((status) => {
      if (!emailStatisStatistic[status.email_status]) {
        emailStatisStatistic[status.email_status] = 1;
      } else {
        emailStatisStatistic[status.email_status] += 1;
      }
    });

    statistic['emailStatusStatistic'] = Object.keys(emailStatisStatistic).map(
      (status) => ({
        status,
        total: emailStatisStatistic[status]
      })
    );
  }, [emailStatus]);

  useEffect(() => {
    props.actGetEmployeeSetting({ tenantId });
    props.actGetEmployeeReasonSetting({ tenantId });
    props.actGetShiftTypeSetting({ tenantId });
  }, [tenantId]);

  const onFromDateChange = (date, dateString) => {
    setFromMonth(date.format('x'));
  };

  const onToDateChange = (date, dateString) => {
    setToMonth(date.format('x'));
  };

  if (!dismissWizard && !isWizardSetupComplete) {
    return <WizardFormModal />;
  }

  const onHandleApiCall = () => {
    let to_date = moment(+ToTime).format('YYYY MM DD');
    let from_date = moment(+FromTime).format('YYYY MM DD');
    let month_diff = moment(to_date).diff(moment(from_date), 'months', true);

    if (parseInt(month_diff) < 12) {
      props.actSetDashboardFromTime(FromTime);
      props.actSetDashboardToTime(ToTime);
      props.actSetDashboardEmployeeChart({
        tenantId,
        from_month: moment(+FromTime).month() + 1,
        to_month: moment(+ToTime).month() + 1,
        from_year: moment(+FromTime).year(),
        to_year: moment(+ToTime).year()
      });
      props.actSetDashboardShiftsChart({
        tenantId,
        from_month: moment(+FromTime).month() + 1,
        to_month: moment(+ToTime).month() + 1,
        from_year: moment(+FromTime).year(),
        to_year: moment(+ToTime).year()
      });
      props.actGetDashboardPreferences({
        tenantId,
        from_month: moment(+FromTime).month() + 1,
        to_month: moment(+ToTime).month() + 1,
        from_year: moment(+FromTime).year(),
        to_year: moment(+ToTime).year()
      });
      props.actGetDashboardWeekdayAllocation({
        tenantId,
        from_month: moment(+FromTime).month() + 1,
        to_month: moment(+ToTime).month() + 1,
        from_year: moment(+FromTime).year(),
        to_year: moment(+ToTime).year()
      });

      props.actEmailStatusCheck({
        tenantId,
        from_month: moment(+FromTime).month() + 1,
        to_month: moment(+ToTime).month() + 1,
        from_year: moment(+FromTime).year(),
        to_year: moment(+ToTime).year()
      });
    } else {
      props.actSetDashboardFromTime(dashboardFromTime);
      props.actSetDashboardToTime(dashboardToTime);
      message.error(translate('dashboard.dateSelectionError'));
    }
  };

  return (
    <DashboardPageWrapper>
      <div className="header-wrapper">
        <div className="filter-wrapper">
          <DatePicker
            size="large"
            picker="month"
            className="input-border"
            style={{ width: '25rem', marginRight: '2rem' }}
            format="MMMM YYYY"
            placeholder={translate('dashboard.selectMonth')}
            onChange={onFromDateChange}
            value={moment(+FromTime)}
            allowClear={false}
          />
        </div>

        <div className="filter-wrapper">
          <DatePicker
            size="large"
            picker="month"
            className="input-border"
            style={{ width: '25rem', marginRight: '2rem' }}
            format="MMMM YYYY"
            placeholder={translate('dashboard.selectMonth')}
            onChange={onToDateChange}
            value={moment(+ToTime)}
            allowClear={false}
          />
        </div>

        <div className="filter-wrapper">
          <Button type="primary" size="large" onClick={onHandleApiCall}>
            Search
          </Button>
        </div>
      </div>

      <div className="main">
        <div className="statistic-wrapper">
          <StatisticItem
            icon={svg1}
            tenantId={tenantId}
            noOfEmployee={listEmployee.length}
            type={STATISTIC_TYPE.NUMBER_OF_EMPLOYEE}
            description={translate('dashboard.employeeCount')}
          />

          <StatisticItem
            icon={svg2}
            tenantId={tenantId}
            type={STATISTIC_TYPE.NUMBER_OF_SHIFT}
            description={translate('dashboard.noOfShift')}
          />

          <div
            style={{
              flexBasis: '32%',
              border: '1px solid let(--color-border-gray)',
              borderRadius: '8px',
              boxShadow: 'let(--box-shadow)'
            }}
          >
            <Table
              dataSource={statistic['emailStatusStatistic']}
              pagination={false}
              // scroll={{ y: 200 }}
              columns={[
                {
                  title: translate('dashboard.status'),
                  dataIndex: 'status',
                  key: 'status',
                  render: (value) => (
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <span
                        style={{
                          width: '12px',
                          height: '12px',
                          borderRadius: '50%',
                          backgroundColor:
                            value !== NOT_SENT_EMAIL
                              ? EMAIL_STATUS_COLOR[value]
                              : 'black',
                          marginRight: '0.8rem'
                        }}
                      />
                      <span>{value}</span>
                    </span>
                  )
                },
                {
                  title: translate('dashboard.total'),
                  dataIndex: 'total',
                  key: 'total'
                }
              ]}
            />
          </div>
        </div>

        <div className="schedule-wrapper">
          <div className="employee-schedule">
            <EmployeeSchedule
              from_date={moment(+FromTime)}
              to_date={moment(+ToTime)}
            />
          </div>

          <div className="weekly-schedule">
            <WeekySchedule />
          </div>
        </div>
      </div>
    </DashboardPageWrapper>
  );
};

export default connect(
  (state) => {
    return {
      dashboardFromTime: state.App.dashboardFromTime,
      dashboardToTime: state.App.dashboardToTime,
      employeeChartData: state.Dashboard.employeeChartData,
      shiftChartData: state.Dashboard.shiftChartData,
      tenantId: state?.App?.user?.tenant?.id,
      dataByColumn: state.Grid.dataByColumn,
      listData: state.Grid.listData,
      listEmployee: state.EmployeeSetting.listData,
      listEmployeeReason: state.EmployeeReason.listData,
      listTimeline: state.Grid.listTimeline,
      statistic: state.Dashboard.statistic,
      listShiftType: state.ShiftTypeSetting.listShiftType,
      emailStatus: state.Grid.emailStatus,
      isWizardSetupComplete: state?.App?.user?.tenant?.wizard_setup_completed,
      dismissWizard: state?.App?.dismissWizard,
      preferences: state.Dashboard.preferences
    };
  },
  {
    actSetDashboardFromTime,
    actSetDashboardToTime,
    actSetDashboardEmployeeChart,
    actSetDashboardShiftsChart,
    actGetAssignmentData,
    actGetRotationSetting,
    actSetDashboardData,
    actGetEmployeeSetting,
    actGetEmployeeReasonSetting,
    actSetSidebarClose,
    actGetShiftTypeSetting,
    actEmailStatusCheck,
    actDismissWizard,
    actGetDashboardPreferences,
    actGetDashboardWeekdayAllocation
  }
)(DashboardPage);
