import styled from 'styled-components';

const RequiredSkillWrapper = styled.div`
  .list-set {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .skill-set-item {
    display: flex;
    flew-wrap: wrap;
  }

  .skill-select {
    margin-right: 2rem;
    margin-left: 1rem;
    position: relative;
    margin-bottom: 1rem;
    width: 18rem;
  }

  .icon-wrapper {
    display: flex;
    align-items: center;
    margin-top: -1rem;
    margin-right: 2rem;

    .trash-icon {
      margin-left: 1rem;
    }
  }
`;

export { RequiredSkillWrapper };
