import styled from 'styled-components';

const SignInPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: relative;
  font-size: 1.6rem;

  .language-switcher {
    position: absolute;
    top: 2rem;
    right: 4rem;
  }

  .img-wrapper {
    margin-right: 15rem;
  }

  .form-wrapper {
    width: 50rem;
    padding: 4rem 2rem;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;

    .heading {
      font-size: 4rem;
      margin-bottom: 4rem;
      font-weight: 600;
    }

    & > .form {
      .register-input {
        border-radius: 8px;
      }

      .forgot-password {
        cursor: pointer;
      }

      .login-button {
        border-radius: 6px;
        background-color: var(--color-secondary);
        font-size: 1.6rem;
        padding: 0 5rem;
        margin-top: 3rem;
        margin-bottom: 3rem;
      }

      .sign-up-text {
        color: var(--color-primary);
        font-weight: 300;
        margin-left: 1.5rem;
        cursor: pointer;
      }
    }
  }
`;

export { SignInPageWrapper };
