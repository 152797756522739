import styled from 'styled-components';

const RotationSettingPageWrapper = styled.div`
  font-size: 1.6rem;

  .header-wrapper {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid var(--color-border-gray);

    .label {
      color: var(--color-text);
      text-transform: uppercase;
      font-size: 1.3rem;
      font-weight: 500;
    }

    .filter-wrapper {
      display: flex;
      justify-content: space-between;

      .filter-date {
        margin-right: 3rem;
      }

      .input-border {
        border-radius: 4px;
      }
    }
  }

  .list-tab {
    margin-bottom: 1.5rem;

    .tab-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .select-date {
      display: flex;
      align-items: center;

      .list-date {
        display: flex;
        align-items: center;
      }
    }
  }
`;

const DisabledDateItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  &:not(:last-of-type) {
    margin-bottom: 1.5rem;
  }
`;

export { RotationSettingPageWrapper, DisabledDateItemWrapper };
