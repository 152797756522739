import { takeLatest, all } from 'redux-saga/effects';
import {
  UPDATE_TENANT_INFO,
  GET_TENANT_INFO,
  SAVE_GET_TENANT_INFO,
  SAVE_GET_FIRST_DRAFT_DATE,
  GET_FIRST_DRAFT_DATE
} from '../type';
import tenantAPI from 'redux/api/tenant';
import { createRequestSaga } from './common';

const updateTenantInfo = createRequestSaga({
  request: tenantAPI.updateTenantInfo,
  key: 'updateTenantInfo',
  success: [
    (res) => ({
      type: UPDATE_TENANT_INFO,
      payload: res
    })
  ],
  failure: []
});

const getTenantInfo = createRequestSaga({
  request: tenantAPI.getTenantInfo,
  key: 'getTenantInfo',
  success: [
    (res) => ({
      type: SAVE_GET_TENANT_INFO,
      payload: res
    })
  ],
  failure: []
});

const getFirstDraftDate = createRequestSaga({
  request: tenantAPI.getFirstDraftDate,
  key: 'getFirstDraftDate',
  success: [
    (res) => ({
      type: SAVE_GET_FIRST_DRAFT_DATE,
      payload: res
    })
  ],
  failure: []
});

const saga = [
  function* fetchWatcher() {
    yield all([
      takeLatest(UPDATE_TENANT_INFO, updateTenantInfo),
      takeLatest(GET_TENANT_INFO, getTenantInfo),
      takeLatest(GET_FIRST_DRAFT_DATE, getFirstDraftDate)
    ]);
  }
];

export default saga;
