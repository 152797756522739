import { useEffect, useRef } from 'react';
import { Form, Input, Select, Tag, Switch } from 'antd';
import { useTranslation } from 'component/useTranslation';
import _ from 'lodash';
import { actSetSelectedEmployeeSetting } from 'redux/action/employeeSetting';
import { connect } from 'react-redux';

const { Option } = Select;

const SkillTag = (props) => {
  const { label, closable, onClose } = props;

  return (
    <Tag
      color="purple"
      closable={closable}
      onClose={onClose}
      style={{ marginTop: '0.5rem' }}
    >
      {label}
    </Tag>
  );
};

const EmployeeForm = ({
  initialProps,
  setActiveChecked,
  selectedEmployeeSetting,
  ...props
}) => {
  const { modalVisible, activeChecked, listContract, listSkill } = initialProps;
  const { translate } = useTranslation();
  const firstInput = useRef(null);

  useEffect(() => {
    if (modalVisible) {
      firstInput?.current?.focus();
    }
  }, [modalVisible]);

  const onChangeName = (event) => {
    props.actSetSelectedEmployeeSetting({
      ...selectedEmployeeSetting,
      name: event?.target?.value
    });
  };

  return (
    <>
      <Form.Item
        label={translate('employeeSetting.name')}
        rules={[
          {
            required: true,
            message: `${translate('employeeSetting.name')} ${translate(
              'isRequired'
            )}`
          }
        ]}
        name="name"
      >
        <Input ref={firstInput} onChange={onChangeName} />
      </Form.Item>

      <Form.Item
        label={translate('employeeSetting.contract')}
        rules={[
          {
            required: true,
            message: `${translate('employeeSetting.contract')} ${translate(
              'isRequired'
            )}`
          }
        ]}
        name="contractName"
      >
        <Select>
          {_.map(listContract, (contract, index) => (
            <Option key={index} value={contract.name}>
              {contract.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label={translate('employeeSetting.team')} name="team">
        <Input />
      </Form.Item>

      <Form.Item label={translate('employeeSetting.rank')} name="rank">
        <Input />
      </Form.Item>

      <Form.Item label={translate('employeeSetting.skills')} name="skills">
        <Select mode="tags" tagRender={SkillTag}>
          {_.map(listSkill, (skill, index) => (
            <Option key={index} value={skill.id}>
              {skill.name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label={translate('employeeSetting.email')}
        name="email"
        rules={[
          {
            type: 'email',
            message: translate('employeeSetting.emailValid')
          },
          {
            required: true,
            message: `${translate('employeeSetting.email')} ${translate(
              'isRequired'
            )}`
          }
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item label={translate('employeeSetting.mobile')} name="mobile">
        <Input />
      </Form.Item>

      <div>
        <label style={{ marginRight: '10px' }}>
          {translate('employeeSetting.isActive')}
        </label>

        <Switch
          checked={activeChecked}
          onChange={() => setActiveChecked(!activeChecked)}
        />
      </div>
    </>
  );
};

export default connect(
  (state) => ({
    selectedEmployeeSetting: state.EmployeeSetting.selectedEmployeeSetting
  }),
  {
    actSetSelectedEmployeeSetting
  }
)(EmployeeForm);
