import { PURGE } from 'redux-persist';
import moment from 'moment';
import {
  SAVE_SIGN_IN,
  LOG_OUT,
  SET_GLOBAL_TIME,
  SAVE_GET_TENANT_INFO,
  SAVE_GET_FIRST_DRAFT_DATE,
  SAVE_PREFERECE_TENANT,
  SAVE_DEFAULT_PREFERENCE,
  SET_GRID_FULLSCREEN,
  CHANGE_WIZARD_STATUS,
  DISMISS_WIZARD,
  SET_SIDEBAR_CLOSE,
  SAVE_GET_SOLVE_ROTATION_STATUS,
  SET_DASHBOARD_FROM_TIME,
  SET_DASHBOARD_TO_TIME,
  SET_ACCESS_TOKEN
} from 'redux/type';
import { get } from 'lodash';

const initialState = {
  token: null,
  accessToken: null,
  refreshToken: null,
  globalTime: moment().format('x'),
  dashboardFromTime: moment().format('x'),
  dashboardToTime: moment().format('x'),
  tenant: {},
  user: {},
  isGridFullScreen: false,
  dismissWizard: false,
  isSidebarClose: true,
  solvingStatus: ''
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case SAVE_SIGN_IN:
      return {
        ...state,
        token: payload.access,
        refreshToken: payload.refresh,
        user: payload.user
      };

    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: payload
      };

    case LOG_OUT:
      return initialState;

    case SET_GLOBAL_TIME:
      return {
        ...state,
        globalTime: payload
      };

    case SET_DASHBOARD_FROM_TIME:
      return {
        ...state,
        dashboardFromTime: payload
      };

    case SET_DASHBOARD_TO_TIME:
      return {
        ...state,
        dashboardToTime: payload
      };

    case SAVE_GET_TENANT_INFO:
      return {
        ...state,
        tenant: payload
      };

    case SAVE_GET_FIRST_DRAFT_DATE:
      return {
        ...state,
        first_draft_date: get(payload, '0.first_draft_date')
      };

    case SAVE_PREFERECE_TENANT:
      return {
        ...state,
        user: { ...state.user, tenant: payload }
      };

    case SAVE_DEFAULT_PREFERENCE:
      return {
        ...state,
        user: {
          ...state.user,
          tenant: { ...state.user.tenant, default_preference: payload }
        }
      };

    case SET_GRID_FULLSCREEN:
      return {
        ...state,
        isGridFullScreen: payload
      };

    case CHANGE_WIZARD_STATUS:
      return {
        ...state,
        user: {
          ...state.user,
          tenant: {
            ...state.user.tenant,
            wizard_setup_completed: payload
          }
        }
      };

    case DISMISS_WIZARD:
      return {
        ...state,
        dismissWizard: payload
      };

    case SET_SIDEBAR_CLOSE:
      return {
        ...state,
        isSidebarClose: payload
      };

    case SAVE_GET_SOLVE_ROTATION_STATUS:
      if (payload !== state?.solvingStatus) {
        return {
          ...state,
          solvingStatus: payload
        };
      } else {
        return state;
      }

    default:
      return state;
  }
};

export default reducer;
