import moment from 'moment';
import { message } from 'antd';
import { takeLatest, all, takeEvery } from 'redux-saga/effects';
import {
  CREATE_SHIFT,
  SAVE_CREATE_SHIFT,
  UPDATE_SHIFT,
  SAVE_UPDATE_SHIFT,
  UPDATE_AVAILABILITY,
  SAVE_UPDATE_AVAILABILITY,
  DELETE_SHIFT,
  CREATE_AVAILABILITY,
  SAVE_CREATE_AVAILABILITY,
  GET_USER_AVAILABILITY,
  SAVE_GET_USER_AVAILABILITY,
  GET_EMAIL_STATUS_CHECK,
  SAVE_EMAIL_STATUS_CHECK,
  GET_SKILL_VIEW,
  SAVE_GET_SKILL_VIEW,
  GET_ASSIGNMENT_DATA,
  SAVE_GET_ASSIGNMENT_DATA,
  DELETE_SHIFT_IN_SKILL_VIEW,
  SET_DASHBOARD_EMPLOYEE_CHART,
  SET_DASHBOARD_SHIFTS_CHART,
  GET_DASHBOARD_WEEKDAY_ALLOCTION
} from 'redux/type';
import gridAPI from 'redux/api/grid';
import { createRequestSaga } from './common';
import { store } from '../store';

// SkillView
const getSkillView = createRequestSaga({
  request: gridAPI.getSkillView,
  key: 'getSkillView',
  success: [
    (res) => ({
      type: SAVE_GET_SKILL_VIEW,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: [(err) => message.error('Error occured when get skill view')]
});

const deleteShiftInSkillView = createRequestSaga({
  request: gridAPI.deleteShift,
  key: 'deleteShiftInSkillView',
  success: [],
  functionSuccess: [],
  functionFailure: [
    (err) => message.error('Error occured when delete shift in skill view')
  ]
});

// shift
const createShift = createRequestSaga({
  request: gridAPI.createShift,
  key: 'createShift',
  success: [
    (res) => ({
      type: SAVE_CREATE_SHIFT,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: []
});

const updateShift = createRequestSaga({
  request: gridAPI.updateShift,
  key: 'updateShift',
  success: [
    (res) => ({
      type: SAVE_UPDATE_SHIFT,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: []
});

const deleteShift = createRequestSaga({
  request: gridAPI.deleteShift,
  key: 'deleteShift',
  functionSuccess: [() => message.success('Delete shift successful')],
  functionFailure: [(err) => message.error('Error occured when delete shift')]
});

const getAssignmentData = createRequestSaga({
  request: gridAPI.getAssignmentData,
  key: 'getAssignmentData',
  success: [
    (res, args) => {
      const { RotationSetting } = store.getState();
      const { listRotation } = RotationSetting;

      const reqPayload = args?.args[0];
      const gTime = moment([reqPayload.year, reqPayload.month - 1, 1]);

      return {
        type: SAVE_GET_ASSIGNMENT_DATA,
        payload: { data: res, listRotation, globalTime: gTime.format('x') }
      };
    }
  ],
  functionSuccess: [],
  functionFailure: [
    (err) => message.error('Error occured when get assignment data')
  ]
});

// availability
const createAvailability = createRequestSaga({
  request: gridAPI.createAvailability,
  key: 'createAvailability',
  success: [
    (res) => ({
      type: SAVE_CREATE_AVAILABILITY,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: []
});

const updateAvailability = createRequestSaga({
  request: gridAPI.updateAvailability,
  key: 'updateAvailability',
  success: [
    (res) => ({
      type: SAVE_UPDATE_AVAILABILITY,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: []
});

const getUserAvailability = createRequestSaga({
  request: gridAPI.getUserAvailability,
  key: 'getUserAvailability',
  success: [
    (res, args) => {
      const reqPayload = args?.args[0];
      const gTime = moment([reqPayload.year, reqPayload.month - 1, 1]);

      return {
        type: SAVE_GET_USER_AVAILABILITY,
        payload: { listData: res, globalTime: gTime.format('x') }
      };
    }
  ],
  functionSuccess: [],
  functionFailure: []
});

const getEmailStatusCheck = createRequestSaga({
  request: gridAPI.getEmailStatusCheck,
  key: 'getEmailStatusCheck',
  success: [
    (res) => {
      return {
        type: SAVE_EMAIL_STATUS_CHECK,
        payload: res
      };
    }
  ],
  functionSuccess: [],
  functionFailure: []
});

const getEmployeeChartData = createRequestSaga({
  request: gridAPI.getEmployeeChartData,
  key: 'getEmployeeChartData',
  success: [
    (res) => ({
      type: SET_DASHBOARD_EMPLOYEE_CHART,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: []
});

const getShiftsChartData = createRequestSaga({
  request: gridAPI.getShiftsChartData,
  key: 'getShiftsChartData',
  success: [
    (res) => ({
      type: SET_DASHBOARD_SHIFTS_CHART,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: []
});

const getWeekdayAllocation = createRequestSaga({
  request: gridAPI.getWeekdayAllocation,
  key: 'getWeekdayAllocation',
  success: [
    (res) => ({
      type: GET_DASHBOARD_WEEKDAY_ALLOCTION,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: []
});

const saga = [
  function* fetchWatcher() {
    yield all([
      takeLatest(CREATE_SHIFT, createShift),
      takeLatest(UPDATE_SHIFT, updateShift),
      takeLatest(UPDATE_AVAILABILITY, updateAvailability),
      takeLatest(DELETE_SHIFT, deleteShift),
      takeLatest(CREATE_AVAILABILITY, createAvailability),
      takeLatest(GET_USER_AVAILABILITY, getUserAvailability),
      takeLatest(GET_EMAIL_STATUS_CHECK, getEmailStatusCheck),
      takeLatest(GET_SKILL_VIEW, getSkillView),
      takeLatest(GET_ASSIGNMENT_DATA, getAssignmentData),
      takeLatest(DELETE_SHIFT_IN_SKILL_VIEW, deleteShiftInSkillView),
      takeEvery(SET_DASHBOARD_EMPLOYEE_CHART, getEmployeeChartData),
      takeEvery(SET_DASHBOARD_SHIFTS_CHART, getShiftsChartData),
      takeEvery(GET_DASHBOARD_WEEKDAY_ALLOCTION, getWeekdayAllocation)
    ]);
  }
];

export default saga;
