import { connect } from 'react-redux';
import { useEffect } from 'react';
import _ from 'lodash';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'component/index';
import { CHART_PALETTE_COLORS, MONTH_STRING } from 'utils/constant';
import { actGetShiftTypeSetting } from 'redux/action/shiftTypeSetting';
import { CHART_MODE } from 'screen/TimeReport';
import { RevenueChartWrapper } from './style';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const RevenueChart = (props) => {
  const { revenues, tenantId, listShiftType, chartMode } = props;
  const { translate } = useTranslation();

  useEffect(() => {
    props.actGetShiftTypeSetting({ tenantId });
  }, [tenantId]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true
      },
      title: {
        display: false
      }
    },
    scales: {
      x: {
        stacked: chartMode === CHART_MODE.STACKED
      },
      y: {
        stacked: chartMode === CHART_MODE.STACKED,
        ticks: {
          beginAtZero: true,
          callback: function (value) {
            return `${value}¥`;
          }
        }
      }
    }
  };

  const labels = revenues?.map((item) =>
    translate('dashboardEmployeeSchedule.' + MONTH_STRING[item?.month - 1])
  );

  const listKey = [];
  revenues?.forEach((item) => {
    Object.keys(item)?.map((key) => {
      if (!listKey?.includes(key) && key !== 'month') {
        listKey.push(key);
      }
    });
  });

  const data = {
    labels,
    datasets: listKey?.map((key, index) => {
      return {
        label: _.find(listShiftType, { id: Number(key) })?.name || '',
        data: revenues?.map((item) => Number(item[key])),
        backgroundColor:
          CHART_PALETTE_COLORS[index % CHART_PALETTE_COLORS.length]
      };
    })
  };

  return (
    <RevenueChartWrapper>
      <div className="title">{translate('timeReport.revenues')}</div>
      <Bar options={options} data={data} />
    </RevenueChartWrapper>
  );
};

export default connect(
  (state) => ({
    revenues: state?.TimeReport?.statistic?.revenues || [],
    tenantId: state?.App?.user?.tenant?.id,
    listShiftType: state.ShiftTypeSetting.listShiftType
  }),
  { actGetShiftTypeSetting }
)(RevenueChart);
