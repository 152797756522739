import { useState, useEffect } from 'react';
import _ from 'lodash';
import { Form, Spin, message, Radio, Divider, InputNumber, Button } from 'antd';
import { connect } from 'react-redux';
import { actUpdateDefaultPreference } from 'redux/action/profile';
import { actGetTenantInfo, actUpdateTenantInfo } from 'redux/action/tenant';
import { useTranslation } from 'component/index';

const FormItem = Form.Item;

const SystemSetting = (props) => {
  const { default_preference, tenantId, preferences, user } = props;

  const [loading, setLoading] = useState(false);
  const { translate } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    props.actGetTenantInfo({ tenantId });
  }, [tenantId]);

  useEffect(() => {
    form.setFieldsValue({
      linkExpiration: preferences?.linkExpiration
    });
  }, [preferences]);

  const setPreference = (event) => {
    const body = {
      default_preference: event.target.value
    };
    setLoading(true);

    props.actUpdateDefaultPreference(
      {
        tenantId,
        body
      },
      (err, res) => {
        setLoading(false);
        if (res) {
          message.success('Default preference changes succesfully');
        }

        if (err) {
          message.error('Error occured while changing preference');
        }
      }
    );
  };

  const onSubmitForm = async () => {
    const { linkExpiration } = await form.validateFields(['linkExpiration']);

    props.actUpdateTenantInfo(
      {
        tenantId: tenantId,
        userId: user.id,
        body: {
          preferences: {
            ...preferences,
            linkExpiration
          }
        }
      },
      (err) => {
        if (err) {
          message.error(err.message);
        } else {
          message.success('Update system settings succesfully');
        }
      }
    );
  };

  return (
    <Form layout="vertical" form={form} style={{ width: '40rem' }}>
      <Spin spinning={loading}>
        <FormItem label={translate('profile.defaultPreference')}>
          <Radio.Group onChange={setPreference} value={default_preference}>
            <Radio value={true}>{translate('profile.available')}</Radio>
            <Radio value={false}>{translate('profile.unavailable')}</Radio>
          </Radio.Group>
        </FormItem>

        <Divider />

        <FormItem
          label={translate('profile.linkExpiration')}
          name="linkExpiration"
        >
          <InputNumber placeholder="5" min={1} style={{ width: '75%' }} />
        </FormItem>

        <Divider />

        <Button
          type="primary"
          className="custom-button"
          size="large"
          onClick={onSubmitForm}
        >
          {translate('update')}
        </Button>
      </Spin>
    </Form>
  );
};

export default connect(
  (state) => ({
    default_preference: state?.App?.user?.tenant?.default_preference,
    tenantId: state?.App?.user?.tenant?.id,
    preferences: state?.App?.tenant?.preferences,
    user: state?.App?.user
  }),
  {
    actGetTenantInfo,
    actUpdateDefaultPreference,
    actUpdateTenantInfo
  }
)(SystemSetting);
