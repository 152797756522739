import API from './common';

const api = {
  getListRotationTab: ({ tenantId }) =>
    API('get', `${tenantId}/rotation/tabs/`),
  getOneRotationTab: ({ tenantId, tabId }) =>
    API('get', `${tenantId}/rotation/tabs/${tabId}/`),
  createRotationTab: ({ tenantId, body }) =>
    API('post', `${tenantId}/rotation/tabs/`, body),
  updateRotationTab: ({ tenantId, body, tabId }) =>
    API('put', `${tenantId}/rotation/tabs/${tabId}/`, body),
  deleteRotationTab: ({ tenantId, tabId }) =>
    API('delete', `${tenantId}/rotation/tabs/${tabId}/`)
};

export default api;
