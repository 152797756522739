import React, { useEffect, useState } from 'react';
import { Table, Popconfirm, Button, Input, message } from 'antd';
import { connect } from 'react-redux';
import { FaRegEdit } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import {
  actGetEmployeeReasonSetting,
  actUpdateEmployeeReasonInfo,
  actDeleteEmployeeReasonInfo,
  actSaveDeleteEmployeeReasonInfo,
  actCreateEmployeeReason,
  actSearchEmployeeReason,
  actSetModalEmployeeReasonVisible,
  actSetSelectedEmployeeReason
} from 'redux/action/employeeReason';
import { useTranslation } from 'component/index';
import { convertTableFilter, convertTableSorter } from 'utils/helper';
import { TableWrapper, TableActionWrapper } from 'component/style';
import EmployeeReasonModal from './EmployeeReasonModal';

const columnConfig = ({
  onDeleteEmployeeReasonInfo,
  getColumnSearchProps,
  onEditEmployeeReason,
  translate
}) => [
  {
    title: translate('employeeReason.name'),
    dataIndex: 'name',
    key: 'name',
    width: '50%',
    sorter: true,
    ...getColumnSearchProps('name')
  },
  {
    title: translate('employeeReason.state'),
    dataIndex: 'state',
    key: 'state',
    width: '20%',
    render: (value, record) => {
      let stateValue;
      switch (value) {
        case '0':
          stateValue = 'Unavailable';
          break;
        case '1':
          stateValue = 'Undesired';
          break;
        case '2':
          stateValue = 'Desired';
          break;
        case '3':
          stateValue = 'Countable';
          break;
        default:
          stateValue = 'Unselected';
          break;
      }

      return stateValue;
    }
  },
  {
    title: translate('employeeReason.icon'),
    dataIndex: 'icon',
    key: 'icon',
    width: '10%',
    render: (value, record) => (
      <img src={value} style={{ width: '1.5rem', height: '1.5rem' }} alt="" />
    )
  },
  {
    title: translate('table.actions'),
    dataIndex: 'actions',
    key: 'actions',
    width: '15%',
    render: (value, record, index) => (
      <TableActionWrapper>
        <FaRegEdit
          className="edit-icon"
          onClick={() => onEditEmployeeReason(record)}
        />

        {!record.default && (
          <Popconfirm
            onConfirm={() => onDeleteEmployeeReasonInfo(record)}
            title={translate('message.confirmToDelete')}
            okText={translate('yes')}
            cancelText={translate('no')}
          >
            <RiDeleteBin6Line className="delete-icon" />
          </Popconfirm>
        )}
      </TableActionWrapper>
    )
  }
];

const EmployeeReasonTable = (props) => {
  const { tenantId, listEmployeeReason } = props;

  let searchInput = null;
  const [isLoading, setLoading] = useState(false);
  const [searchText, onSetSearchText] = useState([]);
  const { translate } = useTranslation();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10
  });

  useEffect(() => {
    setLoading(true);
    props.actGetEmployeeReasonSetting({ tenantId }, (err, res) => {
      setLoading(false);
    });
  }, [tenantId]);

  const getColumnSearchProps = () => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={translate('typeAkeyWord')}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {translate('search')}
        </Button>

        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          {translate('reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => value.toString(),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text) => {
      return (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={searchText}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      );
    }
  });

  const handleSearch = (selectedKeys, confirm) => {
    onSetSearchText(selectedKeys);
    confirm();
  };

  const handleReset = (clearFilters) => {
    onSetSearchText([]);
    clearFilters();
  };

  const onEditEmployeeReason = (record) => {
    props.actSetModalEmployeeReasonVisible(true);
    props.actSetSelectedEmployeeReason(record);
  };

  const onShowTotalData = () => {
    return `${listEmployeeReason.length} ${translate('table.rowInTotal')}`;
  };

  const onDeleteEmployeeReasonInfo = (employeereason) => {
    props.actDeleteEmployeeReasonInfo(
      { tenantId, employeereasonId: employeereason.id },
      (err) => {
        if (!err) {
          props.actSaveDeleteEmployeeReasonInfo({ id: employeereason.id });
          message.success(translate('employeeReason.deleteSuccess'));
        }
        if (err) {
          message.error(err.message);
        }
      }
    );
  };

  const onTableChange = (pagination, filter, sorter) => {
    setPagination(pagination);
    const tableFilter = convertTableFilter(filter);
    const tableSorter = convertTableSorter(sorter);

    props.actSearchEmployeeReason({ filter: tableFilter, sorter: tableSorter });
  };

  const columns = columnConfig({
    onDeleteEmployeeReasonInfo,
    getColumnSearchProps,
    onEditEmployeeReason,
    translate
  });

  return (
    <TableWrapper>
      <Table
        columns={columns}
        rowKey={(data) => data.id}
        dataSource={listEmployeeReason}
        pagination={{
          ...pagination,
          showTotal: onShowTotalData,
          pageSizeOptions: [10, 20, 30, 40],
          showSizeChanger: true
        }}
        size="large"
        rowClassName="editable-row"
        loading={isLoading}
        onChange={onTableChange}
      />
      <EmployeeReasonModal />
    </TableWrapper>
  );
};

export default connect(
  (state) => ({
    tenantId: state?.App?.user?.tenant?.id,
    listEmployeeReason: state.EmployeeReason.listEmployeeReason
  }),
  {
    actGetEmployeeReasonSetting,
    actUpdateEmployeeReasonInfo,
    actDeleteEmployeeReasonInfo,
    actSaveDeleteEmployeeReasonInfo,
    actCreateEmployeeReason,
    actSearchEmployeeReason,
    actSetModalEmployeeReasonVisible,
    actSetSelectedEmployeeReason
  }
)(EmployeeReasonTable);
