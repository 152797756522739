import styled from 'styled-components';

const TestimonialSectionWrapper = styled.div`
  margin-top: 10rem;

  .main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .slider {
      height: 100%;
      width: 75%;

      .slick-track {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .slick-slide {
        height: 100%;

        & > div {
          height: 100%;
        }
      }
    }
  }
`;

const SlideItemWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  .avatar {
    margin-right: 2rem;
  }

  .content {
    font-style: italic;
    font-size: 1.8rem;
    padding: 0 1rem;
  }

  .avatar {
    margin-left: 3rem;
    margin-top: 2rem;
    width: 30rem;
  }

  .slide-footer {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    margin-left: 4rem;

    img {
      width: 10rem;
      height: 10rem;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
      border: 1px solid var(--color-border-gray);
      margin-right: 2rem;
    }

    .detail {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .name {
        text-transform: uppercase;
        font-weight: 600;
        font-style: italic;
        font-size: 1.6rem;
      }

      .position {
        font-size: 1.5rem;
      }
    }
  }
`;

export { TestimonialSectionWrapper, SlideItemWrapper };
