import { Fragment } from 'react';
import { Tag } from 'antd';

const SkillList = ({ skills }) =>
  skills.map((skill, index) => (
    <Fragment key={index}>
      <Tag color="green" key={index} style={{ marginRight: '0.8rem' }}>
        {skill.name}
      </Tag>

      {index < skills.length - 1 ? (
        <span style={{ marginRight: '0.8rem' }}>&</span>
      ) : null}
    </Fragment>
  ));

export default SkillList;
