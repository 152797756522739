import { PURGE } from 'redux-persist';
import { EMAIL_STATUS } from 'utils/constant';
import {
  SET_SELECTED_EMPLOYEE_EMAIL,
  SET_DATE_SEND_EMAIL,
  SET_SEND_EMAIL_STATUS
} from 'redux/type';

const initialState = {
  listSelectedEmployee: [],
  selectedDate: { month: null, year: null },
  sendEmailStatus: EMAIL_STATUS.NOT_SENT
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case SET_SELECTED_EMPLOYEE_EMAIL:
      return {
        ...state,
        listSelectedEmployee: payload
      };

    case SET_DATE_SEND_EMAIL:
      return {
        ...state,
        selectedDate: {
          month: payload.month,
          year: payload.year
        }
      };

    case SET_SEND_EMAIL_STATUS:
      return {
        ...state,
        sendEmailStatus: payload
      };

    default:
      return state;
  }
};

export default reducer;
