import {
  SAVE_LIST_DATA,
  SAVE_LIST_ASSIGNMENT_COLUMN,
  SET_STATISTIC_ROW,
  SAVE_DATA_BY_COLUMN,
  CREATE_SHIFT,
  UPDATE_SHIFT,
  UPDATE_AVAILABILITY,
  DELETE_SHIFT,
  CREATE_AVAILABILITY,
  SET_COLLAPSE_STATISTIC,
  GET_USER_AVAILABILITY,
  RESET_GRID_DATA,
  SET_GLOBAL_TIME,
  SET_GRID_FULLSCREEN,
  GET_EMAIL_STATUS_CHECK,
  SET_AVAIL_EDITABLE,
  SET_GRID_VIEW_MODE,
  SET_SELECTED_SHIFT_TYPE,
  GET_ASSIGNMENT_DATA,
  SET_REFRESH_ASSIGNMENT,
  SET_DASHBOARD_TO_TIME,
  SET_DASHBOARD_FROM_TIME
} from 'redux/type';

// grid data
export const actSetCollapseStatisitc = (payload) => ({
  type: SET_COLLAPSE_STATISTIC,
  payload
});

export const actSaveListData = (payload) => {
  return {
    type: SAVE_LIST_DATA,
    payload
  };
};

export const actSaveListAssignmentColumn = (payload) => {
  return {
    type: SAVE_LIST_ASSIGNMENT_COLUMN,
    payload
  };
};

export const actSetStatisticRow = (payload) => {
  return {
    type: SET_STATISTIC_ROW,
    payload
  };
};

export const actSaveDataByColumn = (payload) => {
  return {
    type: SAVE_DATA_BY_COLUMN,
    payload
  };
};

// shift
export const actCreateShift = (...args) => ({
  type: CREATE_SHIFT,
  args
});

export const actUpdateShift = (...args) => ({
  type: UPDATE_SHIFT,
  args
});

export const actDeleteShift = (...args) => ({
  type: DELETE_SHIFT,
  args
});

// avail
export const actCreateAvailability = (...args) => ({
  type: CREATE_AVAILABILITY,
  args
});

export const actUpdateAvailability = (...args) => ({
  type: UPDATE_AVAILABILITY,
  args
});

export const actGetUserAvailability = (...args) => ({
  type: GET_USER_AVAILABILITY,
  args
});

export const actResetGridData = () => ({
  type: RESET_GRID_DATA
});

export const actSetGlobalTime = (payload) => {
  return {
    type: SET_GLOBAL_TIME,
    payload
  };
};

export const actSetDashboardFromTime = (payload) => {
  return {
    type: SET_DASHBOARD_FROM_TIME,
    payload
  };
};

export const actSetDashboardToTime = (payload) => {
  return {
    type: SET_DASHBOARD_TO_TIME,
    payload
  };
};

export const actSetGridFullScreen = (payload) => ({
  type: SET_GRID_FULLSCREEN,
  payload
});

export const actEmailStatusCheck = (...args) => ({
  type: GET_EMAIL_STATUS_CHECK,
  args
});

export const actSetAvailEditable = (payload) => {
  return {
    type: SET_AVAIL_EDITABLE,
    payload
  };
};

export const actSetGridViewMode = (payload) => {
  return {
    type: SET_GRID_VIEW_MODE,
    payload
  };
};

export const actSetSelectedShiftType = (payload) => {
  return {
    type: SET_SELECTED_SHIFT_TYPE,
    payload
  };
};

export const actGetAssignmentData = (...args) => ({
  type: GET_ASSIGNMENT_DATA,
  args
});

export const actSetRefreshAssignment = (payload) => ({
  type: SET_REFRESH_ASSIGNMENT,
  payload
});
