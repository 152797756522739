import { connect } from 'react-redux';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { MONTH_STRING } from 'utils/constant';
import { useTranslation } from 'component/index';
import { OvertimeChartWrapper } from './style';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const OvertimeChart = (props) => {
  const { overtime } = props;
  const { translate } = useTranslation();

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      }
    },
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          callback: function (value) {
            return `${value} ${translate('timeReport.hours')}`;
          }
        }
      }
    }
  };

  const labels = overtime?.map((item) =>
    translate('dashboardEmployeeSchedule.' + MONTH_STRING[item?.month - 1])
  );

  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: overtime?.map((item) => item?.hours),
        backgroundColor: 'rgb(94, 145, 214)'
      }
    ]
  };

  return (
    <OvertimeChartWrapper>
      <div className="title">{translate('timeReport.overtime')}</div>
      <Bar options={options} data={data} />
    </OvertimeChartWrapper>
  );
};

export default connect(
  (state) => ({
    overtime: state?.TimeReport?.statistic?.overtime || []
  }),
  {}
)(OvertimeChart);
