import styled from 'styled-components';

const FooterWrapper = styled.div`
  .main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .company-logo {
      .logo {
        display: flex;
        flex-direction: column;

        .company-name {
          width: 6rem;
          height: 3rem;
        }
      }
    }

    .list-partner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 6rem 0;

      a {
        img {
          width: 100%;
          height: 7rem;
        }
      }
    }

    .copyright {
      text-align: center;
      border-top: 1px solid var(--color-border-gray);
      padding: 1.5rem 0;
    }
  }
`;

export { FooterWrapper };
