import _ from 'lodash';
import { PURGE } from 'redux-persist';

import {
  GET_SHIFT_GROUP,
  SAVE_DELETE_SHIFT_GROUP,
  SAVE_CREATE_SHIFT_GROUP,
  SAVE_UPDATE_SHIFT_GROUP,
  SET_SELECTED_SHIFT_GROUP_SETTING,
  SET_MODAL_SHIFT_GROUP_SETTING_VISIBLE
} from '../type';
import { update } from './helper';

const initialState = {
  listShiftGroup: [],
  totalData: 0,
  modalVisible: false,
  selectedShiftGroupSetting: {}
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case GET_SHIFT_GROUP:
      return {
        ...state,
        listShiftGroup: payload || []
      };

    case SAVE_DELETE_SHIFT_GROUP:
      return update(state, {
        listShiftGroup: { $updateOrDelete: [payload.id] }
      });

    case SAVE_UPDATE_SHIFT_GROUP: {
      return update(state, {
        listShiftGroup: {
          $updateOrDelete: [payload.id, payload]
        }
      });
    }

    case SAVE_CREATE_SHIFT_GROUP:
      return {
        ...state,
        listShiftGroup: [payload, ...state.listShiftGroup]
      };

    case SET_SELECTED_SHIFT_GROUP_SETTING:
      return {
        ...state,
        selectedShiftGroupSetting: payload
      };

    case SET_MODAL_SHIFT_GROUP_SETTING_VISIBLE:
      return {
        ...state,
        modalVisible: payload
      };

    default:
      return state;
  }
};

export default reducer;
