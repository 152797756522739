import { useContext, useState, useEffect } from 'react';
import { Spin, Radio } from 'antd';
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { Document, Page, pdfjs } from 'react-pdf';
import { LOCALE } from 'languages/index';
import { DocumentSettingWrapper, ZoomWrapper } from './style';
import { LanguageContext } from 'languages/index';
import { actGetTenantInfo } from 'redux/action/tenant';
import { actGetPdfDocumentation } from 'redux/action/profile';
import { connect } from 'react-redux';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// const enDoc =
//   // 'https://aquilastatic.blob.core.windows.net/static/PRO_CustomerProductInformation_en_v1.1.0.pdf';
//   'https://aquilastatic.blob.core.windows.net/static/PRO_CustomerProductInformation_en_v1.1.0.pdf?sp=r&st=2022-02-04T08:00:42Z&se=2022-02-11T16:00:42Z&sip=103.214.60.149&sv=2020-08-04&sr=b&sig=%2FgzfydgjPHrwoSl%2Bk6MUq70Cw8N2XPBpdW6LNcYhPm8%3D';
// const jpDoc =
//   'https://aquilastatic.blob.core.windows.net/static/PRO_CustomerProductInformation_en_v1.1.0.pdf?sp=r&st=2022-02-04T08:00:42Z&se=2022-02-11T16:00:42Z&sip=127.0.0.1&sv=2020-08-04&sr=b&sig=dyVoPQbhtseTi3EOTV63zP%2B%2F6qMi2NtDTWpEss%2FZQqQ%3D';
// 'https://aquilastatic.blob.core.windows.net/static/PRO_CustomerProductInformation_jp_v1.1.0.pdf';

const zoomStep = 0.1;
const minScale = zoomStep * 3;
const maxScale = 3;

const DocumentSetting = (props) => {
  const { tenantId, profiledocs_en, profiledocs_jp } = props;
  const { locale } = useContext(LanguageContext);
  const [numPages, setNumPages] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedLanguage, setSelectedlanguage] = useState(locale);
  const [scale, setScale] = useState(1.5);

  useEffect(() => {
    setSelectedlanguage(locale);
    props.actGetTenantInfo({ tenantId });
    props.actGetPdfDocumentation({ tenantId });
  }, [locale, tenantId]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setIsLoading(false);
  };

  const onDocumentLoadError = () => {
    setIsLoading(false);
  };

  const onZoomIn = () => {
    if (scale <= maxScale) {
      setScale(scale + zoomStep);
    }
  };

  const onZoomOut = () => {
    if (scale >= minScale) {
      setScale(scale - zoomStep);
    }
  };

  const onChangeLanguage = (event) => {
    setSelectedlanguage(event.target.value);
    setIsLoading(true);
  };

  return (
    <Spin spinning={isLoading}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '1rem',
          height: '100%'
        }}
      >
        <Radio.Group
          onChange={onChangeLanguage}
          value={selectedLanguage}
          style={{ marginRight: 'auto' }}
        >
          <Radio value={LOCALE.EN}>English</Radio>
          <Radio value={LOCALE.JP}>日本語</Radio>
        </Radio.Group>

        {!isLoading ? (
          <ZoomWrapper>
            <div
              className="zoom-in"
              onClick={onZoomIn}
              style={{
                cursor: scale <= maxScale ? 'pointer' : 'not-allowed'
              }}
            >
              <ZoomInOutlined />
            </div>

            <div
              className="zoom-out"
              onClick={onZoomOut}
              style={{
                cursor: scale >= minScale ? 'pointer' : 'not-allowed'
              }}
            >
              <ZoomOutOutlined />
            </div>
          </ZoomWrapper>
        ) : null}
      </div>

      <DocumentSettingWrapper>
        <div style={{ height: '1rem' }}>
          <Document
            file={
              selectedLanguage === LOCALE.EN ? profiledocs_en : profiledocs_jp
            }
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={onDocumentLoadError}
            loading=""
          >
            {/* display all page reference: https://github.com/wojtekmaj/react-pdf/wiki/Recipes#display-single-page-with-navigation */}
            {Array.from(new Array(numPages), (el, index) => (
              <Page key={index} pageNumber={index + 1} scale={scale} />
            ))}
          </Document>
        </div>
      </DocumentSettingWrapper>
    </Spin>
  );
};

export default connect(
  (state) => {
    return {
      tenantId: state?.App?.user?.tenant?.id,
      user: state?.App?.user,
      profiledocs_en: state?.Profile?.pdf_links?.profiledocs_en,
      profiledocs_jp: state?.Profile?.pdf_links?.profiledocs_jp
    };
  },
  {
    actGetTenantInfo,
    actGetPdfDocumentation
  }
)(DocumentSetting);
