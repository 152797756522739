import { SlideItemWrapper } from './style';

const SlideItem = (props) => {
  const { name, position, avatar, comment, isLargeImage } = props;

  return (
    <SlideItemWrapper>
      <div className="content" dangerouslySetInnerHTML={{ __html: comment }} />

      {isLargeImage ? <img src={avatar} alt="" className="avatar" /> : null}

      <div className="slide-footer">
        {!isLargeImage ? <img src={avatar} alt="" /> : null}

        <div className="detail">
          <div className="name">{name}</div>
          <div className="position">{position}</div>
        </div>
      </div>
    </SlideItemWrapper>
  );
};

export default SlideItem;
