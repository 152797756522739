import { PURGE } from 'redux-persist';
import _ from 'lodash';
import {
  SAVE_GET_SHIFT_TYPE_SETTING,
  SAVE_DELETE_SHIFT_TYPE_INFO,
  SAVE_CREATE_SHIFT_TYPE,
  SEARCH_SHIFT_TYPE,
  SAVE_UPDATE_SHIFT_TYPE_INFO,
  SET_SELECTED_SHIFT_TYPE_SETTING,
  SET_MODAL_SHIFT_TYPE_SETTING_VISIBLE,
  SET_MODAL_BREAK_PAIR_VISIBLE,
  SET_SELECTED_BREAK_PAIR
} from '../type';
import { filterDataOnTable } from 'utils/helper';
import { update } from './helper';

const initialState = {
  listShiftType: [],
  listData: [],
  totalData: 0,
  filter: {},
  sorter: {},
  filterOr: {},
  modalVisible: false,
  selectedShiftTypeSetting: {},
  modalBreakPairVisible: false,
  selectedBreakPair: {}
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case SAVE_GET_SHIFT_TYPE_SETTING:
      return {
        ...state,
        listShiftType: payload || [],
        listData: payload || []
      };

    case SAVE_DELETE_SHIFT_TYPE_INFO:
      return update(state, {
        listShiftType: { $updateOrDelete: [payload.id] },
        listData: { $updateOrDelete: [payload.id] }
      });

    case SAVE_UPDATE_SHIFT_TYPE_INFO: {
      return update(state, {
        listShiftType: {
          $updateOrDelete: [payload.id, payload]
        },
        listData: { $updateOrDelete: [payload.id, payload] }
      });
    }

    case SAVE_CREATE_SHIFT_TYPE:
      return {
        ...state,
        listShiftType: [payload, ...state.listShiftType],
        listData: [payload, ...state.listData]
      };

    case SEARCH_SHIFT_TYPE:
      const { filter, sorter } = payload;
      const filterOr = payload.filterOr || state.filterOr;

      return {
        ...state,
        filter,
        sorter: !_.isEmpty(sorter) ? sorter : state.sorter,
        filterOr: filterOr,
        listShiftType: filterDataOnTable({
          listData: state.listData,
          filter,
          filterOr: filterOr || {},
          sorter
        })
      };

    case SET_SELECTED_SHIFT_TYPE_SETTING:
      return {
        ...state,
        selectedShiftTypeSetting: payload
      };

    case SET_MODAL_SHIFT_TYPE_SETTING_VISIBLE:
      return {
        ...state,
        modalVisible: payload
      };

    case SET_SELECTED_BREAK_PAIR:
      return {
        ...state,
        selectedBreakPair: payload
      };

    case SET_MODAL_BREAK_PAIR_VISIBLE:
      return {
        ...state,
        modalBreakPairVisible: payload
      };

    default:
      return state;
  }
};

export default reducer;
