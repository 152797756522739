import React from 'react';
import { Modal, Button, Row } from 'antd';
import successStatus from 'assets/check.svg';
import { useTranslation } from 'component/index';

const ModalSuccess = (props) => {
  const { isModalVisible, onHideModal } = props;
  const { translate } = useTranslation();

  return (
    <Modal
      visible={isModalVisible}
      closable={false}
      footer={null}
      wrapClassName="custom-modal"
      width="40rem"
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '9rem',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'var(--color-dark-blue)'
        }}
      >
        <img
          src={successStatus}
          style={{
            position: 'absolute',
            bottom: '0',
            width: '13rem',
            height: '13rem',
            transform: 'translateY(50%)'
          }}
          alt=""
        />
      </div>

      <Row justify="center">
        <span
          style={{
            fontWeight: 600,
            fontSize: '2rem',
            fontFamily: 'var(--font-family)',
            marginTop: '13rem'
          }}
        >
          {translate('sendEmail.scheduleSentSuccess')}
        </span>
      </Row>

      <Row justify="center" style={{ marginTop: '3rem' }}>
        <Button
          type="primary"
          className="custom-button"
          size="large"
          style={{
            height: '4.7rem',
            padding: '0.7rem 5rem'
          }}
          onClick={onHideModal}
        >
          {translate('done')}
        </Button>
      </Row>
    </Modal>
  );
};

export default ModalSuccess;
