import styled from 'styled-components';

const StatisticItemWrapper = styled.div`
  border: 1px solid red;
  flex-basis: 32%;
  /* max-width: 32%; -> make chart responsive */
  max-width: 32%;
  padding: 2rem 2rem;
  border: 1px solid var(--color-border-gray);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: var(--box-shadow);

  .detail {
    display: flex;
    justify-content: flex-start;
    margin-bottom: auto;

    .icon-wrapper {
      margin-right: 1rem;
    }

    .statistic {
      .heading {
      }

      .description {
        font-size: 1.5rem;
        font-weight: 500;
        font-style: italic;
      }
    }

    .shift {
      font-size: 1.4rem;
      font-weight: 500;
    }

    .bold {
      font-weight: 600;
      font-size: 1.9rem;
    }
  }

  .chart {
    margin-top: 1rem;
    width: 100%;
    max-width: 100%;
    height: 13rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
`;

export { StatisticItemWrapper };
