import styled from 'styled-components';

const EmployeeScheduleWrapper = styled.div`
  border: 1px solid var(--color-border-gray);
  border-radius: 5px;
  box-shadow: var(--box-shadow);
  padding: 2rem 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .grid-cell-centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .heading {
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--color-text);
    margin-bottom: 1.5rem;
    display: flex;

    .label {
      margin-right: auto;
    }
  }

  .table-wrapper {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ag-root-wrapper-body.ag-layout-normal {
    flex: 1 1 auto;
    height: auto;
    min-height: auto;
    /* height: 0; */
    /* min-height: 0; */
  }
`;

const CustomHeaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  & > img {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

const CenterHeaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .text {
    width: 100%;
    text-align: center;
    font-size: 1.4rem;
    /* https://stackoverflow.com/questions/33894190/how-to-wordwrap-a-header-in-ag-grid */
    white-space: normal;
    font-weight: 600;
  }
`;

export { EmployeeScheduleWrapper, CustomHeaderWrapper, CenterHeaderWrapper };
