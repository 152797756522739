import React, { useEffect } from 'react';
import { Button, Form, Input, message, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { actSetSignupData, actValidatePassword } from 'redux/action/wizard';
import { useTranslation } from 'component/index';

const WizardSignup = (props) => {
  const [form] = Form.useForm();
  const { next, tenant, signupData } = props;
  const { translate } = useTranslation();
  const text = translate('wizard.setup.passwordRule');

  useEffect(() => {
    if (signupData) {
      const {
        first_name,
        last_name,
        email,
        old_password,
        new_password,
        confirm_password
      } = signupData;

      form.setFieldsValue({
        first_name,
        last_name,
        email,
        old_password,
        new_password,
        confirm_password
      });
    }
  }, [signupData]);

  const onSubmitSignup = (values) => {
    const body = {
      old_password: values.old_password,
      new_password: values.new_password
    };

    props.actValidatePassword({ body: body }, (err) => {
      if (err) {
        message.error(err.message);
      } else {
        message.success('Password Successfully Validated');
        props.actSetSignupData(values);
        next();
      }
    });
  };

  return (
    <div style={{ marginTop: '20px' }}>
      <Form layout="vertical" form={form} onFinish={onSubmitSignup}>
        <Form.Item label={translate('wizard.signup.TenantName')}>
          <Input value={tenant?.name || ''} readOnly={true} />
        </Form.Item>

        <Form.Item
          label={translate('wizard.signup.firstName')}
          name="first_name"
          rules={[
            {
              required: true,
              message: `This Field ${translate('isRequired')}`
            }
          ]}
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          label={translate('wizard.signup.lastName')}
          name="last_name"
          rules={[
            {
              required: true,
              message: `${translate('wizard.signup.lastName')} ${translate(
                'isRequired'
              )}`
            }
          ]}
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          label={translate('wizard.signup.emailAdd')}
          name="email"
          rules={[
            {
              type: 'email',
              message: 'This is not valid Email'
            },
            {
              required: true,
              message: `${translate('wizard.signup.emailAdd')} ${translate(
                'isRequired'
              )}`
            }
          ]}
        >
          <Input style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          label={translate('wizard.signup.oldPassword')}
          name="old_password"
          rules={[
            {
              required: true,
              message: `${translate('wizard.signup.oldPassword')} ${translate(
                'isRequired'
              )}`
            }
          ]}
        >
          <Input.Password style={{ width: '100%' }} />
        </Form.Item>
        <Tooltip placement="right" color="rgb(24,144,255)" title={text}>
          <Form.Item
            label={translate('wizard.signup.newPassword')}
            name="new_password"
            rules={[
              {
                required: true,
                message: `${translate('wizard.signup.newPassword')} ${translate(
                  'isRequired'
                )}`
              }
            ]}
          >
            <Input.Password style={{ width: '100%' }} />
          </Form.Item>
        </Tooltip>
        <Form.Item
          label={translate('wizard.signup.retypePassword')}
          name="confirm_password"
          rules={[
            {
              required: true,
              message: 'Please confirm your password!'
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('new_password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('Password do not match');
              }
            })
          ]}
        >
          <Input.Password style={{ width: '100%' }} />
        </Form.Item>
        <Button type="primary" htmlType="submit">
          {translate('wizard.Next')}
        </Button>
      </Form>
    </div>
  );
};

export default connect(
  (state) => ({
    tenant: state.App?.user?.tenant,
    signupData: state.Wizard.signup
  }),
  {
    actSetSignupData,
    actValidatePassword
  }
)(WizardSignup);
