import styled from 'styled-components';

const HeadingSectionWrapper = styled.div`
  width: 100%;
  background-image: ${({ bgUrl }) => `url(${bgUrl})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom right;

  .main {
    position: relative;
    width: 100%;
    height: 75vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    .navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 2rem;
      width: 100%;

      .logo {
        margin-right: auto;
        font-family: var(--font-family-secondary);
        color: white;
        font-size: 4rem;
      }

      .menu {
        list-style: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-right: 3rem;
        margin-bottom: 0;

        li {
          margin-right: 2rem;
          color: white;
          font-weight: 300;
          font-size: 1.6rem;
        }
      }

      .login-button {
        border: none;
        outline: none;
        border-radius: 1.8rem;
        padding: 0.7rem 2rem;
        background-image: linear-gradient(
          to right,
          var(--color-orange-dark),
          var(--color-orange-light)
        );
        color: white;
        font-weight: 400;
        font-size: 1.7rem;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 1px;
        cursor: pointer;

        span {
          display: flex;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 15vh;
      width: 60%;

      h1 {
        color: white;
        font-size: 3rem;
        font-weight: 600;
      }

      p {
        color: white;
        font-size: 1.7rem;
        font-weight: 300;
        color: var(--color-text-light-gray);
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 4rem;
      }
    }

    .heading-icon {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export { HeadingSectionWrapper };
