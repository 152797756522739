import {
  SET_SELECTED_EMPLOYEE_EMAIL,
  SET_DATE_SEND_EMAIL,
  SEND_EMAIL,
  SET_SEND_EMAIL_STATUS,
  REQUEST_A_DEMO
} from 'redux/type';

export const actSetSelectedEmployeeEmail = (payload) => ({
  type: SET_SELECTED_EMPLOYEE_EMAIL,
  payload
});

export const actSetDateSendEmail = (payload) => ({
  type: SET_DATE_SEND_EMAIL,
  payload
});

export const actSendEmail = (...args) => ({
  type: SEND_EMAIL,
  args
});

export const actSetEmailStatus = (payload) => ({
  type: SET_SEND_EMAIL_STATUS,
  payload
});

export const actRequestADemo = (...args) => ({
  type: REQUEST_A_DEMO,
  args
});
