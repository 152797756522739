import React, { useEffect, useState, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Tooltip } from 'antd';
import { connect } from 'react-redux';
import { useTranslation } from 'component/index';
import { EmployeeScheduleWrapper } from './style';
import { LOCALE } from 'languages/index';
import moment from 'moment';
import _ from 'lodash';
import { EmployeeScheduleHeader } from 'component/DashboardLayout/Dashboard/EmployeeSchedule/EmployeeScheduleHeader';
import { LIST_TAG_COLOR, MONTH_STRING } from 'utils/constant';
import { EmployeeCell } from './CustomHeader';

export const COUNTABLE_HEADER_NAME = 'Countable';
export const COLUMN_WIDTH = 60;

function getSelectedMonthList(from_date, to_date) {
  let month_list = [];
  let from_month = moment(+from_date).month() + 1;
  let to_month = moment(+to_date).month() + 1;
  let from_year = moment(+from_date).year();
  let to_year = moment(+to_date).year();
  if (to_year > from_year) {
    for (let i = from_month; i <= 12; i++) {
      month_list.push(i);
    }
    for (let i = 1; i <= to_month; i++) {
      month_list.push(i);
    }
  } else {
    for (let i = from_month; i <= to_month; i++) {
      month_list.push(i);
    }
  }
  return month_list;
}

const EmployeeSchedule = (props) => {
  const {
    preferences,
    listEmployee,
    listEmployeeReason,
    listShiftType,
    from_date,
    to_date
  } = props;

  const { translate, locale } = useTranslation();
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [tab_header, setHeader] = useState([]);
  const [tab_data, setTableData] = useState([]);
  const gridRef = useRef();

  const onGridReady = (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
  };

  useEffect(() => {
    if (locale === LOCALE.EN) {
      moment.locale('en-us');
    } else if (locale === LOCALE.JP) {
      moment.locale('ja');
    }
    setHeader([
      ...EmployeeScheduleHeader(
        translate,
        MONTH_STRING,
        getSelectedMonthList(from_date, to_date),
        listShiftType,
        listEmployeeReason
      )
    ]);
  }, [locale, preferences, listShiftType, listEmployeeReason]);

  useEffect(() => {
    let month_list = getSelectedMonthList(from_date, to_date);
    if (
      preferences?.employee_shift_aggregation &&
      preferences?.employee_avail_aggregation &&
      preferences?.employee_free_days_aggregation &&
      listEmployee
    ) {
      let table_data = [];

      _.forEach(
        _.filter(listEmployee, (emp) => emp?.is_active),
        (employee) => {
          let _table_data = {};
          _table_data['id'] = employee.id;
          _table_data['name'] = employee.name;

          if (listShiftType) {
            _.forEach(listShiftType, (shift) => {
              _.forEach(month_list, (month) => {
                let employee_shift = _.find(
                  preferences?.employee_shift_aggregation,
                  {
                    employee_id: employee.id,
                    month: month,
                    shift: shift.name
                  }
                );
                if (employee_shift) {
                  _table_data[`${shift.name}_${MONTH_STRING[month - 1]}`] =
                    employee_shift.total;
                } else {
                  _table_data[`${shift.name}_${MONTH_STRING[month - 1]}`] = 0;
                }
              });
            });
            table_data.push(_table_data);
          }

          if (listEmployeeReason) {
            _.forEach(listEmployeeReason, (reason) => {
              _.forEach(month_list, (month) => {
                let employee_reason = _.find(
                  preferences?.employee_avail_aggregation,
                  {
                    employee_id: employee.id,
                    month: month,
                    reason: reason.name
                  }
                );
                if (employee_reason) {
                  _table_data[`${reason.name}_${MONTH_STRING[month - 1]}`] =
                    employee_reason.total;
                } else {
                  _table_data[`${reason.name}_${MONTH_STRING[month - 1]}`] = 0;
                }
              });
            });

            _.forEach(
              preferences?.employee_free_days_aggregation,
              (freeDays) => {
                if (employee.id === freeDays.id) {
                  Object.keys(freeDays).filter((key) => {
                    if (key !== 'id' && key !== 'name') {
                      _table_data[key] = freeDays[key];
                    }
                  });
                }
              }
            );
            table_data.push(_table_data);
          }
        }
      );

      table_data = _.uniqBy(table_data, 'id');
      setTableData(table_data);
    }
  }, [preferences, listEmployee]);

  const EmployeeReasonHeader = (header_props) => {
    return (
      <Tooltip title={header_props.column.colId.split('_')[0]} placement="top">
        <img
          src={header_props.column.colDef.icon}
          alt="icon"
          style={{ width: '1.5rem', height: '1.5rem' }}
        />
        <br />
        <span>{header_props.displayName}</span>
      </Tooltip>
    );
  };

  const ShiftHeader = (props) => {
    const { displayName, column } = props;

    let shiftName = column.colId?.split('_')[0];
    const color =
      LIST_TAG_COLOR[
        listShiftType.findIndex((item) => item.name === shiftName) %
          LIST_TAG_COLOR.length
      ];

    return (
      <Tooltip title={props.column.colId.split('_')[0]} placement="top">
        <div
          style={{
            width: '1.5rem',
            height: '1.5rem',
            borderRadius: '50%',
            backgroundColor: color,
            marginRight: '0.7rem'
          }}
        />
        <span>{displayName}</span>
      </Tooltip>
    );
  };

  const FreeDaysHeader = (header_props) => {
    return <span>{header_props.displayName}</span>;
  };

  return (
    <EmployeeScheduleWrapper>
      <div className="heading">
        <span className="label">{translate('dashboard.employeeSchedule')}</span>
      </div>

      <div className="table-wrapper">
        <div
          className="ag-theme-alpine"
          style={{
            height: preferences?.employee_avail_aggregation ? '100%' : '100vh',
            width: '100%'
          }}
        >
          <AgGridReact
            ref={gridRef}
            columnDefs={tab_header || []}
            rowData={tab_data}
            frameworkComponents={{
              EmployeeReasonHeader,
              ShiftHeader,
              FreeDaysHeader,
              EmployeeCell
            }}
            gridOptions={{
              suppressRowTransform: true,
              suppressScrollOnNewData: true, // https://stackoverflow.com/questions/59360133/ag-grid-resets-scroll-on-new-datasource
              overlayLoadingTemplate:
                '<span class="ag-overlay-loading-center">Please wait while data is loading</span>'
            }}
            onGridReady={onGridReady}
            defaultColDef={{
              wrapText: true,
              resizable: false
            }}
            sideBar={true}
          />
        </div>
      </div>
    </EmployeeScheduleWrapper>
  );
};

export default connect((state) => {
  return {
    tenantId: state?.App?.user?.tenant?.id,
    employeeSchedule: state.Dashboard.employeeSchedule,
    listEmployeeReason: state.EmployeeReason.listEmployeeReason,
    listShiftType: state.ShiftTypeSetting.listShiftType,
    preferences: state.Dashboard.preferences,
    listEmployee: state.EmployeeSetting.listData
  };
})(EmployeeSchedule);
