import React from 'react';
import { Modal, Form, Input, message } from 'antd';
import { connect } from 'react-redux';
import { useTranslation } from 'component/index';
import {
  actSetModalProfileVisible,
  actUpdatePassword
} from 'redux/action/profile';

const UpdatePasswordModal = (props) => {
  const { modalVisible } = props;
  const { translate } = useTranslation();
  const [form] = Form.useForm();

  const onHideModal = () => {
    form.setFieldsValue({
      old_pass: '',
      new_pass: ''
    });
    props.actSetModalProfileVisible(false);
  };

  const onSubmitForm = async () => {
    const { old_pass } = await form.validateFields(['old_pass']);
    const { new_pass } = await form.validateFields(['new_pass']);
    const updated_password = {
      old_password: old_pass,
      new_password: new_pass
    };

    props.actUpdatePassword({ body: updated_password }, (err, res) => {
      if (err) {
        message.error(err.message);
      } else {
        message.success(translate('profile.passSucess'));
      }
    });
  };

  return (
    <div>
      <Modal
        title={translate('profile.updatePassord')}
        visible={modalVisible}
        okText={translate('profile.submit')}
        maskClosable={false}
        onOk={onSubmitForm}
        onCancel={onHideModal}
        forceRender={true}
        destroyOnClose={true}
        getContainer={false}
        style={{ top: '1rem' }}
      >
        <Form layout="vertical" form={form}>
          <Form.Item
            label={translate('profile.oldPassword')}
            rules={[
              {
                required: true,
                message: `old password ${translate('isRequired')}`
              }
            ]}
            name="old_pass"
          >
            <Input type="password" />
          </Form.Item>
          <Form.Item
            label={translate('profile.newPassword')}
            rules={[
              {
                required: true,
                message: `New passowrd ${translate('isRequired')}`
              }
            ]}
            name="new_pass"
          >
            <Input type="password" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default connect(
  (state) => ({
    modalVisible: state?.Profile?.modalVisible
  }),
  {
    actSetModalProfileVisible,
    actUpdatePassword
  }
)(UpdatePasswordModal);
