import moment from 'moment';
import _ from 'lodash';

import { useEffect, useMemo, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  DatePicker,
  Button,
  Popconfirm,
  Tabs,
  Radio,
  Select
} from 'antd';
import { useNavigate as useHistory, useLocation } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import { FaRegEdit } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';

import {
  actGetEmployeeById,
  actGetEmployeeSetting
} from 'redux/action/employeeSetting';
import {
  actGetTimeReporting,
  actSetModalTimeReportVisible,
  actSetSelectedTimeReport,
  actDeleteTimeReport
} from 'redux/action/timeReport';
import { actSetGlobalTime } from 'redux/action/grid';
import { useTranslation } from 'component/index';
import { filterListEmployee } from 'utils/helper';
import { EmployeeDetailWrapper, TableActionWrapper } from './style';
import ModalTimeReport from './ModalTimeReport';
import Statistic from './Statistic';

const { TabPane } = Tabs;
export const CHART_MODE = {
  STACKED: 'STACKED',
  BAR: 'BAR'
};

const columnConfig = (translate, onEditTimeReport, onDeleteTimeReport) => [
  {
    title: translate('timeReport.date'),
    dataIndex: 'date',
    key: 'date'
  },
  {
    title: translate('timeReport.startTime'),
    dataIndex: 'startTime',
    key: 'startTime'
  },
  {
    title: translate('timeReport.endTime'),
    dataIndex: 'endTime',
    key: 'endTime'
  },
  {
    title: translate('timeReport.duration'),
    dataIndex: 'duration',
    key: 'duration'
  },
  {
    title: translate('timeReport.shiftType'),
    dataIndex: ['spot', 'name'],
    key: 'spot'
  },
  {
    title: translate('timeReport.manual'),
    dataIndex: 'manual',
    key: 'manual'
  },
  {
    title: translate('table.actions'),
    dataIndex: 'actions',
    key: 'actions',
    width: '8%',
    render: (value, record) => (
      <TableActionWrapper>
        <FaRegEdit
          className="edit-icon"
          onClick={() => onEditTimeReport(record)}
        />

        <Popconfirm
          onConfirm={() => onDeleteTimeReport(record)}
          title={translate('message.confirmToDelete')}
          okText={translate('yes')}
          cancelText={translate('no')}
        >
          <RiDeleteBin6Line className="delete-icon" />
        </Popconfirm>
      </TableActionWrapper>
    )
  }
];

const TimeReport = (props) => {
  const { tenantId, listData, globalTime, listEmployee } = props;
  const location = useLocation();
  const query = queryString.parse(location.search);
  const { employee } = query;
  const { translate } = useTranslation();
  const [activeTab, setActiveTab] = useState('1');
  const [listOptionEmployee, setListOptionEmployee] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [chartMode, setChartMode] = useState(CHART_MODE.STACKED);
  const [year, setYear] = useState(moment(+globalTime));

  const history = useHistory();

  useEffect(() => {
    props.actGetEmployeeSetting({ tenantId });
  }, [tenantId]);

  useEffect(() => {
    setListOptionEmployee(listEmployee);
  }, [listEmployee]);

  const getDurationFromMinute = (durationInMinute) => {
    const hour = Math.floor(durationInMinute / 60);
    const minute = durationInMinute % durationInMinute;
    return minute > 0 ? `${hour}h ${minute}m` : `${hour}h`;
  };

  const isStatistic = useMemo(() => {
    return activeTab === '1';
  }, [activeTab]);

  const data = useMemo(() => {
    if (listData?.length == 0) {
      return [];
    }

    return listData?.map((item) => {
      const startTime = moment(item?.start_date_time);
      const endTime = item?.end_date_time ? moment(item?.end_date_time) : null;
      const startDate = startTime.format('YYYY-MM-DD');
      const startTimeStr = startTime.format('HH:mm');
      const endTimeStr = endTime ? endTime.format('HH:mm') : '';
      const durationInMinute = endTime
        ? (endTime?.format('x') - startTime?.format('x')) / 1000 / 60
        : '';

      return {
        date: startDate,
        startTime: startTimeStr,
        endTime: endTimeStr,
        duration: endTime ? getDurationFromMinute(durationInMinute) : '',
        spot: item?.spot,
        manual: item?.is_manual ? translate('yes') : translate('no'),
        start_date_time: item?.start_date_time,
        end_date_time: item?.end_date_time,
        id: item?.id,
        employee: item?.employee
      };
    });
  }, [listData]);

  useEffect(() => {
    props.actGetTimeReporting({
      tenantId,
      employeeId: employee,
      year: moment(+globalTime).year(),
      month: moment(+globalTime).month() + 1
    });

    props.actGetEmployeeById({
      employeeId: employee,
      tenantId
    });

    const foundedEmployee = _.find(listEmployee, { id: Number(employee) });
    setSelectedEmployee(foundedEmployee?.name);
  }, [employee, globalTime, listEmployee]);

  const onDateChange = (date) => {
    props.actSetGlobalTime(date.format('x'));
  };

  const onOpenModal = (record) => {
    props?.actSetModalTimeReportVisible(true);
    props?.actSetSelectedTimeReport(record);
  };

  const onDeleteTimeReport = (record) => {
    props?.actDeleteTimeReport(
      {
        tenantId,
        id: record?.id
      },
      (err, res) => {
        if (err) {
          message.error(err.message);
        }
      }
    );
  };

  const onSearchEmployee = (text) => {
    const tempListEmployee = filterListEmployee(listEmployee, text);
    setListOptionEmployee(tempListEmployee);
  };

  const onChangeEmployee = (employeeName) => {
    const employeeInfo = _.find(listEmployee, { name: employeeName });
    history(`/settings/employee-detail?employee=${employeeInfo?.id}`);
  };

  const onChangeChartMode = (e) => {
    setChartMode(e?.target?.value);
  };

  return (
    <EmployeeDetailWrapper>
      <div className="employee-detail">
        <Select
          size="large"
          value={selectedEmployee}
          onChange={onChangeEmployee}
          onSearch={onSearchEmployee}
          style={{ width: '25rem', marginRight: 'auto' }}
          placeholder={translate('timeReport.filterEmployee')}
          showSearch={true}
          options={_.map(listOptionEmployee, (emp) => ({
            label: emp?.name,
            value: emp?.name
          }))}
        />

        {activeTab == '1' ? (
          <Radio.Group onChange={onChangeChartMode} value={chartMode}>
            <Radio value={CHART_MODE.STACKED}>
              {translate('timeReport.stacked')}
            </Radio>
            <Radio value={CHART_MODE.BAR}> {translate('timeReport.bar')}</Radio>
          </Radio.Group>
        ) : null}

        {!isStatistic ? (
          <Fragment>
            <div className="filter-date">
              <DatePicker
                placeholder={translate('preference.filterMonth')}
                size="large"
                picker="month"
                format="MMMM YYYY"
                className="input-border"
                style={{ width: '25rem' }}
                onChange={onDateChange}
                value={moment(+globalTime)}
                allowClear={false}
              />
            </div>

            <Button
              type="primary"
              icon={<PlusOutlined />}
              className="custom-button"
              size="large"
              onClick={() => onOpenModal({})}
            >
              {translate('preference.addTimeReport')}
            </Button>
          </Fragment>
        ) : (
          <div className="filter-date">
            <DatePicker
              placeholder={translate('preference.filterYear')}
              size="large"
              picker="year"
              format="YYYY"
              className="input-border"
              style={{ width: '25rem' }}
              onChange={setYear}
              value={year}
              allowClear={false}
            />
          </div>
        )}
      </div>

      <Tabs
        activeKey={activeTab}
        onChange={(activeKey) => setActiveTab(activeKey)}
      >
        <TabPane tab={translate('timeReport.statistic')} key="1">
          <Statistic year={year.year()} chartMode={chartMode} />
        </TabPane>

        <TabPane tab={translate('timeReport.data')} key="2">
          <Table
            columns={columnConfig(translate, onOpenModal, onDeleteTimeReport)}
            dataSource={data}
          />
        </TabPane>
      </Tabs>

      <ModalTimeReport />
    </EmployeeDetailWrapper>
  );
};

export default connect(
  (state) => ({
    tenantId: state?.App?.user?.tenant?.id,
    listData: state?.TimeReport?.listData,
    globalTime: state.App.globalTime,
    listEmployee: state.EmployeeSetting.listEmployee
  }),
  {
    actGetTimeReporting,
    actGetEmployeeById,
    actSetGlobalTime,
    actSetModalTimeReportVisible,
    actSetSelectedTimeReport,
    actDeleteTimeReport,
    actGetEmployeeSetting
  }
)(TimeReport);
