import styled from 'styled-components';

const EmployeeDetailWrapper = styled.div`
  .employee-detail {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    margin-right: auto;

    .filter-date {
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }
`;

const TableActionWrapper = styled.div`
  display: flex;
  align-items: center;

  .edit-icon,
  .delete-icon {
    font-size: 1.5rem;
    cursor: pointer;
  }

  .edit-icon:hover {
    color: var(--color-secondary);
  }

  .delete-icon {
    margin-left: 1rem;

    &:hover {
      color: var(--color-red);
    }
  }
`;

export { EmployeeDetailWrapper, TableActionWrapper };
