import {
  GET_LIST_ROTATION_TAB,
  GET_ONE_ROTATION_TAB,
  CREATE_ROTATION_TAB,
  UPDATE_ROTATION_TAB,
  DELETE_ROTATION_TAB,
  SAVE_DELETE_ROTATION_TAB
} from 'redux/type';

export const actGetListRotationTab = (...args) => ({
  type: GET_LIST_ROTATION_TAB,
  args
});

export const actGetOneRotationTab = (...args) => ({
  type: GET_ONE_ROTATION_TAB,
  args
});

export const actCreateRotationTab = (...args) => ({
  type: CREATE_ROTATION_TAB,
  args
});

export const actUpdateRotationTab = (...args) => ({
  type: UPDATE_ROTATION_TAB,
  args
});

export const actDeleteRotationTab = (...args) => ({
  type: DELETE_ROTATION_TAB,
  args
});

export const actSaveDeleteRotationTab = (payload) => ({
  type: SAVE_DELETE_ROTATION_TAB,
  payload
});
