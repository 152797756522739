import { useEffect, useState } from 'react';
import _ from 'lodash';
import { Table, Popconfirm, Radio, Switch, Input, Button, message } from 'antd';
import { connect } from 'react-redux';
import { TableWrapper, TableActionWrapper } from 'component/style';
import { useTranslation } from 'component/index';
import {
  actGetRuleAndStatus,
  actUpdateRuleAndStatus,
  actResetRuleAndStatus
} from 'redux/action/profile';

const ACTIVE_CONST = [
  'ACTIVE_TRUE_EDITABLE_TRUE',
  'ACTIVE_FALSE_EDITABLE_TRUE'
];

const SCORE_CONST = [
  'SOFT_EDITABLE_TRUE',
  'MEDIUM_EDITABLE_TRUE',
  'HARD_EDITABLE_TRUE'
];

const convertApiToTableDataFormate = (data) => {
  let data_body = {
    id: 0,
    rules: []
  };

  if (data && !_.isEmpty(data)) {
    Object.keys(data).map((key, index) => {
      if (key === 'id') {
        data_body.id = data[key];
      } else {
        const activeAndEditStatus =
          data[key]['activeAndEditStatus'].toUpperCase();
        const scoreAndEditable = data[key]['scoreAndEditable'].toUpperCase();
        const scoreValue = scoreAndEditable.includes('SOFT')
          ? 0
          : scoreAndEditable.includes('MEDIUM')
          ? 1
          : 2;

        const rule = {
          id: index,
          rule_name: key,
          active: {
            value: activeAndEditStatus.includes('ACTIVE_TRUE'),
            is_editable: activeAndEditStatus.includes('EDITABLE_TRUE')
          },
          score: {
            value: scoreValue,
            is_editable: scoreAndEditable.includes('EDITABLE_TRUE')
          },
          value: {
            value: data[key]['value'],
            is_editable: data[key]['valueEditable']
          }
        };
        data_body.rules.push(rule);
      }
    });
  }

  return data_body;
};

const columnConfig = ({
  onActiveChange,
  onScoreChange,
  onValueChange,
  translate
}) => [
  {
    title: translate('rule.name'),
    dataIndex: 'rule_name',
    key: 'rule_name',
    sorter: false,
    width: '25%',
    render: (name, row) => (
      <>
        <span>{name.split('_').join(' ').replace('rule ', '')}</span>
        <br />
        <i>
          <small>
            <b>{translate('rule.info')}: </b>
            {translate(`rule.${name}`)}
          </small>
        </i>
      </>
    )
  },
  {
    title: translate('rule.active'),
    dataIndex: 'active',
    key: 'active',
    sorter: false,
    width: '12%',
    render: (active, row) => (
      <TableActionWrapper>
        <Switch
          disabled={!active.is_editable}
          checked={active.value}
          checkedChildren="ON"
          unCheckedChildren="OFF"
          onChange={(value) => onActiveChange(row, value)}
        />
      </TableActionWrapper>
    )
  },
  {
    title: translate('rule.score'),
    dataIndex: 'score',
    key: 'score',
    sorter: false,
    width: '25%',
    render: (score, row) => (
      <TableActionWrapper>
        <Radio.Group
          onChange={(value) => onScoreChange(row, value)}
          disabled={!score.is_editable}
          value={score.value}
        >
          <Radio
            className={!score.is_editable ? 'r-soft-disabled' : 'r-soft'}
            value={0}
          >
            Soft
          </Radio>
          <Radio className="r-medium" value={1}>
            Medium
          </Radio>
          <Radio className="r-hard" value={2}>
            Hard
          </Radio>
        </Radio.Group>
      </TableActionWrapper>
    )
  },
  {
    title: translate('rule.value'),
    dataIndex: 'value',
    key: 'value',
    sorter: false,
    width: '10%',
    render: (value, row) => (
      <TableActionWrapper>
        <Input
          className="rule-value-input"
          name="value"
          type="number"
          disabled={!value.is_editable}
          value={value.value}
          onChange={(value) => onValueChange(row, value)}
        />
      </TableActionWrapper>
    )
  }
];

const RulesTable = (props) => {
  const { tenantId, rulesStatus } = props;
  const { translate } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [{ id, rules }, setRules] = useState(
    convertApiToTableDataFormate(rulesStatus)
  );
  const [api_data, setApiData] = useState(rulesStatus);

  useEffect(() => {
    props.actGetRuleAndStatus({ tenantId });
  }, [tenantId]);

  useEffect(() => {
    var data_body = convertApiToTableDataFormate(rulesStatus);
    setRules(data_body);
    setApiData(rulesStatus);
  }, [rulesStatus]);

  const onActiveChange = (row, value) => {
    var data = _.map(rules, (rule) => {
      if (row?.rule_name === rule?.rule_name) {
        rule.active.value = value;
        api_data[rule.rule_name]['activeAndEditStatus'] = value
          ? ACTIVE_CONST[0]
          : ACTIVE_CONST[1];

        setApiData(api_data);
        return rule;
      }

      return rule;
    });

    setRules({ id: id, rules: data });
  };

  const onScoreChange = (row, event) => {
    const data = _.map(rules, (rule) => {
      if (row?.rule_name === rule?.rule_name) {
        rule.score.value = event.target.value;
        api_data[rule.rule_name]['scoreAndEditable'] =
          event.target.value === 0
            ? SCORE_CONST[0]
            : event.target.value === 1
            ? SCORE_CONST[1]
            : SCORE_CONST[2];

        setApiData(api_data);
        return rule;
      }

      return rule;
    });

    setRules({ id: id, rules: data });
  };

  const onValueChange = (row, event) => {
    const data = _.map(rules, (rule) => {
      if (row?.rule_name === rule?.rule_name) {
        rule.value.value = event.target.value;
        api_data[rule.rule_name]['value'] = event.target.value;

        setApiData(api_data);
        return rule;
      }

      return rule;
    });

    setRules({ id: id, rules: data });
  };

  const onRulesSave = (data) => {
    props.actUpdateRuleAndStatus({ tenantId, body: data }, (err, res) => {
      setLoading(false);
      if (res) {
        props.actGetRuleAndStatus({ tenantId });
        message.success('rule and status changes succesfully');
      }

      if (err) {
        props.actGetRuleAndStatus({ tenantId });
        message.error('Error occured while changing rule and status');
      }
    });
  };

  const onRulesReset = () => {
    setLoading(true);
    props.actResetRuleAndStatus({ tenantId }, (err, res) => {
      setLoading(false);
      props.actGetRuleAndStatus({ tenantId });
      if (res) {
        message.success('rule and status reseted succesfully');
      }

      if (err) {
        message.error('Error occured while trying to reset rule and status');
      }
    });
  };

  const onRulesCancel = () => {
    props.actGetRuleAndStatus({ tenantId });
  };

  const columns = columnConfig({
    onActiveChange,
    onScoreChange,
    onValueChange,
    translate
  });

  return (
    <TableWrapper>
      <Table
        columns={columns}
        rowKey={(data) => data.id}
        dataSource={rules}
        pagination={false}
        loading={loading}
        size="large"
      />
      <Popconfirm
        onConfirm={onRulesReset}
        title={translate('message.ruleResetConfirm')}
        okText={translate('yes')}
        cancelText={translate('no')}
      >
        <Button style={{ marginTop: '10px' }} type="danger" ghost>
          Reset
        </Button>
      </Popconfirm>
      <div className="rule-update">
        <Popconfirm
          onConfirm={onRulesCancel}
          title={translate('message.ruleUpdateCancelConfirm')}
          okText={translate('yes')}
          cancelText={translate('no')}
        >
          <Button>Cancel</Button>
        </Popconfirm>
        <Popconfirm
          onConfirm={() => onRulesSave(api_data)}
          title={translate('message.ruleUpdateConfirm')}
          okText={translate('yes')}
          cancelText={translate('no')}
        >
          <Button className="rule-update-button" type="primary">
            Update
          </Button>
        </Popconfirm>
      </div>
    </TableWrapper>
  );
};

export default connect(
  (state) => ({
    tenantId: state?.App?.user?.tenant?.id,
    rulesStatus: state?.Profile?.rulesStatus
  }),
  {
    actGetRuleAndStatus,
    actUpdateRuleAndStatus,
    actResetRuleAndStatus
  }
)(RulesTable);
