import { PURGE } from 'redux-persist';
import _ from 'lodash';
import moment from 'moment';
import {
  SET_SELECTED_ROTATION,
  SET_MODAL_ASSIGN_EMPLOYEE_OPEN,
  SAVE_GET_SKILL_VIEW,
  UPDATE_SKILL_VIEW,
  SAVE_DELETE_SHIFT_IN_SKILL_VIEW
} from '../type';

const initialState = {
  listData: {},
  modalVisible: false,
  selectedSkill: {},
  selectedRotation: null,
  selectedData: {
    shiftInfo: {},
    dateShiftInfo: ''
  },
  isModalAssignEmployeeOpen: false
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case SET_SELECTED_ROTATION:
      return {
        ...state,
        selectedRotation: payload
      };

    case SET_MODAL_ASSIGN_EMPLOYEE_OPEN:
      return {
        ...state,
        isModalAssignEmployeeOpen: !!payload,
        selectedData: {
          shiftInfo: payload?.shiftInfo || {},
          dateShiftInfo: payload?.dateShiftInfo || ''
        }
      };

    case SAVE_GET_SKILL_VIEW:
      let tempListData = payload.map((item) => ({
        ...item,
        shiftId: item?.id,
        date: moment(item?.start_date_time).format('YYYY-MM-DD')
      }));

      tempListData = _.sortBy(tempListData, ['date', 'rotation']);
      tempListData = _.groupBy(tempListData, 'date');

      return { ...state, listData: tempListData };

    case UPDATE_SKILL_VIEW: {
      const { shiftInfo, updateData } = payload;
      let tempListData = { ...state.listData };

      if (updateData?.shiftId) {
        if (!tempListData[updateData.date]) {
          tempListData[updateData.date] = [];
        }
        tempListData[updateData.date].push(updateData);
        return { ...state, listData: tempListData };
      }

      if (!_.isEmpty(shiftInfo)) {
        const dayData = tempListData[shiftInfo?.date];

        const indexOfShift = _.findIndex(dayData, {
          shiftId: shiftInfo?.shiftId
        });

        const newShiftData = {
          ...shiftInfo,
          ...updateData
        };

        dayData.splice(indexOfShift, 1, newShiftData);
        tempListData[shiftInfo?.date] = dayData;
      }

      return { ...state, listData: tempListData };
    }

    case SAVE_DELETE_SHIFT_IN_SKILL_VIEW: {
      const { shiftId, date } = payload;
      const tempListData = { ...state.listData };
      const dayData = tempListData[date];
      const indexOfShift = _.findIndex(dayData, { shiftId });

      dayData.splice(indexOfShift, 1);
      tempListData[date] = dayData;

      return { ...state, listData: tempListData };
    }

    default:
      return state;
  }
};

export default reducer;
