import React, { useEffect, useRef } from 'react';
import { Modal, Form, Input, message, InputNumber, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { connect } from 'react-redux';
import { useTranslation } from 'component/index';
import {
  actSetModalShiftTypeSettingVisible,
  actSetSelectedShiftTypeSetting,
  actCreateShiftType,
  actUpdateShiftTypeInfo
} from 'redux/action/shiftTypeSetting';

const ShiftTypeSettingModal = (props) => {
  const { modalVisible, selectedShiftTypeSetting, tenantId } = props;

  const firstInput = useRef(null);
  const [form] = Form.useForm();
  const { translate } = useTranslation();

  useEffect(() => {
    const { name, break_time, weight, hourly_rate } = selectedShiftTypeSetting;

    form.setFieldsValue({
      name,
      break_time: break_time ? break_time : 600,
      weight: weight ? weight : 0,
      hourly_rate: hourly_rate ? hourly_rate : 0
    });
  }, [selectedShiftTypeSetting]);

  useEffect(() => {
    if (modalVisible) {
      firstInput.current.focus();
    }
  }, [modalVisible]);

  const onHideModal = () => {
    props.actSetModalShiftTypeSettingVisible(false);
    props.actSetSelectedShiftTypeSetting({});
  };

  const onSubmitForm = async () => {
    try {
      const { name, break_time, weight, hourly_rate } =
        await form.validateFields([
          'name',
          'break_time',
          'weight',
          'hourly_rate'
        ]);

      var shiftTypeInfo = {
        name,
        break_time,
        weight,
        hourly_rate
      };

      if (!break_time) delete shiftTypeInfo['break_time'];

      if (_.isEmpty(selectedShiftTypeSetting)) {
        props.actCreateShiftType(
          {
            tenantId,
            body: { ...shiftTypeInfo, version: 0 }
          },
          (err) => {
            if (!err) {
              onHideModal();
              message.success(translate('shiftType.createSuccess'));
            } else {
              message.error(translate('shiftType.createFail'));
            }
          }
        );
      } else {
        props.actUpdateShiftTypeInfo(
          {
            tenantId,
            spotId: selectedShiftTypeSetting.id,
            body: shiftTypeInfo
          },
          (err) => {
            if (!err) {
              onHideModal();
              message.success(translate('shiftType.updateSuccess'));
            } else {
              message.error(translate('shiftType.updateFail'));
            }
          }
        );
      }
    } catch (err) {}
  };

  return (
    <Modal
      title={
        _.isEmpty(selectedShiftTypeSetting)
          ? translate('shiftType.createShiftType')
          : translate('shiftType.updateShiftType')
      }
      visible={modalVisible}
      cancelText={translate('cancel')}
      okText={
        _.isEmpty(selectedShiftTypeSetting)
          ? translate('create')
          : translate('update')
      }
      maskClosable={false}
      onOk={onSubmitForm}
      onCancel={onHideModal}
      forceRender={true}
      destroyOnClose={true}
      getContainer={false}
      style={{ top: '1rem' }}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          label={translate('shiftType.name')}
          rules={[
            {
              required: true,
              message: `${translate('shiftType.name')} ${translate(
                'isRequired'
              )}`
            }
          ]}
          name="name"
        >
          <Input ref={firstInput} />
        </Form.Item>

        <Form.Item
          label={translate('shiftType.breakTime')}
          rules={[{ required: false }]}
          name="break_time"
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          label={
            <>
              <span style={{ marginRight: 5 }}>
                {translate('shiftType.weight')}
              </span>
              <Tooltip title={translate('shiftType.weightTooltip')}>
                <InfoCircleOutlined />
              </Tooltip>
            </>
          }
          rules={[{ required: false }]}
          name="weight"
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item label={translate('shiftType.hourlyRate')} name="hourly_rate">
          <InputNumber style={{ width: '100%' }} addonAfter="¥" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default connect(
  (state) => ({
    selectedShiftTypeSetting: state.ShiftTypeSetting.selectedShiftTypeSetting,
    modalVisible: state.ShiftTypeSetting.modalVisible,
    tenantId: state?.App?.user?.tenant?.id
  }),
  {
    actSetModalShiftTypeSettingVisible,
    actSetSelectedShiftTypeSetting,
    actCreateShiftType,
    actUpdateShiftTypeInfo
  }
)(ShiftTypeSettingModal);
