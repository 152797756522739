import React, { useState } from 'react';
import { Input, Button } from 'antd';
import { connect } from 'react-redux';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import EmployeeReasonTable from 'component/EmployeeReason/EmployeeReasonTable';
import {
  actSearchEmployeeReason,
  actSetModalEmployeeReasonVisible,
  actSetSelectedEmployeeReason
} from 'redux/action/employeeReason';
import { SEARCH_TABLE_TIMEOUT } from 'utils/constant';
import { useTranslation } from 'component/index';
import { EmployeeReasonSettingPageWrapper } from './style';

let searchTimeout = null;

const EmployeeReasonSettingPage = (props) => {
  const { translate } = useTranslation();

  const [searchText, setSearchText] = useState(false);

  const onSearchChange = (event) => {
    const { value } = event.target;

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchText(value);

    searchTimeout = setTimeout(() => {
      onSearch(value);
    }, SEARCH_TABLE_TIMEOUT);
  };

  const onCreateEmployeeReason = () => {
    props.actSetModalEmployeeReasonVisible(true);
    props.actSetSelectedEmployeeReason({});
  };

  const onSearch = (searchValue) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    if (!!searchValue) {
      props.actSearchEmployeeReason({
        filterOr: {
          name: searchValue,
          kpi_count: searchValue,
          state: searchValue
        },
        sorter: {},
        filter: {}
      });
    } else {
      props.actSearchEmployeeReason({
        filterOr: {}
      });
    }
  };

  return (
    <EmployeeReasonSettingPageWrapper>
      <div className="header-wrapper">
        <div className="label">
          {translate('employeeReason.header.employeeReason')}
        </div>
        <div className="filter-wrapper">
          <div className="search-bar">
            <Input
              prefix={<SearchOutlined />}
              size="large"
              className="input-border"
              style={{ width: '25rem' }}
              placeholder={translate('searchByKeyword')}
              onChange={onSearchChange}
              searchText={searchText}
              onPressEnter={() => onSearch(searchText)}
            />
          </div>

          <Button
            type="primary"
            icon={<PlusOutlined />}
            className="custom-button"
            size="large"
            onClick={onCreateEmployeeReason}
          >
            {translate('employeeReason.addReason')}
          </Button>
        </div>
      </div>

      <div className="main-content">
        <EmployeeReasonTable />
      </div>
    </EmployeeReasonSettingPageWrapper>
  );
};

export default connect((state) => ({}), {
  actSetModalEmployeeReasonVisible,
  actSetSelectedEmployeeReason,
  actSearchEmployeeReason
})(EmployeeReasonSettingPage);
