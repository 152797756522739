import styled from 'styled-components';

const EmailStatusWrapper = styled.div`
  display: flex;
  align-items: center;

  .email_circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }

  .label {
    margin-left: 1rem;
  }
`;

export { EmailStatusWrapper };
