export const COLOR_GRAY = '#767a7c';
export const COLOR_BACKGROUND_GRAY = '#ebebeb';

export const NOT_SENT_EMAIL = 'not sent';
export const EMAIL_STATUS_COLOR = {
  delivered: '#bcd514',
  open: '#028690',
  click: '#59c1ca',
  opened: '#028690',
  clicked: '#59c1ca',
  bounce: '#e04427',
  not_delivered: '#6B6B6B',
  processed: '#246201'
};

export const DOMAIN = process.env.REACT_APP_DOMAIN;
export const ICONS_URL = process.env.REACT_APP_ICONS_URL;

export const ICON_CONFIG = [
  ICONS_URL + '/0_beaker.png',
  ICONS_URL + '/0_star.png',
  ICONS_URL + '/0_book.png',
  ICONS_URL + '/0_hospital.png',
  ICONS_URL + '/0_user-md-symbol.png',
  ICONS_URL + '/0_comment-black-oval-bubble-shape.png',
  ICONS_URL + '/0_plane.png',
  ICONS_URL + '/0_square-shape-shadow.png',
  ICONS_URL + '/state_0.png',
  ICONS_URL + '/state_1.png',
  ICONS_URL + '/state_2.png',
  ICONS_URL + '/state_3.png'
];

export const CHART_PALETTE_COLORS = [
  'rgb(214, 94, 148)',
  'rgb(75, 192, 192)',
  'rgb(53, 162, 235)',
  'rgb(255, 179, 0)',
  'rgb(97, 135, 110)',
  'rgb(158, 161, 212)',
  'rgb(24, 29, 49)',
  'rgb(127, 22, 127)',
  'rgb(218, 226, 182)',
  'rgb(173, 231, 146)'
];

export const LIST_REASON_STATE = [
  'Unavailable',
  'Undesired',
  'Desired',
  'Countable'
];

export const LIST_TAG_COLOR = [
  '#ffabe1',
  '#b088f9',
  '#825959',
  '#3c415c',
  '#fed049',
  '#9ddfd3'
];

export const MONTH_NAME = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const API_TIMEOUT = 15000;
export const SEARCH_TABLE_TIMEOUT = 300;

export const EMAIL_STATUS = {
  PREFERENCES: 'preferences',
  CONFIRM_PREFERENCES: 'confirm_preferences',
  ASSIGNMENTS: 'assignments',
  NOT_SENT: 'NOT_SENT'
};

export const GRID_VIEW_MODE = {
  ASSIGNMENTS: 'ASSIGNMENTS',
  SKILLS: 'SKILLS'
};

export const STATISTIC_TYPE = {
  NUMBER_OF_SHIFT: 'NUMBER_OF_SHIFT',
  NUMBER_OF_HOUR: 'NUMBER_OF_HOUR',
  NUMBER_OF_EMPLOYEE: 'NUMBER_OF_EMPLOYEE'
};

export const MONTH_STRING = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];
