import React, { useEffect, useState } from 'react';
import { Table, Input, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  actGetEmployeeSetting,
  actSearchEmployee
} from 'redux/action/employeeSetting';
import {
  EMAIL_STATUS_COLOR,
  NOT_SENT_EMAIL,
  EMAIL_STATUS
} from 'utils/constant';
import { convertTableFilter, convertTableSorter } from 'utils/helper';
import { useTranslation } from 'component/index';
import {
  actSetSelectedEmployeeEmail,
  actSetEmailStatus
} from 'redux/action/sendEmail';
import { EmailStatusWrapper } from './style';

export const statusEnum = [
  EMAIL_STATUS.PREFERENCES,
  EMAIL_STATUS.CONFIRM_PREFERENCES,
  EMAIL_STATUS.ASSIGNMENTS
];

const columns = (getColumnSearchProps, translate, emailStatusOption) => [
  {
    title: translate('sendEmail.name'),
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a > b,
    width: '20%',
    ...getColumnSearchProps('name')
  },
  {
    title: translate('sendEmail.contract'),
    dataIndex: 'contractName',
    key: 'contractName',
    sorter: (a, b) => a > b,
    width: '20%',
    ...getColumnSearchProps('contractName')
  },
  {
    title: translate('sendEmail.team'),
    dataIndex: 'team',
    key: 'team',
    sorter: (a, b) => a > b,
    width: '13%',
    ...getColumnSearchProps('team')
  },
  {
    title: translate('sendEmail.rank'),
    dataIndex: 'rank',
    key: 'rank',
    sorter: (a, b) => a > b,
    width: '13%',
    ...getColumnSearchProps('rank')
  },
  {
    title: translate('sendEmail.email'),
    dataIndex: 'email',
    key: 'email',
    width: '15%',
    sorter: (a, b) => a > b,
    ...getColumnSearchProps('email')
  },
  {
    title: translate('sendEmail.status'),
    dataIndex: 'emailStatus',
    key: 'emailStatus',
    filters: [
      ...emailStatusOption.map((item, index) => ({
        text: (
          <span key={index} style={{ display: 'flex', alignItems: 'center' }}>
            <span
              style={{
                width: '12px',
                height: '12px',
                borderRadius: '50%',
                backgroundColor:
                  item !== NOT_SENT_EMAIL ? EMAIL_STATUS_COLOR[item] : 'black',
                marginRight: '0.5rem'
              }}
            />
            <span>{item}</span>
          </span>
        ),
        value: item
      }))
    ],
    render: (emailStatus) => (
      <EmailStatusWrapper>
        <div
          style={{
            backgroundColor: emailStatus
              ? EMAIL_STATUS_COLOR[emailStatus]
              : 'black'
          }}
          className="email_circle"
        />
        <span className="label">{emailStatus || NOT_SENT_EMAIL}</span>
      </EmailStatusWrapper>
    )
  }
];

const EmployeeTable = (props) => {
  const {
    tenantId,
    listEmployee,
    listSelectedEmployee,
    emailStatus,
    listEmpData
  } = props;
  const { translate } = useTranslation();

  let searchInput = null;
  const [isLoading, setLoading] = useState(false);
  const [searchText, onSetSearchText] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10
  });

  useEffect(() => {
    setLoading(true);
    props.actGetEmployeeSetting({ tenantId }, (err, res) => {
      setLoading(false);
    });
  }, [tenantId]);

  useEffect(() => {
    const listEmpStatus = listSelectedEmployee.map((empId) => {
      const status = emailStatus.filter((item) => item?.employee?.id === empId);
      return status[0]?.mode || EMAIL_STATUS.NOT_SENT;
    });

    const listStatusIndex = [];
    listEmpStatus.forEach((item) => {
      listStatusIndex.push(statusEnum.indexOf(item));
    });

    const minIndex = _.min(listStatusIndex);

    props.actSetEmailStatus(
      minIndex === -1 || typeof minIndex === 'undefined'
        ? EMAIL_STATUS.NOT_SENT
        : statusEnum[minIndex]
    );
  }, [listSelectedEmployee, emailStatus]);

  const getColumnSearchProps = () => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={translate('typeAkeyWord')}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {translate('search')}
        </Button>

        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          {translate('reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => value.toString(),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text) => {
      return (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={searchText}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      );
    }
  });

  const handleSearch = (selectedKeys, confirm) => {
    onSetSearchText(selectedKeys);
    confirm();
  };

  const handleReset = (clearFilters) => {
    onSetSearchText([]);
    clearFilters();
  };

  const onShowTotalData = () => {
    return `${listEmployee.length} ${translate('table.rowInTotal')}`;
  };

  const onRowSelectionChange = (selectedKeys) => {
    props.actSetSelectedEmployeeEmail(selectedKeys);
  };

  const onSelectAllRow = (isSelect) => {
    if (isSelect) {
      props.actSetSelectedEmployeeEmail(listEmployee.map((emp) => emp.id));
    } else {
      props.actSetSelectedEmployeeEmail([]);
    }
  };

  const onTableChange = (pagination, filter, sorter) => {
    setPagination(pagination);
    const tableFilter = convertTableFilter(filter);
    const tableSorter = convertTableSorter(sorter);

    props.actSearchEmployee({
      filter: tableFilter,
      sorter: tableSorter,
      emailStatus,
      matchExact: ['emailStatus']
    });
  };

  const rowSelection = {
    selectedRowKeys: listSelectedEmployee,
    onChange: onRowSelectionChange,
    onSelectAll: onSelectAllRow
  };

  const dataSource = listEmployee.map((emp) => {
    const empEmailStatus = _.find(
      emailStatus,
      (obj) => obj?.employee?.id === emp.id && obj?.employee?.name === emp.name
    );

    return {
      ...emp,
      emailStatus: empEmailStatus?.email_status
    };
  });

  const emailStatusOption = [];
  emailStatus.forEach((item) => {
    if (
      item.email_status &&
      _.find(
        listEmpData,
        (emp) =>
          emp.id === item?.employee?.id && emp.name === item?.employee?.name
      ) &&
      !emailStatusOption.includes(item.email_status)
    ) {
      emailStatusOption.push(item.email_status);
    }
  });
  emailStatusOption.push(NOT_SENT_EMAIL);

  return (
    <Table
      rowSelection={rowSelection}
      rowKey={(data) => data.id}
      columns={columns(getColumnSearchProps, translate, emailStatusOption)}
      dataSource={dataSource}
      pagination={{
        ...pagination,
        showTotal: onShowTotalData,
        pageSizeOptions: [10, 20, 30, 40],
        showSizeChanger: true
      }}
      size="large"
      rowClassName="editable-row"
      loading={isLoading}
      onChange={onTableChange}
    />
  );
};

export default connect(
  (state) => ({
    tenantId: state?.App?.user?.tenant?.id,
    listEmployee: state.EmployeeSetting.listEmployee,
    listEmpData: state.EmployeeSetting.listData,
    listSelectedEmployee: state.SendEmail.listSelectedEmployee,
    emailStatus: state.Grid.emailStatus
  }),
  {
    actGetEmployeeSetting,
    actSetSelectedEmployeeEmail,
    actSearchEmployee,
    actSetEmailStatus
  }
)(EmployeeTable);
