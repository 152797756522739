import { useEffect, createRef, useContext, useMemo } from 'react';
import FullCalendar from '@fullcalendar/react';
import moment from 'moment';
import _ from 'lodash';
import Holidays from 'date-holidays';
import dayGridPlugin from '@fullcalendar/daygrid';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { connect } from 'react-redux';

import { actGetUserAvailability } from 'redux/action/grid';
import { actGetEmployeeReasonSetting } from 'redux/action/employeeReason';
import { actGetShiftTypeSetting } from 'redux/action/shiftTypeSetting';
import { useParams } from 'react-router-dom';
import { actSetModalPreferenceVisible } from 'redux/action/preference';
import { actGetRotationSetting } from 'redux/action/rotationSetting';
import { actGetEmployeeShifts } from 'redux/action/employeeSetting';
import { LanguageContext } from 'languages/index';
import { COLOR_GRAY, COLOR_BACKGROUND_GRAY } from 'utils/constant';
import TimeItem from './TimeItem';
import ModalUpdateAvail from './ModalUpdateAvail';
import { PreferenceGridWrapper, CustomCellWrapper } from './index.style';
import SelectAvail from './SelectAvail/index';
import { getListRotationForDay, isCellDisable } from './util';

const hd = new Holidays('JP');

const PreferenceGrid = (props) => {
  const {
    tenantId,
    listAvailability,
    listEmployee,
    selectedMonth,
    employeeId,
    employeeShifts,
    editable,
    hashTime,
    tenantPreferences,
    tenantGlobalTime,
    listRotation,
    listEmployeeReason
  } = props;

  const { locale } = useContext(LanguageContext);
  const calendarRef = createRef();
  const { hashCode } = useParams();

  const globalTime = hashTime || tenantGlobalTime;

  const listHoliday = useMemo(() => {
    const year = moment(+globalTime).year();
    return hd
      .getHolidays(year)
      ?.map((item) => moment(item?.date)?.format('YYYY-MM-DD'));
  }, [globalTime]);

  useEffect(() => {
    props.actGetEmployeeReasonSetting({ tenantId });

    props.actGetRotationSetting({
      tenantId,
      year: moment(+globalTime).year(),
      month: moment(+globalTime).month() + 1
    });

    props.actGetShiftTypeSetting({ tenantId });
  }, [tenantId, globalTime]);

  // for private, admin view
  useEffect(() => {
    if (globalTime) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(moment(+globalTime).format());
    }
  }, [globalTime, calendarRef]);

  // for public view
  useEffect(() => {
    if (selectedMonth) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(selectedMonth.format());
    }
  }, [selectedMonth, calendarRef]);

  useEffect(() => {
    if ((listEmployee.length > 0 || hashCode) && !!employeeId && !!globalTime) {
      props.actGetUserAvailability(
        {
          tenantId,
          employeeId: +employeeId || listEmployee[0].id,
          month: moment(+globalTime).month() + 1,
          year: moment(+globalTime).year()
        },
        (err, res) => {}
      );

      props.actGetEmployeeShifts({
        employeeId,
        tenantId,
        month: moment(+globalTime).month() + 1,
        year: moment(+globalTime).year()
      });
    }
  }, [employeeId, listEmployee, globalTime, hashCode, tenantId]);

  const renderListAvail = (listAvail = [], start, isDisable) => {
    return listAvail.map((item, index) => {
      const {
        endHour,
        startHour,
        rotation,
        message,
        start_date_time,
        end_date_time
      } = item;

      const shiftTeamName = _.find(employeeShifts, {
        start_date_time,
        end_date_time
      })?.spotName;

      return (
        <TimeItem
          key={index}
          label={`${startHour} - ${endHour}`}
          itemBorderColor={COLOR_GRAY}
          itemBackgroundColor={COLOR_BACKGROUND_GRAY}
          availInfo={{ ...item, start }}
          message={message}
          shiftTeamName={shiftTeamName}
          editable={editable}
          isDisable={isDisable}
          employeeId={employeeId}
          rotation={rotation}
        />
      );
    });
  };

  const renderEventContent = (args) => {
    const { listAvail, start } = args.event.extendedProps;
    const isDisable = isCellDisable(start, tenantPreferences);

    const listRotationForDay = getListRotationForDay(
      listAvail,
      start,
      listRotation,
      listEmployeeReason
    );

    const MAX_ITEM_PER_CELL = 3;
    if (listRotationForDay && listRotationForDay.length > MAX_ITEM_PER_CELL) {
      return (
        <Scrollbars style={{ height: '180px', width: '95%' }}>
          <div style={{ width: '100%' }}>
            {renderListAvail(listRotationForDay, start, isDisable)}
          </div>
        </Scrollbars>
      );
    }

    return (
      <div style={{ width: '95%' }}>
        {renderListAvail(listRotationForDay, start, isDisable)}
      </div>
    );
  };

  const renderCustomCell = (args) => {
    return (
      <CustomCellWrapper>
        {renderDayCellContent(args)}
        {renderEventContent(args)}
      </CustomCellWrapper>
    );
  };

  const renderDayCellContent = (args) => {
    const { start, listAvail } = args.event.extendedProps;
    const date = moment(start).format('Do');

    const isDisable = !editable || isCellDisable(start, tenantPreferences);
    const listRotationForDay = getListRotationForDay(
      listAvail,
      start,
      listRotation,
      listEmployeeReason
    );
    const isCellHasRotation = listRotationForDay?.length > 0;

    // @selectedEmpReason is for the whole day, all Employee Reason in that day must has the same id
    let selectedEmpReason = listAvail?.[0]?.employee_reason || null;
    if (selectedEmpReason !== null) {
      for (let i = 0; i < listAvail?.length; i++) {
        if (selectedEmpReason !== listAvail[i]?.employee_reason) {
          selectedEmpReason = null;
          break;
        }
      }
    }

    return (
      <div className="day-cell">
        <span>{date}</span>

        {!isDisable && isCellHasRotation ? (
          <SelectAvail
            selectedEmpReason={selectedEmpReason}
            listAvail={listAvail}
            start={start}
          />
        ) : null}
      </div>
    );
  };

  const getDayCellClassName = ({ date }) => {
    const dayInWeek = moment(date).day();

    if (isCellDisable(date, tenantPreferences)) {
      return 'gray-cell';
    }

    let classNames = [];
    const SUNDAY = 0;
    const SATURDAY = 6;
    const WEEKENDS = [SATURDAY, SUNDAY];
    if (WEEKENDS.includes(dayInWeek)) {
      classNames.push('light-gray-cell');
    }

    if (listHoliday?.includes(moment(date)?.format('YYYY-MM-DD'))) {
      classNames.push('holiday');
    }

    return classNames.join(' ');
  };

  return (
    <PreferenceGridWrapper>
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        events={listAvailability.map((item) => ({
          start: item.start,
          extendedProps: {
            listAvail: item.listAvail,
            start: item.start
          }
        }))}
        eventContent={renderCustomCell}
        ref={calendarRef}
        locale={locale === 'en' ? 'en' : 'ja'}
        dayCellContent={null} // hide default date on the top right
        dayCellClassNames={getDayCellClassName}
      />

      <ModalUpdateAvail />
    </PreferenceGridWrapper>
  );
};

PreferenceGrid.defaultProps = {
  editable: true,
  hashTime: null
};

export default connect(
  (state) => ({
    tenantId: state?.App?.user?.tenant?.id,
    listAvailability: state.Preference.listAvailability,
    listWeek: state.Preference.listWeek,
    listEmployee: state.EmployeeSetting.listEmployee,
    selectedMonth: state.Preference.selectedMonth,
    employeeShifts: state.Preference.employeeShifts,
    tenantGlobalTime: state?.App?.globalTime,
    tenantPreferences: state?.App?.tenant?.preferences,
    listRotation: state.RotationSetting.listRotation,
    listEmployeeReason: state.EmployeeReason.listEmployeeReason
  }),
  {
    actGetUserAvailability,
    actSetModalPreferenceVisible,
    actGetRotationSetting,
    actGetEmployeeReasonSetting,
    actGetEmployeeShifts,
    actGetShiftTypeSetting
  }
)(PreferenceGrid);
