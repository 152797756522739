import { Row, Col } from 'antd';
import Slider from 'react-slick';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'component/index';
import { TestimonialSectionWrapper } from './style';
import SlideItem from './SlideItem';

const CustomNextArrow = (props) => {
  const { onClick } = props;

  return (
    <RightOutlined
      style={{
        color: 'var(--color-text-gray)',
        position: 'absolute',
        right: '-2rem',
        top: '50%'
      }}
      onClick={onClick}
    />
  );
};

const CustomPrevArrow = (props) => {
  const { onClick } = props;

  return (
    <LeftOutlined
      style={{
        color: 'var(--color-text-gray)',
        position: 'absolute',
        left: '-2rem',
        top: '50%'
      }}
      onClick={onClick}
    />
  );
};

const TestimonialSection = () => {
  const { translate } = useTranslation();

  const listSlideItem = [
    {
      name: translate('homepage.testimonial1.name'),
      position: `${translate('homepage.testimonial1.position')} @${translate(
        'homepage.testimonial1.workplace'
      )}`,
      avatar:
        'https://user-images.githubusercontent.com/6435477/134832724-f3d8dc81-092a-44a1-8f21-03509f794530.png',
      comment: translate('homepage.testimonial1.comment')
    },
    {
      name: translate('homepage.testimonial2.name'),
      position: `${translate('homepage.testimonial2.position')} @${translate(
        'homepage.testimonial2.workplace'
      )}`,
      avatar:
        'https://user-images.githubusercontent.com/6435477/134833225-90a0a3c0-e759-4fe5-9c77-a84fcb87d6f3.png',
      comment: translate('homepage.testimonial2.comment')
    },
    {
      name: translate('homepage.testimonial3.name'),
      position: `${translate('homepage.testimonial3.position')} @${translate(
        'homepage.testimonial3.workplace'
      )}`,
      avatar:
        'https://camo.githubusercontent.com/fb70c8e7c2a15eb4d00d16a57230c0e45c916cee4b08a5a45503472652d0092f/68747470733a2f2f7777772e6d617031322e6a702f696d616765732f696e666f5f70696330322e6a7067',
      comment: translate('homepage.testimonial3.comment')
    },
    {
      name: translate('homepage.testimonial4.name'),
      position: `${translate('homepage.testimonial4.position')}`,
      avatar: 'http://www.marianna-u.ac.jp/hospital/images/common/logo.gif',
      comment: translate('homepage.testimonial4.comment')
    }
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />
  };

  return (
    <TestimonialSectionWrapper>
      <Row type="flex" justify="center">
        <Col xxl={18} xl={20} lg={23} md={24} sm={24} xs={24}>
          <div className="main">
            <Slider {...settings} className="slider">
              {listSlideItem.map((item, index) => (
                <SlideItem key={index} {...item} isLargeImage={index === 3} />
              ))}
            </Slider>
          </div>
        </Col>
      </Row>
    </TestimonialSectionWrapper>
  );
};

export default TestimonialSection;
