import bigLogo from 'assets/big-logo.png';
import smallLogo from 'assets/small-logo.png';
import { Layout, Menu, Drawer } from 'antd';
import { connect } from 'react-redux';
import { useNavigate as useHistory, useLocation } from 'react-router-dom';
import {
  AimOutlined,
  SettingOutlined,
  MailOutlined,
  StarOutlined,
  HomeOutlined
} from '@ant-design/icons';
import { useTranslation } from 'component/index';
import { siderWidth, siderCollapseWidth } from './index';

const { Sider } = Layout;
const { SubMenu } = Menu;

// NOTE: @path must be the same as @key
const menuConfig = [
  {
    label: 'sidebar.dashboard',
    path: '/dashboard',
    icon: () => <HomeOutlined />,
    key: '/dashboard',
    type: 'single-menu'
  },
  {
    label: 'sidebar.assignments',
    path: '/assignments',
    icon: () => <AimOutlined />,
    key: '/assignments',
    type: 'single-menu'
  },
  {
    label: 'sidebar.preferences',
    path: '/preferences',
    icon: () => <StarOutlined />,
    key: '/preferences',
    type: 'single-menu'
  },
  {
    label: 'sidebar.sendMail',
    path: '/send-email',
    icon: () => <MailOutlined />,
    key: '/send-email',
    type: 'single-menu'
  },
  {
    label: 'sidebar.settings',
    icon: () => <SettingOutlined />,
    key: '/settings',
    type: 'sub-menu',
    children: [
      {
        label: 'sidebar.contracts',
        path: '/settings/contracts',
        key: '/settings/contracts'
      },
      {
        label: 'sidebar.skills',
        path: '/settings/skills',
        key: '/settings/skills'
      },
      {
        label: 'sidebar.shiftTypes',
        path: '/settings/shift-types',
        key: '/settings/shift-types'
      },
      {
        label: 'sidebar.shiftGroups',
        path: '/settings/shift-groups',
        key: '/settings/shift-groups'
      },
      {
        label: 'sidebar.employees',
        path: '/settings/employees',
        key: '/settings/employees'
      },
      {
        label: 'sidebar.employeeReason',
        path: '/settings/employee-reason',
        key: '/settings/employee-reason'
      },
      {
        label: 'sidebar.rotation',
        path: '/settings/rotation',
        key: '/settings/rotation'
      }
    ]
  }
];

const subMenuConfig = [
  {
    subMenuKey: '/settings',
    childKey: [
      '/settings/contracts',
      '/settings/skills',
      '/settings/shift-types',
      '/settings/shift-groups',
      '/settings/employees',
      '/settings/employee-reason',
      '/settings/rotation',
      '/settings/advanced'
    ]
  }
];

const nestedMenuConfig = [
  {
    menuKey: '/group-test',
    childKey: ['/group-test', '/test']
  }
];

const Sidebar = (props) => {
  const { isMobileSize, isSidebarClose, isShowDrawer, setIsShowDrawer } = props;
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const { translate } = useTranslation();

  const onCloseDrawer = () => {
    setIsShowDrawer(false);
  };

  const onRenderMenu = () => {
    return menuConfig.map((menuItem) => {
      const { icon: MenuIcon } = menuItem;

      if (menuItem.type === 'single-menu') {
        return (
          <Menu.Item
            key={menuItem.key}
            onClick={() => onClickMenu(menuItem.path)}
            className={
              !isMobileSize && isSidebarClose
                ? 'side-menu-item side-menu-item--collapsed'
                : 'side-menu-item'
            }
          >
            <MenuIcon />

            <span
              className={
                !isMobileSize && isSidebarClose
                  ? 'menu-label--collapse'
                  : 'menu-label'
              }
            >
              {translate(menuItem.label)}
            </span>
          </Menu.Item>
        );
      }

      return (
        <SubMenu
          style={{ color: 'white' }}
          key={menuItem.key}
          title={
            <span>
              <span
                className={
                  !isMobileSize && isSidebarClose
                    ? 'menu-icon--collapsed'
                    : 'menu-icon'
                }
              >
                <MenuIcon />
              </span>

              <span
                className={
                  !isMobileSize && isSidebarClose
                    ? 'menu-label--collapse'
                    : 'menu-label'
                }
              >
                {`${translate(menuItem.label)}`}
              </span>
            </span>
          }
        >
          {menuItem.children.map((childMenu) => (
            <Menu.Item
              key={childMenu.key}
              onClick={() => onClickMenu(childMenu.path)}
              className="side-menu-item"
            >
              {translate(childMenu.label)}
            </Menu.Item>
          ))}
        </SubMenu>
      );
    });
  };

  const onGetActiveNestedMenuKey = () => {
    let subMenuActiveKey = null;
    let foundNestedKey = false;
    let isPathnameInConfig = false;

    nestedMenuConfig.forEach((subMenu) => {
      if (subMenu.childKey.includes(pathname) && !foundNestedKey) {
        subMenuActiveKey = subMenu.menuKey;
        foundNestedKey = true;
        isPathnameInConfig = true;
      }
    });

    return isPathnameInConfig ? subMenuActiveKey : pathname;
  };

  const onGetActiveSubMenuKey = () => {
    let subMenuActiveKey = null;
    let foundSubmenu = false;

    subMenuConfig.forEach((subMenu) => {
      if (subMenu.childKey.includes(pathname) && !foundSubmenu) {
        subMenuActiveKey = subMenu.subMenuKey;
        foundSubmenu = true;
      }
    });

    return subMenuActiveKey;
  };

  const onClickMenu = (path) => {
    // history.push(path);
    history(path);
  };

  const SidebarMenu = () => (
    <Menu
      theme="dark"
      defaultSelectedKeys={[onGetActiveNestedMenuKey()]}
      defaultOpenKeys={[onGetActiveSubMenuKey()]}
      mode="inline"
    >
      {onRenderMenu()}
    </Menu>
  );

  return isMobileSize ? (
    <Drawer
      visible={isShowDrawer}
      onClose={onCloseDrawer}
      placement="left"
      width={siderWidth}
    >
      <Sider
        width={siderWidth}
        className={isMobileSize ? 'sider-mobile' : 'sider-normal'}
      >
        <div
          className={
            !isMobileSize && isSidebarClose
              ? 'sider-logo--collapsed'
              : 'sider-logo'
          }
        >
          <img
            src={!isMobileSize && !isSidebarClose ? bigLogo : smallLogo}
            alt="logo"
          />
        </div>

        <SidebarMenu />
      </Sider>
    </Drawer>
  ) : (
    <Sider
      collapsed={isSidebarClose}
      breakpoint="xl"
      collapsedWidth={isMobileSize ? 0 : siderCollapseWidth}
      width={siderWidth}
      className={isMobileSize ? 'sider--mobile' : 'sider--normal'}
    >
      <div
        className={
          !isMobileSize && isSidebarClose
            ? 'sider-logo--collapsed'
            : 'sider-logo'
        }
      >
        <img
          src={!isMobileSize && !isSidebarClose ? bigLogo : smallLogo}
          alt="logo"
        />
      </div>

      <SidebarMenu />
    </Sider>
  );
};

export default connect((state) => ({
  isSidebarClose: state.App.isSidebarClose
}))(Sidebar);
