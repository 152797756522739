import { Row, Col } from 'antd';
import { FeatureSectionWrapper } from './style';
import FeatureItem from './FeatureItem';
import aiIcon from 'assets/ai-icon.svg';
import automationIcon from 'assets/automation-icon.svg';
import doctorIcon from 'assets/doctor-icon.svg';
import { useTranslation } from 'component/index';

const listFeature = (translate) => [
  {
    title: translate('homepage.features.feature1'),
    description: translate('homepage.features.description1'),
    icon: aiIcon
  },
  {
    title: translate('homepage.features.feature2'),
    description: translate('homepage.features.description2'),
    icon: automationIcon
  },
  {
    title: translate('homepage.features.feature3'),
    description: translate('homepage.features.description3'),
    icon: doctorIcon
  }
];

const FeatureSection = () => {
  const { translate } = useTranslation();

  return (
    <FeatureSectionWrapper>
      <Row type="flex" justify="center">
        <Col xxl={18} xl={20} lg={23} md={24} sm={24} xs={24}>
          <div className="main" id="feature">
            <h2 className="heading">{translate('homepage.features')}</h2>

            <div className="list-feature">
              {listFeature(translate).map((feature, index) => (
                <FeatureItem feature={feature} key={index} />
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </FeatureSectionWrapper>
  );
};

export default FeatureSection;
