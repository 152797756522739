import { PURGE } from 'redux-persist';
import _ from 'lodash';
import {
  SAVE_GET_LIST_ROTATION_TAB,
  SAVE_CREATE_ROTATION_TAB,
  SAVE_UPDATE_ROTATION_TAB,
  SAVE_DELETE_ROTATION_TAB
} from 'redux/type';
import { update } from './helper';

const initialState = {
  listRotationTab: []
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case SAVE_GET_LIST_ROTATION_TAB:
      return {
        ...state,
        listRotationTab: payload
      };

    case SAVE_CREATE_ROTATION_TAB:
      return {
        ...state,
        listRotationTab: [payload, ...state.listRotationTab]
      };

    case SAVE_UPDATE_ROTATION_TAB:
      return update(state, {
        listRotationTab: {
          $updateOrDelete: [payload.id, payload]
        }
      });

    case SAVE_DELETE_ROTATION_TAB:
      return update(state, {
        listRotationTab: { $updateOrDelete: [payload.id] }
      });

    default:
      return state;
  }
};

export default reducer;
