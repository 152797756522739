import React from 'react';
import { Button } from 'antd';
import successStatus from 'assets/check.svg';
import { connect } from 'react-redux';
import { actChangeWizardStatus } from 'redux/action/wizard';
import { useTranslation } from 'component';

const Finish = (props) => {
  const { translate } = useTranslation();

  const submitWizard = () => {
    props.actChangeWizardStatus(true);
  };

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '35px'
      }}
    >
      <img
        style={{ width: '13rem', height: '13rem' }}
        src={successStatus}
        alt=""
      />
      <p
        style={{
          fontWeight: '600',
          fontSize: '2rem',
          fontFamily: 'var(--font-family)'
        }}
      >
        {translate('wizard.sucessMsg')}
      </p>
      <Button type="primary" style={{ width: '30%' }} onClick={submitWizard}>
        {translate('wizard.done')}
      </Button>
    </div>
  );
};

export default connect((state) => ({}), {
  actChangeWizardStatus
})(Finish);
