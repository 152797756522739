import styled from 'styled-components';

const EditableCellWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: absolute;
  border-left: 1px solid var(--color-border-gray);
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  padding: 0.5rem;

  .avail-selection {
    margin-bottom: 0.7rem;
    padding: 2px 8px;
    font-size: 1.4rem;
  }

  &:hover .avail-selection {
    border: 1px solid #1890ff;
  }

  .drag-item--celled {
    padding: 2px 8px;
    font-size: 1.4rem;
    display: block;
    width: 99%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const CustomHeaderGroupWrapper = styled.div`
  display: flex;

  .collapse-icon {
    display: flex;
    align-items: center;
    margin-left: 1rem;
    font-size: 2rem;
    cursor: pointer;
  }

  .customExpandButton {
    margin-top: 2px;
    margin-left: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .expanded {
    animation-name: toExpanded;
    animation-duration: 1s;
    -ms-transform: rotate(180deg); /* IE 9 */
    -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
    transform: rotate(180deg);
  }

  .collapsed {
    color: cornflowerblue;
    animation-name: toCollapsed;
    animation-duration: 1s;
    -ms-transform: rotate(0deg); /* IE 9 */
    -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
    transform: rotate(0deg);
  }

  .customHeaderMenuButton,
  .customHeaderLabel,
  .customHeaderLabel,
  .customSortDownLabel,
  .customSortUpLabel,
  .customSortRemoveLabel {
    margin-top: 2px;
    margin-left: 4px;
    float: left;
  }

  .customSortDownLabel {
    margin-left: 10px;
  }

  .customSortUpLabel {
    margin-left: 1px;
  }

  .customSortRemoveLabel {
    float: left;
    font-size: 11px;
  }

  .active {
    color: cornflowerblue;
  }

  @keyframes toExpanded {
    from {
      color: cornflowerblue;
      -ms-transform: rotate(0deg); /* IE 9 */
      -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
      transform: rotate(0deg);
    }
    to {
      color: black;
      -ms-transform: rotate(180deg); /* IE 9 */
      -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
      transform: rotate(180deg);
    }
  }

  @keyframes toCollapsed {
    from {
      color: black;
      -ms-transform: rotate(180deg); /* IE 9 */
      -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
      transform: rotate(180deg);
    }
    to {
      color: cornflowerblue;
      -ms-transform: rotate(0deg); /* IE 9 */
      -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
      transform: rotate(0deg);
    }
  }
`;

const EmployeeHeaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & a {
    color: inherit;
  }

  .rank {
    font-weight: 300;
    font-size: 1.2rem;
    margin-top: 0.5rem;
    color: var(--color-secondary);
  }

  .email_delivery_cont {
    display: flex;
    padding: 3px;
    width: 100%;
  }

  .email_circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 3px;
  }
`;

const StatisticByRankWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;

  .statistic-item {
    display: flex;

    &:not(:last-of-type) {
      margin-bottom: 1rem;
    }

    .rank-name {
    }

    .number {
      margin-left: 1rem;
      font-weight: 600;
    }
  }
`;

export {
  EditableCellWrapper,
  CustomHeaderGroupWrapper,
  EmployeeHeaderWrapper,
  StatisticByRankWrapper
};
