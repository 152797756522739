import styled from 'styled-components';

const UserProfilePageWrapper = styled.div`
  .header-wrapper {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid var(--color-border-gray);

    .label {
      color: var(--color-text);
      text-transform: uppercase;
      font-size: 1.3rem;
      font-weight: 500;
    }
  }
`;

const DocumentSettingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: scroll;
  border: 1px solid var(--color-border-gray);
  width: 90rem;
  height: 60rem;
`;

const ZoomWrapper = styled.div`
  display: flex;
  background-color: var(--color-primary);
  transition: all 0.1s ease-in-out;
  height: 100%;
  align-self: stretch;

  .zoom-in,
  .zoom-out {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem 1.2rem;
    cursor: pointer;
    font-size: 1.8rem;
    border-radius: 4px;
    color: white;

    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
`;

export { UserProfilePageWrapper, DocumentSettingWrapper, ZoomWrapper };
