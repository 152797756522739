import React, { useState } from 'react';
import { Input, Button, Form, Switch } from 'antd';
import { connect } from 'react-redux';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import EmployeeTable from 'component/EmployeeSetting/EmployeeTable';
import {
  actSearchEmployee,
  actSetModalEmployeeSettingVisible,
  actSetSelectedEmployeeSetting,
  actGetEmployeeSetting
} from 'redux/action/employeeSetting';
import { SEARCH_TABLE_TIMEOUT } from 'utils/constant';
import { useTranslation } from 'component/index';
import { EmployeeSettingPageWrapper } from './index.style';

let searchTimeout = null;

const EmployeeSettingPage = (props) => {
  const { tenantId } = props;
  const [searchText, setSearchText] = useState(false);
  const [activeChecked, setActiveChecked] = useState(false);
  const { translate } = useTranslation();

  const onSearchChange = (event) => {
    const { value } = event.target;

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchText(value);

    searchTimeout = setTimeout(() => {
      onSearch(value);
    }, SEARCH_TABLE_TIMEOUT);
  };

  const toggleActiveEmployees = () => {
    props.actGetEmployeeSetting({
      tenantId,
      employeeStatus: activeChecked ? 'active' : 'all'
    });
    setActiveChecked(!activeChecked);
  };

  const onCreateEmployeeSetting = () => {
    props.actSetModalEmployeeSettingVisible(true);
    props.actSetSelectedEmployeeSetting({});
  };

  const onSearch = (searchValue) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    if (!!searchValue) {
      props.actSearchEmployee({
        filterOr: {
          name: searchValue,
          contractName: searchValue,
          team: searchValue,
          email: searchValue
        },
        sorter: {},
        filter: {}
      });
    } else {
      props.actSearchEmployee({
        filterOr: {}
      });
    }
  };

  return (
    <EmployeeSettingPageWrapper>
      <div className="header-wrapper">
        <div className="label">
          {translate('employeeSetting.header.employees')}
        </div>
        <div className="filter-wrapper">
          <Form.Item label={translate('employeeSetting.show_inactive')}>
            <Switch
              checkedChildren={translate('employeeSetting.yes')}
              unCheckedChildren={translate('employeeSetting.no')}
              checked={activeChecked}
              onChange={toggleActiveEmployees}
            />
          </Form.Item>
          <div className="search-bar">
            <Input
              prefix={<SearchOutlined />}
              size="large"
              className="input-border"
              style={{ width: '25rem' }}
              placeholder={translate('searchByKeyword')}
              onChange={onSearchChange}
              searchText={searchText}
              onPressEnter={() => onSearch(searchText)}
            />
          </div>

          <Button
            type="primary"
            icon={<PlusOutlined />}
            className="custom-button"
            size="large"
            onClick={onCreateEmployeeSetting}
          >
            {translate('employeeSetting.addEmployee')}
          </Button>
        </div>
      </div>

      <div className="main-content">
        <EmployeeTable toggleStatus={activeChecked} />
      </div>
    </EmployeeSettingPageWrapper>
  );
};

export default connect(
  (state) => ({
    tenantId: state?.App?.user?.tenant?.id
  }),
  {
    actSearchEmployee,
    actSetModalEmployeeSettingVisible,
    actSetSelectedEmployeeSetting,
    actGetEmployeeSetting
  }
)(EmployeeSettingPage);
