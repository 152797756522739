import { Fragment } from 'react';
import { Tag } from 'antd';
import _ from 'lodash';
import moment from 'moment';

const MAX_SKILL_SETS = 3;

// shouldTruncate = true means only display skill on a single row.
const renderSkillSetTags = (
  listSkillSet,
  listSkillInfo,
  shouldTruncate = false
) => {
  const requiredTruncate =
    shouldTruncate && listSkillSet.length > MAX_SKILL_SETS;

  const displaySkillSet = requiredTruncate
    ? _.slice(listSkillSet, 0, MAX_SKILL_SETS)
    : listSkillSet;

  const renderedElem = _.map(displaySkillSet, (skillSet, indexOfSet) => {
    return (
      <span style={{ marginTop: '0.7rem' }} key={indexOfSet}>
        {_.map(skillSet, (skillId, index) => {
          const foundedSkill = _.find(listSkillInfo, { id: skillId }) || {};
          const shouldParen = skillSet.length > 1 && displaySkillSet.length > 1;

          return (
            <Fragment key={index}>
              {index === 0 && shouldParen && (
                <span style={{ marginRight: '0.8rem' }}>(</span>
              )}

              <Tag color="purple" key={index}>
                {foundedSkill?.name}
              </Tag>

              {index < skillSet.length - 1 && (
                <span style={{ marginRight: '0.8rem' }}>&</span>
              )}

              {index === skillSet.length - 1 && shouldParen && <span>)</span>}
            </Fragment>
          );
        })}

        {indexOfSet < listSkillSet.length - 1 ? (
          <span style={{ margin: '0.8rem' }}>|</span>
        ) : null}
      </span>
    );
  });

  return requiredTruncate ? <>{renderedElem} ...</> : renderedElem;
};

/**
 * Get Rotation time combined with date such that startTime is before endTime.
 * @param {Date} date
 * @param {string} startTime format HH:mm
 * @param {string} endTime format HH:mm
 * @returns {array} [rotationStartTime, rotationEndTime]
 */
const getRotationTime = (date, startTime, endTime) => {
  const rotationStartTime = moment(`${date}T${startTime}`);
  let rotationEndTime = moment(`${date}T${endTime}`);

  // if endTime < startTime, endTime shoud be in the next day.
  if (rotationEndTime < rotationStartTime) {
    rotationEndTime.add(1, 'day');
  }
  return [rotationStartTime, rotationEndTime];
};

export { getRotationTime, renderSkillSetTags };
