import { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import {
  Modal,
  Row,
  Col,
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  message as mess,
  Tag
} from 'antd';
import {
  actSetSelectedPreferenceAvail,
  actUpdatePreferenceAvail,
  actCreatePreferenceAvail,
  actSetModalPreferenceVisible,
  actDeletePreferenceAvail,
  actSaveOnDeleteEmployeePreference,
  actAfterDeletePreferenceAvail
} from 'redux/action/preference';
import { actGetShiftTypeSetting } from 'redux/action/shiftTypeSetting';
import { useTranslation } from 'component/index';
import { LIST_TAG_COLOR } from 'utils/constant';

const FormItem = Form.Item;
const { TextArea } = Input;
const { Option } = Select;

const ModalUpdateAvail = (props) => {
  const {
    isModalVisible,
    selectedAvail,
    tenantId,
    listEmployeeReason,
    selectedEmployee,
    listRotation,
    default_preference,
    listShiftType
  } = props;

  const [form] = Form.useForm();
  const location = useLocation();
  const { translate } = useTranslation();

  useEffect(() => {
    props.actGetShiftTypeSetting({ tenantId });
  }, [tenantId]);

  const isCreateMode = useMemo(() => {
    return !selectedAvail?.availId;
  }, [selectedAvail]);

  useEffect(() => {
    if (isModalVisible) {
      const foundedRotation = _.find(listRotation, {
        start_time: selectedAvail?.startHour,
        end_time: selectedAvail?.endHour
      });

      form.setFieldsValue({
        rotationId: foundedRotation?.id,
        employee_reason: +selectedAvail?.employee_reason || null,
        message: selectedAvail.message,
        date: selectedAvail.date ? moment(selectedAvail.date) : moment(),
        spots: selectedAvail?.spots || []
      });
    }
  }, [isModalVisible, listRotation, selectedAvail]);

  const onCloseModal = () => {
    props.actSetModalPreferenceVisible(false);
    props.actSetSelectedPreferenceAvail({});
  };

  const onDeleteAvailability = (shouldCloseModal) => {
    if (selectedAvail?.availId) {
      props.actDeletePreferenceAvail(
        { tenantId, availId: selectedAvail.availId },
        (err, res) => {
          if (!err) {
            props.actSaveOnDeleteEmployeePreference({
              availId: selectedAvail.availId
            });
            props.actAfterDeletePreferenceAvail(selectedAvail);
            shouldCloseModal && onCloseModal();
            mess.success(translate('preference.deleteSucess'));
          } else {
            mess.error(translate('preference.deleteFail'));
          }
        }
      );
    }
  };

  const onUpdateAvailability = async () => {
    try {
      const { employee_reason, message, rotationId, date, spots } =
        await form.validateFields([
          'employee_reason',
          'message',
          'rotationId',
          'date',
          'spots'
        ]);

      if (selectedAvail?.employee_reason && !employee_reason) {
        onCloseModal();
        return;
      }

      const selectedRotation = _.find(listRotation, { id: rotationId });
      const selectedEmpReason = _.find(listEmployeeReason, {
        id: employee_reason
      });

      const query = queryString.parse(location.search);
      const { employeeId } = query;

      if (!selectedAvail.availId) {
        const curr_date = `${date.year()}-${date.month() + 1}-${date.date()}`;
        const next_date = date.add(1, 'days');
        const startDateString = curr_date;
        const endDateString =
          selectedRotation.end_time <= selectedRotation.start_time
            ? `${next_date.year()}-${next_date.month() + 1}-${next_date.date()}`
            : curr_date;
        const start_date_time = `${startDateString}T${selectedRotation.start_time}:00Z`;
        const end_date_time = `${endDateString}T${selectedRotation.end_time}:00Z`;

        props.actCreatePreferenceAvail(
          {
            tenantId,
            body: {
              version: 0,
              state: Number(selectedEmpReason?.state),
              employee_reason,
              start_date_time,
              end_date_time,
              // if in public view, use @selectedEmployee.employeeId, else if in admin private view, use @query.employeeId
              employee: !_.isEmpty(selectedEmployee)
                ? selectedEmployee.employeeId
                : employeeId,
              message,
              rotation: rotationId,
              spots
            }
          },
          (err, res) => {
            if (res) {
              onCloseModal();
              mess.success(translate('preference.createAvailSuccess'));
            }
            if (err) {
              mess.error(err.message);
            }
          }
        );
      } else {
        props.actUpdatePreferenceAvail(
          {
            tenantId,
            availId: selectedAvail.availId,
            body: {
              version: 0,
              state: selectedEmpReason && +selectedEmpReason.state,
              employee_reason,
              start_date_time: selectedAvail.start_date_time,
              end_date_time: selectedAvail.end_date_time,
              // if in public view, use @selectedEmployee.employeeId, else if in admin private view, use @query.employeeId
              employee: !_.isEmpty(selectedEmployee)
                ? selectedEmployee.employeeId
                : employeeId,
              message,
              rotation: rotationId,
              spots
            }
          },
          (err, res) => {
            if (res) {
              onCloseModal();
              mess.success(translate('preference.updateAvailSuccess'));
            }
            if (err) {
              mess.error(err.message);
            }
          }
        );
      }
    } catch (err) {}
  };

  return (
    <Modal
      visible={isModalVisible}
      onCancel={onCloseModal}
      title={
        isCreateMode
          ? translate('preference.createPreference')
          : translate('preference.updatePreference')
      }
      wrapClassName="custom-modal"
      width="70rem"
      footer={null}
      destroyOnClose={true}
    >
      <Form form={form} layout="vertical">
        <Row gutter={40}>
          <Col span={12}>
            <FormItem
              name="rotationId"
              rules={[
                {
                  required: true,
                  message: `${translate(
                    'preference.shiftCannotEmpty'
                  )} ${translate('isRequired')}`
                }
              ]}
              label={translate('preference.shiftTimming')}
            >
              <Select
                size="large"
                mode="single"
                placeholder={translate('preference.pickAShift')}
                disabled={!_.isEmpty(selectedAvail)}
              >
                {listRotation.map((rotation, index) => (
                  <Option value={rotation.id} key={index}>
                    {`${rotation.start_time} - ${rotation.end_time}`}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem
              rules={[
                {
                  required: !selectedAvail?.employee_reason,
                  message: `${translate(
                    'preference.availabilityCannotEmpty'
                  )} ${translate('isRequired')}`
                }
              ]}
              name="employee_reason"
              label={translate('preference.selectAvalability')}
            >
              <Select
                size="large"
                placeholder={translate('preference.pickAvalability')}
                allowClear={true}
                onClear={() => onDeleteAvailability(false)}
              >
                {listEmployeeReason.map((empReason, index) => (
                  <Option
                    key={index}
                    value={+empReason.id}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center'
                    }}
                  >
                    <img
                      src={empReason.icon}
                      alt=""
                      style={{
                        width: '1.5rem',
                        height: '1.5rem',
                        marginRight: '1rem'
                      }}
                    />

                    <span>{empReason.name}</span>
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>

        <Row gutter={40}>
          <Col span={12}>
            <FormItem
              name="date"
              label={translate('preference.date')}
              rules={[
                {
                  required: true,
                  message: `${translate('preference.date')} ${translate(
                    'isRequired'
                  )}`
                }
              ]}
            >
              <DatePicker
                placeholder={translate('preference.pickADate')}
                format="YYYY-MM-DD"
                className="input-border"
                size="large"
                style={{ width: '100%' }}
                disabled={!_.isEmpty(selectedAvail)}
              />
            </FormItem>
          </Col>

          <Col span={12}>
            <FormItem
              name="spots"
              label={translate('preference.applicableShiftType')}
            >
              <Select
                size="large"
                placeholder={translate('preference.selectShiftType')}
                allowClear={true}
                mode="multiple"
              >
                {listShiftType.map((shiftType, index) => (
                  <Option
                    key={index}
                    value={+shiftType?.id}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center'
                    }}
                  >
                    <Tag
                      color={LIST_TAG_COLOR[index % LIST_TAG_COLOR.length]}
                      style={{ height: '1.5rem', marginBottom: '0' }}
                    />

                    <span>{shiftType?.name}</span>
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row>

        <FormItem name="message" label={translate('preference.message')}>
          <TextArea
            value={selectedAvail.message}
            style={{ width: '100%', marginBottom: '1rem' }}
            rows={5}
            placeholder={translate('preference.dropAMessage')}
          />
        </FormItem>

        <Row justify="center">
          <Button
            size="large"
            type="primary"
            className="custom-button"
            onClick={onUpdateAvailability}
            style={{ height: '4.7rem', padding: '1rem 3rem' }}
          >
            {translate('save')}
          </Button>

          {!isCreateMode && default_preference && (
            <Button
              size="large"
              type="danger"
              onClick={() => onDeleteAvailability(true)}
              style={{
                height: '4.7rem',
                padding: '1rem 3rem',
                borderRadius: '6px',
                fontSize: '1.4rem',
                marginLeft: '5px'
              }}
            >
              {translate('preference.deletebtn')}
            </Button>
          )}
        </Row>
      </Form>
    </Modal>
  );
};

export default connect(
  (state) => ({
    isModalVisible: state.Preference.isModalVisible,
    selectedAvail: state.Preference.selectedAvail,
    tenantId: state?.App?.user?.tenant?.id,
    listEmployeeReason: state.EmployeeReason.listEmployeeReason,
    selectedEmployee: state.Preference.selectedEmployee,
    listRotation: state.RotationSetting.listRotation,
    default_preference: state?.App?.user?.tenant?.default_preference,
    listShiftType: state?.ShiftTypeSetting?.listShiftType
  }),
  {
    actSetSelectedPreferenceAvail,
    actUpdatePreferenceAvail,
    actCreatePreferenceAvail,
    actSetModalPreferenceVisible,
    actDeletePreferenceAvail,
    actSaveOnDeleteEmployeePreference,
    actAfterDeletePreferenceAvail,
    actGetShiftTypeSetting
  }
)(ModalUpdateAvail);
