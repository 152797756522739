import { Form, Space, Button, Select } from 'antd';
import _ from 'lodash';
import { useTranslation } from 'component/useTranslation';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { actSearchEmployeeAntiPattern } from 'redux/action/employeeSetting';
import { connect } from 'react-redux';
import { useEffect } from 'react';
const { Option } = Select;

const ListAntiPattern = (props) => {
  const { tenantId, searchEmployee, selectedEmployeeSetting } = props;
  const { translate } = useTranslation();

  useEffect(() => {
    props.actSearchEmployeeAntiPattern({ tenantId, search_query: 'all' });
  }, [tenantId]);

  const handleSearch = (search) => {
    if (search)
      props.actSearchEmployeeAntiPattern({ tenantId, search_query: search });
  };

  return (
    <Form.List name="employee_anti_pattern">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, fieldKey, ...restField }) => (
            <Space
              align="center"
              key={key}
              style={{ display: 'flex', marginBottom: '8px' }}
            >
              <div>
                <Form.Item
                  {...restField}
                  label={`${translate('employeeSetting.Employee')}:`}
                  name={[name, 'id']}
                  fieldKey={[fieldKey, 'id']}
                  rules={[
                    {
                      required: true,
                      message: `${translate(
                        'employeeSetting.username'
                      )} ${translate('isRequired')}`
                    }
                  ]}
                >
                  <Select
                    showSearch
                    style={{ width: '590px' }}
                    placeholder="Search Employee"
                    optionFilterProp="children"
                    onSearch={(data) => {
                      handleSearch(data);
                    }}
                  >
                    {searchEmployee &&
                      searchEmployee.map((data, i) => {
                        if (selectedEmployeeSetting.id !== data.id)
                          return (
                            <Option key={i} value={data.id}>
                              {data.name}
                            </Option>
                          );
                      })}
                  </Select>
                </Form.Item>
              </div>

              <MinusCircleOutlined
                style={{
                  fontSize: '23px',
                  color: 'rgb(167 167 167)',
                  width: '100px'
                }}
                onClick={() => remove(name)}
              />
            </Space>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() =>
                add({
                  employee_anti_pattern: ''
                })
              }
              icon={<PlusOutlined />}
            >
              {translate('employeeSetting.addRule')}
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default connect(
  (state) => {
    return {
      tenantId: state?.App?.user?.tenant?.id,
      searchEmployee: state.EmployeeSetting.searchEmployee,
      listEmployee: state.EmployeeSetting.listEmployee,
      selectedEmployeeSetting: state.EmployeeSetting.selectedEmployeeSetting
    };
  },
  {
    actSearchEmployeeAntiPattern
  }
)(ListAntiPattern);
