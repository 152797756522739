import axios from 'axios';
import { API_TIMEOUT } from 'utils/constant';
import { store } from 'redux/store';

const BlobAPI = async (method = 'get', url, requestBody, params) => {
  try {
    const { App } = store.getState();
    const { token } = App;

    const res = await axios({
      method,
      url: url,
      data: requestBody,
      params,
      timeout: API_TIMEOUT,
      responseType: 'blob',
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json',
            'Accept-Language': localStorage.getItem('locale')
              ? localStorage.getItem('locale') === 'jp'
                ? 'ja'
                : localStorage.getItem('locale')
              : 'en'
          }
        : {
            Accept: 'application/json',
            'Accept-Language': localStorage.getItem('locale')
              ? localStorage.getItem('locale') === 'jp'
                ? 'ja'
                : localStorage.getItem('locale')
              : 'en'
          }
    });

    return res;
  } catch (error) {
    return error.response;
  }
};

export default BlobAPI;
