import { message } from 'antd';
import { takeEvery, all } from 'redux-saga/effects';
import {
  DELETE_EMPLOYEE_SKILL_SET,
  SAVE_DELETE_EMPLOYEE_SKILL_SET,
  CREATE_EMPLOYEE_SKILL_SET,
  SAVE_CREATE_EMPLOYEE_SKILL_SET
} from 'redux/type';
import employeeSkillSetAPI from 'redux/api/employeeSkillSet';
import { createRequestSaga } from './common';

const deleteEmployeeSkillSet = createRequestSaga({
  request: employeeSkillSetAPI.deleteEmployeeSkillSet,
  key: 'deleteEmployeeSkillSet',
  functionSuccess: [
    (res) => ({
      type: SAVE_DELETE_EMPLOYEE_SKILL_SET,
      payload: res
    })
  ],
  functionFailure: [
    (err) => message.error('Error occured when delete employee skill')
  ]
});

const createEmployeeSkillSet = createRequestSaga({
  request: employeeSkillSetAPI.createEmployeeSkillSet,
  key: 'createEmployeeSkillSet',
  success: [
    (res) => ({
      type: SAVE_CREATE_EMPLOYEE_SKILL_SET,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: [
    (err) => message.error('Error occured when create employee skill')
  ]
});

const saga = [
  function* fetchWatcher() {
    yield all([
      takeEvery(DELETE_EMPLOYEE_SKILL_SET, deleteEmployeeSkillSet),
      takeEvery(CREATE_EMPLOYEE_SKILL_SET, createEmployeeSkillSet)
    ]);
  }
];

export default saga;
