import _ from 'lodash';
import moment from 'moment';
import Cron from 'react-js-cron';
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'component/useTranslation';
import { Divider, Form, Select, Row, Col, Space, Button } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { actSetSelectedEmployeeSetting } from 'redux/action/employeeSetting';
import { LOCALE } from 'languages/index';
import CRON_JP_LOCALE from 'languages/cron_jp.json';
import 'react-js-cron/dist/styles.css';

const DEFAULT_RULE = '0 0 0 * *';

const RULE_BY_WEEK = {
  1: '1-7',
  2: '8-14',
  3: '15-21',
  4: '22-28'
};

const FORMATED_RULE = {
  '1-7,8-14': '1-14',
  '8-14,15-21': '8-21',
  '15-21,22-28': '15-28',
  '1-7,8-14,15-21': '1-21',
  '8-14,15-21,22-28': '8-28',
  '1-7,8-14,15-21,22-28': '*',
  '': '*'
};

const { Option } = Select;

const ListSchedule = (props) => {
  const { listEmployeeReason, selectedEmployeeSetting } = props;
  const { translate, locale } = useTranslation();
  const [weekOfMonth, setWeekOfMonth] = useState({});

  // period is 'month' or 'week', mapping by index of item in list
  const initialCronPeriod = _.reduce(
    selectedEmployeeSetting?.rrules,
    (result, value, key) => ({ ...result, [key]: 'month' }),
    {}
  );
  const [cronPeriod, setCronPeriod] = useState(initialCronPeriod);

  const weekMonthOptions = useMemo(() => {
    const momentLocale = moment.localeData(locale === LOCALE.JP ? 'jp' : null);
    return [1, 2, 3, 4].map((val) => momentLocale.ordinal(val));
  }, [locale]);

  useEffect(() => {
    const selectedWeeks = {};

    // Generate value for <Select />
    Object.keys(RULE_BY_WEEK).forEach((weekKey) => {
      selectedEmployeeSetting?.rrules?.forEach((item, index) => {
        if (item?.rrule?.includes(RULE_BY_WEEK[weekKey])) {
          if (!selectedWeeks[index]) {
            selectedWeeks[index] = [];
          }
          selectedWeeks[index] = [...selectedWeeks[index], Number(weekKey)];
        }

        // Parse cron value to multiple week.
        // For example, parse 1-14 -> 1-7,8-14 -> get weekKey [1,2] from @RULE_BY_WEEK
        Object.keys(FORMATED_RULE).forEach((key) => {
          if (
            item?.rrule?.includes(FORMATED_RULE[key]) &&
            FORMATED_RULE[key] !== '*'
          ) {
            const listWeek = key?.split(',');
            listWeek?.forEach((weekValue) => {
              if (!selectedWeeks[index]) {
                selectedWeeks[index] = [];
              }

              if (weekValue === RULE_BY_WEEK[weekKey]) {
                selectedWeeks[index] = [
                  ...selectedWeeks[index],
                  Number(weekKey)
                ];
              }
            });
          }
        });
      });
    });

    setWeekOfMonth(selectedWeeks);
  }, [selectedEmployeeSetting]);

  const getCron = (ruleIndex) => {
    const { rrules } = selectedEmployeeSetting;
    const rruleAtIndex = rrules?.[ruleIndex];
    return rruleAtIndex?.rrule?.split(' ');
  };

  // Generate RRule for a given selected week.
  const getWeekRRule = (listValue, ruleIndex) => {
    const listCronValue = getCron(ruleIndex);
    const dayValue = listCronValue?.[listCronValue?.length - 1] || '*'; // get day value MON, TUE, ..., SUN

    let rrule = '';
    listValue?.forEach((weekIndex, index) => {
      rrule += RULE_BY_WEEK[weekIndex];
      if (index < listValue?.length - 1) {
        rrule += ',';
      }
    });

    return `0 0 ${FORMATED_RULE[rrule] || rrule} * ${dayValue}`;
  };

  const updateEmployeeRRule = (rrule, ruleIndex) => {
    let rrules = selectedEmployeeSetting?.rrules;
    const rruleAtIndex = rrules?.[ruleIndex];
    rrules[ruleIndex] = { ...rruleAtIndex, rrule };
    props.actSetSelectedEmployeeSetting({ ...selectedEmployeeSetting, rrules });
  };

  const onSelectWeekOfMonth = (listValue, ruleIndex) => {
    const rrule = getWeekRRule(listValue, ruleIndex);
    updateEmployeeRRule(rrule, ruleIndex);
    setWeekOfMonth(listValue);
  };

  const onChangeCronValue = (rule, selectedPeriod, index) => {
    const newCronPeriod = { ...cronPeriod };
    newCronPeriod[index] = selectedPeriod;
    setCronPeriod(newCronPeriod);
    updateEmployeeRRule(rule, index);
  };

  const onRemoveSchedule = (removeFunc, ruleIndex) => {
    removeFunc(ruleIndex);
    let rrules = selectedEmployeeSetting?.rrules;
    rrules?.splice(ruleIndex, 1);
    props.actSetSelectedEmployeeSetting({ ...selectedEmployeeSetting, rrules });
  };

  const onAddNewSchedule = (addFunc) => {
    const schedule = {
      availability: _.first(listEmployeeReason)?.id,
      rrule: DEFAULT_RULE
    };
    let rrules = selectedEmployeeSetting?.rrules;
    rrules.push(schedule);
    addFunc(schedule);
    props.actSetSelectedEmployeeSetting({ ...selectedEmployeeSetting, rrules });
  };

  const onChangeAvail = (value, ruleIndex) => {
    let rrules = selectedEmployeeSetting?.rrules;
    const rruleAtIndex = rrules?.[ruleIndex];
    rrules[ruleIndex] = { ...rruleAtIndex, availability: value };
    props.actSetSelectedEmployeeSetting({ ...selectedEmployeeSetting, rrules });
  };

  const allowedDropdowns = (ruleIndex) => {
    let listOption = ['period', 'months', 'week-days', 'month-days'];

    const listCronValue = getCron(ruleIndex);
    const dayInMonth = listCronValue[2];
    const dayInWeek = listCronValue[4];

    // Hide day-in-week when day-in-month is selected.
    if (!['*', '0'].includes(dayInMonth)) {
      listOption = ['period', 'months', 'month-days'];
    }

    // Hide day-in-month when day-in-week is selected.
    if (dayInWeek !== '*') {
      listOption = ['period', 'months', 'week-days'];
    }

    return listOption;
  };

  const displaySelectWeek = (ruleIndex) => {
    const listCronValue = getCron(ruleIndex);
    const dayInWeek = listCronValue[4];

    // Hide for week period.
    if (cronPeriod[ruleIndex] !== 'month') return false;

    // Display when day-of-week is selected.
    return dayInWeek !== '*';
  };

  return (
    <Form.List name="rrules">
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, fieldKey, ...restField }) => (
            <Space
              align="center"
              key={key}
              style={{ display: 'flex', marginBottom: '8px' }}
            >
              <div>
                <Form.Item
                  {...restField}
                  label={`${translate('employeeSetting.availability')}:`}
                  name={[name, 'availability']}
                  fieldKey={[fieldKey, 'availability']}
                  rules={[
                    {
                      required: true,
                      message: `${translate(
                        'employeeSetting.availability'
                      )} ${translate('isRequired')}`
                    }
                  ]}
                >
                  <Select
                    size="large"
                    style={{ width: '590px' }}
                    placeholder={translate('employeeSetting.pickAvalability')}
                    allowClear={true}
                    required
                    onChange={(value) => onChangeAvail(value, name)}
                  >
                    {_.map(listEmployeeReason, (empReason, index) => (
                      <Option
                        key={index}
                        value={+empReason.id}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center'
                        }}
                      >
                        <img
                          src={empReason.icon}
                          alt=""
                          style={{
                            width: '1.5rem',
                            height: '1.5rem',
                            marginRight: '1rem'
                          }}
                        />

                        <span>{empReason.name}</span>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  {...restField}
                  name={[name, 'rrule']}
                  fieldKey={[fieldKey, 'rrule']}
                  label={`${translate('employeeSetting.schedule')}:`}
                  trigger="setValue"
                >
                  <Cron
                    locale={locale === LOCALE.JP ? CRON_JP_LOCALE : locale}
                    clearButton={false}
                    allowedPeriods={['month', 'week']}
                    defaultPeriod={'month'}
                    allowedDropdowns={allowedDropdowns(name)}
                    setValue={(value, { selectedPeriod }) =>
                      onChangeCronValue(value, selectedPeriod, name)
                    }
                    displayError={false}
                  />
                </Form.Item>

                {displaySelectWeek(name) && (
                  <Row style={{ marginTop: -15 }}>
                    <Col md={3}>{translate('employeeSetting.onEvery')}: </Col>
                    <Col md={9}>
                      <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder={translate(
                          'employeeSetting.weekOfMonthPlaceholder'
                        )}
                        onChange={(listValue) =>
                          onSelectWeekOfMonth(listValue, name)
                        }
                        value={weekOfMonth[Number(name)]}
                      >
                        {weekMonthOptions.map((value, index) => (
                          <Option key={index} value={index + 1}>
                            {value} {translate('employeeSetting.weekOfMonth')}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                  </Row>
                )}

                <Divider />
              </div>

              <MinusCircleOutlined
                style={{
                  fontSize: '23px',
                  color: 'rgb(167 167 167)',
                  width: '100px'
                }}
                onClick={() => onRemoveSchedule(remove, name)}
              />
            </Space>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => onAddNewSchedule(add)}
              icon={<PlusOutlined />}
            >
              {translate('employeeSetting.addRule')}
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default connect(
  (state) => ({
    selectedEmployeeSetting: state.EmployeeSetting.selectedEmployeeSetting,
    listEmployeeReason: state.EmployeeReason.listEmployeeReason
  }),
  { actSetSelectedEmployeeSetting }
)(ListSchedule);
