import { takeLatest, all } from 'redux-saga/effects';

import {
  CREATE_SHIFT_GROUP,
  DELETE_SHIFT_GROUP,
  GET_SHIFT_GROUP,
  SAVE_CREATE_SHIFT_GROUP,
  SAVE_UPDATE_SHIFT_GROUP,
  UPDATE_SHIFT_GROUP
} from 'redux/type';
import shiftGroupAPI from 'redux/api/shiftGroupSetting';
import { createRequestSaga } from './common';

const getShiftGroup = createRequestSaga({
  request: shiftGroupAPI.getShiftGroup,
  key: 'getShiftGroup',
  success: [
    (res) => ({
      type: GET_SHIFT_GROUP,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: []
});

const updateShiftGroup = createRequestSaga({
  request: shiftGroupAPI.updateShiftGroup,
  key: 'updateShiftGroup',
  success: [
    (res) => ({
      type: SAVE_UPDATE_SHIFT_GROUP,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: []
});

const deleteShiftGroup = createRequestSaga({
  request: shiftGroupAPI.deleteShiftGroup,
  key: 'deleteShiftGroup',
  functionSuccess: [],
  functionFailure: []
});

const createShiftGroup = createRequestSaga({
  request: shiftGroupAPI.createShiftGroup,
  key: 'createShiftGroup',
  success: [
    (res) => ({
      type: SAVE_CREATE_SHIFT_GROUP,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: []
});

const saga = [
  function* fetchWatcher() {
    yield all([
      takeLatest(GET_SHIFT_GROUP, getShiftGroup),
      takeLatest(UPDATE_SHIFT_GROUP, updateShiftGroup),
      takeLatest(DELETE_SHIFT_GROUP, deleteShiftGroup),
      takeLatest(CREATE_SHIFT_GROUP, createShiftGroup)
    ]);
  }
];

export default saga;
