import { Card, Col, Row } from 'antd';
import { useTranslation } from 'component';
import { connect } from 'react-redux';

import { actGetProfileDetails } from 'redux/action/profile';
import teamsLogo from 'assets/teams-logo.png';

const Integrations = (props) => {
  const { translate } = useTranslation();
  const { REACT_APP_API_URL } = process.env;

  return (
    <Row gutter={24}>
      <Col md={8}>
        <Card style={{ textAlign: 'center' }} hoverable>
          <a
            target="_blank"
            href="https://slack.com/oauth/v2/authorize?client_id=744307275825.3778730853543&scope=channels:manage,chat:write,im:write,users:read,users:read.email,chat:write.public&user_scope="
          >
            <img
              alt="Add to Slack"
              height="40"
              width="139"
              src="https://platform.slack-edge.com/img/add_to_slack.png"
              srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
            />
          </a>
          <p style={{ marginTop: '15px' }}>{translate('profile.slackInfo')}</p>
        </Card>
      </Col>
      <Col md={8}>
        <Card style={{ textAlign: 'center' }} hoverable>
          <a
            target="_blank"
            href={`${REACT_APP_API_URL}/${props.tenantId}/msteams/oauth/`}
          >
            <img src={teamsLogo} height="40" width="180" alt="Add to Teams" />
          </a>
          <p style={{ marginTop: '15px' }}>{translate('profile.teamsInfo')}</p>
        </Card>
      </Col>
    </Row>
  );
};

export default connect(
  (state) => ({ tenantId: state?.App?.user?.tenant?.id }),
  { actGetProfileDetails }
)(Integrations);
