import moment from 'moment';
import _ from 'lodash';
import { useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, Tabs, Popconfirm } from 'antd';
import { SettingOutlined, CloseOutlined } from '@ant-design/icons';

import {
  actDeleteRotationTab,
  actCreateRotationTab,
  actGetListRotationTab
} from 'redux/action/rotationTab';
import { useTranslation } from 'component/index';

import DraggableTabs from './DraggableTabs';
import TabPaneContent from './TabPaneContent';

const { TabPane } = Tabs;
const ACTION = { ADD: 'add', DELETE: 'delete' };

const RotationTab = (props) => {
  const {
    listRotationTab,
    setOpenSettingsForm,
    selectedTab,
    setSelectedTab,
    tenantId
  } = props;
  const [selectLast, setSelectLast] = useState(false);
  const { translate, locale } = useTranslation();

  // from Monday to Sunday string
  const listDayInWeek = useMemo(
    () => [
      translate('date.short.Sun'),
      translate('date.short.Mon'),
      translate('date.short.Tue'),
      translate('date.short.Wed'),
      translate('date.short.Thu'),
      translate('date.short.Fri'),
      translate('date.short.Sat')
    ],
    [locale]
  );

  useEffect(() => {
    if (listRotationTab?.length > 0 && selectedTab === null) {
      setSelectedTab(_.first(listRotationTab)?.id);
    }

    if (selectLast) {
      setSelectLast(false);
      setSelectedTab(_.last(listRotationTab)?.id);
    }
  }, [listRotationTab]);

  const tabBarExtraContent = () => (
    <Button
      icon={<SettingOutlined />}
      onClick={() => setOpenSettingsForm(true)}
    >
      {translate('sidebar.settings')}
    </Button>
  );

  const getTabName = (tab, index) => {
    let tabName = `Tab ${index + 1}`;

    if (tab?.is_special) {
      if (tab?.special_dates?.length > 0) {
        tabName = moment(tab?.special_dates?.[0], 'YYYY-MM-DD').format(
          'MMMM Do YYYY'
        );
      }
    } else if (!_.isEmpty(tab?.days)) {
      tab?.days.sort();
      const listDayStr = tab?.days?.map(
        (dayIndex) => listDayInWeek?.[dayIndex]
      );
      tabName = listDayStr?.join(' ');
    }

    return tabName;
  };

  const onAddOrRemoveTab = (targetKey, action) => {
    if (action === ACTION.ADD) {
      props.actCreateRotationTab(
        {
          tenantId,
          body: { days: [] }
        },
        (err) => {
          if (!err) {
            props.actGetListRotationTab({ tenantId });
            setSelectLast(true);
          } else {
            message.error(err);
          }
        }
      );
    } else if (action === ACTION.DELETE) {
      props.actDeleteRotationTab(
        {
          tenantId,
          tabId: targetKey
        },
        (err) => {
          if (!err) {
            props.actGetListRotationTab({ tenantId });
            setSelectedTab(_.first(listRotationTab)?.id);
          }
        }
      );
    }
  };

  return (
    <DraggableTabs
      onChange={(key) => setSelectedTab(key)}
      type="editable-card"
      activeKey={selectedTab?.toString()}
      tabBarExtraContent={tabBarExtraContent()}
      onEdit={onAddOrRemoveTab}
    >
      {listRotationTab?.map((tab, index) => (
        <TabPane
          tab={getTabName(tab, index)}
          closable={index > 0}
          closeIcon={
            <Popconfirm
              title={translate('message.confirmToDelete')}
              onConfirm={() => onAddOrRemoveTab(tab?.id, ACTION.DELETE)}
            >
              <CloseOutlined />
            </Popconfirm>
          }
          key={tab?.id?.toString()}
        >
          <TabPaneContent
            tab={tab}
            selectedTab={selectedTab}
            listDayInWeek={listDayInWeek}
          />
        </TabPane>
      ))}
    </DraggableTabs>
  );
};

export default connect(
  (state) => ({
    listRotationTab: state?.RotationTab?.listRotationTab || [],
    tenantId: state?.App?.user?.tenant?.id
  }),
  { actDeleteRotationTab, actCreateRotationTab, actGetListRotationTab }
)(RotationTab);
