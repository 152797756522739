import { useState, useEffect } from 'react';
import { DatePicker, Select, Button, Switch, Tooltip } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate as useHistory, useLocation } from 'react-router-dom';
import { actSetGlobalTime, actEmailStatusCheck } from 'redux/action/grid';
import { PreferencePageWrapper } from './index.style';
import PreferenceGrid from 'component/Preference';
import { actSetSidebarClose } from 'redux/action/layout';
import { actGetEmployeeSetting } from 'redux/action/employeeSetting';
import {
  actSetSelectedPreferenceAvail,
  actSetModalPreferenceVisible,
  actSetIsViewConfirmed,
  actSaveListPreferenceSnapshot
} from 'redux/action/preference';
import { useTranslation } from 'component/index';
import { filterListEmployee } from 'utils/helper';

const { Option } = Select;

const PreferencePage = (props) => {
  const {
    listEmployee,
    tenantId,
    listEmployeeReason,
    globalTime,
    isViewConfirmed,
    emailStatus
  } = props;

  const history = useHistory();
  const location = useLocation();
  // @selectedEmployee: used when filter by employee
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [listOptionEmployee, setListOptionEmployee] = useState([]);
  const { translate } = useTranslation();
  const [employeeId, setEmployeeId] = useState('');

  useEffect(() => {
    props.actSetSidebarClose(true);
  }, []);

  useEffect(() => {
    props.actGetEmployeeSetting({ tenantId });
  }, [tenantId]);

  useEffect(() => {
    const query = queryString.parse(location.search);
    setEmployeeId(query.employeeId);

    const foundedEmployee = _.find(listEmployee, { id: +query.employeeId });

    if (foundedEmployee && foundedEmployee.name) {
      setSelectedEmployee(foundedEmployee.name);
    } else if (listEmployee.length > 0) {
      history(`/preferences?employeeId=${listEmployee[0].id}`);
      setSelectedEmployee(listEmployee[0].name);
    }

    setListOptionEmployee([...listEmployee]);
  }, [location.search, listEmployee]);

  useEffect(() => {
    props.actEmailStatusCheck({
      tenantId,
      year: moment(+globalTime).year(),
      month: moment(+globalTime).month() + 1
    });
  }, [globalTime]);

  useEffect(() => {
    let listSnapshot = [];
    let listUniqEmailStatus = emailStatus.map((item) => ({
      ...item,
      employeeId: item?.employee?.id
    }));
    listUniqEmailStatus = _.sortBy(listUniqEmailStatus, 'updated_at');
    listUniqEmailStatus = _.reverse(listUniqEmailStatus); // get lastest snapshot
    listUniqEmailStatus = _.uniqBy(listUniqEmailStatus, 'employeeId'); // get uniq email status by employeeId

    listUniqEmailStatus.forEach((item) => {
      // only get snapshot has confirm_preferences mode
      if (item?.snapshot && item?.mode === 'confirm_preferences') {
        listSnapshot = [...listSnapshot, ...item.snapshot];
      }
    });

    props.actSaveListPreferenceSnapshot([...listSnapshot]);
  }, [emailStatus]);

  const renderListAction = () =>
    listEmployeeReason.map((item, index) => (
      <Tooltip title={item.name} key={index}>
        <div className="icon-wrapper">
          <img src={item.icon} alt="" />
        </div>
      </Tooltip>
    ));

  // filter employee
  const onFilterByEmployee = (selectedItem) => {
    setSelectedEmployee(selectedItem);
    const employeeInfo = _.find(listEmployee, { name: selectedItem });

    // history.push(`/preferences?employeeId=${employeeInfo && employeeInfo.id}`);
    history(`/preferences?employeeId=${employeeInfo && employeeInfo.id}`);
  };

  const onSearchEmployee = (text) => {
    const tempListEmployee = filterListEmployee(listEmployee, text);
    setListOptionEmployee(tempListEmployee);
  };

  const onDateChange = (date, dateString) => {
    props.actSetGlobalTime(date.format('x'));
  };

  const onAddPreference = () => {
    props.actSetModalPreferenceVisible(true);
    props.actSetSelectedPreferenceAvail({});
  };

  const onSwitchConfirmed = (checked) => {
    props.actSetIsViewConfirmed(checked);
  };

  return (
    <PreferencePageWrapper>
      <div className="header-wrapper">
        <div className="action-wrapper">
          <div className="title">{translate('preference.header.action')}</div>

          <div className="list-action">{renderListAction()}</div>
        </div>

        <div className="filter-wrapper">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '3rem'
            }}
          >
            <Switch
              defaultChecked
              unCheckedChildren={translate('preference.compareConfirm')}
              checkedChildren={translate('preference.compareConfirm')}
              onChange={onSwitchConfirmed}
              checked={isViewConfirmed}
            />
          </div>

          <Select
            size="large"
            mode="single"
            value={selectedEmployee}
            onChange={onFilterByEmployee}
            onSearch={onSearchEmployee}
            style={{ width: '25rem' }}
            placeholder={translate('preference.filterEmployee')}
            showSearch={true}
          >
            {_.map(listOptionEmployee, (emp, index) => (
              <Option key={index} value={emp.name}>
                {emp.name}
              </Option>
            ))}
          </Select>

          <div className="filter-date">
            <DatePicker
              placeholder={translate('preference.filterMonth')}
              size="large"
              picker="month"
              format="MMMM YYYY"
              className="input-border"
              style={{ width: '25rem' }}
              onChange={onDateChange}
              value={moment(+globalTime)}
              allowClear={false}
            />
          </div>

          <Button
            type="primary"
            icon={<PlusOutlined />}
            className="custom-button"
            size="large"
            onClick={onAddPreference}
          >
            {translate('preference.addPreference')}
          </Button>
        </div>
      </div>

      <div className="main-content">
        <div className="table-wrapper">
          <PreferenceGrid employeeId={employeeId} />
        </div>
      </div>
    </PreferencePageWrapper>
  );
};

export default connect(
  (state) => ({
    listEmployee: state.EmployeeSetting.listEmployee,
    tenantId: state?.App?.user?.tenant?.id,
    listEmployeeReason: state.EmployeeReason.listEmployeeReason,
    globalTime: state.App.globalTime,
    isViewConfirmed: state.Preference.isViewConfirmed,
    emailStatus: state.Grid.emailStatus
  }),
  {
    actGetEmployeeSetting,
    actSetSelectedPreferenceAvail,
    actSetModalPreferenceVisible,
    actSetGlobalTime,
    actEmailStatusCheck,
    actSetIsViewConfirmed,
    actSetSidebarClose,
    actSaveListPreferenceSnapshot
  }
)(PreferencePage);
