import { all, takeLatest } from 'redux-saga/effects';
import {
  SAVE_GET_TIME_REPORTING,
  GET_TIME_REPORTING,
  SAVE_CREATE_TIME_REPORT,
  CREATE_TIME_REPORT,
  SAVE_UPDATE_TIME_REPORT,
  UPDATE_TIME_REPORT,
  SAVE_DELETE_TIME_REPORT,
  DELETE_TIME_REPORT,
  GET_TIME_REPORT_STATISTIC,
  SAVE_GET_TIME_REPORT_STATISTIC
} from 'redux/type';
import timeReportAPI from 'redux/api/timeReport';
import { createRequestSaga } from './common';

const getTimeReporting = createRequestSaga({
  request: timeReportAPI.getTimeReporting,
  key: 'getTimeReporting',
  success: [
    (res) => ({
      type: SAVE_GET_TIME_REPORTING,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: []
});

const createTimeReporting = createRequestSaga({
  request: timeReportAPI.createTimeReporting,
  key: 'createTimeReporting',
  success: [
    (res) => ({
      type: SAVE_CREATE_TIME_REPORT,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: []
});

const updateTimeReporting = createRequestSaga({
  request: timeReportAPI.updateTimeReporting,
  key: 'updateTimeReporting',
  success: [
    (res) => ({
      type: SAVE_UPDATE_TIME_REPORT,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: []
});

const deleteTimeReporting = createRequestSaga({
  request: timeReportAPI.deleteTimeReporting,
  key: 'deleteTimeReporting',
  success: [
    (res) => ({
      type: SAVE_DELETE_TIME_REPORT,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: []
});

const getTimeReportingStatistic = createRequestSaga({
  request: timeReportAPI.getTimeReportingStatistic,
  key: 'getTimeReportingStatistic',
  success: [
    (res) => ({
      type: SAVE_GET_TIME_REPORT_STATISTIC,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: []
});

const saga = [
  function* fetchWatcher() {
    yield all([
      takeLatest(GET_TIME_REPORTING, getTimeReporting),
      takeLatest(CREATE_TIME_REPORT, createTimeReporting),
      takeLatest(UPDATE_TIME_REPORT, updateTimeReporting),
      takeLatest(DELETE_TIME_REPORT, deleteTimeReporting),
      takeLatest(GET_TIME_REPORT_STATISTIC, getTimeReportingStatistic)
    ]);
  }
];
export default saga;
