import styled from 'styled-components';

const HomePageWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  font-size: 1.6rem;
  scroll-behavior: smooth;
`;

export { HomePageWrapper };
