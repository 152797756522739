import { createRef, useContext, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import Holidays from 'date-holidays';
import _ from 'lodash';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import moment from 'moment';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { LanguageContext } from 'languages/index';

import { actGetRotationSetting } from 'redux/action/rotationSetting';
import { actGetSkillSetting } from 'redux/action/skillSetting';
import { actGetEmployeeSetting } from 'redux/action/employeeSetting';
import { actGetSkillView } from 'redux/action/skillView';
import { actGetShiftGroup } from 'redux/action/shiftGroupSetting';

import SkillItem from './SkillItem';
import { SkillViewCalendarWrapper, CustomCellWrapper } from './style';
import ModalAssignEmployee from './ModalAssignEmployee';
import AddShiftButton from './AddShiftButton';

const hd = new Holidays('JP');

const SkillViewCalendar = (props) => {
  const {
    globalTime,
    listData,
    listRotation,
    selectedShiftType,
    tenantId,
    shouldSkillViewRefresh,
    setSkillViewRefresh,
    isGridFullScreen
  } = props;
  const calendarRef = createRef();
  const { locale } = useContext(LanguageContext);

  const listHoliday = useMemo(() => {
    const year = moment(+globalTime).year();
    return hd
      .getHolidays(year)
      ?.map((item) => moment(item?.date)?.format('YYYY-MM-DD'));
  }, [globalTime]);

  useEffect(() => {
    const year = moment(+globalTime).year();
    const month = moment(+globalTime).month() + 1;

    props.actGetRotationSetting({
      tenantId,
      year,
      month
    });

    props.actGetSkillSetting({ tenantId });
    props.actGetEmployeeSetting({ tenantId });

    if (selectedShiftType) {
      props.actGetSkillView({
        tenantId,
        month,
        year,
        spot: selectedShiftType
      });
    }

    props.actGetShiftGroup({ tenantId });
  }, [tenantId, globalTime, selectedShiftType]);

  useEffect(() => {
    if (shouldSkillViewRefresh) {
      const year = moment(+globalTime).year();
      const month = moment(+globalTime).month() + 1;

      props.actGetSkillView({
        tenantId,
        month,
        year,
        spot: selectedShiftType
      });

      setSkillViewRefresh(false);
    }
  }, [shouldSkillViewRefresh]);

  useEffect(() => {
    if (globalTime) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.gotoDate(moment(+globalTime).format());
    }
  }, [globalTime, calendarRef]);

  const skillViewData = useMemo(() => {
    const selectedTime = moment(+globalTime);
    const noOfDay = selectedTime.daysInMonth();
    const year = selectedTime.year();
    const month = selectedTime.month();

    const dates = _.times(noOfDay, (day) =>
      moment({ year, month, day: day + 1 }).format('YYYY-MM-DD')
    );

    const emptyDates = _.reduce(
      dates,
      (result, date) => {
        return { ...result, [date]: [] };
      },
      {}
    );

    return _.merge(emptyDates, listData);
  }, [listData]);

  const renderListSkill = (listItem, start) =>
    listItem.map((item, index) => <SkillItem key={index} data={item} />);

  const renderEventContent = (args) => {
    const { start, listItem } = args.event.extendedProps;

    // only display add button if @dateShiftInfo and match with @selectedShiftType in grid heading and list day of rotation tab
    const weekDay = moment(start, 'YYYY-MM-DD')?.weekday();
    const matchRotations = listRotation?.filter(
      (rotation) =>
        rotation?.tab?.days?.includes(weekDay) &&
        rotation?.spot === selectedShiftType
    );

    return (
      <Scrollbars
        style={{ height: isGridFullScreen ? '9vh' : '200px', width: '80%' }}
      >
        <div className="list-skill">
          {renderListSkill(listItem, start)}
          {matchRotations && <AddShiftButton date={start} />}
        </div>
      </Scrollbars>
    );
  };

  const renderDayCellContent = (props) => {
    const date = moment(props.date).format('Do');

    return <div className="day-cell">{date}</div>;
  };

  const renderCustomCell = (args) => {
    return (
      <CustomCellWrapper
        style={{ maxHeight: isGridFullScreen ? '11vh' : '100%' }}
      >
        {renderEventContent(args)}
      </CustomCellWrapper>
    );
  };

  const getDayCellClassName = ({ date }) => {
    const dayInWeek = moment(date).day();
    const SUNDAY = 0;
    const SATURDAY = 6;
    const WEEKENDS = [SATURDAY, SUNDAY];
    let classNames = [];

    if (WEEKENDS.includes(dayInWeek)) {
      classNames.push('light-gray-cell');
    }

    if (listHoliday?.includes(moment(date)?.format('YYYY-MM-DD'))) {
      classNames.push('holiday');
    }

    return classNames.join(' ');
  };

  return (
    <SkillViewCalendarWrapper>
      <FullCalendar
        height={isGridFullScreen ? '88vh' : '100%'}
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        events={Object.keys(skillViewData)?.map((date) => ({
          start: date,
          extendedProps: {
            listItem: skillViewData[date]?.map((item) => ({
              ...item,
              date
            })),
            start: date
          }
        }))}
        eventContent={renderCustomCell}
        ref={calendarRef}
        locale={locale === 'en' ? 'en' : 'ja'}
        dayCellContent={renderDayCellContent}
        dayCellClassNames={getDayCellClassName}
      />
      <ModalAssignEmployee />
    </SkillViewCalendarWrapper>
  );
};

export default connect(
  (state) => ({
    tenantId: state?.App?.user?.tenant?.id,
    globalTime: state.App.globalTime,
    listData: state.SkillView.listData,
    selectedShiftType: state.Grid.selectedShiftType,
    listRotation: state?.RotationSetting?.listRotation,
    isGridFullScreen: state.App.isGridFullScreen
  }),
  {
    actGetRotationSetting,
    actGetSkillSetting,
    actGetEmployeeSetting,
    actGetSkillView,
    actGetShiftGroup
  }
)(SkillViewCalendar);
