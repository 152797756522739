import API from './common';

const api = {
  getSkillSetting: ({ tenantId }) => API('get', `${tenantId}/skill/`),
  createSkillInfo: ({ tenantId, body }) =>
    API('post', `${tenantId}/skill/add`, body),
  updateSkillInfo: ({ tenantId, body, skillId }) =>
    API('put', `${tenantId}/skill/${skillId}/update`, body),
  deleteSkillInfo: ({ tenantId, skillId }) =>
    API('delete', `${tenantId}/skill/${skillId}`)
};

export default api;
