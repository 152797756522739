import update from 'immutability-helper';
import _ from 'lodash';
import moment from 'moment';

update.extend('$updateOrDelete', (value, listData) => {
  const index = listData.findIndex(
    (item) => item[value[2] || 'id'] === value[0]
  );

  if (index === -1) {
    return listData;
  }

  if (value[1]) {
    return update(listData, {
      [index]: { $merge: value[1] }
    });
  }

  if (value[0] && !value[1]) {
    return update(listData, {
      $splice: [[index, 1]]
    });
  }

  return listData;
});

const parseEmployeeInfo = (emp) => {
  const contractName = emp?.contract?.name;
  const contact = _.get(emp, 'information.contact.0') || {};
  const email = contact?.email;
  const mobile = contact?.line;
  const otherInfo = _.get(emp, 'information.others.0') || {};
  const team = otherInfo?.department;
  const rank = otherInfo?.supervisor;

  return {
    ...emp,
    rrules: emp?.rrules || [],
    contractName,
    email,
    mobile,
    team,
    rank
  };
};

const parseContractInfo = (contract) => {
  if (!_.isEmpty(contract)) {
    return {
      ...contract,
      maximum_minutes_per_day: contract?.maximum_minutes_per_day,
      maximum_minutes_per_week: contract?.maximum_minutes_per_week,
      maximum_minutes_per_month: contract?.maximum_minutes_per_month,
      maximum_minutes_per_year: contract?.maximum_minutes_per_year,
      minimum_minutes_per_week: contract?.minimum_minutes_per_week
    };
  } else {
    return {};
  }
};

const parseRotation = (rotation) => {
  return {
    ...rotation,
    start_time: moment(rotation.start_time, 'HH:mm:ss').format('HH:mm'),
    end_time: moment(rotation.end_time, 'HH:mm:ss').format('HH:mm')
  };
};

const getPreferenceGridData = (rawData = [], currentMonth) => {
  let tempListData = [];

  rawData.forEach((item) => {
    // get start, end of shift, end with AM or PM
    const startTime = moment.utc(item.start_date_time);
    const endTime = moment.utc(item.end_date_time);

    const indexOfAvail = _.findIndex(tempListData, {
      start: `${startTime.format('YYYY-MM-DD')}T00:00:00Z`
    });

    const startHour = startTime.format('HH:mm');
    const endHour = endTime.format('HH:mm');

    const availInfo = {
      startHour,
      endHour,
      availId: item.id,
      employee_reason: item.employee_reason,
      start_date_time: item.start_date_time,
      end_date_time: item.end_date_time,
      employee: item.employee,
      message: item.message,
      spots: item.spots || []
    };

    if (indexOfAvail === -1) {
      tempListData.push({
        start: `${startTime.format('YYYY-MM-DD')}T00:00:00Z`,
        listAvail: [availInfo]
      });
    } else {
      tempListData[indexOfAvail].listAvail.push(availInfo);
    }
  });

  tempListData = tempListData.map((item) => {
    const sortedList = _.sortBy(item.listAvail, ['startHour']);

    return {
      start: item.start,
      listAvail: _.cloneDeep(sortedList)
    };
  });

  const noOfDay = currentMonth.daysInMonth();
  const year = currentMonth.year();
  const month =
    currentMonth.month() < 9
      ? `0${currentMonth.month() + 1}`
      : currentMonth.month() + 1;

  for (let i = 1; i <= noOfDay; i++) {
    let dateString;

    if (i < 10) {
      dateString = `${year}-${month}-0${i}T00:00:00Z`;
    } else {
      dateString = `${year}-${month}-${i}T00:00:00Z`;
    }

    if (!_.find(tempListData, { start: dateString })) {
      tempListData.push({
        start: dateString,
        listAvail: []
      });
    }
  }

  return { listAvailability: tempListData };
};

export {
  update,
  parseEmployeeInfo,
  parseContractInfo,
  parseRotation,
  getPreferenceGridData
};
