import styled from 'styled-components';

const EmployeeSettingPageWrapper = styled.div`
  font-size: 1.6rem;

  .header-wrapper {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid var(--color-border-gray);

    .label {
      color: var(--color-text);
      text-transform: uppercase;
      font-size: 1.3rem;
      font-weight: 500;
    }

    .filter-wrapper {
      display: flex;
      justify-content: space-between;

      .search-bar {
        margin-right: 3rem;
        margin-left: 3rem;
      }

      .input-border {
        border-radius: 4px;
      }
    }
  }
`;

export { EmployeeSettingPageWrapper };
