import _ from 'lodash';

const convertTableFilter = (filter) => {
  const objectFilter = {};

  if (!_.isEmpty(filter)) {
    Object.keys(filter).forEach((key) => {
      if (typeof filter[key] === 'string' || filter[key]) {
        objectFilter[key] = filter[key];
      }

      if (typeof filter[key] === 'object' && filter[key]) {
        objectFilter[key] = filter[key];
      }
    });
  }

  return objectFilter;
};

const convertTableSorter = (sorter) => {
  let objectSorter = {};

  if (!_.isEmpty(sorter) && sorter.order) {
    objectSorter = { field: sorter.field, order: sorter.order };
  }

  return objectSorter;
};

const filterDataOnTable = ({
  listData,
  filter,
  sorter,
  filterOr,
  matchExact = []
}) => {
  let tempListData = [];
  if (_.isEmpty(filterOr)) {
    tempListData = [...listData];
  }

  // filterOr must be take care before filter and sorter
  if (!_.isEmpty(filterOr)) {
    Object.keys(filterOr).forEach((key) => {
      const regex = new RegExp(filterOr[key], 'i');

      const tempResult = listData.filter((record) => regex.test(record[key]));

      tempListData = [...tempListData, ...tempResult];
    });

    tempListData = _.uniqBy(tempListData, 'id');
  }

  if (!_.isEmpty(filter)) {
    Object.keys(filter).forEach((key) => {
      if (!matchExact.includes(key)) {
        const regex = new RegExp(filter[key], 'i');
        tempListData = tempListData.filter((record) => regex.test(record[key]));
      } else {
        tempListData = tempListData.filter((record) =>
          filter[key].includes(record[key])
        );
      }
    });
  }

  if (!_.isEmpty(sorter)) {
    tempListData = _.orderBy(
      tempListData,
      [sorter.field],
      [sorter.order === 'descend' ? 'desc' : 'asc']
    );
  }

  return tempListData;
};

const filterListEmployee = (listEmployee, text) => {
  let tempListEmployee;

  if (!!text) {
    const regex = new RegExp(text, 'i');
    tempListEmployee = listEmployee.filter((emp) => regex.test(emp.name));
  } else {
    tempListEmployee = [...listEmployee];
  }

  return tempListEmployee;
};

export {
  convertTableFilter,
  convertTableSorter,
  filterDataOnTable,
  filterListEmployee
};
