import React, { useEffect, useState } from 'react';
import { Table, Popconfirm, Button, Input, Tag, message } from 'antd';
import { connect } from 'react-redux';
import { FaRegEdit } from 'react-icons/fa';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { RiDeleteBin6Line } from 'react-icons/ri';
import {
  actGetEmployeeSetting,
  actDeleteEmployeeInfo,
  actSaveDeleteEmployeeInfo,
  actSearchEmployee,
  actSetModalEmployeeSettingVisible,
  actSetSelectedEmployeeSetting,
  actUpdateEmployeeToInactive
} from 'redux/action/employeeSetting';
import { actGetContractSetting } from 'redux/action/contractSetting';
import { actGetEmployeeReasonSetting } from 'redux/action/employeeReason';
import { actGetSkillSetting } from 'redux/action/skillSetting';
import { convertTableFilter, convertTableSorter } from 'utils/helper';
import { TableWrapper, TableActionWrapper } from 'component/style';
import { useTranslation } from 'component/index';
import ModalEmployeeSetting from './ModalEmployeeSetting';

const columnConfig = ({
  onDeleteEmployeeInfo,
  getColumnSearchProps,
  onEditEmployee,
  translate
}) => [
  {
    title: translate('employeeSetting.name'),
    dataIndex: 'name',
    key: 'name',
    sorter: true,
    width: '14%',
    ...getColumnSearchProps('name')
  },
  {
    title: translate('employeeSetting.contract'),
    dataIndex: 'contractName',
    key: 'contractName',
    sorter: true,
    width: '12%',
    ...getColumnSearchProps('contractName')
  },
  {
    title: translate('employeeSetting.team'),
    dataIndex: 'team',
    key: 'team',
    sorter: true,
    width: '7%',
    ...getColumnSearchProps('team')
  },
  {
    title: translate('employeeSetting.rank'),
    dataIndex: 'rank',
    key: 'rank',
    sorter: true,
    width: '7%',
    ...getColumnSearchProps('rank')
  },
  {
    title: translate('employeeSetting.skills'),
    dataIndex: 'skills',
    key: 'skills',
    width: '10%',
    render: (skills) => {
      if (skills) {
        return skills.map((skill, index) => (
          <Tag color="purple" style={{ marginTop: '0.5rem' }} key={index}>
            {skill.name}
          </Tag>
        ));
      }

      return null;
    }
  },
  {
    title: translate('employeeSetting.email'),
    dataIndex: 'email',
    key: 'email',
    width: '13%',
    sorter: true,
    ...getColumnSearchProps('email')
  },
  {
    title: translate('employeeSetting.mobile'),
    dataIndex: 'mobile',
    key: 'mobile',
    sorter: true,
    width: '12%',
    ...getColumnSearchProps('mobile')
  },
  {
    title: translate('employeeSetting.isActive'),
    dataIndex: 'is_active',
    key: 'is_active',
    sorter: true,
    width: '12%',
    render: (value) => (value ? 'Yes' : 'No')
  },
  {
    title: translate('table.actions'),
    dataIndex: 'is_active',
    key: 'actions',
    // width: '10%',
    render: (value, record) => (
      <TableActionWrapper>
        <FaRegEdit
          className="edit-icon"
          onClick={() => onEditEmployee(record)}
        />
        {value && (
          <Popconfirm
            onConfirm={() => onDeleteEmployeeInfo(record)}
            title={translate('message.confirmToDelete')}
            okText={translate('yes')}
            cancelText={translate('no')}
          >
            <RiDeleteBin6Line className="delete-icon" />
          </Popconfirm>
        )}
      </TableActionWrapper>
    )
  }
];

const EmployeeTable = (props) => {
  const { tenantId, listEmployee, toggleStatus } = props;

  let searchInput = null;
  const [isLoading, setLoading] = useState(false);
  const [searchText, onSetSearchText] = useState([]);
  const { translate } = useTranslation();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10
  });

  useEffect(() => {
    setLoading(true);
    props.actGetSkillSetting({ tenantId });
    props.actGetContractSetting({ tenantId });
    props.actGetEmployeeReasonSetting({ tenantId });

    props.actGetEmployeeSetting({ tenantId }, (err, res) => {
      setLoading(false);
    });
  }, [tenantId]);

  const getColumnSearchProps = () => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={translate('typeAkeyWord')}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {translate('search')}
        </Button>

        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          {translate('reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => value.toString(),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text) => {
      return (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={searchText}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      );
    }
  });

  const handleSearch = (selectedKeys, confirm) => {
    onSetSearchText(selectedKeys);
    confirm();
  };

  const handleReset = (clearFilters) => {
    onSetSearchText([]);
    clearFilters();
  };

  const onEditEmployee = (record) => {
    props.actSetSelectedEmployeeSetting(record);
    props.actSetModalEmployeeSettingVisible(true);
  };

  const onShowTotalData = () => {
    return `${listEmployee.length} ${translate('table.rowInTotal')}`;
  };

  const onDeleteEmployeeInfo = (employee) => {
    props.actUpdateEmployeeToInactive(
      { tenantId, employeeId: employee.id, body: { is_active: false } },
      (err) => {
        if (!err) {
          props.actGetEmployeeSetting({
            tenantId,
            employeeStatus: toggleStatus ? 'all' : 'active'
          });
          message.success(translate('employeeSetting.deleteSuccess'));
        } else {
          message.error(err.message);
        }
      }
    );
  };

  const onTableChange = (pagination, filter, sorter) => {
    setPagination(pagination);
    const tableFilter = convertTableFilter(filter);
    const tableSorter = convertTableSorter(sorter);

    props.actSearchEmployee({ filter: tableFilter, sorter: tableSorter });
  };

  const columns = columnConfig({
    onDeleteEmployeeInfo,
    getColumnSearchProps,
    onEditEmployee,
    translate
  });

  return (
    <TableWrapper>
      <Table
        columns={columns}
        rowKey={(data) => data.id}
        dataSource={listEmployee}
        scroll={{ x: 'auto' }}
        pagination={{
          ...pagination,
          showTotal: onShowTotalData,
          pageSizeOptions: [10, 20, 30, 40],
          showSizeChanger: true
        }}
        size="large"
        loading={isLoading}
        onChange={onTableChange}
      />

      <ModalEmployeeSetting toggleStatus={toggleStatus} />
    </TableWrapper>
  );
};

export default connect(
  (state) => ({
    tenantId: state?.App?.user?.tenant?.id,
    listEmployee: state.EmployeeSetting.listEmployee
  }),
  {
    actGetEmployeeSetting,
    actDeleteEmployeeInfo,
    actSaveDeleteEmployeeInfo,
    actGetSkillSetting,
    actSearchEmployee,
    actGetContractSetting,
    actSetModalEmployeeSettingVisible,
    actSetSelectedEmployeeSetting,
    actUpdateEmployeeToInactive,
    actGetEmployeeReasonSetting
  }
)(EmployeeTable);
