import React, { useEffect } from 'react';
import EmployeeGrid from 'component/EmployeeGrid';
import { actSetSidebarClose } from 'redux/action/layout';
import { connect } from 'react-redux';
import { actSetGridFullScreen } from 'redux/action/grid';

const EmployeePage = (props) => {
  const { actSetGridFullScreen, isGridFullScreen } = props;

  useEffect(() => {
    props.actSetSidebarClose(true);
  }, []);

  const exitHandler = () => actSetGridFullScreen(!isGridFullScreen);

  const removeFullScreenListener = () => {
    document.removeEventListener('fullscreenchange', exitHandler);
    document.removeEventListener('webkitfullscreenchange', exitHandler);
    document.removeEventListener('mozfullscreenchange', exitHandler);
    document.removeEventListener('MSFullscreenChange', exitHandler);
  };

  const addFullScreenListener = () => {
    document.addEventListener('fullscreenchange', exitHandler, false);
    document.addEventListener('webkitfullscreenchange', exitHandler, false);
    document.addEventListener('mozfullscreenchange', exitHandler, false);
    document.addEventListener('MSFullscreenChange', exitHandler, false);
  };

  useEffect(() => {
    removeFullScreenListener();
    addFullScreenListener();
    return removeFullScreenListener;
  }, [isGridFullScreen]);

  const openFullScreen = () => {
    const node = document.documentElement;

    if (node.requestFullscreen) {
      node.requestFullscreen();
    } else if (node.webkitRequestFullscreen) {
      /* Safari */
      node.webkitRequestFullscreen();
    } else if (node.msRequestFullscreen) {
      /* IE11 */
      node.msRequestFullscreen();
    }
  };

  const closeFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      /* Safari */
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      /* IE11 */
      document.msExitFullscreen();
    }
  };

  return (
    <EmployeeGrid
      openFullScreen={openFullScreen}
      closeFullScreen={closeFullScreen}
    />
  );
};

export default connect(
  (state) => ({
    isGridFullScreen: state?.App?.isGridFullScreen
  }),
  {
    actSetGridFullScreen,
    actSetSidebarClose
  }
)(EmployeePage);
