import React, { useEffect } from 'react';
import { Menu, Dropdown, Avatar } from 'antd';
import { RocketOutlined, UserOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { persistor } from 'redux/store';
import { actLogout } from 'redux/action/auth';
import { useNavigate as useHistory } from 'react-router-dom';
import { useTranslation } from 'component/index';
import { actGetProfileDetails } from 'redux/action/profile';

const UserInfo = (props) => {
  const { profile, user } = props;
  const history = useHistory();
  const { translate } = useTranslation();

  useEffect(() => {
    props.actGetProfileDetails({
      tenantId: user?.tenant?.id,
      userId: user?.id
    });
  }, [user]);

  const onLogOut = () => {
    props.actLogout();
    persistor.purge();
  };

  const changeProfile = () => {
    history('/profile');
  };

  const renderUserMenu = () => {
    return (
      <Menu>
        <Menu.Item key="1" onClick={changeProfile}>
          <UserOutlined />
          <span style={{ marginLeft: '0.5rem' }}>{translate('Profile')}</span>
        </Menu.Item>
        <Menu.Item key="2" onClick={onLogOut}>
          <RocketOutlined />
          <span style={{ marginLeft: '0.5rem' }}>{translate('logout')}</span>
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <Dropdown
      overlay={renderUserMenu()}
      placement="bottomLeft"
      overlayStyle={{ width: 200 }}
    >
      <div>
        <span style={{ marginRight: '10px', fontSize: '1.6rem' }}>
          {profile
            ? profile.first_name +
              ' ' +
              profile.last_name +
              '-' +
              user?.tenant?.name
            : ''}
        </span>
        <Avatar
          icon={<UserOutlined />}
          style={{ cursor: 'pointer', border: '1px solid lightgray' }}
          size={35}
        />
      </div>
    </Dropdown>
  );
};

export default connect(
  (state) => ({
    profile: state.Profile.data,
    user: state.App?.user
  }),
  {
    actLogout,
    actGetProfileDetails
  }
)(UserInfo);
