import axios from 'axios';
import { API_TIMEOUT } from 'utils/constant';
import { store } from 'redux/store';

const API = async (method = 'get', url, requestBody, params) => {
  try {
    const { App } = store.getState();
    const { token, accessToken } = App;
    const localLocale = localStorage.getItem('locale') || 'en';
    const acceptLanguage = localLocale === 'jp' ? 'ja' : localLocale;

    const authHeader =
      token || accessToken
        ? { Authorization: `Bearer ${token || accessToken}` }
        : {};

    const headers = {
      ...authHeader,
      Accept: 'application/json',
      'Accept-Language': acceptLanguage
    };

    const res = await axios({
      method,
      url: url,
      data: requestBody,
      params,
      timeout: API_TIMEOUT,
      headers
    });

    return res;
  } catch (error) {
    return error.response;
  }
};

export default API;
