import {
  DELETE_EMPLOYEE_SKILL_SET,
  CREATE_EMPLOYEE_SKILL_SET
} from 'redux/type';

export const actDeleteEmployeeSkillSet = (...args) => ({
  type: DELETE_EMPLOYEE_SKILL_SET,
  args
});

export const actCreateEmployeeSkillSet = (...args) => ({
  type: CREATE_EMPLOYEE_SKILL_SET,
  args
});
