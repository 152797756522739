import { useState } from 'react';
import { Row, Col } from 'antd';
import bgUrl from 'assets/call-to-action-bg.svg';
import { useTranslation } from 'component/index';
import { CallToActionSectionWrapper } from './style';
import RequestDemoModal from './RequestDemoModal';

const CallToActionSection = () => {
  const { translate } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);

  const onCloseModal = () => {
    setModalVisible(false);
  };

  const onOpenModal = () => {
    setModalVisible(true);
  };

  return (
    <CallToActionSectionWrapper bgUrl={bgUrl}>
      <Row type="flex" justify="center">
        <Col xxl={18} xl={20} lg={23} md={24} sm={24} xs={24}>
          <div className="main">
            <div className="content">
              <div className="title">
                {translate('homepage.callToAction.heading')}
              </div>

              <p className="description">
                {translate('homepage.callToAction.description')}
              </p>
            </div>

            <div className="primary-button" onClick={onOpenModal}>
              {translate('homepage.callToAction.requestDemo')}
            </div>
          </div>
        </Col>
      </Row>

      <RequestDemoModal
        onCloseModal={onCloseModal}
        modalVisible={modalVisible}
      />
    </CallToActionSectionWrapper>
  );
};

export default CallToActionSection;
