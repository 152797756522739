import {
  GET_SHIFT_TYPE_SETTING,
  UPDATE_SHIFT_TYPE_INFO,
  DELETE_SHIFT_TYPE_INFO,
  SAVE_DELETE_SHIFT_TYPE_INFO,
  CREATE_SHIFT_TYPE,
  SEARCH_SHIFT_TYPE,
  SET_SELECTED_SHIFT_TYPE_SETTING,
  SET_MODAL_SHIFT_TYPE_SETTING_VISIBLE,
  SET_SELECTED_BREAK_PAIR,
  SET_MODAL_BREAK_PAIR_VISIBLE
} from 'redux/type';

export const actGetShiftTypeSetting = (...args) => ({
  type: GET_SHIFT_TYPE_SETTING,
  args
});

export const actUpdateShiftTypeInfo = (...args) => ({
  type: UPDATE_SHIFT_TYPE_INFO,
  args
});

export const actDeleteShiftTypeInfo = (...args) => ({
  type: DELETE_SHIFT_TYPE_INFO,
  args
});

export const actSaveDeleteShiftTypeInfo = (payload) => ({
  type: SAVE_DELETE_SHIFT_TYPE_INFO,
  payload
});

export const actCreateShiftType = (...args) => ({
  type: CREATE_SHIFT_TYPE,
  args
});

export const actSearchShiftType = (payload) => ({
  type: SEARCH_SHIFT_TYPE,
  payload
});

export const actSetSelectedShiftTypeSetting = (payload) => ({
  type: SET_SELECTED_SHIFT_TYPE_SETTING,
  payload
});

export const actSetModalShiftTypeSettingVisible = (payload) => ({
  type: SET_MODAL_SHIFT_TYPE_SETTING_VISIBLE,
  payload
});

export const actSetSelectedBreakPair = (payload) => ({
  type: SET_SELECTED_BREAK_PAIR,
  payload
});

export const actSetModalBreakPairVisible = (payload) => ({
  type: SET_MODAL_BREAK_PAIR_VISIBLE,
  payload
});
