import API from './common';

const api = {
  getTimeReporting: ({ tenantId, employeeId, year, month }) =>
    API(
      'get',
      `${tenantId}/time-reporting/?employeeId=${employeeId}&year=${year}&month=${month}`
    ),
  createTimeReporting: ({ tenantId, body }) =>
    API('post', `${tenantId}/time-reporting/`, body),
  updateTimeReporting: ({ tenantId, body, id }) =>
    API('patch', `${tenantId}/time-reporting/${id}`, body),
  deleteTimeReporting: ({ tenantId, id }) =>
    API('delete', `${tenantId}/time-reporting/${id}`),
  getTimeReportingStatistic: ({ tenantId, employeeId, year }) =>
    API(
      'get',
      `${tenantId}/time-reporting/stats?employeeId=${employeeId}&year=${year}`
    )
};

export default api;
