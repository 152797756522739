import styled from 'styled-components';

const PreferenceChartWrapper = styled.div`
  display: flex;
  flex-direction: column;

  .title {
    font-weight: 500;
    font-size: 2rem;
  }

  .legend {
    display: flex;
    justify-self: center;
    margin-bottom: 0.5rem;
    align-self: center;
    margin-top: 2rem;
  }
`;

const LegendWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2rem;

  .icon {
    margin-right: 0.5rem;
    width: 3rem;
    height: 1rem;
  }

  .label {
    font-size: 1.2rem;
  }
`;

const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  span {
    margin-left: 0.5rem;
  }
`;

export { PreferenceChartWrapper, LegendWrapper, TooltipWrapper };
