import { createContext } from 'react';
import en from './en.json';
import jp from './jp.json';

export const dictionaryList = { en, jp };

export const listLocale = {
  en: 'English',
  jp: 'Japanese'
};

export const LOCALE = {
  EN: 'en',
  JP: 'jp'
};

export const LanguageContext = createContext({
  locale: 'en'
});
