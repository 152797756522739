import React, { useState, useRef, useMemo } from 'react';
import { Select, Tag, Checkbox, notification, Tooltip, message } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { LIST_TAG_COLOR } from 'utils/constant';
import {
  actSaveListData,
  actSetStatisticRow,
  actSaveDataByColumn,
  actCreateShift,
  actUpdateShift,
  actCreateAvailability,
  actDeleteShift,
  actUpdateAvailability
} from 'redux/action/grid';
import { actDeletePreferenceAvail } from 'redux/action/preference';
import { useTranslation } from 'component/index';
import {
  getRowStatistic,
  getGridDataByColumn,
  onDrag
} from 'component/EmployeeGrid/util';
import { EditableCellWrapper } from './style';
import { ROW_HEIGHT } from './index';

const { Option } = Select;
const UPDATE_SHIFT_MODE = {
  AFTER_CREATE: 'AFTER_CREATE',
  UPDATE_PIN_STATUS: 'UPDATE_PIN_STATUS',
  DRAG_DROP_SHIFT: 'DRAG_DROP_SHIFT'
};

const ROOL_BACK_SHIFT = 'ROOL_BACK_SHIFT';
const MAX_SHIFT_NAME_LENGTH = 5;

const EditableCell = (props) => {
  const {
    colDef,
    data,
    listData,
    api,
    node,
    tenantId,
    listShiftType,
    listEmployeeReason,
    listTimeline,
    listRotation,
    availEditable
  } = props;

  const rowData = data;
  const colName = colDef.field.toString();
  const cellData = data[colName];

  const [isEditing, setIsEditing] = useState(false);
  const { translate } = useTranslation();

  const selectEl = useRef(null);
  const listOldData = useRef(null);

  const cellReason = useMemo(() => {
    return cellData?.availability
      ? _.find(listEmployeeReason, {
          id: cellData?.availability?.employee_reason
        })
      : {};
  }, [cellData?.availability]);

  // if is statistic value, then just render pure <span> tag, does not need cell to be editable
  if (typeof cellData === 'string' || typeof cellData === 'number') {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        }}
      >
        <span>{cellData}</span>
      </div>
    );
  }

  const forceRefreshStatisticCell = (sourceRowNode) => {
    api.refreshCells({
      columns: listShiftType.map((shiftType) => shiftType.id.toString()),
      force: true,
      rowNodes: sourceRowNode ? [sourceRowNode, node] : [node]
    });
  };

  /**
   * @cellValue {value: "", sourceCell: { sourceCol: "", sourceRowData: {}}}
   *    + value: value of the cell drop to
   *    + sourceCell: if drag from other cell, then sourceCell will have value (sourceCol: the name of source cell, sourceRowData: what is the row source cell belong to)
   *
   */
  const onDropShift = (event) => {
    event.preventDefault();

    if (cellReason?.name === 'Unavailable') {
      notification['warning']({
        message: translate('assignment.canNotAssignShift')
      });

      return;
    }

    const cellValue = JSON.parse(event.dataTransfer.getData('cellValue'));
    listOldData.current = _.cloneDeep(listData);

    // if there is no shift data in this cell, create shift.
    if (!_.get(cellValue, 'sourceCell')) {
      isShiftValidInRotation(cellValue.value) && onCreateShift(cellValue);
    } else {
      const { sourceCell } = cellValue;
      const { sourceCol, sourceRowData } = sourceCell;

      if (
        sourceCol !== colName || // if drag-drop between column
        rowData.date !== sourceRowData.date || // else drag-drop between diference date
        (rowData.date === sourceRowData.date && // else drag-drop between same date but start_time OR end_time is difference
          (rowData.start_time !== sourceRowData.start_time ||
            rowData.end_time !== sourceRowData.end_time))
      ) {
        isShiftValidInRotation(cellValue.value) && afterMoveShift(cellValue);
      }
    }
  };

  const isShiftValidInRotation = (spotId) => {
    const foundedRotation = _.find(
      listRotation,
      (rotation) =>
        rotation.start_time === rowData.start_time &&
        rotation.end_time === rowData.end_time &&
        rotation.spot === +spotId
    );

    if (foundedRotation) {
      return true;
    }

    message.error(
      `${translate('assignment.rotationFor')} ${
        _.find(listShiftType, { id: +spotId })?.name
      } ${translate('assignment.at')} ${rowData.start_time} - ${
        rowData.end_time
      } ${translate('assignment.doesNotExist')}`
    );
    return false;
  };

  const afterMoveShift = (cellValue) => {
    const { sourceCell } = cellValue;
    const { shift } = sourceCell;
    const shiftId = shift?.id;
    const startTime = moment(`${rowData.date}T${rowData.start_time}`);
    let endTime = moment(`${rowData.date}T${rowData.end_time}`);

    const foundRotation = _.find(
      listRotation,
      (rotation) =>
        rotation.start_time === rowData.start_time &&
        rotation.end_time === rowData.end_time &&
        rotation.spot === +cellValue.value
    );

    // Move end time to the day after if the time is before startTime.
    if (endTime < startTime) {
      endTime.add(1, 'day');
    }

    onUpdateGridWhenChangeShift(
      cellValue,
      shiftId,
      UPDATE_SHIFT_MODE.DRAG_DROP_SHIFT
    );

    props.actUpdateShift(
      {
        tenantId,
        shiftId,
        body: {
          version: 0,
          start_date_time: startTime.format('YYYY-MM-DDTHH:mm'),
          end_date_time: endTime.format('YYYY-MM-DDTHH:mm'),
          employee: +colName,
          spot: shift?.spot,
          rotation: foundRotation?.id
        }
      },
      (err, res) => {
        if (err) {
          onRollBackState(ROOL_BACK_SHIFT);
          message.error(translate('assignment.updateShiftFail'));
        } else if (res) {
          message.success(translate('assignment.updateShiftSuccess'));
        }
      }
    );
  };

  const onCreateShift = (cellValue) => {
    onUpdateGridWhenChangeShift(cellValue);
    const startTime = moment(`${rowData.date}T${rowData.start_time}`);
    let endTime = moment(`${rowData.date}T${rowData.end_time}`);

    const foundRotation = _.find(
      listRotation,
      (rotation) =>
        rotation.start_time === rowData.start_time &&
        rotation.end_time === rowData.end_time &&
        rotation.spot === +cellValue.value
    );

    // Move end time to the day after if the time is before startTime.
    if (endTime < startTime) {
      endTime.add(1, 'day');
    }

    props.actCreateShift(
      {
        body: {
          version: 0,
          start_date_time: startTime.format('YYYY-MM-DDTHH:mm'),
          end_date_time: endTime.format('YYYY-MM-DDTHH:mm'),
          spot: +cellValue.value,
          employee: +colName,
          rotation: foundRotation?.id
        },
        tenantId
      },
      (err, res) => {
        if (err) {
          onRollBackState(ROOL_BACK_SHIFT, cellValue.value);
          message.error(translate('assigment.createShiftFail'));
        } else if (res) {
          // update shift_id when receive response from backend
          onUpdateGridWhenChangeShift(
            cellValue,
            res.id,
            UPDATE_SHIFT_MODE.AFTER_CREATE
          );
          message.success(translate('assignment.createShiftSuccess'));
        }
      }
    );
  };

  const onRollBackState = (type) => {
    props.actSaveListData(listOldData.current);

    // if roll back shift
    if (type === ROOL_BACK_SHIFT) {
      const dataByColumn = getGridDataByColumn(listOldData.current);
      props.actSaveDataByColumn(dataByColumn);
      props.actSetStatisticRow(getRowStatistic(dataByColumn, listTimeline));
      forceRefreshStatisticCell();
    }
  };

  const onUpdateGridWhenChangeShift = (cellValue, shiftId, mode) => {
    const { sourceCell } = cellValue;

    // Find row index.
    const indexOfTargetRow = _.findIndex(listData, {
      date: rowData.date,
      end_time: rowData.end_time,
      start_time: rowData.start_time
    });

    const newData = [...listData];
    // Update cell data to row and table data
    const currentCell = newData[indexOfTargetRow][colName] || {
      shifts: [],
      availability: null
    };
    const listShiftInCell = [...currentCell?.shifts];

    if (
      mode === UPDATE_SHIFT_MODE.AFTER_CREATE ||
      mode === UPDATE_SHIFT_MODE.UPDATE_PIN_STATUS
    ) {
      const indexOfShift = _.findIndex(listShiftInCell, {
        id: mode === UPDATE_SHIFT_MODE.AFTER_CREATE ? null : shiftId
      });

      listShiftInCell.splice(indexOfShift, 1, {
        ...listShiftInCell[indexOfShift],
        id: shiftId,
        pinned_by_user: cellValue?.pinned_by_user
      });
    } else {
      listShiftInCell.push({
        id: shiftId || null,
        pinned_by_user: cellValue.pinned_by_user,
        spot: cellValue?.value
      });
    }

    newData[indexOfTargetRow][colName] = {
      ...currentCell,
      shifts: listShiftInCell
    };

    // if @sourceCell then remove data in the source cell
    if (sourceCell) {
      const { sourceCol, sourceRowData, shift } = sourceCell;
      // Find row index.
      const indexOfSourceRow = _.findIndex(listData, {
        date: sourceRowData.date,
        end_time: sourceRowData.end_time,
        start_time: sourceRowData.start_time
      });

      // remove shifts in source cell
      const listShiftInSourceCell =
        newData[indexOfSourceRow]?.[sourceCol]?.shifts || [];
      const indexOfShift = _.findIndex(listShiftInSourceCell, {
        id: shift?.id
      });

      listShiftInSourceCell.splice(indexOfShift, 1);
      newData[indexOfSourceRow][sourceCol].shifts = listShiftInSourceCell;

      // refresh statistic of both row
      if (indexOfSourceRow !== indexOfTargetRow) {
        forceRefreshStatisticCell(api.getRowNode(indexOfSourceRow));
      }
    }

    // Update table data.
    props.actSaveListData([...newData]);

    const dataByColumn = getGridDataByColumn(newData);
    props.actSaveDataByColumn(dataByColumn);
    props.actSetStatisticRow(getRowStatistic(dataByColumn, listTimeline));

    forceRefreshStatisticCell();
  };

  const toggleEdit = () => {
    if (availEditable) {
      setIsEditing(!isEditing);

      setTimeout(() => {
        if (!isEditing) {
          selectEl?.current?.focus();
        }
      }, 100);
    }
  };

  const onChangeEmployeeReason = (value) => {
    // if delete availability, call @onDeleteAvailability function instead
    if (!value) {
      return;
    }

    listOldData.current = _.cloneDeep(listData);

    if (!cellData?.availability?.id) {
      onCreateReasonState(+value);
    } else {
      onUpdateAvailability(+value);
    }
  };

  const onCreateReasonState = (reasonId) => {
    onUpdateGridWhenChangeReasonState(reasonId);

    const reason = _.find(listEmployeeReason, { id: reasonId });
    const currentDate = `${moment(rowData.date).year()}-${
      moment(rowData.date).month() + 1
    }-${moment(rowData.date).date()}`;
    const nextDay = moment(rowData.date).add(1, 'days');
    const startDateString = currentDate;
    const endDateString =
      rowData.end_time <= rowData.start_time
        ? `${moment(nextDay).year()}-${moment(nextDay).month() + 1}-${moment(
            nextDay
          ).date()}`
        : currentDate;

    props.actCreateAvailability(
      {
        tenantId,
        body: {
          version: 0,
          state: +reason.state,
          employee_reason: reasonId,
          start_date_time: `${startDateString}T${rowData.start_time}`,
          end_date_time: `${endDateString}T${rowData.end_time}`,
          employee: +colName
        }
      },
      (err, res) => {
        if (err) {
          onRollBackState();
          message.error(translate('assignment.addAvailablityFail'));
        } else {
          onUpdateGridWhenChangeReasonState(reasonId, res);
          message.success(translate('assignment.addAvailablitySuccess'));
        }
      }
    );
  };

  const onUpdateAvailability = (reasonId) => {
    onUpdateGridWhenChangeReasonState(reasonId);

    const currentDate = `${moment(rowData.date).year()}-${
      moment(rowData.date).month() + 1
    }-${moment(rowData.date).date()}`;
    const nextDate = moment(rowData.date).add(1, 'days');
    const reason = _.find(listEmployeeReason, { id: reasonId });
    const startDateString = currentDate;
    const endDateString =
      rowData.end_time <= rowData.start_time
        ? `${moment(nextDate).year()}-${moment(nextDate).month() + 1}-${moment(
            nextDate
          ).date()}`
        : currentDate;

    props.actUpdateAvailability(
      {
        tenantId,
        availId: cellData?.availability?.id,
        body: {
          version: 0,
          state: +reason.state,
          employee_reason: reasonId,
          start_date_time: `${startDateString}T${rowData.start_time}`,
          end_date_time: `${endDateString}T${rowData.end_time}`,
          employee: +colName
        }
      },
      (err, res) => {
        if (err) {
          onRollBackState();
          message.error(translate('assignment.updateAvailablityFail'));
        } else {
          message.success(translate('assignment.updateAvailablitySuccess'));
        }
      }
    );
  };

  const onUpdateGridWhenChangeReasonState = (reasonId, availInfo) => {
    const reason = _.find(listEmployeeReason, { id: +reasonId });

    // Find row index.
    const indexOfTargetRow = _.findIndex(listData, {
      date: rowData.date,
      end_time: rowData.end_time,
      start_time: rowData.start_time
    });

    const newData = [...listData];

    // Update cell data to row and table data.
    if (!newData[indexOfTargetRow][colName]) {
      newData[indexOfTargetRow][colName] = { shifts: [], availability: null };
    }

    const availCellInfo = {
      ...newData[indexOfTargetRow][colName].availability,
      state: +reason.state,
      employee_reason: +reasonId
    };

    // if create availability, then add created avail_id to redux store
    if (availInfo) {
      availCellInfo.id = availInfo?.id;
      availCellInfo.message = availInfo?.message;
    }

    newData[indexOfTargetRow][colName].availability = availCellInfo;

    // Update table data.
    props.actSaveListData(newData);
    const dataByColumn = getGridDataByColumn(newData);
    props.actSaveDataByColumn(dataByColumn);
    props.actSetStatisticRow(getRowStatistic(dataByColumn, listTimeline));

    toggleEdit();
  };

  const onDeleteAvailability = () => {
    props.actDeletePreferenceAvail(
      { tenantId, availId: cellData?.availability?.id },
      (err, res) => {
        if (!err) {
          message.success(translate('assignment.deleteAvailablitySuccess'));
          onUpdateGridAfterDeleteAvailability();
        } else {
          message.error(translate('assignment.deleteAvailablityFail'));
        }
      }
    );
  };

  const onUpdateGridAfterDeleteAvailability = () => {
    // Find row index.
    const indexOfTargetRow = _.findIndex(listData, {
      date: rowData.date,
      end_time: rowData.end_time,
      start_time: rowData.start_time
    });

    const newData = [...listData];

    newData[indexOfTargetRow][colName].availability = null;

    // Update table data.
    props.actSaveListData(newData);
    const dataByColumn = getGridDataByColumn(newData);
    props.actSaveDataByColumn(dataByColumn);
    props.actSetStatisticRow(getRowStatistic(dataByColumn, listTimeline));

    toggleEdit();
  };

  const onToggleLockShift = (event, shift) => {
    const status = event.target.checked;

    onUpdateGridWhenChangeShift(
      { ...shift, pinned_by_user: status },
      shift?.id,
      UPDATE_SHIFT_MODE.UPDATE_PIN_STATUS
    );

    props.actUpdateShift(
      {
        tenantId,
        shiftId: shift?.id,
        body: {
          pinned_by_user: status
        }
      },
      (err, res) => {
        if (err) {
          onRollBackState(ROOL_BACK_SHIFT);
        }
      }
    );
  };

  /**
   * @record cell {shifts: [], reason_state: ""}
   * @targetCol
   */
  const onRemoveShift = (shift) => {
    if (!shift?.pinned_by_user) {
      props.actDeleteShift(
        {
          tenantId,
          shiftId: shift?.id
        },
        (err, res) => {
          onUpdateGridWhenDeleteShift(shift?.id);
        }
      );
    } else {
      notification['warning']({
        message: translate('assignment.shiftPinned')
      });
    }
  };

  const onUpdateGridWhenDeleteShift = (shiftId) => {
    // Find row index.
    const indexOfRowData = _.findIndex(listData, {
      date: rowData.date,
      end_time: rowData.end_time,
      start_time: rowData.start_time
    });

    const newData = [...listData];
    // Update cell data to row and table data.
    const listShiftInCell = [...newData[indexOfRowData][colName].shifts];

    const indexOfShift = _.findIndex(listShiftInCell, { id: shiftId });
    listShiftInCell.splice(indexOfShift, 1);

    newData[indexOfRowData][colName].shifts = [...listShiftInCell];

    // Update table data.
    props.actSaveListData(newData);
    const dataByColumn = getGridDataByColumn(newData);
    props.actSaveDataByColumn(dataByColumn);
    props.actSetStatisticRow(getRowStatistic(dataByColumn, listTimeline));

    forceRefreshStatisticCell();
  };

  const onDragStart = (event, shift) => {
    if (!shift?.pinned_by_user) {
      onDrag(event, {
        value: shift?.spot,
        sourceCell: { sourceCol: colName, sourceRowData: rowData, shift }
      });
    }
  };

  // Source: https://stackoverflow.com/questions/50230048/react-ondrop-is-not-firing
  const onDragOver = (event) => {
    event.preventDefault();
  };

  const formatShiftName = (shiftName) => {
    return shiftName.length > MAX_SHIFT_NAME_LENGTH
      ? `${shiftName.slice(0, MAX_SHIFT_NAME_LENGTH)}..`
      : shiftName;
  };

  const renderListShift = () => {
    if (cellData?.shifts?.length > 0) {
      return cellData.shifts.map((shift, index) => {
        const indexOnTagColor = _.findIndex(listShiftType, {
          id: shift?.spot
        });

        return (
          <Tag
            draggable={!shift?.pinned_by_user}
            className="drag-item drag-item--celled"
            onDragStart={(event) => onDragStart(event, shift)}
            color={LIST_TAG_COLOR[indexOnTagColor % LIST_TAG_COLOR.length]}
            closable={true}
            onClose={() => onRemoveShift(shift)}
            key={index}
            style={{
              marginBottom:
                index !== cellData?.shifts?.length - 1 ? '0.7rem' : 0
            }}
          >
            <Checkbox
              onChange={(event) => onToggleLockShift(event, shift)}
              checked={shift?.pinned_by_user}
              style={{ marginRight: '10px', transform: 'scale(0.6)' }}
            />

            <span style={{ marginRight: 'auto' }}>
              {formatShiftName(
                _.find(listShiftType, { id: shift?.spot })?.name
              )}
            </span>
          </Tag>
        );
      });
    } else {
      return (
        <Tag className="drag-item drag-item--celled">
          {translate('assignment.shiftEmpty')}
        </Tag>
      );
    }
  };

  return (
    <EditableCellWrapper onDrop={onDropShift} onDragOver={onDragOver}>
      {isEditing ? (
        <span style={{ width: '100%' }}>
          <Select
            value={cellData?.availability?.employee_reason}
            style={{ width: '100%', marginBottom: '0.7rem' }}
            onChange={onChangeEmployeeReason}
            onBlur={toggleEdit}
            ref={selectEl}
            showAction="focus"
            allowClear={true}
            onClear={onDeleteAvailability}
          >
            {listEmployeeReason.map((reason, index) => (
              <Option
                key={index}
                value={reason.id}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center'
                }}
              >
                <img
                  src={reason.icon}
                  alt=""
                  style={{
                    width: '1.5rem',
                    height: '1.5rem',
                    marginRight: '1rem'
                  }}
                />

                <span>{reason.name}</span>
              </Option>
            ))}
          </Select>
        </span>
      ) : typeof cellData?.availability?.employee_reason === 'number' ? (
        <Tag className="avail-selection" onClick={toggleEdit}>
          <span
            className="avail-icon"
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '0.3rem 0'
            }}
          >
            <img
              src={cellReason?.icon}
              alt=""
              style={{ width: '1.3rem', height: '1.3rem' }}
            />
          </span>
        </Tag>
      ) : (
        <Tag className="avail-selection" onClick={toggleEdit}>
          -
        </Tag>
      )}

      {!!cellData?.availability?.message ? (
        <span className="avail-message">
          <Tooltip placement="top" title={cellData?.availability?.message}>
            <InfoCircleOutlined />
          </Tooltip>
        </span>
      ) : null}

      {cellData?.shifts?.length > 2 ? (
        <Scrollbars
          style={{ height: `${ROW_HEIGHT}px`, width: '100%' }}
          autoHideTimeout={500}
          autoHide
        >
          {renderListShift()}
        </Scrollbars>
      ) : (
        renderListShift()
      )}
    </EditableCellWrapper>
  );
};

export default connect(
  (state) => ({
    listData: state.Grid.listData || [],
    listTimeline: state.Grid.listTimeline,
    spotId: state.Grid.spotId,
    availEditable: state.Grid.availEditable,
    tenantId: state?.App?.user?.tenant?.id,
    listShiftType: state.ShiftTypeSetting.listShiftType,
    listEmployeeReason: state.EmployeeReason.listEmployeeReason,
    listRotation: state.RotationSetting.listRotation
  }),
  {
    actSaveListData,
    actSetStatisticRow,
    actSaveDataByColumn,
    actCreateShift,
    actUpdateShift,
    actCreateAvailability,
    actDeleteShift,
    actUpdateAvailability,
    actDeletePreferenceAvail
  }
)(EditableCell);
