import { Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { actSetModalAssignEmployeeOpen } from 'redux/action/skillView';

const AddShiftButton = (props) => {
  const { date } = props;

  const onOpenModalAssignEmployee = () => {
    props.actSetModalAssignEmployeeOpen({
      dateShiftInfo: date
    });
  };

  return (
    <Tag
      className="skill-item"
      onClick={onOpenModalAssignEmployee}
      style={{
        cursor: 'pointer',
        marginBottom: '1.5rem',
        marginTop: '1.5rem'
      }}
    >
      <PlusOutlined className="tag-add-item" />
    </Tag>
  );
};

export default connect((state) => ({}), {
  actSetModalAssignEmployeeOpen
})(AddShiftButton);
