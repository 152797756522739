import API from './common';

const api = {
  getEmployeeSetting: ({ tenantId, employeeStatus = 'active' }) =>
    API('get', `${tenantId}/employee/?show=${employeeStatus}`),
  getEmployeeById: ({ tenantId, employeeId }) =>
    API('get', `${tenantId}/employee/${employeeId}`),
  createEmployeeInfo: ({ tenantId, body }) =>
    API('post', `${tenantId}/employee/add`, body),
  updateEmployeeInfo: ({ tenantId, body, employeeId }) =>
    API('put', `${tenantId}/employee/${employeeId}/update`, body),
  deleteEmployeeInfo: ({ tenantId, employeeId }) =>
    API('delete', `${tenantId}/employee/${employeeId}`),
  updateEmployeeToInactive: ({ tenantId, employeeId, body }) =>
    API('put', `${tenantId}/employee/${employeeId}/update_status`, body),
  getEmployeeShifts: ({ tenantId, employeeId, month, year }) =>
    API('get', `${tenantId}/shift/`, null, {
      employee_id: employeeId,
      month,
      year
    }),
  searchEmployeeAntiPattern: ({ tenantId, search_query }) =>
    API('get', `${tenantId}/employee/search/${search_query}`)
};

export default api;
