import {
  GET_SHIFT_GROUP,
  UPDATE_SHIFT_GROUP,
  DELETE_SHIFT_GROUP,
  SAVE_DELETE_SHIFT_GROUP,
  CREATE_SHIFT_GROUP,
  SET_SELECTED_SHIFT_GROUP_SETTING,
  SET_MODAL_SHIFT_GROUP_SETTING_VISIBLE
} from 'redux/type';

export const actGetShiftGroup = (...args) => ({
  type: GET_SHIFT_GROUP,
  args
});

export const actUpdateShiftGroup = (...args) => ({
  type: UPDATE_SHIFT_GROUP,
  args
});

export const actDeleteShiftGroup = (...args) => ({
  type: DELETE_SHIFT_GROUP,
  args
});

export const actSaveDeleteShiftGroup = (payload) => ({
  type: SAVE_DELETE_SHIFT_GROUP,
  payload
});

export const actCreateShiftGroup = (...args) => ({
  type: CREATE_SHIFT_GROUP,
  args
});

export const actSetSelectedShiftGroupSetting = (payload) => ({
  type: SET_SELECTED_SHIFT_GROUP_SETTING,
  payload
});

export const actSetModalShiftGroupSettingVisible = (payload) => ({
  type: SET_MODAL_SHIFT_GROUP_SETTING_VISIBLE,
  payload
});
