import {
  GET_TIME_REPORTING,
  SET_MODAL_TIME_REPORT_VISIBLE,
  CREATE_TIME_REPORT,
  UPDATE_TIME_REPORT,
  DELETE_TIME_REPORT,
  SET_SELECTED_TIME_REPORT,
  GET_TIME_REPORT_STATISTIC
} from 'redux/type';

export const actGetTimeReporting = (...args) => ({
  type: GET_TIME_REPORTING,
  args
});

export const actCreateTimeReport = (...args) => ({
  type: CREATE_TIME_REPORT,
  args
});

export const actUpdateTimeReport = (...args) => ({
  type: UPDATE_TIME_REPORT,
  args
});

export const actDeleteTimeReport = (...args) => ({
  type: DELETE_TIME_REPORT,
  args
});

export const actSetModalTimeReportVisible = (payload) => ({
  type: SET_MODAL_TIME_REPORT_VISIBLE,
  payload
});

export const actSetSelectedTimeReport = (payload) => ({
  type: SET_SELECTED_TIME_REPORT,
  payload
});

export const actGetTimeReportStatistic = (...args) => ({
  type: GET_TIME_REPORT_STATISTIC,
  args
});
