import { PURGE } from 'redux-persist';
import _ from 'lodash';
import {
  SAVE_GET_TIME_REPORTING,
  SAVE_CREATE_TIME_REPORT,
  SAVE_UPDATE_TIME_REPORT,
  SAVE_DELETE_TIME_REPORT,
  SET_MODAL_TIME_REPORT_VISIBLE,
  SET_SELECTED_TIME_REPORT,
  SAVE_GET_TIME_REPORT_STATISTIC
} from '../type';
import { update } from './helper';

const initialState = {
  listData: [],
  modalVisible: false,
  selectedTimeReport: {},
  statistic: {}
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case SAVE_GET_TIME_REPORTING:
      return {
        ...state,
        listData: payload
      };

    case SAVE_CREATE_TIME_REPORT:
      return {
        ...state,
        listData: [payload, ...state.listData]
      };

    case SAVE_UPDATE_TIME_REPORT:
      return update(state, {
        listData: { $updateOrDelete: [payload.id, payload] }
      });

    case SAVE_DELETE_TIME_REPORT:
      return update(state, {
        listData: { $updateOrDelete: [payload.id] }
      });

    case SET_MODAL_TIME_REPORT_VISIBLE:
      return {
        ...state,
        modalVisible: payload
      };

    case SET_SELECTED_TIME_REPORT:
      return {
        ...state,
        selectedTimeReport: payload
      };

    case SAVE_GET_TIME_REPORT_STATISTIC:
      return {
        ...state,
        statistic: payload
      };

    default:
      return state;
  }
};

export default reducer;
