import { Tooltip } from 'antd';
import { LegendWrapper, TooltipWrapper } from './style';

const CustomLegend = (props) => {
  const { icon, label, color } = props;

  return (
    <Tooltip
      title={
        <TooltipWrapper>
          <div className="icon">
            <img src={icon} />
          </div>
          <span>{label}</span>
        </TooltipWrapper>
      }
    >
      <LegendWrapper>
        <div className="icon" style={{ backgroundColor: color }} />
        <div className="label">
          {label?.length > 5 ? label?.slice(0, 5) + '...' : label}
        </div>
      </LegendWrapper>
    </Tooltip>
  );
};

export default CustomLegend;
