import API from './common';

const api = {
  getContractSetting: ({ tenantId }) => API('get', `${tenantId}/contract/`),
  createContractInfo: ({ tenantId, body }) =>
    API('post', `${tenantId}/contract/add`, body),
  updateContractInfo: ({ tenantId, body, contractId }) =>
    API('put', `${tenantId}/contract/${contractId}/update`, body),
  deleteContractInfo: ({ tenantId, contractId }) =>
    API('delete', `${tenantId}/contract/${contractId}`)
};

export default api;
