import { StepItemWrapper } from './style';

const StepItem = (props) => {
  const { title, description } = props;

  return (
    <StepItemWrapper>
      <div className="title">{title}</div>
      <div className="description">{description}</div>
    </StepItemWrapper>
  );
};

export default StepItem;
