import { PURGE } from 'redux-persist';
import { SET_SIGNUP_DATA, SET_SETUP_DATA } from 'redux/type';

const initialState = {
  signup: {},
  setup: {}
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case SET_SIGNUP_DATA:
      return {
        ...state,
        signup: { ...payload }
      };
    case SET_SETUP_DATA:
      return {
        ...state,
        setup: { ...payload }
      };

    default:
      return state;
  }
};

export default reducer;
