import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import createRootReducer from './reducer';
import rootSaga from './saga';

const persistConfig = {
  key: 'aquilla',
  storage,
  whitelist: ['App', 'ShiftTypeSetting']
};

const persistedReducer = persistReducer(persistConfig, createRootReducer());
const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
