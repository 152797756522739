import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, DatePicker, Popconfirm } from 'antd';
import { CloseOutlined, EditOutlined, CheckOutlined } from '@ant-design/icons';
import { useTranslation } from 'component/index';
import { DateSelectWrapper } from './style';

const DateSelect = (props) => {
  const { date, onChangeDate, index, onDeleteDate, selected } = props;
  const [isEditable, setIsEditable] = useState(false);
  const [currentDate, setCurrentDate] = useState(null);
  const { translate } = useTranslation();

  useEffect(() => {
    if (date !== null) {
      setCurrentDate(moment(date, 'YYYY-MM-DD'));
    } else {
      setIsEditable(true);
    }
  }, [date]);

  const onToggleEdit = () => {
    if (isEditable) {
      onChangeDate(currentDate, index);
    }

    setIsEditable(!isEditable);
  };

  const isDisabledDate = (current) => {
    const fCurrent = current.format('YYYY-MM-DD');
    return fCurrent !== date && selected.includes(fCurrent);
  };

  return (
    <DateSelectWrapper>
      <span className="date">
        {isEditable ? (
          <DatePicker
            defaultPickerValue={currentDate || moment(props.defaultValue)}
            onChange={setCurrentDate}
            value={currentDate}
            disabledDate={isDisabledDate}
            size="small"
          />
        ) : (
          <span>{moment(date).format('MMMM Do YYYY')}</span>
        )}
      </span>

      <Button
        icon={isEditable ? <CheckOutlined /> : <EditOutlined />}
        type="dashed"
        style={{ marginLeft: '0.5rem', fontSize: '0.7rem' }}
        shape="circle"
        size="small"
        onClick={onToggleEdit}
      />

      <Popconfirm
        onConfirm={() => onDeleteDate(index)}
        title={translate('message.confirmToDelete')}
      >
        <Button
          icon={<CloseOutlined />}
          type="dashed"
          style={{ marginLeft: '0.5rem', fontSize: '0.7rem' }}
          shape="circle"
          size="small"
        />
      </Popconfirm>
    </DateSelectWrapper>
  );
};

export default DateSelect;
