import React from 'react';
import { Route } from 'react-router-dom';

const PublicRoute = (props) => {
  const { component: ComponentInRoute, ...rest } = props;

  return <ComponentInRoute />;
  // <Route {...rest} element={<ComponentInRoute />} />;
};

export default PublicRoute;
