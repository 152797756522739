import API from './common';

const api = {
  getEmployeeReasonSetting: ({ tenantId }) =>
    API('get', `reasons/${tenantId}/employee-reason`),
  createEmployeeReasonInfo: ({ tenantId, body }) =>
    API('post', `reasons/${tenantId}/employee-reason`, body),
  updateEmployeeReasonInfo: ({ tenantId, body, employeereasonId }) =>
    API(
      'put',
      `reasons/${tenantId}/employee-reason/update/${employeereasonId}/`,
      body
    ),
  deleteEmployeeReasonInfo: ({ tenantId, employeereasonId }) =>
    API('delete', `reasons/${tenantId}/employee-reason/${employeereasonId}`)
};

export default api;
