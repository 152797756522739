import { Row, Col } from 'antd';
import { PlanningSectionWrapper } from './style';
import planningBg from 'assets/planning-bg.svg';
import planningIcon from 'assets/planning-icon.svg';
import { useTranslation } from 'component/index';
import StepItem from './StepItem';

const listStep = (translate) => [
  {
    title: translate('homepage.planning.step1'),
    description: translate('homepage.planning.description1')
  },
  {
    title: translate('homepage.planning.step2'),
    description: translate('homepage.planning.description2')
  },
  {
    title: translate('homepage.planning.step3'),
    description: translate('homepage.planning.description3')
  },
  {
    title: translate('homepage.planning.step4'),
    description: translate('homepage.planning.description4')
  }
];

const PlanningSection = () => {
  const { translate } = useTranslation();

  return (
    <PlanningSectionWrapper planningBg={planningBg}>
      <Row type="flex" justify="center">
        <Col xxl={18} xl={20} lg={23} md={24} sm={24} xs={24}>
          <div className="main">
            <div className="content">
              <h2 className="heading">
                {translate('homepage.planning.heading')}{' '}
                <span>{translate('homepage.planning.four')}</span>{' '}
                {translate('homepage.planning.step')}
              </h2>

              <div className="list-step">
                {listStep(translate).map(({ title, description }, index) => (
                  <StepItem
                    title={title}
                    description={description}
                    key={index}
                  />
                ))}
              </div>
            </div>

            <div className="icon-wrapper">
              <img src={planningIcon} alt="planning icon" />
            </div>
          </div>
        </Col>
      </Row>
    </PlanningSectionWrapper>
  );
};

export default PlanningSection;
