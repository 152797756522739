import API from './common';

const api = {
  submitwizardinfo: ({ tenantId, body } = {}) =>
    API('post', `${tenantId}/wizard/setup`, body),
  validatePassword: ({ body } = {}) =>
    API('post', `user/validate-password/`, body)
};

export default api;
