import React, { useEffect, useState } from 'react';
import { Table, Popconfirm, Button, Input, message } from 'antd';
import { connect } from 'react-redux';
import { FaRegEdit } from 'react-icons/fa';
import { RiDeleteBin6Line } from 'react-icons/ri';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import {
  actGetSkillSetting,
  actUpdateSkillInfo,
  actDeleteSkillInfo,
  actSaveDeleteSkillInfo,
  actCreateSkill,
  actSearchSkill,
  actSetModalSkillSettingVisible,
  actSetSelectedSkillSetting
} from 'redux/action/skillSetting';
import { convertTableFilter, convertTableSorter } from 'utils/helper';
import { TableWrapper, TableActionWrapper } from 'component/style';
import { useTranslation } from 'component/index';
import ModalSkillSetting from './ModalSkillSetting';

const columnConfig = ({
  onDeleteSkillInfo,
  getColumnSearchProps,
  onEditSkill,
  translate
}) => [
  {
    title: translate('skill.name'),
    dataIndex: 'name',
    key: 'name',
    width: '20%',
    sorter: true,
    ...getColumnSearchProps('name')
  },
  {
    title: translate('table.actions'),
    dataIndex: 'actions',
    fixed: 'right',
    key: 'actions',
    width: '15%',
    render: (value, record) => (
      <TableActionWrapper>
        <FaRegEdit className="edit-icon" onClick={() => onEditSkill(record)} />

        <Popconfirm
          onConfirm={() => onDeleteSkillInfo(record)}
          title={translate('message.confirmToDelete')}
          okText={translate('yes')}
          cancelText={translate('no')}
        >
          <RiDeleteBin6Line className="delete-icon" />
        </Popconfirm>
      </TableActionWrapper>
    )
  },
  {
    title: '',
    dataIndex: 'empty',
    key: 'empty',
    width: '70%'
  }
];

const SkillTable = (props) => {
  const { tenantId, listSkill } = props;

  let searchInput = null;
  const [isLoading, setLoading] = useState(false);
  const [searchText, onSetSearchText] = useState([]);
  const { translate } = useTranslation();
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10
  });

  useEffect(() => {
    setLoading(true);
    props.actGetSkillSetting({ tenantId }, (err, res) => {
      setLoading(false);
    });
  }, [tenantId]);

  const getColumnSearchProps = () => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={translate('typeAkeyWord')}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          {translate('search')}
        </Button>

        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          {translate('reset')}
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => value.toString(),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text) => {
      return (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={searchText}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      );
    }
  });

  const handleSearch = (selectedKeys, confirm) => {
    onSetSearchText(selectedKeys);
    confirm();
  };

  const handleReset = (clearFilters) => {
    onSetSearchText([]);
    clearFilters();
  };

  const onEditSkill = (record) => {
    props.actSetModalSkillSettingVisible(true);
    props.actSetSelectedSkillSetting(record);
  };

  const onShowTotalData = () => {
    return `${listSkill.length} ${translate('table.rowInTotal')}`;
  };

  const onDeleteSkillInfo = (skill) => {
    props.actDeleteSkillInfo({ tenantId, skillId: skill.id }, (err) => {
      if (!err) {
        props.actSaveDeleteSkillInfo({ id: skill.id });
        message.success(translate('skill.deleteSkillSuccess'));
      } else {
        message.error(translate('skill.deleteSkillFail'));
      }
    });
  };

  const onTableChange = (pagination, filter, sorter) => {
    setPagination(pagination);
    const tableFilter = convertTableFilter(filter);
    const tableSorter = convertTableSorter(sorter);

    props.actSearchSkill({ filter: tableFilter, sorter: tableSorter });
  };

  const columns = columnConfig({
    onDeleteSkillInfo,
    getColumnSearchProps,
    onEditSkill,
    translate
  });

  return (
    <TableWrapper>
      <Table
        columns={columns}
        rowKey={(data) => data.id}
        dataSource={listSkill}
        pagination={{
          ...pagination,
          showTotal: onShowTotalData,
          pageSizeOptions: [10, 20, 30, 40],
          showSizeChanger: true
        }}
        rowClassName="editable-row"
        loading={isLoading}
        onChange={onTableChange}
      />

      <ModalSkillSetting />
    </TableWrapper>
  );
};

export default connect(
  (state) => ({
    tenantId: state?.App?.user?.tenant?.id,
    listSkill: state.SkillSetting.listSkill
  }),
  {
    actGetSkillSetting,
    actUpdateSkillInfo,
    actDeleteSkillInfo,
    actSaveDeleteSkillInfo,
    actCreateSkill,
    actSearchSkill,
    actSetModalSkillSettingVisible,
    actSetSelectedSkillSetting
  }
)(SkillTable);
