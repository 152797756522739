import API from './common';

const api = {
  getTeamSetting: ({ tenantId }) => API('get', `${tenantId}/spot/`),
  createTeamSetting: ({ tenantId, body }) =>
    API('post', `${tenantId}/spot/add`, body),
  updateTeamSetting: ({ tenantId, spotId, body }) =>
    API('put', `${tenantId}/spot/${spotId}/update`, body),
  deleteTeamSetting: ({ tenantId, spotId }) =>
    API('delete', `${tenantId}/spot/${spotId}`)
};

export default api;
