import {
  SET_SELECTED_PREFERENCE_AVAIL,
  UPDATE_PREFERENCE_AVAIL,
  CREATE_PREFERENCE_AVAIL,
  SET_MODAL_PREFERENCE_VISIBLE,
  GET_EMPLOYEE_PREFERENCE,
  DELETE_PREFERENCE_AVAIL,
  SAVE_ON_DELETE_EMPLOYEE_PREF,
  SET_IS_VIEW_CONFIRMED,
  SAVE_LIST_PREFERENCE_SNAPSHOT,
  AFTER_DELETE_PREFERENCE_AVAIL
} from 'redux/type';

export const actSetSelectedPreferenceAvail = (payload) => ({
  type: SET_SELECTED_PREFERENCE_AVAIL,
  payload
});

export const actUpdatePreferenceAvail = (...args) => ({
  type: UPDATE_PREFERENCE_AVAIL,
  args
});

export const actDeletePreferenceAvail = (...args) => ({
  type: DELETE_PREFERENCE_AVAIL,
  args
});

export const actCreatePreferenceAvail = (...args) => ({
  type: CREATE_PREFERENCE_AVAIL,
  args
});

export const actSetModalPreferenceVisible = (payload) => ({
  type: SET_MODAL_PREFERENCE_VISIBLE,
  payload
});

export const actGetEmployeePreference = (...args) => ({
  type: GET_EMPLOYEE_PREFERENCE,
  args
});

export const actSaveOnDeleteEmployeePreference = ({ availId }) => ({
  type: SAVE_ON_DELETE_EMPLOYEE_PREF,
  payload: availId
});

export const actSetIsViewConfirmed = (payload) => ({
  type: SET_IS_VIEW_CONFIRMED,
  payload
});

export const actSaveListPreferenceSnapshot = (payload) => ({
  type: SAVE_LIST_PREFERENCE_SNAPSHOT,
  payload
});

export const actAfterDeletePreferenceAvail = (payload) => ({
  type: AFTER_DELETE_PREFERENCE_AVAIL,
  payload
});
