import React, { useState } from 'react';
import { Input, Button } from 'antd';
import { connect } from 'react-redux';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import SkillTable from 'component/SkillSetting/SkillTable';
import {
  actSearchSkill,
  actSetModalSkillSettingVisible,
  actSetSelectedSkillSetting
} from 'redux/action/skillSetting';
import { SEARCH_TABLE_TIMEOUT } from 'utils/constant';
import { useTranslation } from 'component/index';
import { SkillSettingPageWrapper } from './index.style';

let searchTimeout = null;

const SkillSettingPage = (props) => {
  const [searchText, setSearchText] = useState(false);
  const { translate } = useTranslation();

  const onSearchChange = (event) => {
    const { value } = event.target;

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchText(value);

    searchTimeout = setTimeout(() => {
      onSearch(value);
    }, SEARCH_TABLE_TIMEOUT);
  };

  const onCreateSkillSetting = () => {
    props.actSetModalSkillSettingVisible(true);
    props.actSetSelectedSkillSetting({});
  };

  const onSearch = (searchValue) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    if (!!searchValue) {
      props.actSearchSkill({
        filterOr: {
          name: searchValue
        },
        sorter: {},
        filter: {}
      });
    } else {
      props.actSearchSkill({
        filterOr: {}
      });
    }
  };

  return (
    <SkillSettingPageWrapper>
      <div className="header-wrapper">
        <div className="label">{translate('skill.header.skills')}</div>
        <div className="filter-wrapper">
          <div className="search-bar">
            <Input
              prefix={<SearchOutlined />}
              size="large"
              className="input-border"
              style={{ width: '25rem' }}
              placeholder={translate('searchByKeyword')}
              onChange={onSearchChange}
              searchText={searchText}
              onPressEnter={() => onSearch(searchText)}
            />
          </div>

          <Button
            type="primary"
            icon={<PlusOutlined />}
            className="custom-button"
            size="large"
            onClick={onCreateSkillSetting}
          >
            {translate('skill.addSkill')}
          </Button>
        </div>
      </div>

      <div className="main-content">
        <SkillTable />
      </div>
    </SkillSettingPageWrapper>
  );
};

export default connect((state) => ({}), {
  actSearchSkill,
  actSetModalSkillSettingVisible,
  actSetSelectedSkillSetting
})(SkillSettingPage);
