import {
  GET_TENANT_INFO,
  GET_FIRST_DRAFT_DATE,
  UPDATE_TENANT_INFO
} from '../type';

export const actGetTenantInfo = (...args) => {
  return {
    type: GET_TENANT_INFO,
    args
  };
};

export const actUpdateTenantInfo = (...args) => {
  return {
    type: UPDATE_TENANT_INFO,
    args
  };
};

export const actGetFirstDraftDate = (...args) => {
  return {
    type: GET_FIRST_DRAFT_DATE,
    args
  };
};
