import { SUBMIT_WIZARD_INFO, VALIDATE_PASSWORD } from 'redux/type';
import { createRequestSaga } from './common';
import WizardAPI from 'redux/api/wizard';
import { all, takeLatest } from 'redux-saga/effects';

const setupwizard = createRequestSaga({
  request: WizardAPI.submitwizardinfo,
  key: 'setupwizard',
  success: [],
  functionSuccess: [],
  functionFailure: []
});

const validatepassword = createRequestSaga({
  request: WizardAPI.validatePassword,
  key: 'validatepassword',
  success: [],
  functionSuccess: [],
  functionFailure: []
});

const saga = [
  function* fetchWatcher() {
    yield all([
      takeLatest(SUBMIT_WIZARD_INFO, setupwizard),
      takeLatest(VALIDATE_PASSWORD, validatepassword)
    ]);
  }
];

export default saga;
