import { useContext, useEffect } from 'react';
import { Dropdown, Menu } from 'antd';
import { LanguageSwitcherWrapper } from './style';
import { LanguageContext } from 'languages/index';
import { connect } from 'react-redux';
import { useTranslation } from 'component/useTranslation';
import { actUpdateProfileDetails } from 'redux/action/profile';
import enFlagUrl from 'assets/en.png';
import jpFlagUrl from 'assets/jp.jpg';

const LanguageSwitcher = (props) => {
  const { profile, user } = props;
  const { locale, onChangeLocale } = useContext(LanguageContext);
  const { translate } = useTranslation();

  useEffect(() => {
    if (Boolean(profile?.locale)) {
      // update global context for locale
      onChangeLocale(profile?.locale);
    }
  }, [profile?.locale]);

  const onUpdateLocale = (key) => {
    onChangeLocale(key);
    user?.id &&
      props.actUpdateProfileDetails({
        tenantId: user?.tenant?.id,
        userId: user?.id,
        body: { locale: key }
      });
  };

  const renderLanguageSelection = () => {
    return (
      <Menu selectedKeys={[locale]} onClick={({ key }) => onUpdateLocale(key)}>
        <Menu.Item key="jp">
          <img src={jpFlagUrl} alt="" className="language-flag" />
          {translate('japanese')}
        </Menu.Item>

        <Menu.Item key="en">
          <img src={enFlagUrl} alt="" className="language-flag" />
          {translate('english')}
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <LanguageSwitcherWrapper>
      <Dropdown overlay={renderLanguageSelection()}>
        <img
          src={locale === 'jp' ? jpFlagUrl : enFlagUrl}
          alt=""
          className="flag"
        />
      </Dropdown>
    </LanguageSwitcherWrapper>
  );
};

export default connect(
  (state) => ({
    profile: state.Profile.data,
    user: state.App.user
  }),
  {
    actUpdateProfileDetails
  }
)(LanguageSwitcher);
