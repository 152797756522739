import { useEffect, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { GRID_VIEW_MODE } from 'utils/constant';
import { actResetGridData } from 'redux/action/grid';
import { EmployeeGridWrapper } from './index.style';
import GridHeading from './GridHeading';
import AssignmentView from './GridView/AssignmentView/index';
import SkillView from './GridView/SkillViewCalendar/index';

const EmployeeGrid = (props) => {
  const { isGridFullScreen, openFullScreen, closeFullScreen, gridViewMode } =
    props;
  const [shouldSkillViewRefresh, setSkillViewRefresh] = useState(false);

  useEffect(() => {
    return () => {
      props.actResetGridData();
    };
  }, []);

  return (
    <EmployeeGridWrapper isGridFullScreen={isGridFullScreen}>
      <GridHeading
        openFullScreen={openFullScreen}
        closeFullScreen={closeFullScreen}
        setSkillViewRefresh={setSkillViewRefresh}
      />

      <div
        className="ag-theme-alpine"
        style={{ height: '100%', width: '100%' }}
      >
        {gridViewMode === GRID_VIEW_MODE.ASSIGNMENTS ? (
          <AssignmentView />
        ) : (
          <SkillView
            shouldSkillViewRefresh={shouldSkillViewRefresh}
            setSkillViewRefresh={setSkillViewRefresh}
          />
        )}
      </div>
    </EmployeeGridWrapper>
  );
};

export default connect(
  (state) => ({
    isGridFullScreen: state.App.isGridFullScreen,
    gridViewMode: state.Grid.gridViewMode
  }),
  { actResetGridData }
)(EmployeeGrid);
