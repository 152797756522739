import styled from 'styled-components';

const TableWrapper = styled.div`
  height: 100%;

  .react-js-cron-hours {
    display: none;
  }

  .react-js-cron-minutes {
    display: none;
  }

  .rule-update {
    float: right;
    margin-top: 10px;
  }

  .rule-update-button {
    margin-left: 10px;
  }
`;

const TableActionWrapper = styled.div`
  display: flex;
  align-items: center;

  .edit-icon,
  .clone-icon,
  .delete-icon {
    font-size: 1.5rem;
    cursor: pointer;
  }

  .edit-icon:hover {
    color: var(--color-secondary);
  }

  .clone-icon {
    font-size: 1.5rem;
    margin-left: 1rem;

    &:hover {
      color: var(--color-secondary);
    }
  }

  .delete-icon {
    margin-left: 1rem;

    &:hover {
      color: var(--color-red);
    }
  }

  .r-hard {
    color: red !important;
  }

  .r-hard .ant-radio-checked .ant-radio-inner {
    border-color: red !important ;
  }

  .r-hard .ant-radio-checked .ant-radio-inner:after {
    background-color: red;
  }

  .r-hard .ant-radio:hover .ant-radio-inner {
    border-color: red;
  }

  .r-medium {
    color: green !important ;
  }

  .r-medium .ant-radio-checked .ant-radio-inner {
    border-color: green !important ;
  }

  .r-medium .ant-radio-checked .ant-radio-inner:after {
    background-color: green;
  }

  .r-medium .ant-radio:hover .ant-radio-inner {
    border-color: green;
  }

  .r-soft {
    color: orange !important;
  }

  .r-soft .ant-radio-checked .ant-radio-inner {
    border-color: orange !important ;
  }

  .r-soft .ant-radio-checked .ant-radio-inner:after {
    background-color: orange;
  }

  .r-soft .ant-radio:hover .ant-radio-inner {
    border-color: orange;
  }
`;

export { TableWrapper, TableActionWrapper };
