import { useState } from 'react';
import { Input, Button } from 'antd';
import { connect } from 'react-redux';
import { SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { ShiftTypeSettingPageWrapper } from './index.style';
import {
  actSearchShiftType,
  actSetModalShiftTypeSettingVisible,
  actSetSelectedShiftTypeSetting
} from 'redux/action/shiftTypeSetting';
import ShiftTypeSettingTable from 'component/ShiftTypeSetting/ShiftTypeSettingTable';
import { SEARCH_TABLE_TIMEOUT } from 'utils/constant';
import { useTranslation } from 'component/index';

let searchTimeout = null;

const ShiftTypeSettingPage = (props) => {
  const [searchText, setSearchText] = useState(false);
  const { translate } = useTranslation();

  const onSearchChange = (event) => {
    const { value } = event.target;

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchText(value);

    searchTimeout = setTimeout(() => {
      onSearch(value);
    }, SEARCH_TABLE_TIMEOUT);
  };

  const onCreateShiftTypeSetting = () => {
    props.actSetModalShiftTypeSettingVisible(true);
    props.actSetSelectedShiftTypeSetting({});
  };

  const onSearch = (searchValue) => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    if (!!searchValue) {
      props.actSearchShiftType({
        filterOr: {
          name: searchValue
        },
        sorter: {},
        filter: {}
      });
    } else {
      props.actSearchShiftType({
        filterOr: {}
      });
    }
  };

  return (
    <ShiftTypeSettingPageWrapper>
      <div className="header-wrapper">
        <div className="label">{translate('shiftType.header.shiftType')}</div>
        <div className="filter-wrapper">
          <div className="search-bar">
            <Input
              prefix={<SearchOutlined />}
              size="large"
              className="input-border"
              style={{ width: '25rem' }}
              placeholder={translate('searchByKeyword')}
              onChange={onSearchChange}
              searchText={searchText}
              onPressEnter={() => onSearch(searchText)}
            />
          </div>

          <Button
            type="primary"
            icon={<PlusOutlined />}
            className="custom-button"
            size="large"
            onClick={onCreateShiftTypeSetting}
          >
            {translate('shiftType.addShiftType')}
          </Button>
        </div>
      </div>

      <div className="main-content">
        <ShiftTypeSettingTable />
      </div>
    </ShiftTypeSettingPageWrapper>
  );
};

export default connect((state) => ({}), {
  actSearchShiftType,
  actSetModalShiftTypeSettingVisible,
  actSetSelectedShiftTypeSetting
})(ShiftTypeSettingPage);
