import { Select, Button, Tag } from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import { PlusOutlined, DeleteOutlined, CopyOutlined } from '@ant-design/icons';
import { RequiredSkillWrapper } from './style';

const { Option } = Select;

export const RequiredSkillTag = (props) => {
  const { label, closable, onClose } = props;

  return (
    <Tag
      color="purple"
      closable={closable}
      onClose={onClose}
      style={{ marginTop: '0.5rem' }}
    >
      {label}
    </Tag>
  );
};

const RequiredSkill = (props) => {
  const {
    skillSetRow = [],
    indexOfGroup,
    setListSkillSet,
    listSkillSet,
    listSkill,
    onCloneSkillSet
  } = props;

  const onDeleteSkillSetInGroup = (indexOfSkillSetInGroup) => {
    const temListSkillSet = _.cloneDeep(listSkillSet);

    temListSkillSet[indexOfGroup].splice(indexOfSkillSetInGroup, 1);

    // if skill group is empty, delete that skill group
    if (temListSkillSet[indexOfGroup].length === 0) {
      temListSkillSet.splice(indexOfGroup, 1);
    }

    setListSkillSet(_.isEmpty(temListSkillSet) ? [[[]]] : [...temListSkillSet]);
  };

  const onChangeSkillSet = (listSkillId, indexOfSkillSetInGroup) => {
    if (listSkillId.length !== 0) {
      const temListSkillSet = _.cloneDeep(listSkillSet);
      temListSkillSet[indexOfGroup][indexOfSkillSetInGroup] = listSkillId;
      setListSkillSet([...temListSkillSet]);
    }
  };

  const onDeleteSkillGroup = () => {
    if (listSkillSet?.length === 1) {
      setListSkillSet([[[]]]);
    } else {
      const temListSkillSet = _.cloneDeep(listSkillSet);
      temListSkillSet.splice(indexOfGroup, 1);
      setListSkillSet([...temListSkillSet]);
    }
  };

  const onAddSkillSetInGroup = () => {
    const temListSkillSet = _.cloneDeep(listSkillSet);
    temListSkillSet[indexOfGroup].push([]);
    setListSkillSet([...temListSkillSet]);
  };

  return (
    <RequiredSkillWrapper>
      <div className="list-set">
        {/*  @skillSetRow contain array of skill id: [[1, 2], [3]] */}
        {skillSetRow?.map((skillSet, indexOfSkillSetInGroup) => (
          <span
            key={indexOfGroup + indexOfSkillSetInGroup}
            className="skill-set-item"
          >
            <Select
              mode="tags"
              placeholder="Select skill(s)"
              tagRender={RequiredSkillTag}
              value={skillSet}
              onChange={(value) =>
                onChangeSkillSet(value, indexOfSkillSetInGroup)
              }
              allowClear
              onClear={() => onDeleteSkillSetInGroup(indexOfSkillSetInGroup)}
              className="skill-select"
            >
              {listSkill.map((skill, index) => (
                <Option key={indexOfSkillSetInGroup + index} value={skill.id}>
                  {skill.name}
                </Option>
              ))}
            </Select>

            {skillSetRow.length - 1 === indexOfSkillSetInGroup ? (
              <span className="icon-wrapper">
                <Button
                  type="dashed"
                  shape="circle"
                  icon={<PlusOutlined />}
                  onClick={onAddSkillSetInGroup}
                />

                <Button
                  type="dashed"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  danger
                  className="trash-icon"
                  onClick={onDeleteSkillGroup}
                />

                {onCloneSkillSet && (
                  <Button
                    type="dashed"
                    shape="circle"
                    icon={<CopyOutlined />}
                    className="trash-icon"
                    onClick={onCloneSkillSet}
                  />
                )}
              </span>
            ) : null}
          </span>
        ))}
      </div>
    </RequiredSkillWrapper>
  );
};

export default connect(
  (state) => ({
    listSkill: state.SkillSetting.listSkill
  }),
  {}
)(RequiredSkill);
