import { useMemo } from 'react';
import { Tag, Checkbox } from 'antd';
import { connect } from 'react-redux';
import { UserOutlined } from '@ant-design/icons';
import _ from 'lodash';
import { actSetModalAssignEmployeeOpen } from 'redux/action/skillView';

const SkillItem = (props) => {
  const { listEmployee, data, listSkillSetting } = props;

  const onOpenModalAssignEmployee = (event, employeeId) => {
    props.actSetModalAssignEmployeeOpen({
      shiftInfo: data,
      employeeId,
      dateShiftInfo: data?.date
    });
  };

  const isHasEmployee = useMemo(() => !!data?.employee, [data]);
  const skillGroupNames = _.map(data?.skills, (skillSet) => {
    const skillSetNames = _.map(
      skillSet,
      (skillId) => _.find(listSkillSetting, { id: skillId })?.name
    );
    return skillSetNames.join(' & ');
  });
  const skillSetString = skillGroupNames.join(' | ');

  return (
    <Tag
      className="skill-item"
      color={isHasEmployee ? 'green' : 'purple'}
      onClick={(event) => onOpenModalAssignEmployee(event, data?.employee)}
      style={{ cursor: 'pointer' }}
    >
      {isHasEmployee && (
        <span>
          <Checkbox
            checked={data?.pinned_by_user}
            style={{ marginRight: '10px', transform: 'scale(0.7)' }}
          />
          <UserOutlined style={{ marginRight: '0.5rem' }} />
        </span>
      )}

      {isHasEmployee ? (
        _.find(listEmployee, { id: data?.employee })?.name || ''
      ) : (
        <p className="skill-string">{skillSetString}</p>
      )}
    </Tag>
  );
};

export default connect(
  (state) => ({
    listEmployee: state.EmployeeSetting.listData,
    listSkillSetting: state.SkillSetting.listSkill
  }),
  {
    actSetModalAssignEmployeeOpen
  }
)(SkillItem);
