import React, { useState, useEffect } from 'react';
import { Button, Form, Input, DatePicker, TimePicker, InputNumber } from 'antd';
import { connect } from 'react-redux';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'component/index';
import moment from 'moment';
import _ from 'lodash';
import { actSetSetupData } from 'redux/action/wizard';
import './WizardSetup.css';

const WizardSetup = (props) => {
  const [form] = Form.useForm();
  const { next, prev, setupData } = props;
  const { translate } = useTranslation();
  const [shifts, setShifts] = useState([
    {
      shift_name: '',
      shift_info: [{ start_time: '', end_time: '', noOfEmployees: '' }]
    }
  ]);

  useEffect(() => {
    if (!_.isEmpty(setupData)) {
      setShifts(setupData.shift_data);

      form.setFieldsValue({
        schedule_start_date: moment(setupData.schedule_start_date)
      });

      setupData.shift_data.forEach((ele, index) => {
        form.setFieldsValue({
          [`shift_name[${index}]`]: ele.shift_name
        });
        ele.shift_info.forEach((innerele, innerindex) => {
          form.setFieldsValue({
            [`shift_start[${index}][${innerindex}]`]: moment(
              innerele.start_time,
              'HH:mm'
            ),
            [`shift_end[${index}][${innerindex}]`]: moment(
              innerele.end_time,
              'HH:mm'
            ),
            [`no_of_employees[${index}][${innerindex}]`]: innerele.noOfEmployees
          });
        });
      });
    }
  }, [setupData]);

  const onChangeStartTime = (time, indexOfOuterGroup, indexOfInnerGroup) => {
    form.setFieldsValue({
      [`shift_start[${indexOfOuterGroup}][${indexOfInnerGroup}]`]: time
    });
  };

  const onChangeEndTime = (time, indexOfOuterGroup, indexOfInnerGroup) => {
    form.setFieldsValue({
      [`shift_end[${indexOfOuterGroup}][${indexOfInnerGroup}]`]: time
    });
  };

  const handleSetupForm = (values) => {
    let tempShift = _.cloneDeep(shifts);

    tempShift.forEach((element, index) => {
      tempShift[index] = {
        ...tempShift[index],
        shift_name: values[`shift_name[${index}]`]
      };
      element.shift_info.forEach((innerele, innerindex) => {
        tempShift[index].shift_info[innerindex] = {
          ...tempShift[index].shift_info[innerindex],
          start_time:
            values[`shift_start[${index}][${innerindex}]`].format('HH:mm'),
          end_time:
            values[`shift_end[${index}][${innerindex}]`].format('HH:mm'),
          noOfEmployees: values[`no_of_employees[${index}][${innerindex}]`]
        };
      });
    });

    const reduxSetupData = {
      shift_data: [...tempShift],
      schedule_start_date: values.schedule_start_date
        .startOf('month')
        .format('YYYY-MM-DD')
    };

    props.actSetSetupData(reduxSetupData);
    next();
  };

  const onDeleteShiftInfo = (indexOfOuterGroup, indexOfInnerGroup) => {
    const tempShiftInfo = _.cloneDeep(shifts);
    tempShiftInfo[indexOfOuterGroup].shift_info.splice(indexOfInnerGroup, 1);

    form.resetFields([
      `shift_start[${indexOfOuterGroup}][${indexOfInnerGroup}]`,
      `shift_end[${indexOfOuterGroup}][${indexOfInnerGroup}]`,
      `no_of_employees[${indexOfOuterGroup}][${indexOfInnerGroup}]`
    ]);

    setShifts(tempShiftInfo);
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf('month');
  };

  const AddNewShifts = () => {
    setShifts([
      ...shifts,
      {
        shift_name: '',
        shift_info: [{ start_time: '', end_time: '', noOfEmployees: '' }]
      }
    ]);
  };

  const onDeleteShiftCont = (indexOfOuterGroup) => {
    const tempShiftInfo = _.cloneDeep(shifts);
    tempShiftInfo[indexOfOuterGroup].shift_info.forEach((ele, index) => {
      form.resetFields([
        `shift_start[${indexOfOuterGroup}][${index}]`,
        `shift_end[${indexOfOuterGroup}][${index}]`,
        `no_of_employees[${indexOfOuterGroup}][${index}]`
      ]);
    });

    tempShiftInfo.splice(indexOfOuterGroup, 1);
    form.resetFields([`shift_name[${indexOfOuterGroup}]`]);

    setShifts(tempShiftInfo);
  };

  const renderShiftsContainer = () => {
    return shifts.map((shiftItem, indexOfOuterGroup) => (
      <div key={indexOfOuterGroup} className="shift__container">
        <div className="shifts__add">
          <Form.Item
            label={translate('wizard.setup.shiftName')}
            name={`shift_name[${indexOfOuterGroup}]`}
            rules={[
              {
                required: true,
                message: `${translate('wizard.setup.shiftName')} ${translate(
                  'isRequired'
                )}`
              }
            ]}
          >
            <Input style={{ width: '100%' }} />
          </Form.Item>
          {indexOfOuterGroup !== 0 && (
            <Button
              type="dashed"
              shape="circle"
              icon={<DeleteOutlined />}
              danger
              className="trash-icon"
              onClick={() => onDeleteShiftCont(indexOfOuterGroup)}
            />
          )}
        </div>
        {shiftItem.shift_info.map((shiftInfoItem, indexOfInnerGroup) => (
          <div
            key={indexOfInnerGroup}
            style={{ marginTop: '1px' }}
            className="shift__info"
          >
            <Form.Item
              label={translate('wizard.setup.startShift')}
              name={`shift_start[${indexOfOuterGroup}][${indexOfInnerGroup}]`}
              rules={[
                {
                  required: true,
                  message: `${translate('wizard.setup.startShift')} ${translate(
                    'isRequired'
                  )}`
                }
              ]}
            >
              <TimePicker
                format="HH:mm"
                placeholder={'Pick a time'}
                minuteStep={30}
                style={{ marginRight: '10px' }}
                onSelect={(time) =>
                  onChangeStartTime(time, indexOfOuterGroup, indexOfInnerGroup)
                }
              />
            </Form.Item>
            <Form.Item
              label={translate('wizard.setup.endShift')}
              name={`shift_end[${indexOfOuterGroup}][${indexOfInnerGroup}]`}
              rules={[
                {
                  required: true,
                  message: `${translate('wizard.setup.endShift')} ${translate(
                    'isRequired'
                  )}`
                }
              ]}
            >
              <TimePicker
                format="HH:mm"
                placeholder={'Pick a time'}
                minuteStep={30}
                style={{ marginRight: '10px' }}
                onSelect={(time) =>
                  onChangeEndTime(time, indexOfOuterGroup, indexOfInnerGroup)
                }
              />
            </Form.Item>
            <Form.Item
              label={translate('wizard.setup.noOfEmployees')}
              name={`no_of_employees[${indexOfOuterGroup}][${indexOfInnerGroup}]`}
              rules={[
                {
                  required: true,
                  message: `${translate(
                    'wizard.setup.noOfEmployees'
                  )} ${translate('isRequired')}`
                }
              ]}
            >
              <InputNumber
                style={{ width: '90%', marginRight: '10px' }}
                min={0}
              />
            </Form.Item>
            {indexOfInnerGroup !== 0 && (
              <Button
                style={{ marginTop: '27px' }}
                type="dashed"
                shape="circle"
                icon={<DeleteOutlined />}
                danger
                className="trash-icon"
                onClick={() =>
                  onDeleteShiftInfo(indexOfOuterGroup, indexOfInnerGroup)
                }
              />
            )}
          </div>
        ))}
      </div>
    ));
  };
  return (
    <div style={{ marginTop: '20px' }}>
      <Form layout="vertical" form={form} onFinish={handleSetupForm}>
        <Form.Item
          label={translate('wizard.setup.scheduleStartDate')}
          name="schedule_start_date"
          rules={[
            {
              required: true,
              message: `${translate(
                'wizard.setup.scheduleStartDate'
              )} ${translate('isRequired')}`
            }
          ]}
        >
          <DatePicker
            style={{ width: '100%' }}
            picker="month"
            format="MMMM YYYY"
            disabledDate={disabledDate}
          />
        </Form.Item>
        {renderShiftsContainer()}
        <Button type="primary" icon={<PlusOutlined />} onClick={AddNewShifts} />
        <div style={{ marginBottom: '30px', float: 'right' }}>
          <Button
            style={{ marginRight: '10px' }}
            className=""
            type="primary"
            onClick={prev}
          >
            {translate('wizard.prev')}
          </Button>
          <Button className="" type="primary" htmlType="submit">
            {translate('wizard.Next')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default connect(
  (state) => ({
    setupData: state.Wizard && state.Wizard.setup && state.Wizard.setup
  }),
  {
    actSetSetupData
  }
)(WizardSetup);
