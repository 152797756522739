import React, { useEffect, useRef, useState, Fragment } from 'react';
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Button,
  Select,
  Col,
  Row,
  message
} from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import { useTranslation } from 'component/index';
import {
  actSetModalContractSettingVisible,
  actSetSelectedContractSetting,
  actCreateContract,
  actUpdateContractInfo
} from 'redux/action/contractSetting';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

const { Option } = Select;

const ContractSettingModal = (props) => {
  const { modalVisible, selectedContractSetting, tenantId, listShiftType } =
    props;
  const [listShiftSet, setListShiftSet] = useState([]);
  const firstInput = useRef(null);
  const [form] = Form.useForm();
  const { translate } = useTranslation();
  const [shiftList, setShiftList] = useState([]);

  useEffect(() => {
    setShiftList(listShiftType);
  }, [listShiftType]);

  useEffect(() => {
    const {
      name,
      maximum_minutes_per_day,
      maximum_minutes_per_week,
      maximum_minutes_per_month
      // maximum_minutes_per_year
    } = selectedContractSetting;
    if (!_.isEmpty(selectedContractSetting)) {
      form.setFieldsValue({
        name,
        maximum_minutes_per_day,
        maximum_minutes_per_week,
        maximum_minutes_per_month
        // maximum_minutes_per_year
      });
      setListShiftSet(
        selectedContractSetting.contract_spot.map((item) => {
          return {
            id: item.id,
            spot_type: item.spot_type.id,
            spot_type_count: item.spot_type_count
          };
        })
      );
    } else {
      form.resetFields();
      setListShiftSet([]);
    }
  }, [selectedContractSetting]);

  useEffect(() => {
    if (modalVisible) {
      firstInput.current.focus();
    }
  }, [modalVisible]);

  const onHideModal = () => {
    props.actSetModalContractSettingVisible(false);
    props.actSetSelectedContractSetting({});
  };
  const onChangeShiftSet = (listShiftId, indexOfGroup) => {
    const temListSkillSet = _.cloneDeep(listShiftSet);
    temListSkillSet[indexOfGroup]['spot_type'] = listShiftId;
    setListShiftSet([...temListSkillSet]);
  };
  const handleShiftCount = (value, indexOfGroup) => {
    const temListSkillSet = _.cloneDeep(listShiftSet);
    temListSkillSet[indexOfGroup]['spot_type_count'] = value;
    setListShiftSet([...temListSkillSet]);
  };
  const onDeleteShiftGroup = (indexOfGroup) => {
    const temListShiftSet = _.cloneDeep(listShiftSet);
    temListShiftSet.splice(indexOfGroup, 1);
    setListShiftSet([...temListShiftSet]);
  };
  const onAddShiftGroup = () => {
    const temListSkillSet = _.cloneDeep(listShiftSet);
    temListSkillSet.push({
      spot_type: '',
      spot_type_count: ''
    });
    setListShiftSet([...temListSkillSet]);
  };
  const renderListShiftSet = () => {
    return listShiftSet.map((shiftSetRow, indexOfGroup) => (
      <Fragment>
        <Row justify="start" className="skill-set-row">
          <Col span="24" style={{}}>
            <div style={{ display: 'flex' }}>
              <Form.Item label={translate('contract.Shift_type')}>
                <Select
                  style={{ width: 135, marginRight: '10px' }}
                  value={shiftSetRow.spot_type}
                  onChange={(value) => onChangeShiftSet(value, indexOfGroup)}
                  allowClear
                >
                  {shiftList.map((shift, index) => (
                    <Option
                      key={index}
                      value={shift.id}
                      disabled={listShiftSet.some(
                        (item) => item.spot_type === shift.id
                      )}
                    >
                      {shift.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label={translate('contract.Shift_count')}>
                <InputNumber
                  value={shiftSetRow.spot_type_count}
                  className="input-border"
                  style={{ width: '20rem' }}
                  min={1}
                  onChange={(value) => handleShiftCount(value, indexOfGroup)}
                />
              </Form.Item>

              <span className="icon-wrapper">
                <Button
                  style={{ marginTop: '27px', marginLeft: '10px' }}
                  type="dashed"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  danger
                  className="trash-icon"
                  onClick={() => onDeleteShiftGroup(indexOfGroup)}
                />
              </span>
            </div>
          </Col>
        </Row>
      </Fragment>
    ));
  };

  const onSubmitForm = async () => {
    try {
      const {
        name,
        maximum_minutes_per_day,
        maximum_minutes_per_week,
        maximum_minutes_per_month
        // maximum_minutes_per_year
        // minimum_minutes_per_week
      } = await form.validateFields([
        'name',
        'maximum_minutes_per_day',
        'maximum_minutes_per_week',
        'maximum_minutes_per_month'
        // 'maximum_minutes_per_year'
        // 'minimum_minutes_per_week'
      ]);

      const contractInfo = {
        name,
        maximum_minutes_per_day,
        maximum_minutes_per_week,
        maximum_minutes_per_month,
        // maximum_minutes_per_year,
        // minimum_minutes_per_week,
        contract_spot: listShiftSet.filter(
          (item) => item.spot_type && item.spot_type_count
        )
      };

      if (_.isEmpty(selectedContractSetting)) {
        props.actCreateContract(
          {
            tenantId,
            body: contractInfo
          },
          (err) => {
            if (!err) {
              onHideModal();
              message.success(translate('contract.createSuccess'));
            } else {
              message.error(translate('contract.createFail'));
            }
          }
        );
      } else {
        props.actUpdateContractInfo(
          {
            tenantId,
            contractId: selectedContractSetting.id,
            body: contractInfo
          },
          (err) => {
            if (!err) {
              onHideModal();
              message.success(translate('contract.updateSuccess'));
            } else {
              message.error(translate('contract.updateFail'));
            }
          }
        );
      }
    } catch (err) {}
  };

  return (
    <Modal
      title={
        _.isEmpty(selectedContractSetting)
          ? translate('contract.createContract')
          : translate('contract.updateContract')
      }
      visible={modalVisible}
      cancelText={translate('cancel')}
      okText={
        _.isEmpty(selectedContractSetting)
          ? translate('create')
          : translate('update')
      }
      maskClosable={false}
      onOk={onSubmitForm}
      onCancel={onHideModal}
      forceRender={true}
      destroyOnClose={true}
      getContainer={false}
      style={{ top: '1rem' }}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          label={translate('contract.name')}
          rules={[
            {
              required: true,
              message: `${translate('contract.name')} ${translate(
                'isRequired'
              )}`
            }
          ]}
          name="name"
        >
          <Input ref={firstInput} />
        </Form.Item>

        <Form.Item
          label={translate('contract.maxMinutePerDay')}
          name="maximum_minutes_per_day"
        >
          <InputNumber style={{ width: '100%' }} min={0} />
        </Form.Item>

        <Form.Item
          label={translate('contract.maxMinutePerWeek')}
          name="maximum_minutes_per_week"
        >
          <InputNumber style={{ width: '100%' }} min={0} />
        </Form.Item>

        <Form.Item
          label={translate('contract.maxMinutePerMonth')}
          name="maximum_minutes_per_month"
        >
          <InputNumber style={{ width: '100%' }} min={0} />
        </Form.Item>

        {/*<Form.Item
          label={translate('contract.maxMinutePerYear')}
          name="maximum_minutes_per_year"
        >
          <InputNumber style={{ width: '100%' }} min={0} />
        </Form.Item>*/}

        {/* <Form.Item
          label={translate('contract.minMinutePerWeek')}
          name="minimum_minutes_per_week"
        >
          <InputNumber style={{ width: '100%' }} min={0} />
        </Form.Item> */}
        <label>{translate('contract.Shifts')}</label>
        <br />
        <div style={{ marginBottom: '10px' }}></div>
        {renderListShiftSet()}

        <Button
          type="primary"
          icon={<PlusOutlined />}
          style={{ marginTop: '2rem' }}
          onClick={onAddShiftGroup}
        />
      </Form>
    </Modal>
  );
};

export default connect(
  (state) => ({
    selectedContractSetting: state.ContractSetting.selectedContractSetting,
    modalVisible: state.ContractSetting.modalVisible,
    listShiftType: state.ShiftTypeSetting.listShiftType,
    tenantId: state?.App?.user?.tenant?.id
  }),
  {
    actSetModalContractSettingVisible,
    actSetSelectedContractSetting,
    actCreateContract,
    actUpdateContractInfo
  }
)(ContractSettingModal);
