import React, { useState, useMemo, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Form, message, Checkbox, Row, Button, DatePicker } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { actUpdateDefaultPreference } from 'redux/action/profile';
import { actGetTenantInfo, actUpdateTenantInfo } from 'redux/action/tenant';
import { LOCALE } from 'languages/index';
import { useTranslation } from 'component/index';
import { DisabledDateItemWrapper } from './style';

const DAYS_INDEX = [7, 1, 2, 3, 4, 5, 6];

const FormItem = Form.Item;
const CheckboxGroup = Checkbox.Group;

const SettingsForm = (props) => {
  const { tenantId, preferences, user, onSubmit } = props;

  const [listDisabledDate, setListDisabledDate] = useState([null]);
  const { translate, locale } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    props.actGetTenantInfo({ tenantId });
  }, [tenantId]);

  useEffect(() => {
    form.setFieldsValue({
      disabledWeekDay: preferences?.disabledWeekDay || []
    });

    if (!_.isEmpty(preferences?.disabledDates)) {
      setListDisabledDate(
        preferences?.disabledDates?.map((day) => moment(day, 'YYYY-MM-DD'))
      );
    }
  }, [preferences]);

  const listDay = useMemo(() => {
    if (locale === LOCALE.EN) {
      moment.locale('en-us');
    } else if (locale === LOCALE.JP) {
      moment.locale('ja');
    }

    return moment.weekdays();
  }, [locale]);

  const addListDisabledDate = () => {
    setListDisabledDate([...listDisabledDate, null]);
  };

  const updateListDisabledDate = (index, updateData) => {
    const tempList = [...listDisabledDate];
    tempList.splice(index, 1, updateData);
    setListDisabledDate(tempList);
  };

  const removeListDisabledDate = (index) => {
    const tempList = [...listDisabledDate];
    tempList.splice(index, 1);
    setListDisabledDate(tempList);
  };

  const renderListDisabledDate = () =>
    listDisabledDate.map((day, index) => (
      <DisabledDateItemWrapper key={index}>
        <DatePicker
          value={day}
          defaultPickerValue={day || moment(props.first_draft_date)}
          onChange={(date) => updateListDisabledDate(index, date)}
          style={{ marginRight: 'auto', flexBasis: '75%' }}
          format="YYYY-MM-DD"
        />

        {index === listDisabledDate.length - 1 ? (
          <Button
            icon={<PlusOutlined />}
            type="dashed"
            onClick={addListDisabledDate}
            style={{ marginRight: '1rem' }}
          />
        ) : null}

        <Button
          icon={<DeleteOutlined />}
          type="dashed"
          danger
          onClick={() => removeListDisabledDate(index)}
        />
      </DisabledDateItemWrapper>
    ));

  const onSubmitForm = async () => {
    const { disabledWeekDay } = await form.validateFields(['disabledWeekDay']);

    const disabledDates = listDisabledDate
      .filter((day) => Boolean(day))
      .map((day) => day.format('YYYY-MM-DD'));

    props.actUpdateTenantInfo(
      {
        tenantId: tenantId,
        userId: user.id,
        body: {
          preferences: {
            ...preferences,
            disabledWeekDay,
            disabledDates
          }
        }
      },
      (err) => {
        if (err) {
          message.error(err.message);
        } else {
          message.success('Update system settings succesfully');
          onSubmit();
        }
      }
    );
  };

  return (
    <Form layout="vertical" form={form} style={{ width: '40rem' }}>
      <FormItem label={translate('profile.disabledDates')}>
        {renderListDisabledDate()}
      </FormItem>

      <FormItem
        label={translate('profile.disabledWeekDay')}
        name="disabledWeekDay"
      >
        <CheckboxGroup>
          {listDay.map((day, index) => (
            <Row key={index} style={{ marginBottom: '0.5rem' }}>
              <Checkbox value={DAYS_INDEX[index]}>{day}</Checkbox>
            </Row>
          ))}
        </CheckboxGroup>
      </FormItem>

      <Button
        type="primary"
        className="custom-button"
        size="large"
        onClick={onSubmitForm}
      >
        {translate('update')}
      </Button>
    </Form>
  );
};

export default connect(
  (state) => ({
    first_draft_date: state?.App?.first_draft_date,
    tenantId: state?.App?.user?.tenant?.id,
    preferences: state?.App?.tenant?.preferences,
    user: state?.App?.user
  }),
  {
    actGetTenantInfo,
    actUpdateDefaultPreference,
    actUpdateTenantInfo
  }
)(SettingsForm);
