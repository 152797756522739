import { message } from 'antd';
import { takeLatest, all } from 'redux-saga/effects';
import {
  GET_SKILL_SETTING,
  SAVE_GET_SKILL_SETTING,
  UPDATE_SKILL_INFO,
  SAVE_UPDATE_SKILL_INFO,
  DELETE_SKILL_INFO,
  CREATE_SKILL,
  SAVE_CREATE_SKILL
} from 'redux/type';
import skillSettingAPI from 'redux/api/skillSetting';
import { createRequestSaga } from './common';

const getSkillSetting = createRequestSaga({
  request: skillSettingAPI.getSkillSetting,
  key: 'getSkillSetting',
  success: [
    (res) => ({
      type: SAVE_GET_SKILL_SETTING,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: [
    (err) => message.error('Error occured when get skill setting')
  ]
});

const updateSkillInfo = createRequestSaga({
  request: skillSettingAPI.updateSkillInfo,
  key: 'updateSkillInfo',
  success: [
    (res) => ({
      type: SAVE_UPDATE_SKILL_INFO,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: []
});

const deleteSkillInfo = createRequestSaga({
  request: skillSettingAPI.deleteSkillInfo,
  key: 'deleteSkillInfo',
  functionSuccess: [],
  functionFailure: []
});

const createSkillInfo = createRequestSaga({
  request: skillSettingAPI.createSkillInfo,
  key: 'createSkillInfo',
  success: [
    (res) => ({
      type: SAVE_CREATE_SKILL,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: []
});

const saga = [
  function* fetchWatcher() {
    yield all([
      takeLatest(GET_SKILL_SETTING, getSkillSetting),
      takeLatest(UPDATE_SKILL_INFO, updateSkillInfo),
      takeLatest(DELETE_SKILL_INFO, deleteSkillInfo),
      takeLatest(CREATE_SKILL, createSkillInfo)
    ]);
  }
];

export default saga;
