import styled from 'styled-components';

const DashboardLayoutWrapper = styled.div`
  .main-layout {
    min-height: 100vh;
    position: relative;
  }

  .ant-menu-submenu-title {
    padding-left: ${({ isSidebarClose }) =>
      isSidebarClose ? '1.5rem' : 'auto'} !important;
  }

  .header-normal {
    background-color: #fff !important;
    padding: 0 !important;

    .header-icon {
      font-size: 2.2rem;
      margin-left: 1rem;

      & > svg {
        cursor: pointer;
      }
    }
  }

  .sider-logo,
  .sider-logo--collapsed {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sider-logo {
    img {
      width: 10.5rem;
      height: 100%;
      object-fit: cover;
    }
  }

  .sider-logo--collapsed {
    img {
      width: 3rem;
      height: 3rem;
    }
  }

  .sider--normal {
    overflow-x: hidden !important;
    overflow-y: auto !important;
    min-height: 100vh !important;
    height: 100% !important;
    position: fixed !important;
    left: 0 !important;
    top: 0 !important;
    background-image: linear-gradient(
      to bottom,
      var(--color-sidebar),
      var(--color-primary),
      var(--color-secondary)
    ) !important;
  }

  .sider--mobile {
    overflow-x: hidden !important;
    overflow-y: auto !important;
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    height: 100% !important;
    z-index: 10 !important;
  }

  .side-menu-item {
    color: white;
    font-weight: 300;
    padding-left: 1.5rem !important;
    margin-left: 1rem !important;
    border-radius: 20px 0 0 20px !important;

    &--collapsed {
      margin-left: 0 !important;
    }
  }

  .menu-icon--collapsed {
    margin-left: 0;
  }

  .menu-label--collapse {
    display: none;
    margin-left: 10px;
  }
  span.anticon.anticon-home {
    color: unset !important;
  }
  span.menu-label {
    color: unset !important;
    margin-left: 10px;
  }
  span.anticon.anticon-aim {
    color: unset !important;
  }
  span.anticon.anticon-star {
    color: unset !important;
  }
  span.anticon.anticon-mail {
    color: unset !important;
  }
`;

const ContentWrapper = styled.div`
  background-color: white;
  padding: 2rem 2.4rem 2rem 2.4rem;
  border-radius: 5px;
  border-top: 3px solid var(--color-border-blue);
  height: ${({ isGridFullScreen }) => (!isGridFullScreen ? '100%' : '100vh')};
  min-height: 70vh;

  .fullscreen {
    height: 100%;
  }
`;

const NotificationWrapper = styled.div`
  background-color: white;
  box-shadow: var(--box-shadow);
  padding: 0 2rem 1.5rem 2rem;
  width: 40rem;

  @media only screen and(max-width:400px) {
    width: 100vw;
  }
`;

export { DashboardLayoutWrapper, ContentWrapper, NotificationWrapper };
