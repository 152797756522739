import _ from 'lodash';
import moment from 'moment';

// @start format in string YYYY-MM-DD
const getListRotationForDay = (
  listAvail,
  start,
  listRotation,
  listEmployeeReason
) => {
  const listDayHasRotation = {};
  listRotation.forEach((rotation) => {
    listDayHasRotation[rotation?.id] = rotation?.tab?.days;
  });

  const date = moment(start).format('YYYY-MM-DD');
  const dayInWeek = moment(start).day();

  const listRotationForDay = [];
  const isDailyRotation = _.isEmpty(_.flatten(_.map(listRotation, 'tab.days')));

  const uniqRotations = _.uniqBy(listRotation, (rotation) =>
    JSON.stringify(_.pick(rotation, ['start_time', 'end_time', 'tab.id']))
  );

  _.sortBy(uniqRotations, 'start_time').forEach((rotation) => {
    const { start_time, end_time } = rotation;

    const foundedAvail = _.find(listAvail, {
      startHour: start_time,
      endHour: end_time
    });

    // compare with rotation in Rotation Setting screen
    // if preference item has @dayInWeek matched with data in @listDayHasRotation, then this preference item is valid
    if (
      isDailyRotation ||
      listDayHasRotation[rotation?.id?.toString()]?.includes(dayInWeek)
    ) {
      if (foundedAvail) {
        const foundedEmpReason = _.find(listEmployeeReason, {
          id: foundedAvail?.employee_reason
        });

        listRotationForDay.push({
          ...foundedAvail,
          iconUrl: foundedEmpReason && foundedEmpReason.icon
        });
      } else {
        const startTime = moment(`${date}T${start_time}:00`);
        let endTime = moment(`${date}T${end_time}:00`);

        // Move end time to the day after if the time is before startTime.
        if (endTime < startTime) {
          endTime.add(1, 'day');
        }

        listRotationForDay.push({
          date,
          startHour: start_time,
          endHour: end_time,
          rotation: rotation.id,
          start_date_time: `${date}T${start_time}:00`,
          end_date_time: `${endTime.format('YYYY-MM-DD')}T${end_time}:00`
        });
      }
    }
  });

  return listRotationForDay;
};

const isCellDisable = (start, tenantPreferences) => {
  const date = moment(start).format('YYYY-MM-DD');
  const dayInWeek = moment(start).day();
  const isDisable =
    tenantPreferences?.disabledWeekDay?.includes(dayInWeek) ||
    tenantPreferences?.disabledDates?.includes(date);

  return isDisable;
};

export { getListRotationForDay, isCellDisable };
