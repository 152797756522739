import { message } from 'antd';
import {
  GET_PROFILE_DETAILS,
  SAVE_PROFILE_DETAILS,
  UPDATE_PROFILE_DETAILS,
  UPDATE_PASSWORD,
  UPDATE_DEFAULT_PREFERENCE,
  SAVE_DEFAULT_PREFERENCE,
  GET_PDF_DOCUMENTATION,
  GET_RULE_STATUS,
  UPDATE_RULE_STATUS,
  RESET_RULE_STATUS
} from 'redux/type';
import { createRequestSaga } from './common';
import ProfileAPI from 'redux/api/profile';
import { all, takeEvery, takeLatest } from 'redux-saga/effects';

const getUserDetails = createRequestSaga({
  request: ProfileAPI.getProfileDetails,
  key: 'getProfileDetails',
  success: [
    (res) => {
      return {
        type: SAVE_PROFILE_DETAILS,
        payload: res
      };
    }
  ],
  functionSuccess: [],
  functionFailure: [
    (err) => message.error('Error occured when loading profile')
  ]
});

const updateProfileDetails = createRequestSaga({
  request: ProfileAPI.updateProfileDetails,
  key: 'updateProfileDetails',
  success: [
    (res) => {
      return {
        type: SAVE_PROFILE_DETAILS,
        payload: res
      };
    }
  ],
  functionSuccess: [],
  functionFailure: []
});

const updatePassword = createRequestSaga({
  request: ProfileAPI.updatePassword,
  key: 'updatePassword',
  success: [],
  functionSuccess: [],
  functionFailure: []
});

const updateDefaultPreference = createRequestSaga({
  request: ProfileAPI.updateDefaultPreference,
  key: 'updateDefaultPreference',
  success: [
    (res) => {
      return {
        type: SAVE_DEFAULT_PREFERENCE,
        payload: res.default_preference
      };
    }
  ],
  functionSuccess: [],
  functionFailure: []
});

const getPdfDocumentation = createRequestSaga({
  request: ProfileAPI.getPdfDocumentationApi,
  key: 'getPdfLinks',
  success: [
    (res) => {
      return {
        type: GET_PDF_DOCUMENTATION,
        payload: res
      };
    }
  ],
  functionSuccess: [],
  functionFailure: []
});

const getRuleAndStatus = createRequestSaga({
  request: ProfileAPI.getRuleAndStatusApi,
  key: 'getRuleAndStatus',
  success: [
    (res) => {
      return {
        type: GET_RULE_STATUS,
        payload: res
      };
    }
  ],
  functionSuccess: [],
  functionFailure: []
});

const updateRuleAndStatus = createRequestSaga({
  request: ProfileAPI.updateRuleAndStatusApi,
  key: 'updateRuleAndStatus',
  success: [
    (res) => {
      return {
        type: UPDATE_RULE_STATUS,
        payload: res
      };
    }
  ],
  functionSuccess: [],
  functionFailure: []
});

const resetRuleAndStatus = createRequestSaga({
  request: ProfileAPI.resetRuleAndStatusApi,
  key: 'resetRuleAndStatus',
  success: [],
  functionSuccess: [],
  functionFailure: []
});

const saga = [
  function* fetchWatcher() {
    yield all([
      takeEvery(GET_PROFILE_DETAILS, getUserDetails),
      takeEvery(UPDATE_PROFILE_DETAILS, updateProfileDetails),
      takeEvery(UPDATE_PASSWORD, updatePassword),
      takeLatest(UPDATE_DEFAULT_PREFERENCE, updateDefaultPreference),
      takeEvery(GET_PDF_DOCUMENTATION, getPdfDocumentation),
      takeEvery(GET_RULE_STATUS, getRuleAndStatus),
      takeEvery(UPDATE_RULE_STATUS, updateRuleAndStatus),
      takeEvery(RESET_RULE_STATUS, resetRuleAndStatus)
    ]);
  }
];

export default saga;
