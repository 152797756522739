import {
  GET_CONTRACT_SETTING,
  UPDATE_CONTRACT_INFO,
  DELETE_CONTRACT_INFO,
  SAVE_DELETE_CONTRACT_INFO,
  CREATE_CONTRACT,
  SEARCH_CONTRACT,
  SET_SELECTED_CONTRACT_SETTING,
  SET_MODAL_CONTRACT_SETTING_VISIBLE
} from 'redux/type';

export const actGetContractSetting = (...args) => ({
  type: GET_CONTRACT_SETTING,
  args
});

export const actUpdateContractInfo = (...args) => ({
  type: UPDATE_CONTRACT_INFO,
  args
});

export const actDeleteContractInfo = (...args) => ({
  type: DELETE_CONTRACT_INFO,
  args
});

export const actSaveDeleteContractInfo = (payload) => ({
  type: SAVE_DELETE_CONTRACT_INFO,
  payload
});

export const actCreateContract = (...args) => ({
  type: CREATE_CONTRACT,
  args
});

export const actSearchContract = (payload) => ({
  type: SEARCH_CONTRACT,
  payload
});

export const actSetSelectedContractSetting = (payload) => ({
  type: SET_SELECTED_CONTRACT_SETTING,
  payload
});

export const actSetModalContractSettingVisible = (payload) => ({
  type: SET_MODAL_CONTRACT_SETTING_VISIBLE,
  payload
});
