import { Row, Col } from 'antd';
import fullNameLogo from 'assets/company-long-name.png';
import conpanyName from 'assets/company-name.svg';
import { useTranslation } from 'component/index';
import moment from 'moment';
import { FooterWrapper } from './style';

const listPartner = [
  {
    icon: '/static/icons/pic1.png',
    link: 'https://nf-startup.jp/list/details/019/',
    isSmall: true
  },
  {
    icon: '/static/icons/pic2.png',
    link: 'https://www.impactech.com/nippon-foundation-social-change-makers-cohort-2-demo-day/'
  },
  {
    icon: '/static/icons/pic3.png',
    link: 'https://thebridge.jp/2021/06/aquila-systems-helps-mass-vaccination-operations'
  },
  {
    icon: '/static/icons/pic4.jpg',
    link: 'https://www.linkedin.com/pulse/japans-vaccine-rollout-startup-ecosystem-delivers-mark-bivens/'
  },
  {
    icon: '/static/icons/pic5.png',
    link: 'https://prtimes.jp/main/html/rd/p/000000001.000081372.html'
  }
];

const Footer = () => {
  const { translate } = useTranslation();

  return (
    <FooterWrapper>
      <Row type="flex" justify="center">
        <Col xxl={18} xl={20} lg={23} md={24} sm={24} xs={24}>
          <div className="main">
            <div className="list-partner">
              {listPartner.map((partner, index) => (
                <a
                  key={index}
                  href={partner?.link}
                  index={index}
                  target="_blank"
                  style={
                    partner?.isSmall && {
                      marginRight: '-80px',
                      marginLeft: '-40px'
                    }
                  }
                >
                  <img
                    src={partner?.icon}
                    alt=""
                    style={{
                      transform: `scale(${partner?.isSmall ? 0.6 : 1})`
                    }}
                  />
                </a>
              ))}
              <div className="company-logo">
                <div className="logo">
                  <img src={fullNameLogo} alt="logo" />
                  <a href="https://aquila.company/" target="_blank">
                    <img
                      src={conpanyName}
                      alt="company name"
                      className="company-name"
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="copyright">
              {translate('homepage.footer.copyright')} ©{' '}
              {moment().subtract(2, 'year').year()}-{moment().year()},{' '}
              <a href="https://aquila.company/" target="_blank">
                Aquila Systems, Inc.
              </a>{' '}
              - {translate('homepage.footer.allRightReserved')}
            </div>
          </div>
        </Col>
      </Row>
    </FooterWrapper>
  );
};

export default Footer;
