import API from './common';

const api = {
  getShiftGroup: ({ tenantId }) => API('get', `${tenantId}/shift-groups/`),
  createShiftGroup: ({ tenantId, body }) =>
    API('post', `${tenantId}/shift-groups/add`, body),
  updateShiftGroup: ({ tenantId, shiftGroupId, body }) =>
    API('put', `${tenantId}/shift-groups/${shiftGroupId}/update`, body),
  deleteShiftGroup: ({ tenantId, shiftGroupId }) =>
    API('delete', `${tenantId}/shift-groups/${shiftGroupId}`)
};

export default api;
