import { combineReducers } from 'redux';
import Grid from './grid';
import App from './app';
import Preference from './preference';
import EmployeeSetting from './employeeSetting';
import SkillSetting from './skillSetting';
import ContractSetting from './contractSetting';
import ShiftTypeSetting from './shiftTypeSetting';
import ShiftGroupSetting from './shiftGroupSetting';
import EmployeeReason from './employeeReason';
import SendEmail from './sendEmail';
import RotationSetting from './rotationSetting';
import Profile from './profile';
import Wizard from './wizard';
import Dashboard from './dashboard';
import SkillView from './skillView';
import RotationTab from './rotationTab';
import TimeReport from './timeReport';

const reducer = () => {
  return combineReducers({
    Grid,
    App,
    Preference,
    EmployeeSetting,
    SkillSetting,
    ContractSetting,
    ShiftTypeSetting,
    ShiftGroupSetting,
    EmployeeReason,
    SendEmail,
    RotationSetting,
    Profile,
    Wizard,
    Dashboard,
    SkillView,
    RotationTab,
    TimeReport
  });
};

export default reducer;
