import { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import { useNavigate as useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'component';
import RulesTable from 'component/Profile/RulesTable';

import DocumentSetting from './DocumentSetting';
import SystemSetting from './SystemSetting';
import UserProfile from './UserProfile';
import Integrations from './Integrations';

import { UserProfilePageWrapper } from './style';

const { TabPane } = Tabs;
const PATH_NAME = {
  PROFILE: '/profile',
  SYSTEM_SETTING: '/system-settings',
  RULES_SETTING: '/rules-settings',
  DOCUMENTATION: '/documentation',
  INTEGRATIONS: '/integrations'
};

const UserProfilePage = () => {
  const { translate } = useTranslation();
  const [activeTab, setActiveTab] = useState('1');
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname?.includes(PATH_NAME.PROFILE)) {
      setActiveTab('1');
    } else if (location.pathname?.includes(PATH_NAME.SYSTEM_SETTING)) {
      setActiveTab('2');
    } else if (location.pathname?.includes(PATH_NAME.RULES_SETTING)) {
      setActiveTab('3');
    } else if (location.pathname?.includes(PATH_NAME.DOCUMENTATION)) {
      setActiveTab('4');
    } else if (location.pathname?.includes(PATH_NAME.INTEGRATIONS)) {
      setActiveTab('5');
    }
  }, [location]);

  const onTabChange = (key) => {
    if (key === '1') {
      history(PATH_NAME.PROFILE); // history.replace
    } else if (key === '2') {
      history(PATH_NAME.SYSTEM_SETTING); // history.replace
    } else if (key === '3') {
      history(PATH_NAME.RULES_SETTING); // history.replace
    } else if (key === '4') {
      history(PATH_NAME.DOCUMENTATION); // history.replace
    } else if (key === '5') {
      history(PATH_NAME.INTEGRATIONS); // history.replace
    }
  };

  return (
    <UserProfilePageWrapper>
      <div className="header-wrapper">
        <div className="label">{translate('Profile')}</div>
      </div>

      <div className="main-content">
        <Tabs activeKey={activeTab} onChange={onTabChange}>
          <TabPane tab={translate('profile.tenantProfile')} key="1">
            <UserProfile />
          </TabPane>

          <TabPane tab={translate('profile.systenSetting')} key="2">
            <SystemSetting />
          </TabPane>
          <TabPane tab={translate('profile.rulesSetting')} key="3">
            <RulesTable />
          </TabPane>
          <TabPane tab={translate('profile.documentation')} key="4">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <DocumentSetting />
            </div>
          </TabPane>
          <TabPane tab={translate('profile.integrations')} key="5">
            <Integrations />
          </TabPane>
        </Tabs>
      </div>
    </UserProfilePageWrapper>
  );
};

export default UserProfilePage;
