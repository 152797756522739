import styled from 'styled-components';

const WeeklyScheduleWrapper = styled.div`
  border: 1px solid var(--color-border-gray);
  border-radius: 5px;
  box-shadow: var(--box-shadow);
  padding: 2rem 2rem;
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  padding-bottom: 80px !important;
  position: relative;
  display: flex;
  flex-direction: column;

  .heading {
    font-size: 1.6rem;
    font-weight: 600;
    color: var(--color-text);
    margin-bottom: 1.5rem;
  }

  .list-day {
    display: flex;
    justify-content: space-around;
    font-size: 1.3rem;
    color: var(--color-text);
    margin-bottom: 0.5rem;
  }

  .chart-wrapper {
    width: 100%;
    height: 320px;
  }
`;

export { WeeklyScheduleWrapper };
