import React from 'react';
import { Result, Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate as useHistory } from 'react-router-dom';

const NotFoundpage = (props) => {
  const history = useHistory();

  return (
    <div className="not-found-page">
      <Result
        status="404"
        title="404"
        subTitle="This page does not exist"
        extra={
          <Button type="primary" onClick={history.goBack}>
            <ArrowLeftOutlined />
            Go back!
          </Button>
        }
      />
    </div>
  );
};

export default NotFoundpage;
