import { call, put, take, race } from 'redux-saga/effects';
import { delay } from 'redux-saga/effects';
import { API_TIMEOUT } from 'utils/constant';
import {
  markRequestPending,
  markRequestSuccess,
  markRequestCancelled,
  markRequestFailed,
  invokeCallback
} from 'redux/action/common';
import { actLogout } from 'redux/action/auth';

export const rejectErrors = (res = {}) => {
  const { status, statusText, data } = res;
  const formatError = (err = []) => {
    for (let key in err) {
      return err[key][0];
    }
  };
  // we can get message from Promise but no need, just use statusText instead of

  return Promise.reject({
    status,
    statusText,
    message:
      typeof data === 'string'
        ? data
        : data && data?.detail
        ? data?.detail
        : formatError(data)
  });
};

// create saga here
// convenient way: [] instead of polymorph, such as item is not array then [item]
// because later changes to code will be so easy, just add new row
export const createRequestSaga = ({
  request,
  key,
  start,
  stop,
  success,
  failure,
  cancelled,
  functionSuccess,
  functionFailure,
  timeout = API_TIMEOUT,
  cancel
}) =>
  function* (action) {
    let args = action.args || [];

    // the last parameter in action and if that parameter is function, them that parameter would be a callback
    const callback =
      typeof args[args.length - 1] === 'function'
        ? args[args.length - 1]
        : null;

    if (callback) {
      args = args.slice(0, -1);
    }

    // error first callback
    let res = null;
    let err = null;

    // store into redux
    const requestKey = typeof key === 'function' ? key(...args) : key;

    if (start) {
      for (const actionCreator of start) {
        yield put(actionCreator());
      }
    }
    // mark pending
    yield put(markRequestPending(requestKey));

    try {
      if (!request) {
        throw new Error('Api method not found!!!');
      }

      // start invoke
      const invokeRequest = async () => {
        const chainRequest = request.apply(request, args);
        const response = await chainRequest;

        if (
          response &&
          (response.status === 200 ||
            response.status === 201 ||
            response.status === 204)
        ) {
          return response?.data;
        }

        return rejectErrors(response);
      };

      // we do not wait forever for whatever request !!!
      // timeout is 0 mean default timeout, so default is 0 in case user input 0
      const raceOptions = {
        data: call(invokeRequest),
        isTimeout: delay(timeout)
      };

      // wait for action cancel
      if (cancel) {
        raceOptions.cancelRet = take(cancel);
      }

      const { data, isTimeout, cancelRet } = yield race(raceOptions);

      if (isTimeout) {
        throw new Error(`Api method is timeout after ${timeout} ms!!!`);
      } else if (cancelRet) {
        // callback on success
        if (cancelled) {
          for (const actionCreator of cancelled) {
            yield put(actionCreator(cancelRet, action));
          }
        }
        // mark cancelled request
        yield put(markRequestCancelled(cancelRet, requestKey));
      } else {
        // callback on success

        if (success) {
          for (const actionCreator of success) {
            yield put(actionCreator(data, action));
          }
        }

        if (functionSuccess) {
          for (const actionCreator of functionSuccess) {
            actionCreator(data);
          }
        }

        // finally mark the request success
        yield put(markRequestSuccess(requestKey));

        res = data;
      }
    } catch (reason) {
      // automatic logout user if token is expired
      if (
        reason &&
        reason.status === 401 &&
        reason.statusText === 'Unauthorized'
      ) {
        yield put(actLogout());
      }

      if (failure) {
        for (const actionCreator of failure) {
          yield put(actionCreator(reason, action));
        }
      }
      if (functionFailure) {
        for (const actionCreator of functionFailure) {
          actionCreator(reason);
        }
      }

      yield put(markRequestFailed(reason.message, requestKey));

      // mark error
      err = reason;
    } finally {
      if (stop) {
        for (const actionCreator of stop) {
          yield put(actionCreator(res, action));
        }
      }

      if (callback) {
        yield put(invokeCallback(callback, err, res));
      }
    }
  };
