import { useState, useEffect } from 'react';
import { ConfigProvider } from 'antd';
import moment from 'moment';
import enUS from 'antd/lib/locale/en_US';
import jaJP from 'antd/lib/locale/ja_JP';
import AppRoute from 'component/route';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import '@fullcalendar/timegrid/main.css';
import { store, persistor } from 'redux/store';
import { LanguageContext, listLocale, LOCALE } from 'languages/index';
import 'moment/locale/ja';

const App = () => {
  const [locale, setLocale] = useState(LOCALE.EN);

  useEffect(() => {
    if (locale === LOCALE.EN) {
      moment.locale('en-us');
    } else if (locale === LOCALE.JP) {
      moment.locale('ja');
    }
  }, [locale]);

  const provider = {
    locale,
    onChangeLocale: (selected) => {
      const newLocale = listLocale[selected] ? selected : LOCALE.EN;
      setLocale(newLocale);
    }
  };

  return (
    <ConfigProvider locale={locale === LOCALE.EN ? enUS : jaJP}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <LanguageContext.Provider value={provider}>
            <AppRoute />
          </LanguageContext.Provider>
        </PersistGate>
      </Provider>
    </ConfigProvider>
  );
};

export default App;
