import { message } from 'antd';
import { takeLatest, all, takeEvery } from 'redux-saga/effects';
import {
  GET_LIST_ROTATION_TAB,
  SAVE_GET_LIST_ROTATION_TAB,
  CREATE_ROTATION_TAB,
  SAVE_CREATE_ROTATION_TAB,
  UPDATE_ROTATION_TAB,
  SAVE_UPDATE_ROTATION_TAB,
  DELETE_ROTATION_TAB,
  SAVE_GET_ONE_ROTATION_TAB,
  GET_ONE_ROTATION_TAB
} from 'redux/type';
import rotationTabAPI from 'redux/api/rotationTab';
import { createRequestSaga } from './common';

const getListRotationTab = createRequestSaga({
  request: rotationTabAPI.getListRotationTab,
  key: 'getListRotationTab',
  success: [
    (res) => ({
      type: SAVE_GET_LIST_ROTATION_TAB,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: [
    (err) => message.error('Error occured when get list rotation tab')
  ]
});

const getOneRotationTab = createRequestSaga({
  request: rotationTabAPI.getOneRotationTab,
  key: 'getOneRotationTab',
  success: [
    (res) => ({
      type: SAVE_GET_ONE_ROTATION_TAB,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: [
    (err) => message.error('Error occured when get list rotation tab')
  ]
});

const createRotationTab = createRequestSaga({
  request: rotationTabAPI.createRotationTab,
  key: 'createRotationTab',
  success: [
    (res) => ({
      type: SAVE_CREATE_ROTATION_TAB,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: [
    (err) => message.error('Error occured when create rotation tab')
  ]
});

const updateRotationTab = createRequestSaga({
  request: rotationTabAPI.updateRotationTab,
  key: 'updateRotationTab',
  success: [
    (res) => ({
      type: SAVE_UPDATE_ROTATION_TAB,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: [
    (err) => message.error('Error occured when update rotation tab')
  ]
});

const deleteRotationTab = createRequestSaga({
  request: rotationTabAPI.deleteRotationTab,
  key: 'deleteRotationTab',
  success: [],
  functionSuccess: [],
  functionFailure: [
    (err) => message.error('Error occured when delete rotation tab')
  ]
});

const saga = [
  function* fetchWatcher() {
    yield all([
      takeLatest(GET_LIST_ROTATION_TAB, getListRotationTab),
      takeLatest(GET_ONE_ROTATION_TAB, getOneRotationTab),
      takeLatest(CREATE_ROTATION_TAB, createRotationTab),
      takeEvery(UPDATE_ROTATION_TAB, updateRotationTab),
      takeLatest(DELETE_ROTATION_TAB, deleteRotationTab)
    ]);
  }
];
export default saga;
