import {
  SET_SIGNUP_DATA,
  SET_SETUP_DATA,
  SUBMIT_WIZARD_INFO,
  CHANGE_WIZARD_STATUS,
  VALIDATE_PASSWORD,
  DISMISS_WIZARD
} from 'redux/type';

export const actSetSignupData = (payload) => ({
  type: SET_SIGNUP_DATA,
  payload
});

export const actSetSetupData = (payload) => ({
  type: SET_SETUP_DATA,
  payload
});

export const actSubmitWizard = (...args) => ({
  type: SUBMIT_WIZARD_INFO,
  args
});

export const actChangeWizardStatus = (payload) => ({
  type: CHANGE_WIZARD_STATUS,
  payload
});

export const actDismissWizard = (payload) => ({
  type: DISMISS_WIZARD,
  payload
});

export const actValidatePassword = (...args) => ({
  type: VALIDATE_PASSWORD,
  args
});
