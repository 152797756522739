import styled from 'styled-components';

const PlanningSectionWrapper = styled.div`
  margin-top: 10rem;
  background-image: ${({ planningBg }) => `url(${planningBg})`};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  width: 100%;

  .main {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 7rem;

    .content {
      flex-grow: 1;
      margin-right: 5rem;

      .heading {
        font-size: 2.6rem;
        font-weight: 600;
        color: var(--color-text);
        margin-bottom: 4rem;

        span {
          color: var(--color-secondary);
        }
      }
    }

    .icon-wrapper {
      flex-basis: 30%;
    }
  }
`;

const StepItemWrapper = styled.div`
  padding: 0rem 2rem 6rem 3rem;
  position: relative;
  border: 1px solid transparent;

  &::after {
    content: '';
    position: absolute;
    width: 1rem;
    height: 1rem;
    background-color: var(--color-secondary);
    border-radius: 50%;
    top: 0;
    left: -0.15rem;
    transform: translate(-50%, -50%);
  }

  &:not(:last-of-type) {
    border-left: 1px dashed var(--color-secondary);
  }

  .title {
    color: var(--text-color);
    font-size: 2rem;
    margin-bottom: 0.3rem;
    margin-top: -0.75em;
  }

  .description {
    font-size: 1.6rem;
    color: var(--text-color);
    font-weight: 300;
  }
`;

export { PlanningSectionWrapper, StepItemWrapper };
