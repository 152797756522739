import _ from 'lodash';
import { Checkbox, Button, Switch } from 'antd';
import { useState, useEffect, useMemo } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useTranslation } from 'component/index';
import { actUpdateRotationTab } from 'redux/action/rotationTab';

import DateSelect from '../DateSelect';

const CheckboxGroup = Checkbox.Group;

const TabPaneContent = (props) => {
  const { tab, selectedTab, listRotationTab, listDayInWeek, tenantId } = props;

  const { translate } = useTranslation();
  const [isSelectDate, setIsSelectDate] = useState(false);
  const [listDate, setListDate] = useState([]);

  useEffect(() => {
    if (Number(selectedTab) === tab?.id) {
      const newList = !_.isEmpty(tab?.special_dates)
        ? tab?.special_dates
        : [null];
      setListDate(newList);
      setIsSelectDate(tab?.is_special);
    }
  }, [selectedTab, listRotationTab]);

  // Day selected in a tab cannot be selected again in another tab.
  const listDayInOtherTab = useMemo(() => {
    let tempList = [];

    listRotationTab.forEach((tabInfo) => {
      if (tab?.id !== tabInfo?.id) {
        tempList = [...tempList, ...(tabInfo?.days || [])];
      }
    });

    tempList = _.uniq(tempList);

    return tempList;
  }, [listRotationTab, selectedTab]);

  const onChangeDateInTab = (date, indexOfDate) => {
    let newList = [...listDate];
    newList.splice(indexOfDate, 1, date?.format('YYYY-MM-DD'));

    props.actUpdateRotationTab(
      {
        tenantId,
        tabId: +selectedTab,
        body: { special_dates: newList }
      },
      (err) => {
        !err && setListDate(newList);
      }
    );
  };

  const onDeleteDateInTab = (indexOfDate) => {
    let newList = [...listDate];
    newList.splice(indexOfDate, 1);

    props.actUpdateRotationTab(
      {
        tenantId,
        tabId: +selectedTab,
        body: { special_dates: newList }
      },
      (err) => {
        !err && setListDate(newList);
      }
    );
  };

  const renderListDate = () => {
    const allSelectedDates = _.flatten(
      _.map(_.filter(listRotationTab, 'is_special'), 'special_dates')
    );

    return listDate?.map((date, index) => (
      <DateSelect
        key={index}
        date={date}
        onChangeDate={onChangeDateInTab}
        onDeleteDate={onDeleteDateInTab}
        selected={allSelectedDates}
        defaultValue={props.first_draft_date}
        index={index}
      />
    ));
  };

  const renderListWeekday = () =>
    listDayInWeek.map((day, index) => (
      <Checkbox
        value={index}
        disabled={listDayInOtherTab?.includes(index)}
        key={index}
      >
        {day}
      </Checkbox>
    ));

  const onToggleSpecialDate = () => {
    if (isSelectDate) {
      props.actUpdateRotationTab(
        {
          tenantId,
          tabId: +selectedTab,
          body: { is_special: false, special_dates: [] }
        },
        (err) => {
          if (!err) {
            setIsSelectDate(false);
          }
        }
      );
    } else {
      props.actUpdateRotationTab(
        {
          tenantId,
          tabId: +selectedTab,
          body: { is_special: true, days: [] }
        },
        (err) => {
          if (!err) {
            setIsSelectDate(true);
          }
        }
      );
    }
  };

  const onAddNewDate = () => {
    setListDate([...listDate, null]);
  };

  const onSelectDay = (value) => {
    props.actUpdateRotationTab({
      tenantId,
      tabId: +selectedTab,
      body: { days: value || [] }
    });
  };

  return (
    <div className="tab-heading">
      {isSelectDate ? (
        <span className="select-date">
          <span className="list-date">{renderListDate()}</span>
          <Button
            icon={<PlusOutlined />}
            type="dashed"
            onClick={onAddNewDate}
            style={{ marginLeft: '1rem' }}
          />
        </span>
      ) : (
        <CheckboxGroup value={tab?.days || []} onChange={onSelectDay}>
          {renderListWeekday()}
        </CheckboxGroup>
      )}

      <Switch
        defaultChecked
        onChange={onToggleSpecialDate}
        checkedChildren={translate('rotation.specialDates')}
        unCheckedChildren={translate('rotation.specialDates')}
        checked={isSelectDate}
        size="default"
      />
    </div>
  );
};

export default connect(
  (state) => ({
    first_draft_date: state?.App?.first_draft_date,
    listRotationTab: state?.RotationTab?.listRotationTab || [],
    tenantId: state?.App?.user?.tenant?.id
  }),
  {
    actUpdateRotationTab
  }
)(TabPaneContent);
