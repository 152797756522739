import { message } from 'antd';
import { takeLatest, all, takeEvery } from 'redux-saga/effects';
import {
  GET_ROTATION_SETTING,
  SAVE_GET_ROTATION_SETTING,
  UPDATE_ROTATION_INFO,
  SAVE_UPDATE_ROTATION_INFO,
  DELETE_ROTATION_INFO,
  CREATE_ROTATION,
  SAVE_CREATE_ROTATION,
  APPLY_ROTATION,
  SOLVE_ROTATION,
  GET_SOLVE_ROTATION_STATUS,
  SAVE_GET_SOLVE_ROTATION_STATUS,
  TERMINATE_ROTATION,
  SAVE_TERMINATE_ROTATION,
  SAVE_GET_FIRST_DRAFT_DATE,
  PUBLISH_ROTATION,
  GET_ASSIGN_SCORE,
  SAVE_ASSIGN_SCORE,
  GET_EXCEL_FILE
} from 'redux/type';
import rotationSettingAPI from 'redux/api/rotationSetting';
import { createRequestSaga } from './common';

const getRotationSetting = createRequestSaga({
  request: rotationSettingAPI.getRotationSetting,
  key: 'getRotationSetting',
  success: [
    (res) => ({
      type: SAVE_GET_ROTATION_SETTING,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: [
    (err) => message.error('Error occured when get rotation setting')
  ]
});

const updateRotationInfo = createRequestSaga({
  request: rotationSettingAPI.updateRotationInfo,
  key: 'updateRotationInfo',
  success: [
    (res) => ({
      type: SAVE_UPDATE_ROTATION_INFO,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: []
});

const deleteRotationInfo = createRequestSaga({
  request: rotationSettingAPI.deleteRotationInfo,
  key: 'deleteRotationInfo',
  functionSuccess: [],
  functionFailure: []
});

const createRotationInfo = createRequestSaga({
  request: rotationSettingAPI.createRotationInfo,
  key: 'createRotationInfo',
  success: [
    (res) => ({
      type: SAVE_CREATE_ROTATION,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: []
});
const getAssignmentScore = createRequestSaga({
  request: rotationSettingAPI.getAssignmentScore,
  key: 'getAssignmentScore',
  success: [
    (res) => ({
      type: SAVE_ASSIGN_SCORE,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: []
});
const applyRotation = createRequestSaga({
  request: rotationSettingAPI.applyRotation,
  key: 'applyRotation',
  success: [],
  functionSuccess: [],
  functionFailure: []
});

const solveRotation = createRequestSaga({
  request: rotationSettingAPI.solveRotation,
  key: 'solveRotation',
  success: [],
  functionSuccess: [],
  functionFailure: []
});

const getSolveRotationStatus = createRequestSaga({
  request: rotationSettingAPI.getSolveRotationStatus,
  key: 'getSolveRotationStatus',
  success: [
    (res) => ({
      type: SAVE_GET_SOLVE_ROTATION_STATUS,
      payload: res || null
    })
  ],
  functionSuccess: [],
  functionFailure: []
});

const terminateRotation = createRequestSaga({
  request: rotationSettingAPI.terminateRotation,
  key: 'terminateRotation',
  success: [
    (res) => ({
      type: SAVE_TERMINATE_ROTATION,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: [
    (err) => message.error('Error occured when terminate rotation')
  ]
});

const publishRotation = createRequestSaga({
  request: rotationSettingAPI.publishRotation,
  key: 'publishRotation',
  success: [
    (res) => ({
      type: SAVE_GET_FIRST_DRAFT_DATE,
      payload: [res]
    })
  ],
  functionSuccess: [],
  functionFailure: []
});

const getExcelFile = createRequestSaga({
  request: rotationSettingAPI.getExcelFile,
  key: 'getExcelFile',
  success: [],
  functionSuccess: [],
  functionFailure: []
});

const saga = [
  function* fetchWatcher() {
    yield all([
      takeLatest(GET_ROTATION_SETTING, getRotationSetting),
      takeLatest(CREATE_ROTATION, createRotationInfo),
      takeLatest(UPDATE_ROTATION_INFO, updateRotationInfo),
      takeLatest(DELETE_ROTATION_INFO, deleteRotationInfo),
      takeLatest(SOLVE_ROTATION, solveRotation),
      takeLatest(APPLY_ROTATION, applyRotation),
      takeLatest(GET_SOLVE_ROTATION_STATUS, getSolveRotationStatus),
      takeLatest(TERMINATE_ROTATION, terminateRotation),
      takeLatest(PUBLISH_ROTATION, publishRotation),
      takeLatest(GET_EXCEL_FILE, getExcelFile),
      takeEvery(GET_ASSIGN_SCORE, getAssignmentScore)
    ]);
  }
];

export default saga;
