import styled from 'styled-components';

const LanguageSwitcherWrapper = styled.div`
  .flag {
    width: 2.7rem;
    height: 1.6rem;
    cursor: pointer;
  }
`;

export { LanguageSwitcherWrapper };
