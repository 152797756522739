import { useEffect, useState, useMemo } from 'react';
import {
  Modal,
  Form,
  Select,
  message,
  Row,
  Col,
  Checkbox,
  Switch,
  Button,
  TimePicker
} from 'antd';
import { CheckOutlined, EditOutlined } from '@ant-design/icons';
import _ from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import {
  actSetModalAssignEmployeeOpen,
  actUpdateSKillView,
  actDeleteShiftInSKillView,
  actSaveDeleteShiftInSKillView
} from 'redux/action/skillView';
import { useTranslation } from 'component/index';
import { actCreateShift, actUpdateShift } from 'redux/action/grid';
import { getRotationTime, renderSkillSetTags } from 'component/Rotation/util';
import RequiredSkill from 'component/RequiredSkill';
import SkillList from './SkillList';

const { RangePicker } = TimePicker;
const { Option } = Select;

const ModalAssignEmployee = (props) => {
  const {
    isModalAssignEmployeeOpen,
    selectedData,
    listEmployee,
    tenantId,
    selectedShiftType,
    listSkill,
    listRotation,
    listShiftGroup,
    selectedRotation
  } = props;

  const { shiftInfo = {}, dateShiftInfo = '' } = selectedData || {};
  const [form] = Form.useForm();
  const { translate } = useTranslation();

  const [listEmployeeHasSkill, setListEmployeeHasSkill] = useState([]);
  const [isShowAllEmployee, setIsShowAllEmployee] = useState(false);
  const [listSkillSet, setListSkillSet] = useState([[[]]]);
  const [isRequiredSkillEdited, setIsRequiredSkillEdited] = useState(false);

  useEffect(() => {
    setListSkillSet(
      _.isEmpty(shiftInfo?.skills) ? [[[]]] : [shiftInfo?.skills]
    );
  }, [shiftInfo]);

  // rotation for shift must inlude @dateShiftInfo and match with @selectedShiftType in grid heading and list day of rotation tab
  const listRotationForDay = useMemo(() => {
    if (isModalAssignEmployeeOpen) {
      const weekDay = moment(dateShiftInfo, 'YYYY-MM-DD')?.weekday();

      return listRotation?.filter(
        (rotation) =>
          rotation?.tab?.days?.includes(weekDay) &&
          rotation?.spot === selectedShiftType
      );
    }
  }, [dateShiftInfo, listRotation, isModalAssignEmployeeOpen]);

  const isCreateMode = useMemo(() => _.isEmpty(shiftInfo), [shiftInfo]);

  useEffect(() => {
    setIsRequiredSkillEdited(isCreateMode);

    if (isCreateMode) {
      const rotationInfo =
        _.find(listRotation, { id: selectedRotation }) ||
        _.first(listRotationForDay);

      const defaultShiftGroup = _.find(listShiftGroup, { name: 'default' });
      form.setFieldsValue({
        rotation: [
          moment(rotationInfo?.start_time, 'HH:mm'),
          moment(rotationInfo?.end_time, 'HH:mm')
        ],
        shift_group: defaultShiftGroup?.id
      });
    } else if (!_.isEmpty(shiftInfo)) {
      form.setFieldsValue({
        rotation: [
          moment(shiftInfo?.start_date_time),
          moment(shiftInfo?.end_date_time)
        ],
        shift_group: shiftInfo?.shift_group
      });
    }
  }, [
    dateShiftInfo,
    listRotationForDay,
    selectedRotation,
    listRotation,
    isCreateMode,
    shiftInfo
  ]);

  const title = useMemo(() => {
    if (isCreateMode) return translate('assignment.createShiftForEmployee');

    return shiftInfo?.employee
      ? translate('assignment.updateShiftForEmployee')
      : translate('assignment.assignShiftForEmployee');
  }, [isCreateMode, shiftInfo?.employee]);

  useEffect(() => {
    if (isModalAssignEmployeeOpen) {
      form.setFieldsValue({
        employee: shiftInfo?.employee || null,
        shiftType: selectedShiftType,
        pin: shiftInfo?.pinned_by_user
      });
    }
  }, [isModalAssignEmployeeOpen]);

  // Take the first element, as there is only 1 Employee.
  const requiredSkills = useMemo(() => _.first(listSkillSet), [listSkillSet]);

  useEffect(() => {
    const tempListEmployee = [];

    if (!isModalAssignEmployeeOpen) {
      return;
    }

    if (isShowAllEmployee) {
      setListEmployeeHasSkill(listEmployee);
      return;
    }

    if (!requiredSkills || _.isEmpty(requiredSkills)) {
      setIsShowAllEmployee(true);
      return;
    }

    listEmployee.forEach((emp) => {
      const empSkill = emp?.skills?.map((skill) => skill?.id);
      const matchRequired = _.find(
        requiredSkills,
        (required) =>
          _.intersection(empSkill, required)?.length === required?.length
      );

      if (matchRequired) tempListEmployee.push(emp);
    });

    const currentEmployee = _.find(listEmployee, {
      id: +shiftInfo?.employee
    });

    if (
      currentEmployee &&
      !_.find(tempListEmployee, { id: currentEmployee?.id })
    ) {
      tempListEmployee.push(currentEmployee);
    }

    setListEmployeeHasSkill(tempListEmployee);
  }, [
    listEmployee,
    isShowAllEmployee,
    selectedData,
    isModalAssignEmployeeOpen,
    requiredSkills
  ]);

  const onDeleteShift = () => {
    if (shiftInfo?.shiftId) {
      props.actDeleteShiftInSKillView(
        {
          tenantId,
          shiftId: shiftInfo?.shiftId
        },
        (err, res) => {
          if (!err) {
            props.actSaveDeleteShiftInSKillView({
              shiftId: shiftInfo?.shiftId,
              date: shiftInfo?.date
            });
            onHideModal();
            message.success('Delete shift success');
          }
        }
      );
    }
  };

  const onAssignEmployee = async () => {
    try {
      const { employee, shiftType, pin, rotation, shift_group } =
        await form.validateFields([
          'employee',
          'shiftType',
          'pin',
          'rotation',
          'shift_group'
        ]);

      const startTime = rotation?.[0]?.format('HH:mm');
      const endTime = rotation?.[1]?.format('HH:mm');

      const [rotationStartTime, rotationEndTime] = getRotationTime(
        dateShiftInfo,
        startTime,
        endTime
      );

      const body = {
        version: 0,
        start_date_time: `${rotationStartTime?.format(
          'YYYY-MM-DD'
        )}T${rotationStartTime?.format('HH:mm')}:00`,
        end_date_time: `${rotationEndTime?.format(
          'YYYY-MM-DD'
        )}T${rotationEndTime?.format('HH:mm')}:00`,
        employee: employee ? employee : null,
        spot: shiftType,
        pinned_by_user: pin,
        skills: requiredSkills,
        shift_group: shift_group
      };

      if (shiftInfo?.shiftId) {
        props.actUpdateShift(
          {
            tenantId,
            shiftId: shiftInfo?.shiftId,
            body
          },
          (err, res) => {
            if (err) {
              message.error(translate('assignment.updateShiftFail'));
            } else if (res) {
              afterUpdateShift({ ...res });
              message.success(translate('assignment.updateShiftSuccess'));
              onHideModal();
            }
          }
        );
      } else {
        props.actCreateShift(
          {
            body,
            tenantId
          },
          (err, res) => {
            if (err) {
              message.error(translate('assigment.createShiftFail'));
            } else if (res) {
              // update shift_id when receive response from backend
              afterUpdateShift({
                ...res,
                shiftId: res?.id,
                date: dateShiftInfo
              });
              message.success(translate('assignment.createShiftSuccess'));
              onHideModal();
            }
          }
        );
      }
    } catch (err) {}
  };

  const afterUpdateShift = (updateData) => {
    props.actUpdateSKillView({ shiftInfo, updateData });
  };

  const onHideModal = () => {
    props.actSetModalAssignEmployeeOpen(false);
  };

  const afterCloseModal = () => {
    setIsShowAllEmployee(false);
  };

  const preferredEmployee = useMemo(() => {
    if (shiftInfo?.rotation_employee !== null) {
      return _.find(listEmployee, { id: shiftInfo?.rotation_employee });
    }

    return null;
  }, [shiftInfo?.rotation_employee]);

  const renderListSkillSet = () => {
    return listSkillSet.map((skillSetRow, indexOfGroup) => (
      <Row
        key={indexOfGroup}
        justify="space-between"
        className="skill-set-row"
        gutter={16}
      >
        <Col span={24} style={{ padding: 0 }}>
          <RequiredSkill
            indexOfGroup={indexOfGroup}
            setListSkillSet={setListSkillSet}
            listSkillSet={listSkillSet}
            skillSetRow={skillSetRow}
          />
        </Col>
      </Row>
    ));
  };

  const renderToggleRequiredSkill = () => (
    <span
      style={{
        cursor: 'pointer',
        marginLeft: '1rem',
        fontSize: '2.2rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      onClick={() => setIsRequiredSkillEdited(!isRequiredSkillEdited)}
    >
      {isRequiredSkillEdited ? (
        <Button
          type="dashed"
          shape="circle"
          icon={<CheckOutlined style={{ color: 'green' }} />}
        />
      ) : (
        <Button type="dashed" shape="circle" icon={<EditOutlined />} />
      )}
    </span>
  );

  return (
    <Modal
      title={title}
      visible={isModalAssignEmployeeOpen}
      cancelText={translate('cancel')}
      maskClosable={false}
      onCancel={onHideModal}
      afterClose={afterCloseModal}
      forceRender={true}
      destroyOnClose={true}
      getContainer={false}
      width="60rem"
      footer={
        <div style={{ display: 'flex' }}>
          {shiftInfo?.shiftId ? (
            <Button danger onClick={onDeleteShift}>
              {translate('assignment.delete')}
            </Button>
          ) : null}

          <Button onClick={onHideModal} style={{ marginLeft: 'auto' }}>
            {translate('cancel')}
          </Button>

          <Button type="primary" onClick={onAssignEmployee}>
            {shiftInfo?.employee
              ? translate('update')
              : translate('assignment.assign')}
          </Button>
        </div>
      }
    >
      <Form layout="vertical" form={form}>
        <Form.Item style={{ marginBottom: '1rem' }}>
          <span style={{ marginRight: '1rem' }}>{`${translate(
            'preference.date'
          )} :`}</span>
          <span>{moment(dateShiftInfo).format('dddd, MMMM Do YYYY')}</span>
        </Form.Item>

        {isRequiredSkillEdited ? (
          <Form.Item label={translate('assignment.requiredSkills')}>
            <Row gutter={8}>
              <Col span={22}>{renderListSkillSet()}</Col>
              <Col span={2}>{renderToggleRequiredSkill()}</Col>
            </Row>
          </Form.Item>
        ) : (
          <Form.Item>
            <span style={{ marginRight: '1rem' }}>
              {translate('assignment.requiredSkills')} :
            </span>

            <span style={{ display: 'flex' }}>
              {renderSkillSetTags(requiredSkills, listSkill)}
              <span>{renderToggleRequiredSkill()}</span>
            </span>
          </Form.Item>
        )}

        {preferredEmployee && !isCreateMode && (
          <Form.Item style={{ marginBottom: '1rem' }}>
            <span>{translate('rotation.preferredEmployee')}: </span>
            {preferredEmployee.name}
            <span style={{ marginLeft: '1rem' }}>
              <SkillList skills={preferredEmployee.skills} />
            </span>
          </Form.Item>
        )}

        <Row align="middle" gutter={16}>
          <Col span={18}>
            <Form.Item label={translate('assignment.employee')} name="employee">
              <Select
                placeholder={translate('assignment.selectAnEmployee')}
                showSearch={true}
                allowClear
              >
                {_.map(listEmployeeHasSkill, (emp, index) => (
                  <Option key={index} value={emp.id}>
                    {emp.name}
                    <span style={{ marginLeft: '1rem' }}>
                      : <SkillList skills={emp.skills} />
                    </span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={6}>
            <Switch
              onChange={(checked) => setIsShowAllEmployee(checked)}
              checked={isShowAllEmployee}
              checkedChildren={translate('assignment.showAllEmployee')}
              unCheckedChildren={translate('assignment.showAllEmployee')}
            />
          </Col>
        </Row>

        <Row align="bottom" gutter={16}>
          <Col span={18}>
            <Form.Item
              label={translate('rotation.header.rotation')}
              rules={[
                {
                  required: true,
                  message: `${translate(
                    'rotation.header.rotation'
                  )} ${translate('isRequired')}`
                }
              ]}
              name="rotation"
            >
              <RangePicker
                format="HH:mm"
                order={false}
                onChange={(value, dateStrings) => {
                  form.setFieldsValue({
                    rotation: [
                      moment(dateStrings[0], 'HH:mm'),
                      moment(dateStrings[1], 'HH:mm')
                    ]
                  });
                }}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item name="pin" valuePropName="checked">
              <Checkbox>Pin</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row align="bottom" gutter={16}>
          <Col span={18}>
            <Form.Item
              label={translate('rotation.shiftGroup')}
              name="shift_group"
              rules={[
                {
                  required: true,
                  message: `${translate('rotation.shiftGroup')} ${translate(
                    'isRequired'
                  )}`
                }
              ]}
            >
              <Select>
                {listShiftGroup.map((shiftGroup, index) => (
                  <Option key={index} value={shiftGroup?.id}>
                    {shiftGroup?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default connect(
  (state) => ({
    isModalAssignEmployeeOpen: state.SkillView.isModalAssignEmployeeOpen,
    selectedData: state.SkillView.selectedData,
    listEmployee: state.EmployeeSetting.listEmployee,
    tenantId: state?.App?.user?.tenant?.id,
    selectedShiftType: state.Grid.selectedShiftType,
    listSkill: state.SkillSetting.listSkill,
    listRotation: state?.RotationSetting?.listRotation,
    listRotationTab: state?.RotationTab?.listRotationTab || [],
    listShiftGroup: state?.ShiftGroupSetting?.listShiftGroup,
    selectedRotation: state.SkillView.selectedRotation
  }),
  {
    actSetModalAssignEmployeeOpen,
    actCreateShift,
    actUpdateShift,
    actUpdateSKillView,
    actDeleteShiftInSKillView,
    actSaveDeleteShiftInSKillView
  }
)(ModalAssignEmployee);
