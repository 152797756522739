import { connect } from 'react-redux';
import _ from 'lodash';
import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { LIST_TAG_COLOR } from 'utils/constant';
import { CustomHeaderWrapper, CenterHeaderWrapper } from './style';
import { COUNTABLE_HEADER_NAME } from './index';

const RotationHeader = (props) => {
  const { displayName } = props;

  return (
    <CustomHeaderWrapper>
      <img src={displayName} alt="icon" />
    </CustomHeaderWrapper>
  );
};

const FreeDaysHeader = (props) => {
  const { displayName } = props;

  return (
    <CenterHeaderWrapper>
      <span className="text">{displayName}</span>
    </CenterHeaderWrapper>
  );
};

const MAX_EMPLOYEE_NAME = 15;

let EmployeeCell = (props) => {
  const { colDef, data, listEmployee } = props;

  const colName = colDef?.field;
  const empId = data[colName];
  const employeeName = _.find(listEmployee, { id: Number(empId) })?.name;

  // Prevent employee name not visible when it's too long.
  return employeeName?.length > MAX_EMPLOYEE_NAME ? (
    <Link to={`/settings/employee-detail?employee=${empId}`}>
      <span className="text">
        <Tooltip title={employeeName}>
          {employeeName?.slice(0, MAX_EMPLOYEE_NAME) + '...'}
        </Tooltip>
      </span>
    </Link>
  ) : (
    <Link to={`/settings/employee-detail?employee=${empId}`}>
      <span className="text">{employeeName}</span>
    </Link>
  );
};

EmployeeCell = connect((state) => ({
  listEmployee: state.EmployeeSetting.listEmployee
}))(EmployeeCell);

let ShiftTypeHeader = (props) => {
  const { displayName, listShiftType = [], listEmployeeReason } = props;
  const color =
    LIST_TAG_COLOR[
      listShiftType.findIndex((item) => item.id === +displayName) %
        LIST_TAG_COLOR.length
    ];

  const headerName =
    displayName === COUNTABLE_HEADER_NAME
      ? ''
      : _.find(listShiftType, { id: +displayName })?.name;
  const foundedReason =
    displayName === COUNTABLE_HEADER_NAME &&
    _.find(listEmployeeReason, { state: '3', default: true });

  return (
    <CustomHeaderWrapper>
      {displayName !== COUNTABLE_HEADER_NAME ? (
        <span
          style={{
            width: '1.5rem',
            height: '1.5rem',
            borderRadius: '50%',
            backgroundColor: color,
            marginRight: '0.7rem'
          }}
        />
      ) : (
        <Tooltip title={foundedReason?.name} placement="top">
          <img
            src={foundedReason?.icon}
            alt="icon"
            style={{ width: '1.5rem', height: '1.5rem' }}
          />
        </Tooltip>
      )}

      <span>{headerName}</span>
    </CustomHeaderWrapper>
  );
};

ShiftTypeHeader = connect((state) => ({
  listShiftType: state.ShiftTypeSetting.listShiftType,
  listEmployeeReason: state.EmployeeReason.listEmployeeReason
}))(ShiftTypeHeader);

export { RotationHeader, FreeDaysHeader, ShiftTypeHeader, EmployeeCell };
