import React, { useState, useEffect } from 'react';
import { Form, Button, Row, Input, Alert, Checkbox } from 'antd';
import { connect } from 'react-redux';
import { useNavigate as useHistory } from 'react-router-dom';
import { actSignIn } from 'redux/action/auth';
import { actGetTenantInfo } from 'redux/action/tenant';
import { useTranslation } from 'component/index';

import loginUrl from 'assets/login-img.svg';
import LanguageSwitcher from 'component/LanguageSwitcher';
import { SignInPageWrapper } from './index.style';

const FormItem = Form.Item;

const SignInPage = (props) => {
  const { token, tenantId } = props;
  const [errorMessage, setErrorMessage] = useState('');
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const { translate } = useTranslation();

  useEffect(() => {
    form.setFieldsValue({
      email: '',
      password: ''
    });
  }, []);

  useEffect(() => {
    if (token) {
      props.actGetTenantInfo({ tenantId }, (err) => {
        if (!err) {
          // history.push('/dashboard');
          history('/dashboard');
        }
      });
    }
  }, [token, tenantId]);

  const onSignIn = async (values) => {
    setIsButtonLoading(true);
    setErrorMessage('');

    props.actSignIn(
      {
        body: values
      },
      (err, res) => {
        if (res) {
          // Delay 2 seconds for the redux state to get updated correctly.
          if (res?.user?.tenant) {
            setTimeout(() => {
              // history.push('/dashboard');
              history('/dashboard');
            }, 2000);
          } else {
            setErrorMessage('Tenant is not assigned');
            setIsButtonLoading(false);
          }
        }

        if (err && err?.message) {
          setErrorMessage(err?.message);
          setIsButtonLoading(false);
        }
      }
    );
  };

  return (
    <SignInPageWrapper>
      <div className="language-switcher">
        <LanguageSwitcher />
      </div>

      <div className="img-wrapper">
        <img src={loginUrl} alt="" />
      </div>

      <div className="form-wrapper">
        <div className="heading">
          <div>{`${translate('signin.hello')},`}</div>
          <div>{translate('signin.welcomeBack')}</div>
        </div>

        <div className="form">
          <Form form={form} layout="vertical" onFinish={onSignIn}>
            <FormItem
              rules={[
                {
                  required: true,
                  message: translate('signin.emailOrMobileCannotEmpty')
                }
              ]}
              name="email"
              label={translate('signin.emailOrMobileNumber')}
            >
              <Input
                placeholder={translate('signin.enterYourEmail')}
                size="large"
                className="register-input"
              />
            </FormItem>

            <FormItem
              name="password"
              rules={[
                {
                  required: true,
                  message: translate('signin.passwordCannotEmpty')
                }
              ]}
              label={translate('signin.password')}
            >
              <Input.Password
                placeholder={translate('signin.enterYourPassword')}
                size="large"
                className="register-input"
              />
            </FormItem>

            {errorMessage && (
              <Row style={{ marginBottom: '2rem' }}>
                <Alert message={errorMessage} type="error" closable banner />
              </Row>
            )}

            <Row type="flex" justify="space-between">
              <Checkbox>{translate('signin.rememberMe')}</Checkbox>

              {/* <div className="forgot-password">
                {translate('signin.forgotPassword')}
              </div> */}
            </Row>

            <Row type="flex" justify="center">
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                loading={isButtonLoading}
                className="login-button"
              >
                {translate('signin.login')}
              </Button>
            </Row>
          </Form>
        </div>
      </div>
    </SignInPageWrapper>
  );
};

export default connect(
  (state) => ({
    token: state.App.token,
    tenantId: state?.App?.user?.tenant?.id
  }),
  { actSignIn, actGetTenantInfo }
)(SignInPage);
