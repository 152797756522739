import _ from 'lodash';

const EmployeeScheduleHeader = (
  translation,
  months,
  selectedMonthList,
  shiftType,
  employeeReason
) => {
  var Headers = [
    {
      headerName: '',
      field: 'id',
      pinned: true,
      lockPosition: true,
      width: 150,
      cellClass: 'grid-cell-centered',
      cellRenderer: 'EmployeeCell'
    }
  ];

  /*---------------------- Freedays Header ------------------------ */
  var _header = {
    headerName: 'Free Days',
    marryChildren: true,
    children: []
  };
  var count = 1;
  _.forEach(selectedMonthList, (month) => {
    var _children = {
      headerName: translation(`dashboardEmployeeSchedule.${months[month - 1]}`),
      icon: '',
      color: '',
      field: `free_days_${months[month - 1]}`,
      lockPosition: true,
      resizable: false,
      headerComponent: 'FreeDaysHeader',
      width: 80,
      columnGroupShow: count > 2 ? 'open' : 'close'
    };
    count += 1;
    _header['children'].push(_children);
  });
  Headers.push(_header);
  /* -----------------------End Freedays ---------------------- */

  /* ----------------------- Shift Types ---------------------- */
  _.forEach(shiftType || [], (shift) => {
    _header = {
      headerName: '',
      marryChildren: true,
      children: []
    };
    count = 1;
    _header['headerName'] = shift.name;
    _.forEach(selectedMonthList, (month) => {
      var _children = {
        headerName: translation(
          `dashboardEmployeeSchedule.${months[month - 1]}`
        ),
        icon: '',
        field: `${shift.name}_${months[month - 1]}`,
        // lockPosition: true,
        resizable: false,
        headerComponent: 'ShiftHeader',
        width: 80,
        columnGroupShow: count > 2 ? 'open' : 'close'
      };
      count += 1;
      _header['children'].push(_children);
    });
    Headers.push(_header);
  });
  /* -----------------------End Shift Types ------------------- */
  /* ----------------------- Employee Reason ---------------------- */
  _.forEach(employeeReason || [], (reason) => {
    _header = {
      headerName: '',
      marryChildren: true,
      children: []
    };
    count = 1;
    _header['headerName'] = reason.name;
    _.forEach(selectedMonthList, (month) => {
      var _children = {
        headerName: translation(
          `dashboardEmployeeSchedule.${months[month - 1]}`
        ),
        icon: `${reason.icon}`,
        color: '',
        field: `${reason.name}_${months[month - 1]}`,
        // lockPosition: true,
        resizable: false,
        headerComponent: 'EmployeeReasonHeader',
        width: 80,
        columnGroupShow: count > 2 ? 'open' : 'close'
      };
      count += 1;
      _header['children'].push(_children);
    });
    Headers.push(_header);
  });
  /* -----------------------End Employee Reason ------------------- */
  return Headers;
};
export { EmployeeScheduleHeader };
