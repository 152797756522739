import { PURGE } from 'redux-persist';
import {
  SET_DASHBOARD_DATA,
  SET_DASHBOARD_EMPLOYEE_CHART,
  SET_DASHBOARD_SHIFTS_CHART,
  GET_DASHBOARD_PREFERENCES,
  GET_DASHBOARD_WEEKDAY_ALLOCTION,
  SET_DASHBOARD_WEEKDAY_ALLOCTION
} from 'redux/type';

const initialState = {
  statistic: {},
  employeeSchedule: [],
  weeklySchedule: [],
  employeeChartData: {},
  shiftChartData: {},
  preferences: [],
  weekdayAllocation: [],
  weeklySchedule: []
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case SET_DASHBOARD_DATA:
      return {
        statistic: payload.statistic,
        employeeSchedule: payload.employeeSchedule,
        weeklySchedule: payload.weeklySchedule
      };

    case SET_DASHBOARD_EMPLOYEE_CHART:
      return {
        ...state,
        employeeChartData: payload
      };
    case SET_DASHBOARD_SHIFTS_CHART:
      return {
        ...state,
        shiftChartData: payload
      };

    case GET_DASHBOARD_PREFERENCES:
      return {
        ...state,
        preferences: payload
      };

    case GET_DASHBOARD_WEEKDAY_ALLOCTION:
      return {
        ...state,
        weekdayAllocation: payload
      };
    case SET_DASHBOARD_WEEKDAY_ALLOCTION:
      return {
        ...state,
        weeklySchedule: payload
      };
    default:
      return state;
  }
};

export default reducer;
