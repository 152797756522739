import { message } from 'antd';
import { takeLatest, all } from 'redux-saga/effects';
import {
  CREATE_SHIFT_TYPE,
  DELETE_SHIFT_TYPE_INFO,
  GET_SHIFT_TYPE_SETTING,
  SAVE_CREATE_SHIFT_TYPE,
  SAVE_GET_SHIFT_TYPE_SETTING,
  SAVE_UPDATE_SHIFT_TYPE_INFO,
  UPDATE_SHIFT_TYPE_INFO
} from 'redux/type';
import teamSettingAPI from 'redux/api/shiftTypeSetting';
import { createRequestSaga } from './common';

const getTeamSetting = createRequestSaga({
  request: teamSettingAPI.getTeamSetting,
  key: 'getTeamSetting',
  success: [
    (res) => ({
      type: SAVE_GET_SHIFT_TYPE_SETTING,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: [
    (err) => message.error('Error occured when get team setting')
  ]
});

const updateTeamSetting = createRequestSaga({
  request: teamSettingAPI.updateTeamSetting,
  key: 'updateTeamSetting',
  success: [
    (res) => ({
      type: SAVE_UPDATE_SHIFT_TYPE_INFO,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: []
});

const deleteTeamSetting = createRequestSaga({
  request: teamSettingAPI.deleteTeamSetting,
  key: 'deleteTeamSetting',
  functionSuccess: [],
  functionFailure: []
});

const createTeamSetting = createRequestSaga({
  request: teamSettingAPI.createTeamSetting,
  key: 'createTeamSetting',
  success: [
    (res) => ({
      type: SAVE_CREATE_SHIFT_TYPE,
      payload: res
    })
  ],
  functionSuccess: [],
  functionFailure: []
});

const saga = [
  function* fetchWatcher() {
    yield all([
      takeLatest(GET_SHIFT_TYPE_SETTING, getTeamSetting),
      takeLatest(UPDATE_SHIFT_TYPE_INFO, updateTeamSetting),
      takeLatest(DELETE_SHIFT_TYPE_INFO, deleteTeamSetting),
      takeLatest(CREATE_SHIFT_TYPE, createTeamSetting)
    ]);
  }
];
export default saga;
