import React, { useState, useEffect } from 'react';
import { CheckCircleTwoTone } from '@ant-design/icons';
import {
  Modal,
  Button,
  Radio,
  Row,
  message,
  Col,
  Space,
  Steps,
  Input,
  Form,
  Checkbox
} from 'antd';
import { connect } from 'react-redux';
import { actSendEmail } from 'redux/action/sendEmail';
import { useTranslation } from 'component/index';
import { MONTH_NAME, EMAIL_STATUS } from 'utils/constant';
import { statusEnum } from './EmployeeTable';

const { Step } = Steps;
const { TextArea } = Input;

const ASSIGNMENT_MODE = {
  CALENDAR: 'CALENDAR',
  EXCEL: 'EXCEL'
};

const ConfirmationDialog = (props) => {
  const {
    isModalVisible,
    onHideModal,
    onToggleModalSucess,
    selectedDate,
    tenantId,
    listEmployee,
    listSelectedEmployee,
    sendEmailStatus,
    currentStep
  } = props;

  const [mode, setMode] = useState(EMAIL_STATUS.PREFERENCES);
  const [assignmentMode, setAssignmentMode] = useState([
    ASSIGNMENT_MODE.CALENDAR,
    ASSIGNMENT_MODE.EXCEL
  ]);
  const [isButtonLoading, setButtonLoading] = useState(false);
  const { translate } = useTranslation();
  const [form] = Form.useForm();

  useEffect(() => {
    if (sendEmailStatus === EMAIL_STATUS.NOT_SENT) {
      setMode(EMAIL_STATUS.PREFERENCES);
    } else if (sendEmailStatus === EMAIL_STATUS.PREFERENCES) {
      setMode(EMAIL_STATUS.CONFIRM_PREFERENCES);
    } else if (sendEmailStatus === EMAIL_STATUS.CONFIRM_PREFERENCES) {
      setMode(EMAIL_STATUS.ASSIGNMENTS);
    } else if (sendEmailStatus === EMAIL_STATUS.ASSIGNMENTS) {
      setMode(EMAIL_STATUS.ASSIGNMENTS);
    }
  }, [sendEmailStatus]);

  const onSendEmail = async () => {
    const listEmpToSend = listEmployee.filter((emp) =>
      listSelectedEmployee.includes(emp.id)
    );

    setButtonLoading(true);
    const { extraNote } = await form.validateFields(['extraNote']);

    props.actSendEmail(
      {
        tenantId,
        body: {
          month: MONTH_NAME[+selectedDate.month - 1],
          year: +selectedDate.year,
          mode: mode,
          data: listEmpToSend.map((emp) => ({
            email: emp.email,
            id: emp.id
          })),
          calendar: assignmentMode.includes(ASSIGNMENT_MODE.CALENDAR),
          excel: assignmentMode.includes(ASSIGNMENT_MODE.EXCEL),
          extra_note: extraNote
        }
      },
      (err, res) => {
        setButtonLoading(false);

        if (res) {
          onHideModal();
          onToggleModalSucess();
        } else {
          message.error(translate('sendEmail.mailFail'));
        }
      }
    );
  };

  const renderSuccessStatus = (status) => {
    if (statusEnum.indexOf(sendEmailStatus) >= statusEnum.indexOf(status)) {
      return (
        <CheckCircleTwoTone
          twoToneColor="#52c41a"
          style={{ fontSize: '1.7rem', marginLeft: '1rem' }}
        />
      );
    }

    return null;
  };

  return (
    <Modal
      visible={isModalVisible}
      onCancel={onHideModal}
      footer={null}
      wrapClassName="custom-modal"
      title={translate('sendEmail.chooseEmailTemplate')}
      width="70rem"
    >
      <div style={{ marginBottom: '2rem' }}>
        <Steps current={currentStep} size="small" labelPlacement="horizontal">
          <Step title={translate('sendEmail.sendPreference')} />
          <Step title={translate('sendEmail.confirmPreference')} />
          <Step title={translate('sendEmail.sendAssignment')} />
        </Steps>
      </div>

      <Row
        gutter={8}
        justify={mode === EMAIL_STATUS.ASSIGNMENTS ? 'space-between' : 'start'}
        style={{ padding: '0 1rem' }}
      >
        <Col span={10}>
          <Radio.Group
            onChange={(e) => setMode(e.target.value)}
            value={mode}
            size="large"
            style={{ width: '100%' }}
          >
            <Space direction="vertical">
              <Radio value={EMAIL_STATUS.PREFERENCES}>
                {translate('sendEmail.sendPreference')}
                {renderSuccessStatus(EMAIL_STATUS.PREFERENCES)}
              </Radio>

              <Radio value={EMAIL_STATUS.CONFIRM_PREFERENCES}>
                {translate('sendEmail.confirmPreference')}
                {renderSuccessStatus(EMAIL_STATUS.CONFIRM_PREFERENCES)}
              </Radio>

              <Radio value={EMAIL_STATUS.ASSIGNMENTS}>
                {translate('sendEmail.sendAssignment')}
              </Radio>
            </Space>
          </Radio.Group>
        </Col>

        {mode === EMAIL_STATUS.ASSIGNMENTS && (
          <Col span={12}>
            <Checkbox.Group
              onChange={(value) => setAssignmentMode(value)}
              value={assignmentMode}
              size="large"
              style={{ width: '100%' }}
            >
              <Space direction="vertical">
                <Checkbox value={ASSIGNMENT_MODE.CALENDAR}>
                  {translate('preference.withCalendar')}
                </Checkbox>

                <Checkbox value={ASSIGNMENT_MODE.EXCEL}>
                  {translate('preference.attachTeamAssignmentExell')}
                </Checkbox>
              </Space>
            </Checkbox.Group>
          </Col>
        )}
      </Row>

      <Row justify="center" style={{ marginTop: '2rem' }}>
        <Col span={23}>
          <Form layout="vertical" form={form}>
            <Form.Item label={translate('sendEmail.note')} name="extraNote">
              <TextArea
                rows={4}
                placeholder={translate('sendEmail.writeSomeNote')}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <Row justify="center" style={{ marginTop: '3rem' }}>
        <Button
          type="primary"
          className="custom-button"
          size="large"
          style={{ height: '4.7rem', padding: '1rem 3rem' }}
          onClick={onSendEmail}
          loading={isButtonLoading}
        >
          {translate('send')}
        </Button>
      </Row>
    </Modal>
  );
};

export default connect(
  (state) => ({
    selectedDate: state.SendEmail.selectedDate,
    listSelectedEmployee: state.SendEmail.listSelectedEmployee,
    tenantId: state?.App?.user?.tenant?.id,
    listEmployee: state.EmployeeSetting.listEmployee,
    sendEmailStatus: state.SendEmail.sendEmailStatus
  }),
  { actSendEmail }
)(ConfirmationDialog);
