import {
  GET_EMPLOYEE_SETTING,
  UPDATE_EMPLOYEE_INFO,
  DELETE_EMPLOYEE_INFO,
  SAVE_DELETE_EMPLOYEE_INFO,
  CREATE_EMPLOYEE,
  SET_SELECTED_EMPLOYEE_SETTING,
  SET_MODAL_EMPLOYEE_SETTING_VISIBLE,
  GET_EMPLOYEE_SHIFTS,
  UPDATE_EMPLOYEE_INACTIVE,
  SEARCH_EMPLOYEE,
  SEARCH_EMPLOYEE_ANTI_PATTERN,
  GET_EMPLOYEE_BY_ID
} from 'redux/type';

export const actGetEmployeeSetting = (...args) => ({
  type: GET_EMPLOYEE_SETTING,
  args
});

export const actUpdateEmployeeInfo = (...args) => ({
  type: UPDATE_EMPLOYEE_INFO,
  args
});

export const actDeleteEmployeeInfo = (...args) => ({
  type: DELETE_EMPLOYEE_INFO,
  args
});

export const actSaveDeleteEmployeeInfo = (payload) => ({
  type: SAVE_DELETE_EMPLOYEE_INFO,
  payload
});

export const actSetSelectedEmployeeSetting = (payload) => ({
  type: SET_SELECTED_EMPLOYEE_SETTING,
  payload
});

export const actSetModalEmployeeSettingVisible = (payload) => ({
  type: SET_MODAL_EMPLOYEE_SETTING_VISIBLE,
  payload
});

export const actCreateEmployee = (...args) => ({
  type: CREATE_EMPLOYEE,
  args
});

export const actSearchEmployee = (payload) => ({
  type: SEARCH_EMPLOYEE,
  payload
});

export const actGetEmployeeShifts = (...args) => ({
  type: GET_EMPLOYEE_SHIFTS,
  args
});

export const actUpdateEmployeeToInactive = (...args) => ({
  type: UPDATE_EMPLOYEE_INACTIVE,
  args
});

export const actSearchEmployeeAntiPattern = (...args) => ({
  type: SEARCH_EMPLOYEE_ANTI_PATTERN,
  args
});

export const actGetEmployeeById = (...args) => ({
  type: GET_EMPLOYEE_BY_ID,
  args
});
