import { useEffect, useMemo } from 'react';
import { Modal, Form, message, InputNumber, Select } from 'antd';
import _ from 'lodash';
import { connect } from 'react-redux';
import { useTranslation } from 'component/index';
import {
  actSetModalBreakPairVisible,
  actSetSelectedBreakPair,
  actUpdateShiftTypeInfo,
  actSetSelectedShiftTypeSetting
} from 'redux/action/shiftTypeSetting';
import _default from 'antd/lib/input-number';

const { Option } = Select;

const ModalAddShiftBreak = (props) => {
  const {
    modalBreakPairVisible,
    selectedBreakPair = {},
    tenantId,
    selectedShiftTypeSetting,
    listShiftType
  } = props;

  const [form] = Form.useForm();
  const { translate } = useTranslation();

  useEffect(() => {
    const { breakTime = null, shiftTypeId } = selectedBreakPair;

    form.setFieldsValue({
      breakTime: breakTime,
      spotId: shiftTypeId
    });
  }, [selectedBreakPair]);

  const isCreateMode = useMemo(() => {
    return _.isEmpty(selectedBreakPair);
  }, [selectedBreakPair]);

  const onHideModal = () => {
    props.actSetModalBreakPairVisible(false);
    props.actSetSelectedBreakPair({});
    props?.actSetSelectedShiftTypeSetting({});
  };

  const onSubmitForm = async () => {
    try {
      let listBreakPair;
      if (isCreateMode) {
        listBreakPair = selectedShiftTypeSetting?.break_pairs || [];
      } else {
        listBreakPair =
          _.find(listShiftType, {
            id: selectedBreakPair?.shiftTypeId
          })?.break_pairs || [];
      }

      let indexOfBreakPair = listBreakPair?.length;
      if (!isCreateMode) {
        indexOfBreakPair = _.findIndex(listBreakPair, {
          id: selectedBreakPair?.id
        });
      }

      // Map spotRight to its id before sending request.
      listBreakPair = listBreakPair?.map((pair) => ({
        break_time: pair?.break_time,
        spot_right: pair?.spot_right?.id
      }));

      // Insert new/updated breakTime to the right position.
      const { breakTime: break_time, spotId: spot_right } =
        await form.validateFields(['breakTime', 'spotId']);
      listBreakPair?.splice(indexOfBreakPair, 1, { break_time, spot_right });

      props.actUpdateShiftTypeInfo(
        {
          tenantId,
          spotId: selectedBreakPair?.shiftTypeId || selectedShiftTypeSetting.id,
          body: {
            break_pairs: listBreakPair
          }
        },
        (err) => {
          if (!err) {
            onHideModal();
            message.success(translate('shiftType.updateSuccess'));
          } else {
            message.error(translate('shiftType.updateFail'));
          }
        }
      );
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <Modal
      title={
        _.isEmpty(selectedBreakPair)
          ? translate('shiftType.createBreakPair')
          : translate('shiftType.updateBreakPair')
      }
      visible={modalBreakPairVisible}
      cancelText={translate('cancel')}
      okText={
        _.isEmpty(selectedBreakPair) ? translate('create') : translate('update')
      }
      maskClosable={false}
      onOk={onSubmitForm}
      onCancel={onHideModal}
      forceRender={true}
      destroyOnClose={true}
      getContainer={false}
      style={{ top: '1rem' }}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          label={translate('shiftType.shiftType')}
          rules={[
            {
              required: true,
              message: `${translate('shiftType.shiftType')} ${translate(
                'isRequired'
              )}`
            }
          ]}
          name="spotId"
        >
          <Select placeholder={translate('shiftType.selectAShiftType')}>
            {listShiftType
              ?.filter(
                (shiftType) => shiftType?.id !== selectedShiftTypeSetting?.id
              )
              .map((shiftType, index) => (
                <Option key={index} value={shiftType?.id}>
                  {shiftType?.name}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={translate('shiftType.breakTime')}
          rules={[
            {
              required: true,
              message: `${translate('shiftType.breakTime')} ${translate(
                'isRequired'
              )}`
            },
            {
              message: translate('shiftType.breakTimeError'),
              validator: (rule, value) => {
                const shiftTypeInfo = _.first(
                  listShiftType?.filter(
                    (item) => item?.id === selectedBreakPair?.shiftTypeId
                  )
                );

                if (shiftTypeInfo?.break_time === value) {
                  return Promise.reject();
                }

                return Promise.resolve();
              }
            }
          ]}
          name="breakTime"
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default connect(
  (state) => ({
    selectedBreakPair: state.ShiftTypeSetting.selectedBreakPair,
    selectedShiftTypeSetting: state.ShiftTypeSetting.selectedShiftTypeSetting,
    modalBreakPairVisible: state.ShiftTypeSetting.modalBreakPairVisible,
    listShiftType: state.ShiftTypeSetting.listShiftType,
    tenantId: state?.App?.user?.tenant?.id
  }),
  {
    actSetModalBreakPairVisible,
    actSetSelectedBreakPair,
    actUpdateShiftTypeInfo,
    actSetSelectedShiftTypeSetting
  }
)(ModalAddShiftBreak);
