import { all, takeEvery } from 'redux-saga/effects';
import { SEND_EMAIL, REQUEST_A_DEMO } from 'redux/type';
import sendEmailAPI from 'redux/api/sendEmail';
import { createRequestSaga } from './common';

const sendPreferenceEmail = createRequestSaga({
  request: sendEmailAPI.sendPreferenceEmail,
  key: 'sendPreferenceEmail',
  success: [],
  functionSuccess: [],
  functionFailure: []
});

const requestADemo = createRequestSaga({
  request: sendEmailAPI.requestADemo,
  key: 'requestADemo',
  success: [],
  functionSuccess: [],
  functionFailure: []
});

const saga = [
  function* fetchWatcher() {
    yield all([
      takeEvery(SEND_EMAIL, sendPreferenceEmail),
      takeEvery(REQUEST_A_DEMO, requestADemo)
    ]);
  }
];

export default saga;
