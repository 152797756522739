import styled from 'styled-components';

const SkillViewCalendarWrapper = styled.div`
  height: 100%;
  overflow-y: hidden;

  .day-cell {
    color: var(--color-text);
    font-size: 1.4rem;
  }

  .light-gray-cell {
    background-color: rgba(0, 0, 0, 0.06) !important;
  }

  .holiday {
    background-color: rgba(230, 129, 150, 0.1) !important;
  }
`;

const CustomCellWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .list-skill {
    display: flex;
    flex-direction: column;
    width: 80%;

    .skill-item {
      width: 100%;
      height: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;

      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }

      .tag-add-item {
        font-size: 10px;
      }

      .skill-string {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 1px;
      }
    }
  }
`;

export { SkillViewCalendarWrapper, CustomCellWrapper };
