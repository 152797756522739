import moment from 'moment';
import _ from 'lodash';
import { useEffect, useState, Fragment } from 'react';
import {
  Modal,
  Form,
  Select,
  TimePicker,
  Button,
  Row,
  Col,
  Divider,
  message
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useTranslation } from 'component/index';
import RequiredSkill, { RequiredSkillTag } from 'component/RequiredSkill';
import {
  actSetModalRotationSettingVisible,
  actSetSelectedRotationSetting,
  actCreateRotation,
  actUpdateRotationInfo
} from 'redux/action/rotationSetting';
import { RotationModalWrapper } from './style';

const { Option } = Select;

const RotationSettingModal = (props) => {
  const {
    modalVisible,
    selectedRotationSetting,
    tenantId,
    listShiftType,
    listEmployee,
    listShiftGroup
  } = props;

  const [form] = Form.useForm();
  const { translate } = useTranslation();
  const [listSkillSet, setListSkillSet] = useState([]);
  const [listPreferredEmployee, setListPreferredEmployee] = useState({});

  useEffect(() => {
    setListPreferredEmployee(
      _.reduce(
        selectedRotationSetting?.preferred_employees,
        (result, value, index) => ({
          ...result,
          [index]: value
        }),
        {}
      )
    );
  }, [selectedRotationSetting]);

  useEffect(() => {
    const {
      skills = [],
      start_time,
      end_time,
      start_day_offset,
      end_day_offset,
      spot,
      shift_group
    } = selectedRotationSetting;

    if (!_.isEmpty(selectedRotationSetting)) {
      form.setFieldsValue({
        start_time: moment(start_time, 'HH:mm'),
        end_time: moment(end_time, 'HH:mm'),
        start_day_offset,
        end_day_offset,
        spot,
        shift_group: shift_group?.id
      });

      setListSkillSet(skills);
    } else {
      form.resetFields();
      setListSkillSet([[[]]]);

      const defaultShiftGroup = _.find(listShiftGroup, { name: 'default' });
      form.setFieldValue('shift_group', defaultShiftGroup?.id);
    }
  }, [selectedRotationSetting]);

  const onHideModal = () => {
    props.actSetModalRotationSettingVisible(false);
    props.actSetSelectedRotationSetting({});
  };

  const onSubmitForm = async () => {
    try {
      const {
        start_time,
        end_time,
        start_day_offset,
        end_day_offset,
        spot,
        shift_group
      } = await form.validateFields([
        'start_time',
        'end_time',
        'start_day_offset',
        'end_day_offset',
        'spot',
        'shift_group'
      ]);

      const rotationInfo = {
        start_time: start_time.format('HH:mm'),
        end_time: end_time.format('HH:mm'),
        start_day_offset,
        end_day_offset,
        spot,
        skills: listSkillSet,
        version: 0,
        tab: props.tabId,
        preferred_employees: listSkillSet?.map(
          (value, index) => listPreferredEmployee[index] || null
        ),
        shift_group: shift_group || null
      };

      if (_.isEmpty(selectedRotationSetting)) {
        props.actCreateRotation(
          {
            tenantId,
            body: rotationInfo
          },
          (err, res) => {
            if (!err) {
              onHideModal();
              props.onSuccess();
              message.success(translate('rotation.createInfoSuccess'));
            } else {
              message.error(err.message);
            }
          }
        );
      } else {
        props.actUpdateRotationInfo(
          {
            tenantId,
            rotationId: selectedRotationSetting.id,
            body: rotationInfo
          },
          (err) => {
            if (!err) {
              onHideModal();
              props.onSuccess();
              message.success(translate('rotation.updateInfoSuccess'));
            } else {
              message.error(err.message);
            }
          }
        );
      }
    } catch (err) {}
  };

  const onChangeStartTime = (time) => {
    form.setFieldsValue({
      start_time: time
    });
  };

  const onChangeEndTime = (time) => {
    form.setFieldsValue({
      end_time: time
    });
  };

  const onSelectPreferredEmployee = (indexOfGroup, value) => {
    const tempList = { ...listPreferredEmployee };
    tempList[indexOfGroup?.toString()] = value;
    setListPreferredEmployee(tempList);
  };

  const renderListSkillSet = () => {
    return listSkillSet.map((skillSetRow, indexOfGroup) => (
      <Fragment key={indexOfGroup}>
        <Divider
          style={{
            marginBottom: '1rem',
            marginTop: '1.5rem',
            fontSize: '1.4rem'
          }}
          orientation="left"
        >
          {`Employee ${indexOfGroup + 1}`}
        </Divider>

        <Row
          key={indexOfGroup}
          justify="space-between"
          className="skill-set-row"
          gutter={16}
        >
          <Col span={18}>
            <RequiredSkill
              indexOfGroup={indexOfGroup}
              setListSkillSet={setListSkillSet}
              listSkillSet={listSkillSet}
              skillSetRow={skillSetRow}
              onCloneSkillSet={() => onCloneSkillSet(skillSetRow)}
            />
          </Col>

          <Col span={6}>
            {/* preferred employee */}
            <Form.Item label={translate('rotation.preferredEmployee')}>
              <Select
                placeholder={translate('assignment.selectAnEmployee')}
                showSearch={true}
                onChange={(value) =>
                  onSelectPreferredEmployee(indexOfGroup, value)
                }
                allowClear
                style={{ width: '15rem' }}
                value={listPreferredEmployee[indexOfGroup?.toString()]}
              >
                {_.map(listEmployee, (emp, index) => (
                  <Option key={index} value={emp.id}>
                    {emp.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Fragment>
    ));
  };

  const onAddSkillGroup = () => {
    const temListSkillSet = _.cloneDeep(listSkillSet);
    temListSkillSet.push([[]]);
    setListSkillSet([...temListSkillSet]);
  };

  const onCloneSkillSet = (skillSetRow) => {
    const temListSkillSet = _.cloneDeep(listSkillSet);
    temListSkillSet.push(skillSetRow);
    setListSkillSet([...temListSkillSet]);
  };

  return (
    <RotationModalWrapper>
      <Modal
        title={
          _.isEmpty(selectedRotationSetting)
            ? translate('rotation.createRotation')
            : translate('rotation.updateRotation')
        }
        visible={modalVisible}
        cancelText={translate('cancel')}
        okText={
          _.isEmpty(selectedRotationSetting)
            ? translate('create')
            : translate('update')
        }
        maskClosable={false}
        onOk={onSubmitForm}
        onCancel={onHideModal}
        forceRender={true}
        destroyOnClose={true}
        getContainer={false}
        style={{ top: '1rem' }}
        width="80rem"
      >
        <Form layout="vertical" form={form}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={translate('rotation.startTime')}
                rules={[
                  {
                    required: true,
                    message: `${translate('rotation.startTime')} ${translate(
                      'isRequired'
                    )}`
                  }
                ]}
                name="start_time"
              >
                <TimePicker
                  format="HH:mm"
                  placeholder={translate('rotation.pickATime')}
                  minuteStep={30}
                  style={{ width: '100%' }}
                  onSelect={onChangeStartTime}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={translate('rotation.endTime')}
                rules={[
                  {
                    required: true,
                    message: `${translate('rotation.endTime')} ${translate(
                      'isRequired'
                    )}`
                  }
                ]}
                name="end_time"
              >
                <TimePicker
                  format="HH:mm"
                  placeholder={translate('rotation.pickATime')}
                  minuteStep={30}
                  style={{ width: '100%' }}
                  onSelect={onChangeEndTime}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label={translate('rotation.shiftType')}
                name="spot"
                rules={[
                  {
                    required: true,
                    message: `${translate('rotation.shiftType')} ${translate(
                      'isRequired'
                    )}`
                  }
                ]}
              >
                <Select tagRender={RequiredSkillTag}>
                  {listShiftType.map((shiftType, index) => (
                    <Option key={index} value={shiftType.id}>
                      {shiftType.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                label={translate('rotation.shiftGroup')}
                name="shift_group"
                rules={[
                  {
                    required: true,
                    message: `${translate('rotation.shiftGroup')} ${translate(
                      'isRequired'
                    )}`
                  }
                ]}
              >
                <Select>
                  {listShiftGroup.map((shiftGroup, index) => (
                    <Option key={index} value={shiftGroup?.id}>
                      {shiftGroup?.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item label={translate('rotation.skills')}>
            {renderListSkillSet()}

            <Button
              type="primary"
              icon={<PlusOutlined />}
              style={{ marginTop: '2rem' }}
              onClick={onAddSkillGroup}
            />
          </Form.Item>
        </Form>
      </Modal>
    </RotationModalWrapper>
  );
};

export default connect(
  (state) => ({
    selectedRotationSetting: state.RotationSetting.selectedRotationSetting,
    modalVisible: state.RotationSetting.modalVisible,
    listShiftType: state.ShiftTypeSetting.listShiftType,
    tenantId: state?.App?.user?.tenant?.id,
    listEmployee: state.EmployeeSetting.listEmployee,
    listShiftGroup: state.ShiftGroupSetting.listShiftGroup
  }),
  {
    actSetModalRotationSettingVisible,
    actSetSelectedRotationSetting,
    actCreateRotation,
    actUpdateRotationInfo
  }
)(RotationSettingModal);
