import React, { useState } from 'react';
import { Button, Table, message } from 'antd';
import { connect } from 'react-redux';
import { actSubmitWizard } from '../../redux/action/wizard';
import { useTranslation } from 'component/index';
import './preview.css';

const Preview = (props) => {
  const { prev, next, setupData, signupData, tenant, userId } = props;
  const [isSubmitLoading, setLoading] = useState(false);
  const { translate } = useTranslation();

  const finalSubmit = () => {
    setLoading(true);
    let rotationData = [];
    setupData.shift_data.forEach((item, index) => {
      item.shift_info.forEach((shiftInfo, innerIndex) => {
        rotationData = [
          ...rotationData,
          {
            start_time: shiftInfo.start_time,
            end_time: shiftInfo.end_time,
            shift_type: item.shift_name,
            no_of_employees: shiftInfo.noOfEmployees
          }
        ];
      });
    });

    let finalWizardData = {
      tenant_name: tenant.name,
      schedule_start_date: setupData.schedule_start_date,
      user_first_name: signupData.first_name,
      user_last_name: signupData.last_name,
      user_email: signupData.email,
      old_password: signupData.old_password,
      new_password: signupData.new_password,
      user_id: userId,
      rotations: rotationData
    };
    props.actSubmitWizard(
      {
        tenantId: tenant.id,
        body: finalWizardData
      },
      (err, res) => {
        if (res) {
          setLoading(false);
          next();
        }
        if (err) {
          setLoading(false);
          message.error(
            err.message.length < 100 ? err.message : 'Internal Server Error'
          );
        }
      }
    );
  };

  const columns = [
    {
      title: translate('wizard.preview.shift'),
      key: 'shift',
      render: (val, test, index) => index + 1
    },
    {
      title: translate('wizard.setup.startShift'),
      dataIndex: 'start_time',
      key: 'start_shift'
    },
    {
      title: translate('wizard.setup.endShift'),
      dataIndex: 'end_time',
      key: 'end_shift'
    },
    {
      title: translate('wizard.preview.employees'),
      dataIndex: 'noOfEmployees',
      key: 'employees'
    }
  ];
  return (
    <div>
      {setupData.shift_data.map((item, index) => {
        return (
          <>
            <div key={index} className="shift_name">{`${translate(
              'wizard.preview.shiftType'
            )}- ${item.shift_name}`}</div>
            <Table
              dataSource={item.shift_info}
              columns={columns}
              pagination={false}
            />
          </>
        );
      })}
      <Button type="primary" style={{ marginTop: '10px' }} onClick={prev}>
        {translate('wizard.prev')}
      </Button>
      <Button
        type="primary"
        style={{ marginTop: '10px', float: 'right' }}
        onClick={finalSubmit}
        loading={isSubmitLoading}
      >
        {translate('wizard.Submit')}
      </Button>
    </div>
  );
};

export default connect(
  (state) => ({
    setupData: state.Wizard.setup,
    signupData: state.Wizard.signup,
    tenant: state.App.user.tenant,
    userId: state.App.user.id
  }),
  {
    actSubmitWizard
  }
)(Preview);
