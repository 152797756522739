import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'component/index';
import { connect } from 'react-redux';
import {
  actSetStatisticRow,
  actGetAssignmentData,
  actSetRefreshAssignment
} from 'redux/action/grid';
import { actGetEmployeeSetting } from 'redux/action/employeeSetting';
import { actGetShiftTypeSetting } from 'redux/action/shiftTypeSetting';
import { actGetRotationSetting } from 'redux/action/rotationSetting';
import { actGetEmployeeReasonSetting } from 'redux/action/employeeReason';
import { getRowStatistic } from 'component/EmployeeGrid/util';
import { actUpdateProfileDetails } from 'redux/action/profile';
import {
  CustomHeaderGroup,
  EmployeeHeader,
  ShiftPickerHeader,
  ShiftPickerCell
} from './CustomGridComponent';
import defaultHeader from './defaultHeader';
import EditableCell from './EditableCell';
import _ from 'lodash';

export const ROW_HEIGHT = 120;
export const FIX_ROW_HEIGHT = 40;
let columnMoveTimeout = null;

const AssignmentView = (props) => {
  const {
    listData,
    listColumn,
    dataByColumn,
    statisticRow,
    spotId,
    tenantId,
    isStatisticCollapse,
    listShiftType,
    globalTime,
    listTimeline,
    shouldRefresh,
    preferences,
    user
  } = props;
  const [gridApi, setGridApi] = useState(null);
  const [columnApi, setColumnApi] = useState(null);
  const { translate, locale } = useTranslation();
  const [header, setHeader] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const gridColumnOrder = preferences?.gridColumnOrder || [];

  useEffect(() => {
    if (columnApi && !isLoading) {
      const numFixedColumns = 3 + listShiftType.length; // first 3 columns are for time.
      columnApi?.moveColumns(gridColumnOrder, numFixedColumns);
    }
  }, [gridColumnOrder, columnApi, isLoading, listShiftType]);

  useEffect(() => {
    if (gridApi && globalTime) {
      onGetGridData();
    }
  }, [spotId, globalTime, tenantId, gridApi, shouldRefresh]);

  useEffect(() => {
    props.actGetEmployeeReasonSetting({ tenantId });
    props.actGetShiftTypeSetting({ tenantId });
    props.actGetEmployeeSetting({ tenantId });
    props.actGetRotationSetting({
      tenantId,
      year: moment(+globalTime).year(),
      month: moment(+globalTime).month() + 1
    });
  }, [tenantId]);

  useEffect(() => {
    props.actSetStatisticRow(getRowStatistic(dataByColumn, listTimeline));
  }, [dataByColumn, listColumn, listTimeline]);

  useEffect(() => {
    if (gridApi) {
      if (!isStatisticCollapse) {
        gridApi.setPinnedTopRowData(statisticRow);
      } else {
        gridApi.setPinnedTopRowData([]);
      }
    }
  }, [isStatisticCollapse, gridApi, statisticRow]);

  useEffect(() => {
    // group employee header by team
    if (listShiftType.length > 0) {
      const listTeam = _.uniq(_.map(_.sortBy(listColumn, 'team'), 'team'));
      const listEmployeeHeader = _.map(listTeam, (team, index) => {
        let listEmployeeInTeam = listColumn.filter((emp) => emp.team === team);
        listEmployeeInTeam = _.sortBy(listEmployeeInTeam, 'rank');

        return {
          headerName: team,
          headerClass:
            index % 2 === 0 ? 'team-header--even' : 'team-header--odd',
          pinned: false,
          lockPosition: true,
          marryChildren: true,
          children: listEmployeeInTeam.map((emp) => ({
            headerName: emp?.name?.toString(),
            field: emp.id?.toString(),
            columnGroupShow: 'open',
            width: 150,
            cellRenderer: 'EditableCell',
            headerComponent: 'EmployeeHeader',
            pinned: false,
            lockPosition: false
          }))
        };
      });

      const tempHeader = [
        ...defaultHeader(translate, listShiftType),
        ...listEmployeeHeader
      ];

      setHeader(tempHeader);
    }
  }, [listColumn, locale, listShiftType]);

  const onGetRowHeight = (params) =>
    params?.data?.date ? ROW_HEIGHT : FIX_ROW_HEIGHT;

  const onGetGridData = () => {
    gridApi.showLoadingOverlay();
    setIsLoading(true);

    props.actGetAssignmentData(
      {
        tenantId,
        month: moment(+globalTime).month() + 1,
        year: moment(+globalTime).year()
      },
      (err, res) => {
        setIsLoading(false);
        gridApi.hideOverlay();

        if (shouldRefresh) {
          props.actSetRefreshAssignment(false);
        }
      }
    );
  };

  const onColumnMoved = () => {
    const listOrderColumn = columnApi?.getAllGridColumns();

    const columnOrder = listOrderColumn
      .filter(
        (colInfo) => colInfo?.colDef?.headerComponent === 'EmployeeHeader'
      )
      .map((colInfo) => {
        return colInfo.getColId();
      });

    if (columnMoveTimeout) {
      clearTimeout(columnMoveTimeout);
      columnMoveTimeout = null;
    } else {
      columnMoveTimeout = setTimeout(() => {
        props.actUpdateProfileDetails(
          {
            tenantId,
            userId: user.id,
            body: {
              preferences: { ...preferences, gridColumnOrder: columnOrder }
            }
          },
          (err) => {
            if (err) {
              message.error(err.message);
            }
          }
        );
      }, 200);
    }
  };

  const onGridReady = (params) => {
    setGridApi(params?.api);
    setColumnApi(params?.columnApi);
  };

  return (
    <AgGridReact
      maintainColumnOrder={true}
      onGridReady={onGridReady}
      columnDefs={header}
      rowData={isLoading ? [] : listData}
      onColumnMoved={onColumnMoved}
      gridOptions={{
        suppressRowTransform: true,
        suppressDragLeaveHidesColumns: true,
        suppressScrollOnNewData: true, // https://stackoverflow.com/questions/59360133/ag-grid-resets-scroll-on-new-datasource
        overlayLoadingTemplate:
          '<span class="ag-overlay-loading-center">Please wait while your data is loading</span>',
        rowHeight: ROW_HEIGHT // should not remove this line, because it will make Date column can not display properly in a span row
      }}
      frameworkComponents={{
        CustomHeaderGroup,
        EditableCell,
        EmployeeHeader,
        ShiftPickerHeader,
        ShiftPickerCell
      }}
      defaultColDef={{
        wrapText: true,
        resizable: false,
        sortable: true
      }}
      getRowHeight={onGetRowHeight}
    />
  );
};

export default connect(
  (state) => ({
    listData: state.Grid.listData,
    dataByColumn: state.Grid.dataByColumn,
    statisticRow: state.Grid.statisticRow,
    spotId: state.Grid.spotId,
    listTimeline: state.Grid.listTimeline,
    tenantId: state?.App?.user?.tenant?.id,
    isStatisticCollapse: state.Grid.isStatisticCollapse,
    listShiftType: state.ShiftTypeSetting.listShiftType,
    globalTime: state.App.globalTime,
    listColumn: state.EmployeeSetting.listEmployee,
    shouldRefresh: state.Grid.shouldRefresh,
    preferences: state?.Profile?.data?.preferences,
    user: state?.App?.user
  }),
  {
    actGetShiftTypeSetting,
    actGetEmployeeReasonSetting,
    actSetStatisticRow,
    actGetRotationSetting,
    actGetEmployeeSetting,
    actGetAssignmentData,
    actSetRefreshAssignment,
    actUpdateProfileDetails
  }
)(AssignmentView);
