import { useState } from 'react';
import { Modal, Radio } from 'antd';
import { useTranslation } from 'component/index';

import exportByShiftTypeImg from 'assets/exportByShiftType.png';
import exportByEmployeeName from 'assets/exportByEmployeeName.png';

import { ModalExportWrapper } from './style';

export const EXPORT_TYPE = {
  BY_NAME: 'name',
  BY_VALUE: 'value'
};

const ModalExport = (props) => {
  const { isModalOpen, onCloseModal, onExportToExcel } = props;
  const { translate } = useTranslation();
  const [exportType, setExportType] = useState(EXPORT_TYPE.BY_NAME);

  const onChangeExportType = (event) => setExportType(event?.target?.value);

  return (
    <Modal
      title={translate('assignment.exportExcel')}
      visible={isModalOpen}
      onCancel={onCloseModal}
      onOk={() => onExportToExcel(exportType)}
      okText={translate('assignment.export')}
      cancelText={translate('cancel')}
      width="80rem"
    >
      <Radio.Group onChange={onChangeExportType} value={exportType}>
        <ModalExportWrapper>
          <div className="group">
            <img src={exportByEmployeeName} alt="logo" />
            <Radio value={EXPORT_TYPE.BY_NAME}>
              {translate('assignment.exportGroupByName')}
            </Radio>
          </div>

          <div className="group">
            <img src={exportByShiftTypeImg} alt="logo" />
            <Radio value={EXPORT_TYPE.BY_VALUE}>
              {translate('assignment.exportGroupByShiftType')}
            </Radio>
          </div>
        </ModalExportWrapper>
      </Radio.Group>
    </Modal>
  );
};

export default ModalExport;
